import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-page-header-notification-bar',
  templateUrl: './page-header-notification-bar.component.html',
  styleUrls: ['./page-header-notification-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageHeaderNotificationBarComponent {
  @Input() headerTitle: string = '';

  @Input() barText: string = '';
}
