<app-page-header-notification-bar
  *ngIf="text || subtext"
  [headerTitle]="text"
  [barText]='subtext'
/>
<div class="fin-items-wrapper">
  <div class="col-sm-12 col-md-12">
  </div>
  <div class="app-page-title" style="margin: 0">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div class="page-title-icon">
          <i class="pe-7s-box2 icon-gradient bg-mean-fruit"></i>
        </div>
        <div>
          Финансовые статьи
        </div>

        <app-mp-video-play [videoLink]="getVideoLinkByPage('fin/settings/items')" />

      </div>
      <div class="page-title-actions">
        <div class="d-inline-block" ngbDropdown placement="bottom-right">
          <button class="mb-2 btn btn-success" ngbDropdownToggle type="button">
            Добавить статью
          </button>
          <div class="dropdown-menu-right-rounded" ngbDropdownMenu>
            <button (click)="openModal(item, 'receipt')"
                    class="dropdown-item"
                    style="color:green">
              Поступления
            </button>
            <button (click)="openModal(item, 'order')"
                    class="dropdown-item"
                    style="color:red">
              Списания
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <p-tree [draggableNodes]="false"
            [droppableNodes]="false"
            [value]="finItemsMain"
            class="w-full md:w-30rem"
            draggableScope="self"
            droppableScope="self">
      <ng-template let-node
                   pTemplate="default">
        <div class="node-item">
          <div class="item-block">
            <ng-container *ngIf="checkIsNodeWithChildren(node); else itemNode">
              <p class="title-part">
                {{ node.label }}
              </p>
            </ng-container>
            <ng-template #itemNode>
              <div class="block-part-l">
                <p class="title-part">
                  {{ node.label }} {{ getNameForNode(node) }}
                  <span [innerHTML]="(node && node?.pnl_name ? node?.pnl_name : '')"
                        class="pnl-tag"></span>
                </p>
              </div>

              <!--
              d_l: 1 - p_l: 0 => [ДДС]
              d_l: 1 - p_l: 1 => [ДДС/ОПИ] + pl_name (серым текстом)
              d_l: 0 - p_l: 1 => [ОПИ] + pl_name (серым текстом)
              -->
            </ng-template>
            <div *ngIf="!node.children"
                 class="manage-part">
              <i *ngIf="+node.is_visible === 1"
                 aria-hidden="true"
                 class="fa fa-eye-slash">
              </i>
              <i *ngIf="!node.is_block"
                 (click)="modalEditItem(node)"
                 class="lnr-pencil">
              </i>
              <i *ngIf="!node.is_block"
                 (click)="deleteItem(node)"
                 [ngClass]="{'disable-btn' : +node.is_block}"
                 class="lnr-trash">
              </i>
            </div>
          </div>
        </div>
      </ng-template>
    </p-tree>
  </div>
</div>
<ng-template #item>
  <app-header-modal (closeEmitter)="closeModal()"
                    [modalTitle]="(isEditModal ? 'Изменение' : 'Добавление') + ' статьи ' +  (isReceiptActive ? 'поступления' : 'списания')" />
  <form [formGroup]="itemsForm"
        class="needs-validation"
        autocomplete="off"
        novalidate>
    <div class="modal-body">
      <div class="modalRow">
        <label class="col-sm-3 col-form-label"
               for="name_label">
          Наименование
          <span style="color:red">*</span>
        </label>
        <input class="form-control"
               formControlName="name"
               maxLength="100"
               name="name"
               type="text">
      </div>
      <div class="modalRow">
        <label class="col-sm-3 col-form-label">
          Попадает в ДДС (CF)
        </label>
        <p-checkbox formControlName="d_l"
                    class="pointer checkboxModal"
                    [binary]="true" />
      </div>
      <div class="modalRow" *ngIf="isHideRowByControl('d_l')">
        <label class="col-sm-3 col-form-label"
               for="name_label">
          Вид деятельности
          <span style="color:red">*</span>
        </label>

        <ng-select [items]="listOfGroupsDds"
                   [searchable]="true"
                   [style]="{'width': '100%'}"
                   bindLabel="value"
                   bindValue="id"
                   id="name_label"
                   formControlName="activity"
                   notFoundText="Ничего не найдено"
                   placeholder="Категория" />
      </div>
      <div class="modalRow" *ngIf="isHideRowByControl('d_l')">
        <p-panel header="Детально о видах деятельности"
                 class="pointer"
                 [collapsed]="true"
                 [toggler]="'header'"
                 [toggleable]="true">
          <p class="panelTitle">Операционная — все движения денег, связанные с основной деятельностью,
            которая дает
            прибыль.</p>
          <p class="panelTitle">
            Финансовая — финансовые операции, связанные с привлечением финансирования для осуществления
            операционной или инвестиционной деятельности компании: привлечение и выплата кредитов,
            ввод/вывод денег в оборотный капитал собственниками и выплата дивидендов.
          </p>
          <p class="m-0 panelTitle">Инвестиционная — покупка и продажа основных средств, вложение денег и
            проценты, которые
            получаем от этих вложений.
          </p>
        </p-panel>
      </div>
      <div class="modalRow">
        <label class="col-sm-3 col-form-label">
          Попадает в ОПиУ (PnL)
        </label>
        <p-checkbox formControlName="p_l"
                    class="pointer checkboxModal"
                    [binary]="true" />
      </div>
      <ng-container *ngIf="isHideRowByControl('p_l')">
        <div class="modalRow">
          <label class="col-sm-3 col-form-label"
                 for="name_label">
            Относится к
            <span style="color:red">*</span>
          </label>

          <ng-select *ngIf="isReceiptActive else spisanieSelect;"
                     [items]="listOfGroupsPnlReceipt"
                     [searchable]="true"
                     bindLabel="value" bindValue="id"
                     formControlName="pnl_id"
                     [style]="{'width': '100%'}"
                     notFoundText="Ничего не найдено"
                     placeholder="Категория">
          </ng-select>
          <ng-template #spisanieSelect>
            <ng-select [items]="listOfGroupsPnlSpisanie"
                       [searchable]="true"
                       bindLabel="value" bindValue="id"
                       formControlName="pnl_id"
                       [style]="{'width': '100%'}"
                       notFoundText="Ничего не найдено"
                       placeholder="Категория">
            </ng-select>
          </ng-template>

        </div>
        <div class="modalRow">
          <p-panel header="Детально о категориях ОПиУ (PnL)"
                   class="pointer"
                   [collapsed]="true"
                   [toggler]="'header'"
                   [toggleable]="true">
            <ng-container *ngIf="!isReceiptActive else receiptDisplayPanel;">
              <p class="panelTitle">
                Переменные затраты (прямые) - Расходы, которые напрямую зависят от выручки и
                изменяются в
                зависимости от размера выручки.
                <br>
                Например: логистика, комиссия, себестоимость.
              </p>
              <p class="panelTitle">
                Постоянные затраты (прямые) - Расходы, которые не зависят напрямую от выручки, но их
                можно отнести к определенному товару.
                <br>
                Например: продвижение, удержания МП, самовыкупы и раздачи
              </p>
              <p class="panelTitle">
                Постоянные затраты (косвенные) - Косвенные расходы, которые относятся ко всему
                бизнесу и их однозначно нельзя привязать к определенному товару.
                <br>
                Например: изготовление лекал, софт, командировки, аренда.
              </p>

              <p class="panelTitle">
                Внереализационные расходы - Расходы, которые не имеют непосредственной связи с
                производством
                и реализацией. <br>
                Например: судебные расходы, расходы за обслуживание банковского счета
              </p>

              <p class="panelTitle m-0">
                Расходы ниже EBITDA - проценты по привлеченным кредитам и займам, расходы на налоги
                и
                амортизацию.
                <br>
                Например: выплата процентов инвесторам, банкам
              </p>
            </ng-container>

            <ng-template #receiptDisplayPanel>
              <p class="panelTitle">
                Выручка - Доход, который получил бизнес от результатов деятельности на МП. <br>
                Например: выручка от ВБ
              </p>
              <p class="panelTitle">
                Доходы ниже EBITDA - проценты по выданным кредитам и займам. <br>
                Например: поступление процентов от заемщиков
              </p>
              <p class="panelTitle m-0">
                Доходы внереализационные - Доходы, которые не имеют непосредственной связи с
                производством и
                реализацией. <br>
                Например: субаренда, кэшбэк за пользование банковским счетом, возмещение убытков
              </p>
            </ng-template>

          </p-panel>
        </div>
      </ng-container>
      <!--                <div class="modalRow">-->
      <!--                    <label class="col-sm-3 col-form-label"-->
      <!--                           for="description_l1">-->
      <!--                        Комментарий-->
      <!--                    </label>-->
      <!--                    <textarea class="form-control"-->
      <!--                              formControlName="description"-->
      <!--                              id="description_l1"-->
      <!--                              name="description">-->
      <!--                    </textarea>-->
      <!--                </div>-->
    </div>
    <app-footer-modal (submitEmitter)="createUpdateItem(isReceiptActive ? 'receipt' : 'order')"
                      [isCreate]="!isEditModal"
                      [isDisabled]="btnDisabled" />
  </form>
</ng-template>
<p-contextMenu #cm />
<p-toast [baseZIndex]="5000" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}" />
<p-confirmDialog [baseZIndex]="10000" [style]="{width: '30vw'}" />

