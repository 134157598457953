import { ColDef, ColGroupDef } from 'ag-grid-community';
import { BtnPartnersCellRendererComponent } from './cell-component/btn-partners-cell-renderer.component';
import { PartnersSpecificCellsComponent } from './cell-component/partners-specific-cells.component';
import { NumberSpacesFormatter } from '../../../../shared/utils/functions/number-space-formatter';
import { NumberSortFunc } from '../../../../shared/utils/functions/number-sort-func';

export const FINPARTNERS_COLUMN_DEFS: (ColDef | ColGroupDef)[] = [
  {
    headerName: 'Наименование',
    field: 'name',
    width: 200,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Оборот',
    field: 'sum_partners',
    width: 200,
    filter: 'agTextColumnFilter',
    valueFormatter: NumberSpacesFormatter,
    cellRenderer: PartnersSpecificCellsComponent,
  },
  {
    headerName: 'Количество операций',
    field: 'count_operation',
    width: 200,
    filter: 'agNumberColumnFilter',
    cellRenderer: PartnersSpecificCellsComponent,
  },
  {
    headerName: 'ИНН',
    field: 'INN',
    width: 200,
    filter: 'agNumberColumnFilter',
    cellRenderer: PartnersSpecificCellsComponent,
  },
  {
    headerName: 'КПП',
    field: 'KPP',
    width: 200,
    filter: 'agNumberColumnFilter',
    cellRenderer: PartnersSpecificCellsComponent,
  },
  {
    headerName: 'Расчетный счет',
    field: 'checking_account',
    width: 200,
    filter: 'agTextColumnFilter',
    cellRenderer: PartnersSpecificCellsComponent,
  },
  {
    headerName: 'Комментарии',
    field: 'description',
    width: 200,
    floatingFilter: false,
  },
  {
    headerName: 'Действия',
    icons: {
      sortAscending: '<i class="fa fa-sort-alpha-up"/>',
      sortDescending: '<i class="fa fa-sort-alpha-down"/>',
    },
    filter: false,
    cellRenderer: BtnPartnersCellRendererComponent,
    autoHeaderHeight: true,
    width: 140,
  },
];
