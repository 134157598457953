import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../shared/services/api.service';
import * as moment from 'moment';
import { IMyOrdersHintDataArr } from '../../../shared/mocks/my-orders';
import { DATE_PICKER_LOCALE, FIRST_RANGE_TYPE } from '../../../shared/common-variables/time-ranges-date-picker';
import { DatePickerCommon } from '../../../shared/common-variables/date-picker-common';
import { ChartOptions, myOrdersHintDataArr } from './myorders.mock';
import { forkJoin, Observable, throwError } from 'rxjs';
import {
  IGetChartOrders,
  IMyOrdersDetailData,
  IMyOrdersResponse,
  IOrdersBarcodeResponse,
  IOrdersCategoryDetail,
  IOrdersSubjectDetail,
} from './myorders.interface';
import { catchError, concatMap, finalize, switchMap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfirmationService, MessageService } from 'primeng/api';
import { PopUpMessages } from '../../../shared/mocks/pop-up-messages.mock';
import { AbstractMenuTabComponent } from '../../abstract-menu-tab.component';
import { GenerateOrdersChartData, YAXIS_RETURNS } from './myorders.model';
import { ISelectedNgbDate } from '../../../shared/interfaces/common.interface';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-myorders',
  templateUrl: './myorders.component.html',
  styleUrls: ['./myorders.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class MyordersComponent extends AbstractMenuTabComponent implements OnInit {
  readonly YAXIS_RETURNS = YAXIS_RETURNS;
  readonly ranges: any = FIRST_RANGE_TYPE;
  ordersChartData: Partial<ChartOptions> = null;
  // Dropdown
  wareHousesList: any;
  categoriesList: IOrdersCategoryDetail[] = [];
  subjectsList: IOrdersSubjectDetail[] = [];
  nmidList: IOrdersSubjectDetail[] = [];
  fboFbsList: any = [
    { name: 'Все', value: 1 },
    { name: 'FBO', value: 2 },
    { name: 'FBS', value: 3 },
  ];

  selectedWH: any;
  selectedCategory: string;
  selectedSubject: string;
  selectedNm: any;
  selectedFBOFBS = 1;
  selectedDate: ISelectedNgbDate = { startDate: moment(), endDate: moment() };

  text = ''
  subtext = ''

  // Paginator
  paginationPage = 1;
  paginationItemsCount: number;

  //DATA
  ordersDataTable: IMyOrdersDetailData[] = [];

  //Variables
  avgItemsCount: number;
  cancelPercentNum = 0;
  totalOrderMoney = 0;
  totalOrderQty = 0;
  totalOrderCancel = 0;
  showCancelOrders = 1;
  isGoogleTableInfoVisible = true;

  // Hint (Test)
  dialogHint1: HTMLElement;
  dialogHint2: HTMLElement;
  dialogHint3: HTMLElement;
  dialogHint4: HTMLElement;
  myOrdersHintDataArr: IMyOrdersHintDataArr[] = myOrdersHintDataArr;
  isHintDialogOpenIds: number[] = [];
  currentHintId = 0;
  isOzon = false
  constructor(private api: ApiService) {
    super();
    this.isGoogleTableInfoVisible = localStorage.getItem('googleMsgInfo') == null;

    if (localStorage.getItem('supplier') === null) {
      this._router.navigate(['/settings/shop/']);
    }
  }

  ngOnInit(): void {
    this.isOzon = JSON.parse(localStorage.getItem('mpall_shop')).marketPlace === 2;
    this._mpSurfService
      .loadMetaData(this.SHOP_ID, this.TOKEN)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        //@ts-ignore
        const { die_text, die_title } = res.data.tutorialVideoLink.find(item => item.pageTitle === 'dashboard/orders') || { die_text: null, die_title: null }
        this.text = die_title
        this.subtext = die_text
      })
  }

  get bodyRequestGeneral(): {} {
    return {
      shop_id: this.SHOP_ID,
      startDate: this.selectedDate.startDate.format('YYYY-MM-DD'),
      endDate: this.selectedDate.endDate.format('YYYY-MM-DD'),
    };
  }

  get resForResponseSales(): number {
    const b = moment(this.selectedDate.startDate.format('YYYY-MM-DD'));
    const a = moment(this.selectedDate.endDate.format('YYYY-MM-DD'));
    return +a.diff(b, 'days');
  }

  closeGoogleTableMsg(param: number) {
    if (+param === 1) {
      localStorage.setItem('googleMsgInfo', '1');
      this.isGoogleTableInfoVisible = false;
    }
  }

  dateChanged(selectedDate: ISelectedNgbDate) {
    this.selectedDate = selectedDate;
    this.getBarcodeNmid();
    this.loadAllParameters();
    this.showCancelOrders = 1;
    this.getOrders();
  }

  onClearParameters(parameter: string) {
    switch (parameter) {
      case 'article':
        this.selectedNm = undefined;
        break;
      case 'warehouse':
        this.selectedWH = undefined;
        break;
      case 'category':
        this.selectedCategory = undefined;
        break;
      case 'subject':
        this.selectedSubject = undefined;
        break;
      default:
        this.selectedParameters();
    }
    this.selectedParameters();
  }

  selectedParameters(page?: number) {
    const hidden = +this.showCancelOrders === 1 ? 0 : 1;
    const body = {
      barcode: this.selectedNm ?? undefined,
      cancel: hidden,
      fbofbs: this.selectedFBOFBS ?? undefined,
      wh: this.selectedWH ?? undefined,
      category: this.selectedCategory ?? undefined,
      subject: this.selectedSubject ?? undefined,
      currentPage: page ?? undefined,
    };
    this.loadData(body);
  }

  changeBuyoutStatus(ev, itemFbsFbo: string, itemId: string) {
    if (!ev.target.checked) {
      this.removeBuyout(itemId);
    } else {
      this.isLoading = true;
      const data = {
        id: null,
        check_id: itemFbsFbo,
        comment: null,
        cost_buyout: null,
        cost_logistics: null,
      };
      const bodyCreate = { shop_id: this.SHOP_ID };
      const bodyUpdate = { shop_id: this.SHOP_ID, data };
      this.createNewBuyOut(bodyCreate)
        .pipe(
          switchMap((createResponse: { data: number; id_record: number }) => {
            bodyUpdate.data.id = createResponse.id_record;
            return this.updateBuyout(bodyUpdate);
          }),
        )
        .subscribe(
          () => {
            this.loadData();
            this.showPopUpMessage('success', PopUpMessages.createSuccessSummary, 'Самовыкуп добавлен');
          },
          () => {
            this.showPopUpMessage('error', PopUpMessages.deleteFailedSummary, PopUpMessages.deleteFailedMessage);
          },
        );
    }
  }

  removeBuyout(id: any) {
    if (confirm('Вы уверены, что хотите удалить самовыкуп?')) {
      const body = { shop_id: this.SHOP_ID, delete_id: id };
      this.api.userRegister('getBuyoutsDelete', localStorage.getItem('token'), body).subscribe(
        () => {
          this.showPopUpMessage('success', PopUpMessages.deleteSuccessSummary, PopUpMessages.deleteSuccessMessage);
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.deleteFailedSummary, PopUpMessages.deleteFailedMessage);
        },
      );
    }
  }

  loadAllParameters() {
    forkJoin([
      this.getParametersList('getOrdersWh'),
      this.getParametersList('getOrdersCategory'),
      this.getParametersList('getOrdersSubject'),
    ]).subscribe(([responseWH, responseCategory, responseSubject]) => {
      this.wareHousesList = responseWH.data;
      this.categoriesList = responseCategory.data;
      this.subjectsList = responseSubject.data;
    });
  }

  // TODO LOAD DATA
  loadData(params = null) {
    this.api
      .userRegister('getOrders', localStorage.getItem('token'), this.bodyRequestWithParams(params))
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe(
        (data: IMyOrdersResponse) => {
          this.ordersDataTable = data.data;
          this.paginationItemsCount = data.count;
          this.getChart({
            cancel: this.showCancelOrders,
            barcode: this.selectedNm,
            wh: this.selectedWH,
            category: this.selectedCategory,
            subject: this.selectedSubject,
          });
          if (+this.resForResponseSales === 0) {
            +data.count === 0 ? (this.avgItemsCount = 0) : (this.avgItemsCount = Math.round(data.count));
          } else {
            this.avgItemsCount = Math.round(data.count / this.resForResponseSales);
          }
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
        },
      );
  }

  loadCanceledOrders(hidden: any) {
    +hidden === 1 ? (this.showCancelOrders = 0) : (this.showCancelOrders = 1);
    const body = { cancel: hidden, barcode: this.selectedNm, fbofbs: this.selectedFBOFBS };
    this.loadData(body);
  }

  // TODO - Hint Managers
  manageDialogsForHint(stepId: number) {
    this.dialogHint1 = document.querySelector('.dialog-1') as HTMLElement;
    this.dialogHint2 = document.querySelector('.dialog-2') as HTMLElement;
    this.dialogHint3 = document.querySelector('.dialog-3') as HTMLElement;
    this.dialogHint4 = document.querySelector('.dialog-4') as HTMLElement;
    this.currentHintId = stepId;
    if (!this.isHintDialogOpenIds.includes(stepId)) {
      this.isHintDialogOpenIds.push(stepId);
      if (stepId === 1) {
        this.dialogHint1.style.display = 'block';
      }
      if (stepId === 2) {
        this.dialogHint1.style.display = 'none';
        this.dialogHint2.style.display = 'block';
      }
      if (stepId === 3) {
        this.dialogHint2.style.display = 'none';
        this.dialogHint3.style.display = 'block';
      }
      if (stepId === 4) {
        this.dialogHint3.style.display = 'none';
        this.dialogHint4.style.display = 'block';
      }
      if (stepId === 5) {
        this.closeHintModal();
      }
    }
  }

  closeHintModal() {
    this.dialogHint1.style.display = 'none';
    this.dialogHint2.style.display = 'none';
    this.dialogHint3.style.display = 'none';
    this.dialogHint4.style.display = 'none';
    this.isHintDialogOpenIds = [];
    this.currentHintId = 0;
  }

  private getChart(params = null) {
    this.api
      .userRegister('getChartOrders', localStorage.getItem('token'), this.bodyRequestWithParams(params))
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe(
        (data: IGetChartOrders) => {
          this.totalOrderMoney = +data.order_money_total;
          this.totalOrderQty = +data.order_qty_total;
          this.totalOrderCancel = +data.order_cancel_total;

          this.totalOrderQty === 0
            ? (this.cancelPercentNum = 0)
            : (this.cancelPercentNum = +((this.totalOrderCancel * 100) / this.totalOrderQty).toFixed(2));

          this.ordersChartData = GenerateOrdersChartData(data);
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
        },
      );
  }

  private getOrders() {
    let hidden = 0;
    this.showCancelOrders === 1 ? (hidden = 0) : (hidden = 1);
    const body = {
      cancel: hidden,
      barcode: this.selectedNm,
      fbofbs: this.selectedFBOFBS,
    };
    this.loadData(body);
  }

  private getBarcodeNmid() {
    this.isLoading = true;
    const body = this.bodyRequestGeneral;
    this.api
      .userRegister('getOrdersNm', localStorage.getItem('token'), body)
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe(
        (response: IOrdersBarcodeResponse) => {
          this.nmidList = response.data;
          this.paginationItemsCount = response.count;
          if (+this.resForResponseSales === 0) {
            +response.count === 0 ? (this.avgItemsCount = 0) : (this.avgItemsCount = Math.round(response.count));
          } else {
            this.avgItemsCount = Math.round(response.count / this.resForResponseSales);
          }
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
        },
      );
  }

  private getParametersList(parameterActionName: string): Observable<any> {
    const body = this.bodyRequestGeneral;
    return this.api.userRegister(parameterActionName, localStorage.getItem('token'), body);
  }

  private createNewBuyOut(body) {
    return this.api.userRegister('getBuyoutsAdd', localStorage.getItem('token'), body);
  }

  private updateBuyout(body) {
    return this.api.userRegister('getBuyoutsUpdate', localStorage.getItem('token'), body);
  }

  private bodyRequestWithParams(params): {} {
    return {
      startDate: this.selectedDate.startDate.format('YYYY-MM-DD'),
      endDate: this.selectedDate.endDate.format('YYYY-MM-DD'),
      shop_id: this.SHOP_ID,
      params,
    };
  }
}