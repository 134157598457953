import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { faPlay } from '@fortawesome/free-solid-svg-icons/faPlay';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DOCUMENT } from '@angular/common';
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { PopUpStatus } from '../../../interfaces/common.type';

@Component({
  selector: 'app-mp-video-play',
  templateUrl: './video-play.component.html',
  styleUrls: ['./video-play.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService],
})
export class VideoPlayComponent implements OnChanges {
  protected readonly playIcon = faPlay;

  private readonly _modalService = inject(NgbModal);

  private readonly _messageService = inject(MessageService);

  private readonly _document = inject(DOCUMENT);

  private readonly _renderer = inject(Renderer2);

  private readonly _sanitizer = inject(DomSanitizer);

  protected link: SafeUrl = '';

  protected isLinkNotProvided: boolean = false;

  @Input() set videoLink(link: string) {
    this.link = this._sanitizer.bypassSecurityTrustResourceUrl(link);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes?.videoLink.currentValue) {
      this.isLinkNotProvided = true;
    } else {
      this.isLinkNotProvided = false;
    }
  }

  openVideoModal(content: TemplateRef<any>) {
    if (this.isLinkNotProvided) {
      this.showPopUpMessage();
    } else {
      this._modalService.open(content, {
        centered: true,
        windowClass: 'play-modal',
      });

      const dialogRef = this._document.querySelector('.modal-dialog');

      if (dialogRef) {
        this._renderer.setStyle(dialogRef, 'box-shadow', 'none');
        this._renderer.setStyle(dialogRef, 'max-width', '720px');
      }
    }
  }

  private showPopUpMessage(): void {
    this._messageService.add({
      severity: 'error',
      summary: 'Ошибка воспроизведения видео',
      detail:
        'За обучением по данному разделу приложения, обратитесь в техническую поддержку!',
    });
  }
}
