<div class="modal-footer">
    <button (click)="cancel()"
            *ngIf="isClose"
            class="btn btn-outline-danger me-3">
        Закрыть
    </button>
    <button (click)="cancel()"
            *ngIf="isCancel"
            class="btn btn-outline-danger me-3">
        Отмена
    </button>
    <ng-container *ngIf="isSave; else createUpdate">
        <button (click)="submit()"
                [disabled]="isDisabled"
                class="btn btn-primary">
            Сохранить
        </button>
    </ng-container>

    <ng-template #createUpdate>
        <button (click)="submit()"
                [disabled]="isDisabled"
                class="btn btn-primary">
            Создать
        </button>
    </ng-template>
</div>

