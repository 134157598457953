import { Pipe, PipeTransform } from '@angular/core';
import { converterMappingReverse } from '../mappings';

@Pipe({
  name: 'titlePipe'
})
export class TitlePipe implements PipeTransform {

  transform(value: string): string {
    return converterMappingReverse.get(value)
  }
}