import { Component, OnInit } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FIRST_RANGE_TYPE } from '../../../shared/common-variables/time-ranges-date-picker';
import { ChartOptions } from './mysales.mock';
import {
  IChartSales,
  IMySaleItem,
  IMySalesAdditionalParameters,
  IMySalesGeneral,
  IMySalesViewCancel,
} from './mysales.interface';
import { PopUpMessages } from '../../../shared/mocks/pop-up-messages.mock';
import { GenerateSalesChartData, YAXIS_RETURNS } from './mysales.model';
import { AbstractGeneralTabComponent } from '../../../general-components/abstract-general-tab.component';
import { ISelectedNgbDate } from '../../../shared/interfaces/common.interface';

import * as moment from 'moment';
import { AbstractMenuTabComponent } from '../../abstract-menu-tab.component';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-mysales',
  templateUrl: './mysales.component.html',
  styleUrls: ['./mysales.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class MysalesComponent
  extends AbstractMenuTabComponent
  implements OnInit
{
  readonly ranges = FIRST_RANGE_TYPE;
  readonly YAXIS_RETURNS = YAXIS_RETURNS;
  salesChartData: Partial<ChartOptions> = null;

  wareHousesList: IMySalesAdditionalParameters[] = [];
  categoryList: IMySalesAdditionalParameters[] = [];
  subjectList: IMySalesAdditionalParameters[] = [];
  nmidList: any = [];
  selectedWH: IMySalesAdditionalParameters = null;
  selectedCategory: IMySalesAdditionalParameters = null;
  selectedSubject: IMySalesAdditionalParameters = null;
  selectedNm: any;
  selectedDate: ISelectedNgbDate = { startDate: moment(), endDate: moment() };

  paginationPage = 1;
  visibleBtn = 1;
  isDisplayGoogleTableInfo = true;
  salesItemsAverageByDay: number;
  salesTableData: IMySaleItem[] = [];
  salesCount: number;
  salesMoneyTotal: number;
  salesQtyTotal: number;
  salesReturnMoneyTotal: number;
  salesReturnQTY: number;
  salesReturnQTYAverage: number;
  isOzon = false

  text = ''
  subtext = ''

  constructor() {
    super();
    this.isDisplayGoogleTableInfo =
      localStorage.getItem('googleMsgInfo') == null;
  }

  private get generalRequest(): {} {
    return {
      startDate: this.selectedDate.startDate.format('YYYY-MM-DD'),
      endDate: this.selectedDate.endDate.format('YYYY-MM-DD'),
    };
  }

  private get resForResponseSales(): number {
    const b = moment(this.selectedDate.startDate.format('YYYY-MM-DD'));
    const a = moment(this.selectedDate.endDate.format('YYYY-MM-DD'));
    return +a.diff(b, 'days');
  }

  ngOnInit(): void {
    if (!this.SHOP_ID) {
      this.initShopID();
    }
    this.isOzon = JSON.parse(localStorage.getItem('mpall_shop')).marketPlace === 2;
    this._mpSurfService
      .loadMetaData(this.SHOP_ID, this.TOKEN)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        //@ts-ignore
        const { die_text, die_title } = res.data.tutorialVideoLink.find(item => item.pageTitle === 'dashboard/sales') || { die_text: null, die_title: null }
        this.text = die_title
        this.subtext = die_text
      })
  }

  closeGoogleTableMsg(param: number) {
    if (param === 1) {
      localStorage.setItem('googleMsgInfo', '1');
      this.isDisplayGoogleTableInfo = false;
    }
  }

  onClearParameters(parameter: string) {
    switch (parameter) {
      case 'article':
        this.selectedNm = undefined;
        break;
      case 'warehouse':
        this.selectedWH = undefined;
        break;
      case 'category':
        this.selectedCategory = undefined;
        break;
      case 'subject':
        this.selectedSubject = undefined;
        break;
      default:
        this.selectedParameters();
    }
    this.selectedParameters();
  }

  selectedParameters() {
    const hidden = +this.visibleBtn === 1 ? 0 : 1;
    const body = {
      barcode: this.selectedNm ?? undefined,
      cancel: hidden,
      wh: this.selectedWH ?? undefined,
      category: this.selectedCategory ?? undefined,
      subject: this.selectedSubject ?? undefined,
      currentPage: this.paginationPage ?? undefined,
    };
    this.loadSales(body);
  }

  dateChanged(selectedDate: ISelectedNgbDate) {
    this.selectedDate = selectedDate;
    this.paginationPage = 1;
    this.visibleBtn = 1;

    this.selectedParameters();
    this.loadNm();
    this.loadAllParameters();
  }

  switchCancelAllStatus(hiddenStatus: number): void {
    +hiddenStatus === 1 ? (this.visibleBtn = 0) : (this.visibleBtn = 1);
    this.paginationPage = 1;
    this.selectedParameters();
  }

  private loadAllParameters() {
    this.isLoading = true;
    forkJoin([
      this.getParametersList('getSalesWh'),
      this.getParametersList('getSalesCategory'),
      this.getParametersList('getSalesSubject'),
    ])
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(([responseWH, responseCategory, responseSubject]) => {
        this.wareHousesList = responseWH.data;
        this.categoryList = responseCategory.data;
        this.subjectList = responseSubject.data;
      });
  }

  private loadSales(params = null) {
    this.isLoading = true;
    const body = {
      ...this.generalRequest,
      ...params,
    };
    this._mpSurfService
      .loadByOld(body, 'getSales', 'data')
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(
        (data: any) => {
          this.salesAutoSetter(data);
          this.salesTableData = data.data;
          this.salesCount = data.count;

          this.getChart();
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }

  private getChart() {
    const body = {
      ...this.generalRequest,
      params: {
        barcode: this.selectedNm ? this.selectedNm : undefined,
        wh: this.selectedWH ? this.selectedWH : undefined,
        category: this.selectedCategory ?? undefined,
        subject: this.selectedSubject ?? undefined,
      },
    };
    this._mpSurfService
      .loadByOld(body, 'getChartSales', 'data')
      .pipe(untilDestroyed(this))
      .subscribe(
        (data: IChartSales) => {
          this.salesMoneyTotal = data.sales_money_total;
          this.salesQtyTotal = data.sales_qty_total;
          this.salesReturnMoneyTotal = data.sales_return_money_total * -1;
          this.salesChartData = GenerateSalesChartData(data);
          this.salesReturnQTY = data.sales_return_qty.reduce(
            (a, c) => a + c,
            0
          );
          this.salesReturnQTYAverage = Math.floor(
            this.salesReturnQTY / data.sales_return_qty.length
          );
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }

  private getParametersList(parameterActionName: string): Observable<any> {
    const body = this.generalRequest;
    return this._mpSurfService.loadByOld(body, parameterActionName, 'data');
  }

  private loadNm() {
    const body = this.generalRequest;
    this._mpSurfService
      .loadByOld(body, 'getSalesNm', 'data')
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: IMySalesGeneral<IMySalesAdditionalParameters[]>) => {
          this.nmidList = response.data;
          if (response.data) {
            this.salesCount = response.data.length;
          }
          this.resForResponseSales === 0
            ? +response.data.length === 0
              ? (this.salesItemsAverageByDay = 0)
              : (this.salesItemsAverageByDay = Math.round(response.data.length))
            : (this.salesItemsAverageByDay = Math.round(
                response.data.length / this.resForResponseSales
              ));
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }

  private salesAutoSetter(data: IMySalesViewCancel) {
    this.salesTableData = data.data;
    this.salesCount = +data.count;
    if (+this.resForResponseSales === 0) {
      +data.count === 0
        ? (this.salesItemsAverageByDay = 0)
        : (this.salesItemsAverageByDay = Math.round(+data.count));
    } else {
      this.salesItemsAverageByDay = Math.round(
        +data.count / this.resForResponseSales
      );
    }
  }
}
