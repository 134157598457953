<article class="mp_card" [style.backgroundColor]="getBgColor(card)">
    <section *ngIf="card?.underConstruction" class="underConstruction">
      В разработке
    </section>
    <section>
      <div *ngIf="card.title" class="mp_card-title">
        {{ card.title }}
        <fa-icon *ngIf="card?.description" [icon]="tooltipIcon" placement="top" [ngbTooltip]="card?.description ?? null"
          style="font-size: 0.8rem">
        </fa-icon>
      </div>
      <div class="mp_card-body">
        <div *ngFor="let field of card.subfields" class="mp_card-body_item">
          <section [placement]="['right','left']" [ngbTooltip]="field.description ? field.description : null" tooltipClass="tooltip">
            {{ field.name }}
          </section>
          <section class="right_part">
            <span *ngIf="field.diffInPercents"
              [ngClass]="{ 'red-text': field.diffInPercents < 0, 'green-text': field.diffInPercents > 0 }">
              <span *ngIf="field.diffInPercents > 0">+</span>{{ field.diffInPercents }} %
            </span>
            <span *ngIf="field.ratioInPercents">
              {{ field.ratioInPercents }} %
            </span>
            <span>
              {{ field.value | number: '1.0-0' | mpUnit: field.unit }}
            </span>
          </section>
        </div>
      </div>
    </section>
    <div *ngIf="card.totalSum || card.totalSum == 0 || card.totalUnits|| card.totalUnits == 0 || isNumber(card.salesPercent) || isNumber(card.ordersPercent)" class="mp_card-footer">
      <span *ngIf="card.totalCount"> {{ card.totalCount | number: '1.0-0' | mpUnit: 'item' }}</span>
      <span *ngIf="isNumber(card.salesPercent)" placement="right" ngbTooltip="ДРР по продажам"> {{ card.salesPercent | number: '1.1-1' | mpUnit: 'percent' }}</span>
      <section class="mp_card-footer_total">
        <span *ngIf="isNumber(card.ordersPercent)" placement="right" ngbTooltip="ДРР по заказам"> {{ card.ordersPercent | number: '1.1-1' | mpUnit: 'percent' }}</span>
        <span *ngIf="card.totalSum || card.totalSum == 0">{{ card.totalSum | number: '1.0-0' | mpUnit: 'rubles' }}</span>
        <span *ngIf="card.totalUnits || card.totalUnits == 0">{{ card.totalUnits }}</span>
        <span *ngIf="card.diffInPercents" placement="left" [ngbTooltip]="'Lorem ipsum dolor set amet'"
          class="mp_card-footer_total_hint">
          <span>{{ card.diffInPercents < 0 ? '↓' : '↑' }}</span><span>{{ card.diffInPercents | mpUnit: 'percent' }}</span>
          </span>
      </section>
    </div>
  </article>