<div class="app-page-title mb-0">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <i class="pe-7s-box2 icon-gradient bg-mean-fruit"></i>
      </div>
      <div>Склады МП</div>

      <app-mp-video-play
        [videoLink]="getVideoLinkByPage('fin/settings/stores')" />
    </div>
    <div class="page-title-actions">
      <button
        class="btn-shadow d-inline-flex align-items-center btn btn-success"
        type="button">
        Добавить склад
      </button>
    </div>
  </div>
</div>
<ag-grid-angular
    (gridReady)="onGridReady($event)"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultDef"
    [rowData]="rowData"
    [pagination]="true"
    [paginationPageSize]="25"
    (rowEditingStopped)="onRowEditingStopped($event)"
    (rowEditingStarted)="onRowEditingStarted($event)"
    (cellClicked)="onCellClicked($event)"
    editType="fullRow"
    [suppressClickEdit]="true"
    class="ag-theme-alpine"
    style="width: 100%; height: 720px"
/>