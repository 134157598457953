import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { TreeTable } from 'primeng/treetable';
import { ConfirmationService, MessageService } from 'primeng/api';

import { AbstractMenuTabComponent } from '../../abstract-menu-tab.component';
import { FORTH_RANGE_TYPE } from '../../../shared/common-variables/time-ranges-date-picker';
import { ISelectedNgbDate } from '../../../shared/interfaces/common.interface';

import * as moment from 'moment/moment';
import * as FileSaver from 'file-saver';

import { ISupplierResponse } from '../../../general-components/profile/users/users.interface';
import { PopUpMessages } from '../../../shared/mocks/pop-up-messages.mock';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-pnl-consolidation',
  templateUrl: './pnl-consolidation.component.html',
  styleUrls: ['./pnl-consolidation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ConfirmationService, MessageService],
})
export class PnlConsolidationComponent
  extends AbstractMenuTabComponent
  implements OnInit
{
  readonly ranges = FORTH_RANGE_TYPE;
  readonly specialHeaders = [
    'Выручка',
    'Маржинальная прибыль',
    'Валовый доход',
    'Операционная прибыль',
    'Прибыль до вычета налогов, процентов и амортизации (EBITDA)',
    'Чистая прибыль ИТОГ',
    'Переменные затраты (прямые)',
    'Постоянные затраты (прямые)',
    'Постоянные затраты (косвенные)',
    'Доходы внереализационные',
    'Нераспределенная прибыль',
    'Расходы ниже EBITDA',
    'Доходы ниже EBITDA',
    'Расходы внереализационные',
  ];
  
  readonly fw_700_headers = [
    'Маржинальная прибыль',
    'Валовый доход',
    'Операционная прибыль',
    'Прибыль до вычета налогов, процентов и амортизации (EBITDA)',
  ]

  readonly fw_500_headers = [
    'Переменные затраты (прямые)',
    'Постоянные затраты (прямые)',
    'Постоянные затраты (косвенные)',
    'Доходы внереализационные',
    'Нераспределенная прибыль',
    'Расходы ниже EBITDA',
    'Доходы ниже EBITDA',
    'Расходы внереализационные',
  ]

  readonly color_grey_headers = [
    'Продажи в ценах поставщика с самовыкупами',
    'Продажи в ценах WB с самовыкупами',
    'Продажи Ozon с самовыкупами'
  ]


  pnlData = null;

  pnlTableData$ = this._mpSurfService.load({}, 'fin-pnl/get-data');

  selectedDate: ISelectedNgbDate = {
    startDate: moment().subtract(21, 'day'),
    endDate: moment(),
  };

  selectedDateType = 3;
  selectedSuppliers: number[] = [this.SHOP_ID];

  suppliersList: any = [];
  lastReportDate = ''

  checked = false;
  isOzon = false;

  tariff = ''

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (localStorage.getItem('supplier')) {
      const supplier = JSON.parse(
        localStorage.getItem('supplier')
      ) as ISupplierResponse[];
      this.suppliersList = supplier.map((item: ISupplierResponse) => ({
        label: item.name,
        value: item.id,
      }));
    }
    this.getReportDate().pipe(untilDestroyed(this)).subscribe(data => {
      this.lastReportDate = data.last_data
    })
    this.isOzon = JSON.parse(localStorage.getItem('mpall_shop')).marketPlace === 2;
  }

  dateChanged($event: any) {
    this.selectedDate = $event;
    this.loadPNLList();
  }

  loadPNLList(body = {}) {
    if (this.selectedSuppliers.length === 0) {
      this.selectedSuppliers = [this.SHOP_ID];
      this.showPopUpMessage(
        'error',
        'Ошибка получение отчета',
        'Должен быть выбран минимум один поставщик'
      );
    } else {
      const params = {
        startDate: this.selectedDate.startDate.format('YYYY-MM-DD'),
        endDate: this.selectedDate.endDate.format('YYYY-MM-DD'),
        type_group: this.selectedDateType,
        shop_list: this.selectedSuppliers,
        adv_oper: this.checked ? 1 : 0,
        ...body
      };
      this._mpSurfService
        .loadByPost(params, 'fin-pnl/get-data')
        .pipe(untilDestroyed(this))
        .subscribe(
          (data: any) => {
            this.pnlData = data;
            this._cdr.detectChanges();
          },
          () => {
            this.showPopUpMessage(
              'error',
              PopUpMessages.loadFailedSummary,
              PopUpMessages.loadFailedMessages
            );
          }
        );
    }
  }

  onCheckboxChange(event: {checked: boolean}): void {
    this.checked = event.checked;
    this.loadPNLList()
  }

  exportExcel() {

    this._mpSurfService
    .loadByPost(this.pnlData, `fin-pnl/export-consolidation`)
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: any) => {
          this.importXlsx(response);
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }

  private importXlsx(excelData: any): void {
    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(excelData);
      const csvOutput: string = xlsx.utils.sheet_to_csv(worksheet);
      FileSaver.saveAs(new Blob([csvOutput]), 'Fin-pnl-consolidation_export_' + new Date().getTime() + '.csv');
    });
  }
}
