import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ThousandSeparatorPipe } from "./thousand-separator/mpThousandSeparator";

@NgModule({
    imports: [CommonModule],
    declarations: [
      ThousandSeparatorPipe
    ],
    exports: [
        ThousandSeparatorPipe
    ],
})
export class SharedUiPipesModule {}
