import { FinOperationActionComponent } from '../aggrid-components/fin-operation-action.component';
import {
    FinoperationExcelRenderCellsComponent
} from '../aggrid-components/finoperation-excel-render-cells.component';

export enum PaymentTypes {'Безналичный', 'Наличный', 'Карта физ лица'}

export const PaymentTypesWithOperations = {
    'Поступление': 1,
    'Списание': 2,
    'Перемещение': 3,
    'Погашение кредита/займа': 5
}

export let agentList = ['1', '2'];
export let accountType = ['Поступление', 'Списание', 'Перемещение'];
export let accountsList = ['123', '312', '123', '123', '123'];
export const accountsListOriginal = ['123', '312', '123', '123', '123'];
export let itemsList = ['Item1', 'Item2', '123', '123', '123'];
export const ACCOUNT_TYPES = [{id: 1, name: 'Наличный'}, {id: 2, name: 'Безналичный'}, {id: 3, name: 'Карта физ лица'}];

export const FINOPERATION_COLUMN_DEFS_EXCEL: any[] = [
    {
        field: 'selection',
        headerName: '',
        headerCheckboxSelection: true,
        sortable: true,
        filter: true,
        width: 50,
        checkboxSelection: true,
    },
    {
        headerName: 'Дата оплаты (для ДДС)',
        headerClass: 'header-centered',
        field: 'finOperationExcelPayment.value',
        editable: true,
        maxWidth: 120,
        filter: 'agNumberColumnFilter',
        sortable: true, resizable: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'},
        cellRenderer: params => {
            const span = document.createElement('span');
            if (params.data.finOperationExcelPayment) {
                span.style.color = params.data.finOperationExcelPayment.style;
                span.innerText = params.value;
                span.addEventListener('click', () => span.style.color = '');
            }
            return span;
        }
    },
    {
        headerName: 'Дата начисления (для ОПиУ)',
        headerClass: 'header-centered',
        field: 'finOperationExcelAccrual.value',
        maxWidth: 120,
        editable: true,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'},
        cellRenderer: params => {
            const span = document.createElement('span');
            if (params.data.finOperationExcelAccrual) {
                span.style.color = params.data.finOperationExcelAccrual.style;
                span.innerText = params.value;
                span.addEventListener('click', () => span.style.color = '');
                return span;
            }
        }
    },
    {
        headerName: 'Счёт',
        headerClass: 'header-centered',
        field: 'finOperationExcelAccount.value',
        tooltipField: 'finOperationExcelAccount.warningTooltip',
        maxWidth: 220,
        editable: true,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'},
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
            values: accountsList,
            defaultValue: accountsList[1],
            afterGuiAttached: (params) => {
                params.values = accountsList;
                params.refresh();
            },
        },
        cellRenderer: FinoperationExcelRenderCellsComponent
    },
    {
        headerName: 'Добавить счет',
        headerClass: 'header-centered',
        field: '',
        maxWidth: 120,
        editable: false,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellRendererFramework: FinOperationActionComponent,
        cellEditorParams: {
            operationStatus: 'account'
        }
    },
    {
        headerName: 'Тип операции',
        headerClass: 'header-centered',
        field: 'finOperationExcelTypeOperation.value',
        maxWidth: 220,
        editable: true,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'},
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
            values: accountType,
            defaultValue: 'Поступление',
        },
        cellRenderer: FinoperationExcelRenderCellsComponent
    },
    {
        headerName: 'Артикул',
        headerClass: 'header-centered',
        field: 'finOperationExcelArticle.value',
        maxWidth: 220,
        editable: true,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'},
        cellEditor: 'agSelectCellEditor',
        cellRenderer: FinoperationExcelRenderCellsComponent
    },
    {
        headerName: 'Контрагент',
        headerClass: 'header-centered',
        field: 'finOperationExcelContrAgent.value',
        maxWidth: 220,
        editable: true,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'},
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
            values: agentList,
            defaultValue: agentList[1],
        },
        cellRenderer: FinoperationExcelRenderCellsComponent
    },
    {
        headerName: 'Добавить контрагента',
        headerClass: 'header-centered',
        field: '',
        maxWidth: 130,
        editable: false,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellRendererFramework: FinOperationActionComponent,
        cellEditorParams: {
            operationStatus: 'agent'
        }
    },
    {
        headerName: 'Статья',
        headerClass: 'header-centered',
        field: 'finOperationExcelItem.value',
        maxWidth: 220,
        editable: true,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'},
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
            values: itemsList,
            defaultValue: itemsList[1],
        },
        cellRenderer: FinoperationExcelRenderCellsComponent
    },
    {
        headerName: 'Сумма',
        headerClass: 'header-centered',
        field: 'finOperationExcelSum.value',
        maxWidth: 120,
        editable: true,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'},
        cellRenderer: FinoperationExcelRenderCellsComponent
    },
    {
        headerName: 'Кабинеты',
        headerClass: 'header-centered',
        field: 'finOperationExcelShop.value',
        maxWidth: 120,
        editable: true,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'},
        cellEditor: 'agSelectCellEditor',
        cellRenderer: FinoperationExcelRenderCellsComponent
    },
    {
        headerName: 'Комментарий',
        headerClass: 'header-centered',
        field: 'finOperationExcelComment.value',
        maxWidth: 250,
        editable: true,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'},
        cellRenderer: FinoperationExcelRenderCellsComponent
    },
];

export function formatValue(value: string): string {
    if (value) {
        return (value.split('-')[0]).trim();
    }
    return '';
}
