import {Component, OnInit} from '@angular/core';
import {Params} from '@angular/router';
import {ICellRendererAngularComp} from 'ag-grid-angular';

@Component({
    selector: 'app-fin-operation-component',
    templateUrl: './fin-operation-action.component.html',
    styleUrls: ['../../finoperations.component.sass'],
})
export class FinOperationActionComponent implements ICellRendererAngularComp {
    operationStatus: string;
    componentParent: any;
    displayBtn = false;
    private params: Params;

    agInit(params: Params): void {
        this.displayBtn = false;
        this.params = params;
        this.operationStatus = params.colDef.cellEditorParams.operationStatus;
        if (this.operationStatus && this.operationStatus === 'account') {
            if (this.params.data?.finOperationExcelAccount?.style === 'red') {
                this.displayBtn = true;
            }
        }
        if (this.operationStatus && this.operationStatus === 'agent') {
            if (this.params.data?.finOperationExcelContrAgent?.style === 'red') {
                this.displayBtn = true;
            }
        }
        this.componentParent = this.params.context.componentParent;
    }

    addFinOperation(): void {
        if (this.operationStatus && this.operationStatus === 'account') {
            this.componentParent.triggerAccountModal(this.params.data, 'account');
        }
        if (this.operationStatus && this.operationStatus === 'agent') {
            this.componentParent.triggerAgentModal(this.params.data, 'agent');
        }
    }

    refresh() {
        return false;
    }
}
