import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../shared/services/api.service';
import * as moment from 'moment';
import {
  FIRST_RANGE_TYPE,
} from '../../../shared/common-variables/time-ranges-date-picker';
import { forkJoin, Observable } from 'rxjs';
import {
  IChartPoint,
  IMyOrdersRowData,
  IMyOrdersTableData,
} from './myorders.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfirmationService, MessageService } from 'primeng/api';
import { PopUpMessages } from '../../../shared/mocks/pop-up-messages.mock';
import { AbstractMenuTabComponent } from '../../abstract-menu-tab.component';
import { ISelectedNgbDate } from '../../../shared/interfaces/common.interface';
import {
  IGeneralPayload,
  ISalesFilterData,
} from '../mysales/mysales.interface';
import { ApexOptions } from 'ng-apexcharts';
import { ICardWidget } from '../main-test/main-test.interface';
import { ColDef, GridOptions, GridReadyEvent, RowNode } from 'ag-grid-community';
import { generateColumnDefsByDate, generateSummary, getChartOptions } from './myorders.func';
import { ORDERS_TABLE_COLUMN_DEFS } from './myorders.mock';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-myorders',
  templateUrl: './myorders.component.html',
  styleUrls: ['./myorders.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class MyordersComponent
  extends AbstractMenuTabComponent
  implements OnInit
{
  readonly ranges = FIRST_RANGE_TYPE;

  salesGridOptions: GridOptions;


  chartOptions: Partial<ApexOptions> | null = null;
  warehouses: ISalesFilterData[] = [];
  categories: ISalesFilterData[] = [];
  subjects: ISalesFilterData[] = [];
  nmids: ISalesFilterData[] = [];
  fboFbsList = [
    { name: 'Все', value: 1 },
    { name: 'FBO', value: 2 },
    { name: 'FBS', value: 3 },
  ];

  typeOptions = [
    { name: 'Лента', value: '1' },
    { name: 'Таблица', value: '2' },
  ];

  selectedType = this.typeOptions[0].value;

  columnDefs = ORDERS_TABLE_COLUMN_DEFS;

  summaryRow = [];

  showOnlyCancels = false;

  showInRubles = false;

  ordersTableData: IMyOrdersRowData[] = [];

  readonly defaultColDef: ColDef = {
    headerClass: 'header-centered',
    resizable: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: 'center' },
    sortable: true,
    width: 160
  };

  cardData: ICardWidget = null;

  selectedWarehouse: ISalesFilterData[] = [];
  selectedCategory: ISalesFilterData[] = [];
  selectedSubject: ISalesFilterData[] = [];
  selectedNm: ISalesFilterData[] = [];
  selectedFBOFBS = 1;
  selectedDate: ISelectedNgbDate = { startDate: moment(), endDate: moment() };

  text = '';
  subtext = '';

  isGoogleTableInfoVisible = true;

  isOzon = false;
  constructor(private api: ApiService) {
    super();
    this.isGoogleTableInfoVisible =
      localStorage.getItem('googleMsgInfo') == null;

    if (localStorage.getItem('supplier') === null) {
      this._router.navigate(['/settings/shop/']);
    }
    this.salesGridOptions = {
      context: { componentParent: this },
      enableCellTextSelection: true,
    } as GridOptions;
  }

  ngOnInit(): void {
    this.isOzon =
      JSON.parse(localStorage.getItem('mpall_shop')).marketPlace === 2;
    this._mpSurfService
      .loadMetaData(this.SHOP_ID, this.TOKEN)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        //@ts-ignore
        const { die_text, die_title } = res.data.tutorialVideoLink.find(
          item => item.pageTitle === 'dashboard/orders'
        ) || { die_text: null, die_title: null };
        this.text = die_title;
        this.subtext = die_text;
      });
  }

  private get generalRequest(): IGeneralPayload & {
    barcodes: string[];
    warehouses: string[];
    categories: string[];
    subjects: string[];
  } {
    return {
      startDate: this.selectedDate.startDate.format('YYYY-MM-DD'),
      endDate: this.selectedDate.endDate.format('YYYY-MM-DD'),
      barcodes: Array.isArray(this.selectedNm)
        ? this.selectedNm.map(i => i.code)
        : [],
      warehouses: Array.isArray(this.selectedWarehouse)
        ? this.selectedWarehouse.map(i => i.code)
        : [],
      categories: Array.isArray(this.selectedCategory)
        ? this.selectedCategory.map(i => i.code)
        : [],
      subjects: Array.isArray(this.selectedSubject)
        ? this.selectedSubject.map(i => i.code)
        : [],
    };
  }

  onGridReady(params: GridReadyEvent<any>) {
    this.gridApi = params.api;
  }

  closeGoogleTableMsg(param: number) {
    if (+param === 1) {
      localStorage.setItem('googleMsgInfo', '1');
      this.isGoogleTableInfoVisible = false;
    }
  }

  dateChanged(selectedDate: ISelectedNgbDate) {
    this.selectedDate = selectedDate;
    this.getFilters()
    this.loadPageData();
  }

  onGridFilterChanged(): void {
    const rows = [];
    this.gridApi.forEachNodeAfterFilter((node : RowNode) => {
      rows.push(node.data)
    })
    this.summaryRow = generateSummary(rows, this.selectedType, this.columnDefs);
  }

  changeType(event: { originalEvent: PointerEvent; value: string }) {
    this.selectedType = event.value;
    this.loadPageData()
  }

  onClearParameters(parameter: string) {
    switch (parameter) {
      case 'article':
        this.selectedNm = [];
        break;
      case 'warehouse':
        this.selectedWarehouse = [];
        break;
      case 'category':
        this.selectedCategory = [];
        break;
      case 'subject':
        this.selectedSubject = [];
        break;
      default:
        console.error('Unsupported filter');
    }
    this.loadPageData();
  }

  onFilterChange(): void {
    this.loadPageData();
  }

  onShowOnlyCancelsChange(event: { checked: boolean }): void {
    this.showOnlyCancels = event.checked;
    this.loadPageData();
  }

  onShowInRublesChange(event: { checked: boolean }): void {
    this.showInRubles = event.checked;
    this.columnDefs = generateColumnDefsByDate(
      this.ordersTableData[0],
      this.showInRubles
    );
  }

  loadPageData() {
    this.getOrders();
    this.getChart();
    if (this.selectedType === '1') {
      this.getCard();
    }
  }

  private getOrders() {
    const body = {
      ...this.generalRequest,
      type: this.selectedType,
    };
    if (this.selectedType === '1') {
      body['only_cancels'] = this.showOnlyCancels ? 1 : 0;
    }
    this._mpSurfService
      .loadByPost(body, 'orders/table')
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data: IMyOrdersTableData) => {
          this.columnDefs =
            this.selectedType === '1'
              ? ORDERS_TABLE_COLUMN_DEFS
              : generateColumnDefsByDate(data.table[0], this.showInRubles);
          this.ordersTableData = data.table;
          this.gridApi.setFilterModel(this.gridApi.getFilterModel());
          this.gridApi.onFilterChanged()
        },
        error: () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        },
      });
  }

  private getChart() {
    this._mpSurfService
      .loadByPost(this.generalRequest, 'orders/chart')
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data: { chart: IChartPoint[] }) => {
          this.chartOptions = getChartOptions(data);
        },
        error: () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        },
      });
  }

  private getCard() {
    this._mpSurfService
      .loadByPost(this.generalRequest, 'orders/card')
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data: { card: ICardWidget[] }) => {
          this.cardData = data.card[0];
          this._cdr.markForCheck();
        },
        error: () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        },
      });
  }

  private getWarehousesList(
    body: IGeneralPayload
  ): Observable<{ data: ISalesFilterData[] }> {
    return this._mpSurfService.loadByOld(body, 'getOrdersWh', 'data');
  }

  private getCategoriesList(
    body: IGeneralPayload
  ): Observable<{ data: ISalesFilterData[] }> {
    return this._mpSurfService.loadByOld(body, 'getOrdersCategory', 'data');
  }

  private getSubjectsList(
    body: IGeneralPayload
  ): Observable<{ data: ISalesFilterData[] }> {
    return this._mpSurfService.loadByOld(body, 'getOrdersSubject', 'data');
  }

  private getNmList(
    body: IGeneralPayload
  ): Observable<{ data: ISalesFilterData[] }> {
    return this._mpSurfService.loadByOld(body, 'getOrdersNm', 'data');
  }

  private getFilters(): void {
    const body = this.generalRequest;
    forkJoin([
      this.getWarehousesList(body),
      this.getCategoriesList(body),
      this.getSubjectsList(body),
      this.getNmList(body),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([warehouses, categories, subjects, nmList]) => {
        (this.warehouses = warehouses.data),
          (this.categories = categories.data),
          (this.subjects = subjects.data),
          (this.nmids = nmList?.data || []);
      });
  }
}
