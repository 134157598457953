<app-page-header-notification-bar
  *ngIf="text || subtext"
  [headerTitle]="text"
  [barText]="subtext" />
<div *ngIf="isGoogleTableInfoVisible" class="col-sm-6 col-md-6">
  <div class="mb-3 card text-white card-body bg-warning">
    <h5 class="card-title">
      Обращаем внимание, веб сервис и гугл таблица - разные продукты от MP Surf
    </h5>

    <div class="col-sm-4 col-md-4">
      <button
        (click)="closeGoogleTableMsg(1)"
        class="btn btn-primary mt-2 mr-2">
        Все понятно
      </button>
    </div>
  </div>
  <br />
</div>

<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="upper-filters">
      <section style="display: flex; align-items: center; gap: 15px">
        <div class="page-title-icon">
          <i class="lnr-cart icon-gradient bg-mean-fruit"></i>
        </div>
        <div>
          Мои заказы
          <i
            class="fa fa-question-circle"
            aria-hidden="true"
            pTooltip="Информация на листе основана на оперативных данных и является предварительной.">
          </i>
        </div>

        <app-mp-video-play
          [videoLink]="getVideoLinkByPage('dashboard/orders')" />

        <p-selectButton
          (onChange)="changeType($event)"
          [(ngModel)]="selectedType"
          [options]="typeOptions"
          optionLabel="name"
          optionValue="value"
          selectionMode="single"
          styleClass="dark-panel"
          [ngStyle]="{ fontSize: '14px' }">
        </p-selectButton>

        <p-checkbox
          *ngIf="selectedType === '2'"
          [(ngModel)]="showInRubles"
          [binary]="true"
          inputId="binary"
          label="Показать в рублях"
          (onChange)="onShowInRublesChange($event)">
        </p-checkbox>
      </section>
      <div
        class="orders-filters"
        style="display: flex; align-items: center; gap: 8px; flex-wrap: wrap">
        <app-mp-ngb-range-datepicker
          [rangeType]="ranges"
          [initialData]="initialDate"
          (selectedDateEmitter)="dateChanged($event)">
        </app-mp-ngb-range-datepicker>

        <div *ngIf="selectedType === '1'">
          <p-checkbox
            [(ngModel)]="showOnlyCancels"
            [binary]="true"
            inputId="binary"
            label="Показать только отмены"
            (onChange)="onShowOnlyCancelsChange($event)">
          </p-checkbox>
        </div>

        <div class="d-flex align-items-center gap-3 filterBlock">
          <p-dropdown
            *ngIf="!isOzon"
            (onChange)="onFilterChange()"
            [(ngModel)]="selectedFBOFBS"
            [options]="fboFbsList"
            [showClear]="false"
            [style]="{ display: 'flex', alignItems: 'center', height: '32px' }"
            dataKey="1"
            emptyMessage="Список пуст"
            optionLabel="name"
            placeholder="Тип доставки" />
          <div
            *ngIf="!isOzon"
            class="d-flex align-items-center gap-3 filterBlock">
            <p-multiSelect
              (onChange)="onFilterChange()"
              (onClear)="onClearParameters('article')"
              [(ngModel)]="selectedNm"
              [maxSelectedLabels]="1"
              [options]="nmids"
              [showClear]="true"
              [style]="{
                display: 'flex',
                alignItems: 'center',
                height: '32px',
              }"
              defaultLabel="Товар"
              emptyMessage="Товаров не найдено"
              itemSize="30"
              optionLabel="name"
              selectedItemsLabel="Выбрано {0}" />
            <p-multiSelect
              (onChange)="onFilterChange()"
              (onClear)="onClearParameters('warehouse')"
              [(ngModel)]="selectedWarehouse"
              [maxSelectedLabels]="1"
              [options]="warehouses"
              [showClear]="true"
              [style]="{
                display: 'flex',
                alignItems: 'center',
                height: '32px',
              }"
              defaultLabel="Склад"
              emptyMessage="Складов не найдено"
              itemSize="30"
              optionLabel="name"
              selectedItemsLabel="Выбрано {0}">
            </p-multiSelect>
          </div>
          <div
            *ngIf="!isOzon"
            class="d-flex align-items-center gap-3 filterBlock">
            <p-multiSelect
              (onChange)="onFilterChange()"
              (onClear)="onClearParameters('category')"
              [(ngModel)]="selectedCategory"
              [maxSelectedLabels]="1"
              [options]="categories"
              [showClear]="true"
              [style]="{
                display: 'flex',
                alignItems: 'center',
                height: '32px',
              }"
              defaultLabel="Категория"
              emptyMessage="Категорий не найдено"
              itemSize="30"
              optionLabel="name"
              selectedItemsLabel="Выбрано {0}">
            </p-multiSelect>
            <p-multiSelect
              (onChange)="onFilterChange()"
              (onClear)="onClearParameters('subject')"
              [(ngModel)]="selectedSubject"
              [maxSelectedLabels]="1"
              [options]="subjects"
              [showClear]="true"
              [style]="{
                display: 'flex',
                alignItems: 'center',
                height: '32px',
              }"
              defaultLabel="Предмет"
              emptyMessage="Предметов не найдено"
              itemSize="30"
              optionLabel="name"
              selectedItemsLabel="Выбрано {0}">
            </p-multiSelect>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="chart-wrapper" [class.chart-wrapper__table]="selectedType === '2'">

  <div *ngIf="chartOptions" style="background: #fff;">
    <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [yaxis]="chartOptions.yaxis"
      [xaxis]="chartOptions.xaxis"
      [stroke]="chartOptions.stroke"
      [dataLabels]="chartOptions.dataLabels" />
  </div>

  <div *ngIf="cardData && selectedType === '1'"> 
    <app-mp-card-info [card]="cardData"></app-mp-card-info>
  </div>

</section>
<section style="margin-top: 16px;">
  <ag-grid-angular
    #agGrid
    (gridReady)="onGridReady($event)"
    (filterChanged)="onGridFilterChanged()"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [enableRangeSelection]="true"
    [gridOptions]="salesGridOptions"
    [localeText]="localeTextForFilters"
    [pagination]="true"
    [paginationPageSize]="20"
    [tooltipShowDelay]="0"
    [domLayout]="'autoHeight'"
    [rowHeight]="30"
    [rowData]="ordersTableData"
    [pinnedBottomRowData]="summaryRow"
    overlayNoRowsTemplate="Нет данных для отображения"
    class="ag-theme-alpine"
    headerHeight="28"
    style="width: 100%">
  </ag-grid-angular>
</section>
<p-toast />

<ng-template #noPhotoTpl>
  <td class="text-center">
    <img src="/assets/images/nophoto.jpg" alt="" width="40" />
  </td>
</ng-template>
