import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-btn-cell-renderer',
  template: ` <span>{{ value }}</span> `,
})
export class AccountsSpecificCellsComponent
  implements ICellRendererAngularComp
{
  value: string;

  agInit(params: any): void {
    this.value = params.value;

    if (params.colDef) {
      switch (params.colDef.field) {
        case 'is_visible':
          if (!params.value) {
            this.value = '-';
          } else {
            this.renderAccountVisible();
          }
          break;
        case 'is_main_account':
          this.renderMainAccount();
          break;
        default:
          break;
      }
    }
  }

  refresh(params: any): boolean {
    this.value = params.value;
    return true;
  }

  private renderAccountVisible(): void {
    this.value = +this.value === 0 ? 'Да' : 'Нет';
  }

  private renderMainAccount(): void {
    if (this.value === null || +this.value === 1) {
      this.value = 'Нет';
    }
    if (this.value === '-' || +this.value === 0) {
      this.value = 'Да';
    }
  }
}
