<div *ngIf="displayPayList else notForPayList">
  <div class="app-page-title">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div class="page-title-icon">
          <i class="pe-7s-albums icon-gradient bg-mean-fruit"></i>
        </div>
        <div>
          <a href="/profile/tariff">Перейти к тарифам</a>
          <div class="page-title-subheading"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="main-card mb-3 card">
    <div class="card-header">Счета на оплату</div>
    <div class="table-responsive">
      <table class="align-middle mb-0 table table-borderless table-striped table-hover">
        <thead>
        <tr>
          <th class="text-center">#</th>

          <th class="text-center">Статус</th>

          <th class="text-center">Дата счета</th>

          <th class="text-center">Дата оплаты</th>

          <th class="text-center">Название тарифа<br>сумма</th>

          <th class="text-center">Промокод</th>

          <th class="text-center">Бонусы</th>

          <th class="text-center">Сумма к оплате</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of listAccountsPay; let i = index">
          <td class="text-center text-muted">#{{ i + 1 }}</td>

          <td class="text-center text-muted">
            <div *ngIf="item.status_pay == 1" class="badge bg-success">Оплачен</div>
            <div *ngIf="item.status_pay == 0" class="badge bg-warning">В процессе</div>
          </td>


          <td class="text-center text-muted">{{ item.date_create | date:'dd.MM.yyyy' }}</td>

          <td lass="text-center text-muted">
            <div *ngIf="item.date_pay == '0000-00-00 00:00:00'" class="text-center text-muted">------</div>

            <div *ngIf="item.date_pay != '0000-00-00 00:00:00'" class="text-center text-muted"> {{ item.date_pay | date:'dd.MM.yyyy' }}</div>
          </td>

          <td class="text-center text-muted">
            <div class="widget-content p-0">
              <div class="flex-centered">
                <div class="flex-column-centered">
                  <div class="widget-heading">{{ item.tariff_name }}</div>
                  <div class="widget-subheading opacity-7">
                    сумма {{ item.tariff_price | currency:'RUB':'symbol-narrow' }}
                  </div>
                </div>
              </div>
            </div>
          </td>

          <td class="text-center text-muted">
            <div *ngIf="item.promokod !=''" class="widget-content p-0">
              <div class="flex-centered">
                <div class="flex-column-centered">
                  <div class="widget-heading">{{ item.promokod }}</div>
                  <div *ngIf="item.promokod_pecent > 0" class="widget-subheading opacity-7">
                    скидка {{ item.promokod_pecent }}%
                  </div>
                </div>
              </div>
            </div>
          </td>

          <td class="text-center text-muted">
            <span *ngIf="item.bonuses; else noTpl">
              {{ item.bonuses | currency:'₽ ' }}
            </span>
          </td>

          <td class="text-center text-muted">{{ item.cost | currency:'₽ ' }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ng-template #notForPayList>
  <div class="app-page-title">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div class="page-title-icon">
          <i class="pe-7s-albums icon-gradient bg-mean-fruit"></i>
        </div>
        <div>
          <a href="/profile/bill">Вернуться к списку счетов</a>
        </div>
      </div>
    </div>
  </div>

  <form [formGroup]="form">
    <div class="tariffCard">
      <div class="main-card mb-3 card p-4">
        <h3 class="fw-500">
          Ваш тариф: {{ currentTariff?.name }}
        </h3>

        <h5 class="ml-15">Действует до: {{ currentTariff?.expdate }} </h5>
        <h5 class="ml-15 mb-3">Осталось дней: {{ currentTariff?.days }} </h5>

        <div class="mb-3 flex flex-column gap-2">
          <p-dropdown [options]="tariffPlans"
                      [style]="{'width' : '100%'}"
                      class="no-event"
                      formControlName="tariffPlan"
                      placeholder="Выбирите тариф"
                      [showClear]="false" />
          <p-dropdown [options]="tariffPeriods"
                      [style]="{'width' : '100%'}"
                      class="no-event"
                      formControlName="tariffPeriod"
                      placeholder="Выбирите период оплаты"
                      [showClear]="false" />
        </div>

        <h5 class="ml-15">Действует до: {{ form.controls.expiredDay.value ?? 'Не расчитано' }} </h5>
        <h5 class="ml-15 mb-3">Доступно дней: {{ form.controls.daysAdded.value }} </h5>


        <div class="mb-3 flex gap-2">
          <input type="text"
                 [style]="{'width' : '70%'}"
                 placeholder="Введите промокод для скидки"
                 formControlName="promokod"
                 pInputText />

          <p-button (click)="applyPromokod()"
                    [disabled]="!form.controls.promokod.value"
                    label="Применить" />
        </div>

        <button *ngIf="isTransferUnavailable else payForm" class="btn-wide mb-2 mr-2 btn btn-danger"
                style="font-size: 16px; width: 23vw; margin: auto">
          Переход невозможен
        </button>

        <ng-template #payForm>
          <div *ngIf="(form.controls.tariffPeriod.value !== 'Перерасчет')"
               class="ml-15 flex align-items-center gap-1 mb-2">
            <h5 class="fw-500">Сумма к оплате:</h5>
            <h4 class="fw-500 widget-numbers text-success">
              {{ paymentSum | mpThousandSeparator }}₽
            </h4>
          </div>
          <button *ngIf="!isTransferUnavailable" class="btn-wide mb-2 mr-2 btn btn-success"
                  style="font-size: 16px; width: 60%; margin: auto">
            <a href="/profile/bill?id={{hashId}}&pay=1" class="text-white">
              {{ form.controls.tariffPeriod.value === 'Перерасчет' ? 'Перейти' : 'Оплатить онлайн' }}
            </a>
          </button>
        </ng-template>
      </div>
    </div>
  </form>
</ng-template>

<div *ngIf="displayPayment" class="row">
  <div class="col-md-6">
    <div *ngIf="paymentStatus else notPaid" class="mb-3 card text-white card-body bg-success">
      <h5 class="text-white card-title">
        Счет оплачен поздравляем
      </h5>
      Статус платежа обычно обновлется в течении 5 минут
    </div>
    <ng-template #notPaid>
      <div class="mb-3 card text-white card-body bg-danger">
        <h5 class="text-white card-title">
          Счет не оплчен повторите попытку заново
        </h5>
      </div>
    </ng-template>

  </div>
</div>
<p-toast />

<ng-template #noTpl></ng-template>
