<app-page-header-notification-bar
  *ngIf="text || subtext"
  [headerTitle]="text"
  [barText]="subtext" />

<div class="app-page-title app-page-title-simple">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <i class="pe-7s-cart icon-gradient bg-mean-fruit"></i>
      </div>
      <div class="get-price-title">
        <div>
          Самовыкупы
          <i
            class="fa fa-question-circle"
            aria-hidden="true"
            [pTooltip]="
              'Пример номера заказа для \n WB:41054105590859084.0.0 \n Ozon:57557577-0057-1'
            "></i>
        </div>
        <app-mp-video-play [videoLink]="getVideoLinkByPage('guides/buyouts')" />
        <!--        <div class="download_part">-->
        <!--           <span [pTooltip]="-->
        <!--           'Самовыкупы можно загружать через эксель, добавлять по 1 шт и скачивать.' +-->
        <!--            'При загрузки уже существующего ID чека,данные заменяються. ' +-->
        <!--            'шаблон для загрузки.'" class="pe-2" tooltipPosition="right">-->
        <!--               <fa-icon [icon]="faQuestionCircle"></fa-icon>-->
        <!--           </span>-->
        <!--          <a class="link-part-buyouts" href="../../../../assets/images/download/buyouts_template.xlsx">Скачать</a>-->
        <!--        </div>-->
      </div>
    </div>
    <div class="right-side-header">
      <!--      <button (click)="exportExcel()"-->
      <!--              class="p-button-success mr-2"-->
      <!--              icon="pi pi-file-excel"-->
      <!--              pButton pRipple-->
      <!--              pTooltip="Выгрузить в эксель"-->
      <!--              tooltipPosition="bottom"-->
      <!--              type="button">-->
      <!--      </button>-->
      <!--      <p-fileUpload #uploadedFiles (onError)="onBasicUploadError($event,uploadedFiles)"-->
      <!--                    (onUpload)="onBasicUploadAuto($event,uploadedFiles)"-->
      <!--                    [auto]="true"-->
      <!--                    [maxFileSize]="10000000" accept=".xls, .xlsx, .csv"-->
      <!--                    chooseLabel="Загрузить из excel"-->
      <!--                    class="button-add-purchase mr-4 inline-block"-->
      <!--                    label="Import"-->
      <!--                    mode="basic"-->
      <!--                    name="file"-->
      <!--                    url="{{url_link}}data?action=upload&type=purchase&token={{token}}"-->
      <!--      ></p-fileUpload>-->
      <button
        (click)="addNewBuyout()"
        class="p-button-success mr-2"
        icon="pi pi-plus"
        label="Добавить 1 самовыкуп"
        pButton
        pRipple
        style="font-size: 1rem"></button>
    </div>
  </div>
</div>
<div class="card">
    <ag-grid-angular
      (cellValueChanged)="onCellValueChanged($event)"
      [columnDefs]="columnDataForGrid"
      [defaultColDef]="defaultColDef"
      [enableRangeSelection]="true"
      [gridOptions]="gridOptions"
      [pagination]="true"
      [paginationPageSize]="30"
      [domLayout]="'autoHeight'"
      [rowData]="rowData"
      [rowHeight]="40"
      overlayNoRowsTemplate="Нет данных для отображения"
      class="ag-theme-alpine"
      style="width: 100%;">
    </ag-grid-angular>
</div>
<p-confirmDialog
  [baseZIndex]="10000"
  [style]="{ width: '30vw' }"></p-confirmDialog>
