<app-page-header-notification-bar
  *ngIf="text || subtext"
  [headerTitle]="text"
  [barText]="subtext" />
<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <i class="pe-7s-box2 icon-gradient bg-mean-fruit"></i>
      </div>
      <div>Список счетов</div>
      <app-mp-video-play
        [videoLink]="getVideoLinkByPage('fin/settings/accounts')" />
    </div>
    <div class="page-title-actions">
      <button
        (click)="openCreateAccountModal(createUpdateAccountModalNew)"
        class="btn-shadow d-inline-flex align-items-center btn btn-success"
        type="button">
        Добавить счет
      </button>
    </div>
  </div>
</div>
<div *ngIf="rowData?.length !== 0" class="main-card mb-3 card">
    <ag-grid-angular
      (gridReady)="onGridReady($event)"
      [columnDefs]="finAccountsColumnDF"
      [defaultColDef]="defaultColDef"
      [enableRangeSelection]="true"
      [gridOptions]="gridOptions"
      [localeText]="localeTextForFilters"
      [pagination]="true"
      [paginationPageSize]="30"
      [domLayout]="'autoHeight'"
      [rowData]="rowData"
      overlayNoRowsTemplate="Нет данных для отображения"
      class="ag-theme-alpine"
      style="width: 100%;">
    </ag-grid-angular>
</div>

<!-- НОВАЯ ФОРМА СОЗДАНИЯ СЧЕТА-->
<ng-template #createUpdateAccountModalNew let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ isEditModal ? 'Изменение' : 'Добавление' }} счета
    </h4>
    <p (click)="d('Cross click')" class="m-0">
      <i
        aria-hidden="true"
        class="fa fa-times"
        style="font-size: 24px; cursor: pointer"></i>
    </p>
  </div>
  <form
    [formGroup]="accountForm"
    autocomplete="off"
    class="needs-validation"
    novalidate>
    <div class="modal-body">
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="name_label1">
          Название
          <span style="color: red">*</span>
        </label>
        <div class="col-sm-9">
          <input
            class="form-control"
            formControlName="name"
            id="name_label1"
            name="name"
            placeholder="Введите название"
            type="text" />
        </div>
      </div>
      <!--            <div class="position-relative row form-group">-->
      <!--                <label class="col-sm-3 col-form-label" for="type_lable">-->
      <!--                    Тип счета-->
      <!--                    <span style="color:red">*</span>-->
      <!--                </label>-->
      <!--                <div class="col-sm-9 mt-2">-->
      <!--                    <select (change)="autoSetupCredentials($event.target.value)"-->
      <!--                            class="form-control"-->
      <!--                            formControlName="type"-->
      <!--                            id="type_lable"-->
      <!--                            name="type">-->
      <!--                        <option *ngFor="let pf of accountType"-->
      <!--                                [ngValue]="pf.id">-->
      <!--                            {{ pf.name }}-->
      <!--                        </option>-->
      <!--                    </select>-->
      <!--                </div>-->
      <!--            </div>-->

      <div *ngIf="creditLoanSelected" class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="name_label1">
          Доступные средства
          <span style="color: red">*</span>
        </label>
        <div class="col-sm-9 mt-2">
          <input
            class="form-control"
            formControlName="credit_limit"
            name="name"
            placeholder="Введите сумму доступных средств"
            type="number" />
        </div>
      </div>

      <!--<div *ngIf="creditLimitSelected"
           class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="name_label1">
          Доступные средства
          <span style="color:red">*</span>
        </label>
        <div class="col-sm-9 mt-2">
          <input class="form-control"
                 formControlName="credit_limit"
                 name="name"
                 placeholder="Введите сумму доступных средств"
                 type="number">
        </div>
      </div>-->
      <div *ngIf="creditLimitSelected" class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="name_label1">
          Кредитный лимит
          <span style="color: red">*</span>
        </label>
        <div class="col-sm-9 mt-2">
          <input
            class="form-control"
            formControlName="credit_limit"
            name="name"
            placeholder="Введите кредитный лимит"
            type="text" />
        </div>
      </div>

      <div *ngIf="creditLoanSelected" class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="balance_label">
          Основной долг
          <span style="color: red">*</span>
        </label>
        <div class="col-sm-9" style="margin-top: 10px">
          <div class="column-date-part" style="display: flex">
            <input
              class="form-control"
              formControlName="balance_init"
              name="balance_init"
              placeholder="Основной долг"
              type="text" />
            <input
              #d1="ngbDatepicker"
              (click)="d1.toggle()"
              autocomplete="off"
              class="form-control"
              formControlName="date_balance_init"
              id="date_balance_init"
              name="dp1"
              ngbDatepicker
              placeholder="DD.MM.YYYY" />
          </div>
        </div>
      </div>

      <div *ngIf="!creditLoanSelected" class="position-relative row form-group">
        <label class="col-sm-3 col-form-label">
          Начальный остаток
          <span style="color: red">*</span>
        </label>
        <div class="col-sm-9" style="margin-top: 10px">
          <div class="column-date-part" style="display: flex">
            <p-inputNumber
              [maxlength]="15"
              placeholder="Начальный остаток"
              [style]="{ width: '200', height: '37px', fontSize: '12px' }"
              formControlName="balance_init"
              mode="decimal"
              locale="ru-RU"
              [minFractionDigits]="2" />
            <input
              #d1="ngbDatepicker"
              (click)="d1.toggle()"
              autocomplete="off"
              class="form-control"
              readonly
              formControlName="date_balance_init"
              name="dp1"
              ngbDatepicker
              placeholder="DD.MM.YYYY" />
          </div>
        </div>
      </div>
      <!--            <div class="position-relative row form-group">-->
      <!--                <label class="col-sm-3 col-form-label" for="is_visible1">Скрыть счет</label>-->
      <!--                <div class="col-sm-9" style="margin-top: 4px;">-->
      <!--                    <input formControlName="is_visible" id="is_visible1"-->
      <!--                           name="checkbox"-->
      <!--                           type="checkbox">-->
      <!--                </div>-->
      <!--            </div>-->
      <!--            <div class="position-relative row form-group">-->
      <!--                <label class="col-sm-3 col-form-label" for="is_visible2">Основной счет</label>-->
      <!--                <div class="col-sm-9" style="margin-top: 4px;">-->
      <!--                    <input formControlName="is_main_account"-->
      <!--                           id="is_visible2"-->
      <!--                           name="checkbox"-->
      <!--                           type="checkbox">-->
      <!--                </div>-->
      <!--            </div>-->

      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="description_l">
          Описание
        </label>
        <div class="col-sm-9">
          <textarea
            class="form-control"
            formControlName="description"
            id="description_l"
            name="description">
          </textarea>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        (click)="c('Close click')"
        class="btn btn-secondary"
        type="button">
        Закрыть
      </button>
      <button
        (click)="createNewAccount()"
        *ngIf="!isEditModal"
        [disabled]="accountForm.invalid || accountForm.disabled"
        class="btn btn-primary"
        type="submit">
        Добавить
      </button>
      <button
        (click)="updateAccount(activeUID)"
        *ngIf="isEditModal"
        [disabled]="accountForm.invalid || accountForm.disabled"
        class="btn btn-primary"
        type="submit">
        Изменить
      </button>
    </div>
  </form>
</ng-template>
<p-confirmDialog [baseZIndex]="10000" [style]="{ width: '30vw' }" />
<p-toast />
