<app-page-header-notification-bar
  *ngIf="text || subtext"
  [headerTitle]="text"
  [barText]='subtext'
/>
<div class="col-sm-12 col-md-12">
  <div class="mb-3 card text-white card-body bg-warning">
    <h5 class="card-title">
      Обращаем внимание, цифры ниже не исключают сделанные самовыкупы
    </h5>
  </div>
  <br />
</div>
<div class="app-page-title">
  <div class="page-title-wrapper gap-3">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <i class="lnr-map icon-gradient bg-mean-fruit"></i>
      </div>
      <div>
        Тепловая карта
        <i
          class="fa fa-question-circle"
          aria-hidden="true"
          [pTooltip]="
            'Строится на основе реализации. Отчет по реализации доступен до ' +
            lastReportDate
          ">
        </i>
      </div>
      <div class="flex gap-2">
        <app-mp-video-play
          [videoLink]="getVideoLinkByPage('dashboard/heatmap')" />
        <app-mp-ngb-range-datepicker
          [initialData]="selectedDate"
          (selectedDateEmitter)="dateChanged($event)" 
        />
      </div>
    </div>

    <div class="page-title-heading">
      <p-dropdown
        (onChange)="changeFilter()"
        [(ngModel)]="categoryValue"
        [options]="selectCategory"
        [showClear]="true"
        [style]="{ display: 'flex', alignItems: 'center', height: '32px' }"
        emptyMessage="Список пуст"
        optionLabel="name"
        placeholder="Категория" />
      <p-dropdown
        (onChange)="changeFilter()"
        [(ngModel)]="subjectValue"
        [options]="selectSubject"
        [showClear]="true"
        [style]="{ display: 'flex', alignItems: 'center', height: '32px' }"
        emptyMessage="Предметы не найдены"
        optionLabel="name"
        placeholder="Предмет" />
      <p-dropdown
        (onChange)="changeFilter()"
        [(ngModel)]="skuValue"
        [filter]="true"
        [options]="selectSku"
        [showClear]="true"
        [style]="{ display: 'flex', alignItems: 'center', height: '32px' }"
        emptyMessage="Список пуст"
        optionLabel="name"
        placeholder="Артикул" />
    </div>
  </div>
  <div *ngIf="SHOP_ID === 0" class="col-md-12">
    <div class="main-card mb-3 card">
      <ul class="list-group">
        <li class="list-group-item-danger list-group-item">
          Необходимо выбрать конкретный магазин из списка справо вверху
        </li>
      </ul>
    </div>
  </div>
  <!--   ЗАКАЗЫ  -->
  <div *ngIf="!isLoading; else loading" class="row">
    <div class="col-md-12 col-lg-12 col-xl-12">
      <div class="main-card mb-3 card">
        <div class="rm-border card-header">
          <div>
            <h5 class="menu-header-title text-primary">Заказы, шт</h5>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="ordersByDayOfTheWeek" class="col-md-6 col-lg-6 col-xl-6">
      <div class="main-card mb-3 card">
        <div class="card-header">Количество заказов по дням недели</div>
        <div class="pt-0 card-body">
          <apx-chart
            [chart]="ordersByDayOfTheWeek?.chart"
            [colors]="ordersByDayOfTheWeek?.colors"
            [dataLabels]="ordersByDayOfTheWeek?.dataLabels"
            [plotOptions]="ordersByDayOfTheWeek?.plotOptions"
            [series]="ordersByDayOfTheWeek?.series"
            [stroke]="ordersByDayOfTheWeek?.stroke"
            [xaxis]="ordersByDayOfTheWeek?.xaxis">
          </apx-chart>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-6 col-xl-6">
      <div *ngIf="ordersByDayOfWeekFromTo" class="main-card mb-3 card">
        <div class="card-header">
          Количество заказов по дням недели c
          {{ selectedDate.startDate.format('DD.MM.YYYY') }} по
          {{ selectedDate.endDate.format('DD.MM.YYYY') }}
        </div>
        <div class="pt-0 card-body">
          <apx-chart
            [chart]="ordersByDayOfWeekFromTo?.chart"
            [dataLabels]="ordersByDayOfWeekFromTo?.dataLabels"
            [fill]="{ opacity: 1 }"
            [plotOptions]="ordersByDayOfWeekFromTo?.plotOptions"
            [series]="ordersByDayOfWeekFromTo?.series"
            [stroke]="ordersByDayOfWeekFromTo?.stroke"
            [xaxis]="ordersByDayOfWeekFromTo?.xaxis"
            [yaxis]="{}" />
        </div>
      </div>
    </div>
    <div class="col-md-12 col-lg-12 col-xl-12">
      <div *ngIf="ordersByHoursFromTo" class="main-card mb-3 card">
        <div class="card-header">
          Количество заказов по часам c
          {{ selectedDate.startDate.format('DD.MM.YYYY') }} по
          {{ selectedDate.endDate.format('DD.MM.YYYY') }}
        </div>
        <div class="pl-3 pr-3 pb-2 slick-slider-md slick-slider-hover">
          <apx-chart
            [chart]="ordersByHoursFromTo?.chart"
            [dataLabels]="{ enabled: true }"
            [fill]="{ opacity: 1 }"
            [plotOptions]="ordersByHoursFromTo?.plotOptions"
            [series]="ordersByHoursFromTo?.series"
            [stroke]="ordersByHoursFromTo?.stroke"
            [xaxis]="ordersByHoursFromTo?.xaxis"
            [yaxis]="{}" />
        </div>
      </div>
    </div>
  </div>
  <!--   ПРОДАЖИ  -->
  <div *ngIf="!isLoading; else loading" class="row">
    <div class="col-md-12 col-lg-12 col-xl-12">
      <div class="main-card mb-3 card">
        <div class="rm-border card-header">
          <div>
            <h5 class="menu-header-title text-primary">Продажи, шт</h5>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-6 col-xl-6">
      <div *ngIf="salesByDayOfTheWeek" class="main-card mb-3 card">
        <div class="card-header">Количество продаж по дням недели</div>
        <div class="pt-0 card-body">
          <apx-chart
            [chart]="salesByDayOfTheWeek?.chart"
            [colors]="salesByDayOfTheWeek?.colors"
            [dataLabels]="salesByDayOfTheWeek?.dataLabels"
            [plotOptions]="salesByDayOfTheWeek?.plotOptions"
            [series]="salesByDayOfTheWeek?.series"
            [stroke]="salesByDayOfTheWeek?.stroke"
            [title]="salesByDayOfTheWeek?.title"
            [xaxis]="salesByDayOfTheWeek?.xaxis">
          </apx-chart>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-6 col-xl-6">
      <div *ngIf="salesByDayFromTo" class="main-card mb-3 card">
        <div class="card-header">
          Количество продаж по дням c
          {{ selectedDate.startDate.format('DD.MM.YYYY') }} по
          {{ selectedDate.endDate.format('DD.MM.YYYY') }}
        </div>
        <div class="pt-0 card-body">
          <apx-chart
            [chart]="salesByDayFromTo?.chart"
            [dataLabels]="salesByDayFromTo?.dataLabels"
            [fill]="{ opacity: 1 }"
            [plotOptions]="salesByDayFromTo?.plotOptions"
            [series]="salesByDayFromTo?.series"
            [stroke]="salesByDayFromTo?.stroke"
            [xaxis]="salesByDayFromTo?.xaxis"
            [yaxis]="{}" />
        </div>
      </div>
    </div>
    <div class="col-md-12 col-lg-12 col-xl-12">
      <div *ngIf="salesByHoursFromTo" class="main-card mb-3 card">
        <div class="card-header">
          Количество продаж по часам c
          {{ selectedDate.startDate.format('DD.MM.YYYY') }} по
          {{ selectedDate.endDate.format('DD.MM.YYYY') }}
        </div>
        <div class="pl-3 pr-3 pb-2 slick-slider-md slick-slider-hover">
          <apx-chart
            [chart]="salesByHoursFromTo?.chart"
            [dataLabels]="{ enabled: true }"
            [fill]="{ opacity: 1 }"
            [plotOptions]="salesByHoursFromTo?.plotOptions"
            [series]="salesByHoursFromTo?.series"
            [stroke]="salesByHoursFromTo?.stroke"
            [xaxis]="salesByHoursFromTo?.xaxis"
            [yaxis]="{}" />
        </div>
      </div>
    </div>
  </div>
  <!--   СРЕДНИЙ ЧЕК  -->
  <div *ngIf="!isLoading; else loading" class="row">
    <div class="col-md-12 col-lg-12 col-xl-12">
      <div class="main-card mb-3 card">
        <div class="rm-border card-header">
          <div>
            <h5 class="menu-header-title text-primary">Заказы, руб</h5>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="ordersAverageCheckByDayOfWeek"
      class="col-md-6 col-lg-6 col-xl-6">
      <div class="main-card mb-3 card">
        <div class="card-header">Средний чек по заказам по дням недели</div>
        <div class="pt-0 card-body">
          <apx-chart
            [chart]="ordersAverageCheckByDayOfWeek?.chart"
            [colors]="ordersAverageCheckByDayOfWeek?.colors"
            [dataLabels]="ordersAverageCheckByDayOfWeek?.dataLabels"
            [plotOptions]="ordersAverageCheckByDayOfWeek?.plotOptions"
            [series]="ordersAverageCheckByDayOfWeek?.series"
            [stroke]="ordersAverageCheckByDayOfWeek?.stroke"
            [title]="ordersAverageCheckByDayOfWeek?.title"
            [xaxis]="ordersAverageCheckByDayOfWeek?.xaxis">
          </apx-chart>
        </div>
      </div>
    </div>
    <div *ngIf="ordersInRubblesByDay" class="col-md-6 col-lg-6 col-xl-6">
      <div class="main-card mb-3 card">
        <div class="card-header">
          Заказы в рублях по дням c
          {{ selectedDate.startDate.format('DD.MM.YYYY') }} по
          {{ selectedDate.endDate.format('DD.MM.YYYY') }}
        </div>
        <div class="pt-0 card-body">
          <apx-chart
            [chart]="ordersInRubblesByDay?.chart"
            [dataLabels]="ordersInRubblesByDay?.dataLabels"
            [fill]="ordersInRubblesByDay?.fill"
            [plotOptions]="ordersInRubblesByDay?.plotOptions"
            [series]="ordersInRubblesByDay?.series"
            [stroke]="ordersInRubblesByDay?.stroke"
            [xaxis]="ordersInRubblesByDay?.xaxis"
            [yaxis]="ordersInRubblesByDay?.yaxis" />
        </div>
      </div>
    </div>
    <div
      *ngIf="ordersAverageReceiptByHours"
      class="col-md-12 col-lg-12 col-xl-12">
      <div class="main-card mb-3 card">
        <div class="card-header">
          Средний чек заказов по часам c
          {{ selectedDate.startDate.format('DD.MM.YYYY') }} по
          {{ selectedDate.endDate.format('DD.MM.YYYY') }}
        </div>
        <div class="pl-3 pr-3 pb-2 slick-slider-md slick-slider-hover">
          <apx-chart
            [chart]="ordersAverageReceiptByHours?.chart"
            [dataLabels]="ordersAverageReceiptByHours?.dataLabels"
            [fill]="ordersAverageReceiptByHours?.fill"
            [plotOptions]="ordersAverageReceiptByHours?.plotOptions"
            [series]="ordersAverageReceiptByHours?.series"
            [stroke]="ordersAverageReceiptByHours?.stroke"
            [xaxis]="ordersAverageReceiptByHours?.xaxis"
            [yaxis]="ordersAverageReceiptByHours?.yaxis" />
        </div>
      </div>
    </div>
  </div>
  <ng-template #loading>
    <div class="font-icon-wrapper float-left mr-12 mb-12">
      <div class="col-md-12 col-lg-12 col-xl-12">
        <div class="main-card mb-3 card">
          <div
            class="loader-wrapper d-flex justify-content-center align-items-center">
            <div class="loader">
              <div class="ball-pulse">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
<p-toast />
