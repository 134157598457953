export const ROUTES = {
  DASHBOARD_RATES: '/dashboard/rates',
  DASHBOARD_ABS: '/dashboard/abc',
  DASHBOARD_ORDERS: '/dashboard/orders',
  DASHBOARD_SALES: '/dashboard/sales',
  DASHBOARD_HEATMAP: '/dashboard/heatmap',
  DASHBOARD_REMAINS: '/dashboard/remains',
  FIN_OPERATIONS: '/fin/operations',
  FIN_DDS: '/fin/dds',
  FIN_PNL: '/fin/pnl',
  FIN_PNL_CONSOLIDATION: '/fin/pnl-consolidation',
  FIN_SETTINGS_ACCOUNTS: '/fin/settings/accounts',
  SETTINGS_ITEMS: '/settings/items',
  SETTINGS_PARTNERS: '/settings/partners',
  SETTINGS_ACCOUNTS: '/settings/accounts',
  SETTINGS_SHOP: '/settings/shop',
  GUIDES_PRODUCTS: '/guides/products',
  GUIDES_BUYOUTS: '/guides/buyouts',
  PROFILE_TARIFF: '/profile/tariff',
  PROFILE_BILL: '/profile/bill',
  PROFILE_USERS: '/profile/users',
  PROFILE_PARTNERS: '/profile/partners'
};
