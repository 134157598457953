import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AbstractGeneralTabComponent } from '../../abstract-general-tab.component';
import { IBillPromokod, IBillsPayList } from './bills.interface';
import { Params } from '@angular/router';
import {
  ICurrentTariff,
  TariffPlane,
  TariffPlansTitles,
} from '../tariff/tariff.interface';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  TARIFF_PERCENTS,
  TARIFF_PLANS,
  TARIFF_PLANS_PRICE_PER_DAY,
  TARIFF_PRICES,
} from '../tariff/tariff.mock';
import moment from 'moment';
import { LOAD_STATUSES, LOAD_STATUSES_EN } from 'app/shared/contants/statuses';
import { PopUpMessages } from 'app/shared/mocks/pop-up-messages.mock';

const types = [
  LOAD_STATUSES_EN.SUCCESS,
  LOAD_STATUSES_EN.ERROR,
  'info',
  'warning',
];

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-biils',
  templateUrl: 'bills.component.html',
  styleUrls: ['bills.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class BillsComponent
  extends AbstractGeneralTabComponent
  implements OnInit
{
  private queryParams: Params = null;
  hashId: string = null;
  displayPayList: boolean = true;
  displayPayment: string = null;
  paymentStatus: boolean = false;
  listAccountsPay: IBillsPayList[] = [];
  priceWithPromokod: number = null;
  currentTariff: ICurrentTariff = null;

  form = new FormGroup({
    promokod: new FormControl(null),
    tariffPlan: new FormControl(null, [Validators.required]),
    tariffPeriod: new FormControl(null, [Validators.required]),
    expiredDay: new FormControl(null),
    daysAdded: new FormControl(0),
  });

  tariffPlans: string[] = [];
  readonly tariffPeriods = TARIFF_PERCENTS.map(a => a.name).slice(
    0,
    TARIFF_PERCENTS.length - 1
  );

  isTransferUnavailable = false;

  paymentSum: number = 0;

  constructor() {
    super();
    this._route.queryParams.pipe(untilDestroyed(this)).subscribe(params => {
      this.queryParams = params;
      if (params.payment) {
        this.displayPayment = params.payment;
      }
      if (params.id) {
        this.displayPayList = false;
        this.hashId = params.id;
        if (params.pay) {
          return this.generateUrlPayment();
        }
      } else {
        this.displayPayList = true;
      }
    });
  }

  ngOnInit() {
    if (this.queryParams.pay) {
      return this.generateUrlPayment();
    }
    if (this.hashId) {
      this.loadBillByHash(this.hashId);
    } else {
      if (this.displayPayment) {
        this.paymentStatus = this.displayPayment === 'ok';
        this.loadBills();
      } else {
        this.loadBills();
      }
    }

    this.loadTariff();

    const currentTariff = TARIFF_PLANS.filter(
      a => a.code === this.queryParams.plan
    );
    if (currentTariff && currentTariff.length) {
      this.form.controls['tariffPlan']?.patchValue(currentTariff[0].title);
    }

    const currentTariffPeriod = TARIFF_PERCENTS.filter(
      a => a.selector === this.queryParams.period
    );
    if (currentTariff && currentTariff.length) {
      this.form.controls['tariffPeriod']?.patchValue(
        currentTariffPeriod[0].name
      );
    }

    this.form.controls.tariffPlan.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((controlValue: string) => {
        this.currentTariff.priceForUpcomingTariff =
          TARIFF_PLANS_PRICE_PER_DAY[this.tariffCode(controlValue)];
        const availableDaysByPeriod = TARIFF_PERCENTS.filter(
          a => a.name === this.form.controls.tariffPeriod.value
        )[0]?.days;

        const planCode = TARIFF_PLANS.filter(a => a.title === controlValue)[0]
          ?.code;
        const selector = TARIFF_PERCENTS.filter(
          a => a.name === this.form.controls.tariffPeriod.value
        )[0]?.selector;
        this.paymentSum =
          TARIFF_PRICES[selector][planCode] * TARIFF_PRICES[selector]?.months;

        this.countDaysAdded(availableDaysByPeriod);
      });

    this.form.controls.tariffPeriod.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((controlValue: string) => {
        const currentPeriod = TARIFF_PERCENTS.filter(
          a => a.name === controlValue
        )[0];
        const availableDaysByPeriod = currentPeriod?.days;

        const planCode = TARIFF_PLANS.filter(
          a => a.title === this.form.controls.tariffPlan.value
        )[0]?.code;
        const selector = currentPeriod.selector;
        this.paymentSum =
          TARIFF_PRICES[selector][planCode] * TARIFF_PRICES[selector]?.months;

        this.countDaysAdded(availableDaysByPeriod);
      });

    if (this.queryParams?.period && this.queryParams?.plan) {
      this.paymentSum =
        TARIFF_PRICES[this.queryParams?.period][this.queryParams?.plan] *
        TARIFF_PRICES[this.queryParams?.period]?.months;
    }
  }

  private loadTariff(): void {
    this._mpSurfService
      .loadByOld({}, 'getTariff', 'data')
      .pipe(untilDestroyed(this))
      .subscribe((response: ICurrentTariff) => {
        this.currentTariff = response;

        this.currentTariff.onCash =
          TARIFF_PLANS_PRICE_PER_DAY[this.tariffCode(this.currentTariff.name)] *
          +this.currentTariff.days;
        this.currentTariff.priceForUpcomingTariff =
          TARIFF_PLANS_PRICE_PER_DAY[
            this.tariffCode(this.form.controls.tariffPlan.value)
          ];

        const activeDaysByPeriod = TARIFF_PERCENTS.filter(
          a => a.selector === this.queryParams.period
        )[0]?.days;

        this.tariffPlans = TARIFF_PLANS.slice(0, TARIFF_PLANS.length - 1).map(
          a => a.title
        );

        if (this.currentTariff.name !== TariffPlansTitles.FREE) {
          this.tariffPlans = this.tariffPlans.slice(
            this.tariffPlans.indexOf(this.currentTariff.name),
            this.tariffPlans.length
          );
        }

        this.countDaysAdded(activeDaysByPeriod);
      });
  }

  private countDaysAdded(availableDaysByPeriod: number) {
    if (
      this.form.controls.tariffPeriod.value === 'Перерасчет' &&
      this.currentTariff.onCash < this.currentTariff.priceForUpcomingTariff
    ) {
      this.isTransferUnavailable = true;

      this.form.controls.expiredDay.patchValue(null);
      this.form.controls.daysAdded.patchValue(0);
    } else {
      this.isTransferUnavailable = false;
      
      const availableDaysByCurrentTariff = Math.ceil(
        this.currentTariff.onCash / this.currentTariff.priceForUpcomingTariff
      );

      this.form.controls.daysAdded.patchValue(
        availableDaysByCurrentTariff + availableDaysByPeriod
      );

      this.form.controls.expiredDay.patchValue(
        moment()
          .add(availableDaysByCurrentTariff + availableDaysByPeriod, 'days')
          .format('DD.MM.YYYY')
      );
    }
  }

  getPriceForCurrentTariff(plan: string) {}

  applyPromokod(): void {
    const promokod = this.form.controls.promokod.value;
    if (promokod) {
      const body = {
        promokod,
        id_hash: this.hashId,
      };
      this._mpSurfService
        .loadByOld(body, 'getPromokod', 'data')
        .pipe(untilDestroyed(this))
        .subscribe(
          (data: IBillPromokod) => {
            if (+data.is_error === 1) {
              this.showPopUpMessage(
                LOAD_STATUSES_EN.ERROR,
                LOAD_STATUSES.ERROR,
                data.msg
              );
            }
            if (+data.is_error === 0) {
              if(data.tariff_extended){
                this._router.navigate(['profile', 'tariff'])
              } else {
                this.paymentSum =
                +this.paymentSum - (+this.paymentSum * data.percent) / 100;
                this.priceWithPromokod = data.price;
                this.showPopUpMessage(
                  LOAD_STATUSES_EN.SUCCESS,
                  LOAD_STATUSES.SUCCESS,
                  data.msg
                );
              }
            }
          },
          () => {
            this.showPopUpMessage(
              LOAD_STATUSES_EN.ERROR,
              PopUpMessages.loadFailedSummary,
              PopUpMessages.loadFailedMessages
            );
          }
        );
    }
  }

  private loadBills(): void {
    this._mpSurfService
      .loadByOld({}, 'getListBill', 'data')
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: IBillsPayList[]) => {
          this.listAccountsPay = [...response];
          if (this.queryParams?.InvId) {
            const activePay = response.filter(
              a => a.ID === this.queryParams?.InvId
            );
            if (activePay) {
              this.showPopUpMessage(
                LOAD_STATUSES_EN.SUCCESS,
                'Оплата прошла успешно',
                'Ваш Счет успешно оплачен'
              );
            }
          }
        },
        () => {
          this.showPopUpMessage(
            LOAD_STATUSES_EN.ERROR,
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }

  private loadBillByHash(hash: string): void {
    this._mpSurfService
      .loadByOld({ hash }, 'getOneBill', 'data')
      .pipe(untilDestroyed(this))
      .subscribe(
        (data: any) => {
          if (+data.is_error === 1) {
            this.navigateBill();
          }
        },
        () => {
          this.showPopUpMessage(
            LOAD_STATUSES_EN.ERROR,
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }

  deleteBill(hash: string) {
    this._mpSurfService
      .loadByOld({ hash }, 'deleteBill', 'data')
      .pipe(untilDestroyed(this))
      .subscribe(
        (data: any) => {
          if (data) {
            this.navigateBill();
            this.showPopUpMessage(
              LOAD_STATUSES_EN.ERROR,
              PopUpMessages.deleteSuccessSummary,
              PopUpMessages.deleteSuccessMessage
            );
          } else {
            this.showPopUpMessage(
              LOAD_STATUSES_EN.ERROR,
              PopUpMessages.deleteFailedSummary,
              PopUpMessages.deleteFailedMessage
            );
          }
        },
        () => {
          this.showPopUpMessage(
            LOAD_STATUSES_EN.ERROR,
            PopUpMessages.deleteFailedSummary,
            PopUpMessages.deleteFailedMessage
          );
        }
      );
  }

  private generateUrlPayment(): void {
    this._mpSurfService
      .loadByOld({ id_hash: this.hashId }, 'generateUrlPayment', 'data')
      .pipe(untilDestroyed(this))
      .subscribe((a: { url: string }) => {
        window.location.href = a.url;
      });
  }

  private navigateBill(): void {
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = 'reload';
    this._router.navigate(['/profile/bill/']);
  }

  private tariffCode(name: string): string {
    let code = 'base';
    switch (name) {
      case TariffPlansTitles.BASE:
        code = 'base';
        break;

      case TariffPlansTitles.EXTENDED:
        code = 'extended';
        break;

      case TariffPlansTitles.PROFESSIONAL:
        code = 'professional';
        break;

      // case 'Корпоративный':
      //   code = 'corporate';
      //   break;
    }

    return code;
  }
}
