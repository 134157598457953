import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MpCommonResponse, Nullable } from '../interfaces/common.type';
import { IMpMetaData } from '../interfaces/mp-common.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MpSurfApiService {
  private readonly URL_OLD: string;

  private readonly URL_NEW: string;

  private readonly URL_ADVERTS: string;

  protected readonly shopId$ = new BehaviorSubject<Nullable<number>>(null);

  constructor(private http: HttpClient) {
    this.URL_OLD = environment.apiOld;
    this.URL_NEW = environment.apiNew;
    this.URL_ADVERTS = environment.apiAdverts;
  }

  get shopId(): number {
    return JSON.parse(localStorage.getItem('mpall_shop') || '{}')?.shop;
  }

  get token(): string {
    return localStorage.getItem('token') || '';
  }

  load<T>(params: any, URLParameter: string): Observable<T> {
    params.shop_id = this.shopId;
    params.token = this.token;
    let httpParams = new HttpParams();
    for (const key in params) {
      if (params.hasOwnProperty(key) && params[key]) {
        httpParams = httpParams.set(key, params[key]);
      }
    }
    return this.http.get<T>(`${this.URL_NEW}${URLParameter}`, {
      params: httpParams,
    });
  }

  loadByPost<T, K>(params: T, URLParameter: string): Observable<K> {
    const body = { token: this.token, shop_id: this.shopId, params };
    return this.http.post<K>(`${this.URL_NEW}${URLParameter}`, body);
  }

  loadById<T>(params: any, id: number, URLParameter: string): Observable<T> {
    params.token = this.token;
    let httpParams = new HttpParams();
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        httpParams = httpParams.set(key, params[key]);
      }
    }
    return this.http.get<T>(`${this.URL_NEW}${URLParameter}/${id}`, {
      params: httpParams,
    });
  }

  createOrUpdate<T, K>(params: T, URLParameter: string): Observable<K> {
    const body = { token: this.token, shop_id: this.shopId, params };
    return this.http.post<K>(`${this.URL_NEW}${URLParameter}`, body);
  }

  createOrUpdateWithAction(
    action: string,
    params: any,
    URLParameter: string
  ): Observable<any> {
    params.token = this.token;
    const body = { action, token: this.token, shop_id: this.shopId, params };
    return this.http.post(`${this.URL_NEW}${URLParameter}`, body);
  }

  delete(params: any, URLParameter: string): Observable<any> {
    params.token = this.token;
    params.shop_id = this.shopId;
    let httpParams = new HttpParams();
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        httpParams = httpParams.set(key, params[key]);
      }
    }
    return this.http.delete<any>(`${this.URL_NEW}${URLParameter}`, {
      params: httpParams,
    });
  }

  loadMetaData(
    shop_id: number,
    token: string
  ): Observable<MpCommonResponse<IMpMetaData>> {
    const body = {
      action: 'getMetaData',
      token,
      params: { shop_id },
    };
    return this.http.post<MpCommonResponse<IMpMetaData>>(
      environment.apiOld + 'data',
      body
    );
  }

  // LOAD FIN_PURCHASE
  loadFinPurchase<T>(params: any, URLParameter: string): Observable<T> {
    params.token = this.token;
    let httpParams = new HttpParams();
    for (const key in params) {
      if (key === 'barcode' && params[key]) {
        params[key].length === 0
          ? (httpParams = httpParams.append(key, ''))
          : (httpParams = httpParams.append(key, params[key].join(', ')));
      } else {
        httpParams = httpParams.set(key, params[key]);
      }
    }
    return this.http.get<T>(`${this.URL_NEW}${URLParameter}`, {
      params: httpParams,
    });
  }

  // LOAD ADVERTS
  loadAdverts<T>(params: any, URLParameter: string): Observable<T> {
    let httpParams = new HttpParams();
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        httpParams = httpParams.set(key, params[key]);
      }
    }
    return this.http.get<T>(`${this.URL_ADVERTS}${URLParameter}`, {
      params: httpParams,
    });
  }

  // LOAD BY OLD API
  loadByOld(params: any, action: string, URLParameter = ''): Observable<any> {
    params.shop_id = this.shopId;
    const body = { action, token: localStorage.getItem('token'), params };
    return this.http.post(
      `${this.URL_OLD}${URLParameter.length ? URLParameter : ''}`,
      body
    );
  }
}
