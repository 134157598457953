<div class="app-page-title" style="margin-bottom: 0">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <i class="pe-7s-server icon-gradient bg-mean-fruit"></i>
      </div>
      <div>Остатки</div>
    </div>
    <p-multiSelect
        (onChange)="selectWareHouses()"
        (onClear)="clearWareHouses()"
        [(ngModel)]="selectedWh"
        [maxSelectedLabels]="1"
        [options]="whList"
        [showClear]="true"
        [style]="{ display: 'flex', alignItems: 'center', height: '32px', marginLeft: '16px' }"
        defaultLabel="Склады"
        emptyMessage="Складов не найдено"
        itemSize="30"
        optionLabel="name"
        selectedItemsLabel="Выбрано {0}">
      </p-multiSelect>
  </div>
</div>

<div class="main-card mb-3 card">
  <ag-grid-angular
    #agGrid
    style="width: 100%;"
    class="ag-theme-alpine"
    [columnDefs]="dataForColumnDefs"
    [defaultColDef]="defaultColDef"
    rowSelection="multiple"
    [rowHeight]="30"
    [localeText]="localRuFilters"
    [rowData]="rowData"
    (gridReady)="onGridReady($event)"
    (dragStopped)="onDragStopped($event)"
    [gridOptions]="gridOptions"
    [tooltipShowDelay]="tooltipShowDelay"
    [tooltipHideDelay]="tooltipHideDelay"
    [pinnedBottomRowData]="summaryData"
    [rowDragManaged]="true"
    [pagination]="true"
    [paginationPageSize]="30"
    [domLayout]="'autoHeight'"
    overlayNoRowsTemplate="Нет данных для отображения"
    [suppressRowTransform]="true"
    [enableRangeSelection]="true" />
</div>
<ng-template #createCompanyModal></ng-template>
