import { CellStyle, ColDef, ColGroupDef } from 'ag-grid-community';
import { UnitPipe } from '../../../shared/pipes/unit.pipe';
import { DecimalPipe } from '@angular/common';

const ABC_COLOR_MAP: Map<string, CellStyle> = new Map()
  .set('AAA', {'background-color': 'rgb(188 233 222)'})
  .set('AAB', {'background-color': 'rgb(192 233 221)'})
  .set('AAC', {'background-color': 'rgb(197 233 220)'})
  .set('ABA', {'background-color': 'rgb(202 234 219)'})
  .set('ABB', {'background-color': 'rgb(207 234 218)'})
  .set('ABC', {'background-color': 'rgb(212 234 217)'})
  .set('ACA', {'background-color': 'rgb(217 235 216)'})
  .set('ACB', {'background-color': 'rgb(222 235 215)'})
  .set('ACC', {'background-color': 'rgb(227 235 214)'})
  .set('BAA', {'background-color': 'rgb(232 236 213)'})
  .set('BAB', {'background-color': 'rgb(237 236 212)'})
  .set('BAC', {'background-color': 'rgb(242 237 211)'})
  .set('BBA', {'background-color': 'rgb(247 237 210)'})
  .set('BBB', {'background-color': 'rgb(252 238 209)'})
  .set('BBC', {'background-color': 'rgb(250 233 207)'})
  .set('BCA', {'background-color': 'rgb(249 229 205)'})
  .set('BCB', {'background-color': 'rgb(247 225 204)'})
  .set('BCC', {'background-color': 'rgb(246 221 202)'})
  .set('CAA', {'background-color': 'rgb(244 217 200)'})
  .set('CAB', {'background-color': 'rgb(243 213 199)'})
  .set('CAC', {'background-color': 'rgb(241 209 197)'})
  .set('CBA', {'background-color': 'rgb(240 205 196)'})
  .set('CBB', {'background-color': 'rgb(238 201 194)'})
  .set('CBC', {'background-color': 'rgb(237 197 192)'})
  .set('CCA', {'background-color': 'rgb(235 193 191)'})
  .set('CCB', {'background-color': 'rgb(234 189 189)'})
  .set('CCC', {'background-color': 'rgb(233 185 188)'})

const decimalPipe = new DecimalPipe('ru-RU')
const unitPipe = new UnitPipe()

export const ABS_ANALYST_COLUMN_DEFS: ColGroupDef[] = [
  {
    children: [
      {
        headerName: '',
        field: 'photo_new',
        width: 30,
        cellStyle: {paddingLeft: '0px', paddingRight: '0px'},
        pinned: 'left',
        cellRenderer: (params) => {
          if(params.data.photo_new === 'Итого'){
            return 'Итого'
          }
          if(!params.data.photo_new){
            return `<img style="height: 30px; width: 30px" src="/assets/images/nophoto.jpg" />`
          }
          return `<img style="height: 30px; width: 30px" src=${params.data.photo_new} />`
        } 
      },
      {
        headerName: 'Артикул МП',
        cellStyle: { textAlign: 'left' },
        field: 'nmID',
        width: 100,
        filter: 'agTextColumnFilter',
        pinned: 'left',
      },
      {
        headerName: 'Предмет',
        cellStyle: { textAlign: 'left' },
        field: 'object',
        width: 120,
        filter: 'agTextColumnFilter',
        pinned: 'left',
      },
      {
        headerName: 'Артикул поставщика',
        cellStyle: { textAlign: 'left' },
        field: 'sku',
        width: 200,
        filter: 'agTextColumnFilter',
        pinned: 'left',
      },
      {
        headerName: 'ABC',
        field: 'abc',
        width: 70,
        filter: 'agTextColumnFilter',
        pinned: 'left',
        cellStyle: params => {
          return ABC_COLOR_MAP.get(params.value) || {'background-color': 'null'}
        }
      },
    ]
  },
  {
    headerName: 'ABC Выручка',
    children: [
      {
        headerName: 'Выручка, руб.',
        field: 'sales',
        width: 180,
        sortable: true,
        filter: 'agTextColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
        headerTooltip: 'В выручке учтены продажи (без вычета СПП/ баллов), возвраты, сторно'
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Продажи, шт',
        field: 'sales_count',
        width: 120,
        sortable: true,
        filter: 'agTextColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
        headerTooltip: 'Кол-во продаж за выбранный период'
      },
      {
        columnGroupShow: 'closed',
        headerName: '% от выручки ',
        field: 'percent_sales',
        width: 120,
        sortable: true,
        filter: 'agTextColumnFilter',
        valueFormatter: v => unitPipe.transform(decimalPipe.transform(v.value, '1.0-1'), 'percent'),
        headerTooltip: 'Процент продажи от общей суммы продаж всех артикулов за выбранный период'
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Заказы, руб.',
        field: 'orders_sum',
        width: 120,
        sortable: true,
        filter: 'agTextColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
        headerTooltip: 'Процент продажи от общей суммы продаж всех артикулов за выбранный период'
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Заказы, шт.',
        field: 'orders',
        width: 120,
        sortable: true,
        filter: 'agTextColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
        headerTooltip: 'Кол-во заказов сделанное за выбранный период'
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Продано из заказанных',
        field: 'sales_from_orders',
        width: 120,
        sortable: true,
        filter: 'agTextColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
        headerTooltip: 'Кол-во продаж из заказов сделанных за выбранный период'
      },
      {
        columnGroupShow: 'closed',
        headerName: '% выкупа (по доехавшим)',
        field: 'percent_sales_delivered',
        width: 120,
        sortable: true,
        filter: 'agTextColumnFilter',
        valueFormatter: v => unitPipe.transform(decimalPipe.transform(v.value, '1.0-1'), 'percent'),
        headerTooltip: '% выкупа по заказам по которым уже есть решение'
      },
      {
        columnGroupShow: 'closed',
        headerName: '% выкупа (по заказанным)',
        field: 'percent_sales_redeemed',
        width: 120,
        sortable: true,
        filter: 'agTextColumnFilter',
        valueFormatter: v => unitPipe.transform(decimalPipe.transform(v.value, '1.0-1'), 'percent'),
        headerTooltip: '% выкупа по всем заказам, показатель может меняться из-за товаров "В пути"'
      },
    ]
  },
  {
    headerName: 'ABC Прибыль',
    children: [
      {
        headerName: 'Валовая прибыль, руб',
        field: 'profit',
        width: 120,
        sortable: true,
        filter: 'agTextColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
        headerTooltip: 'Продажи минус удержания которые можно распределить на артикул (например, штрафы, подписки и др. по API не распределяются по артикулам)'
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Валовая прибыль, %',
        headerClass: 'header-centered',
        field: 'percent_profit',
        width: 120,
        sortable: true,
        filter: 'agTextColumnFilter',
        valueFormatter: v => unitPipe.transform(decimalPipe.transform(v.value, '1.0-1'), 'percent'),
        headerTooltip: 'Процент прибыли от общей суммы прибыли всех артикулов за выбранный период'
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Рентабельность товара по с/с, %',
        field: 'profitability',
        width: 180,
        sortable: true,
        filter: 'agTextColumnFilter',
        valueFormatter: v => unitPipe.transform(decimalPipe.transform(v.value, '1.0-1'), 'percent'),
        headerTooltip: 'Процентное соотношение прибыли к себестоимости'
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Себес-ть (закупка), руб.',
        field: 'cost_price',
        width: 120,
        sortable: true,
        filter: 'agTextColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
        headerTooltip: 'Вносится на листе Мои товары'
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Логистика, руб',
        field: 'logistic',
        width: 120,
        sortable: true,
        filter: 'agTextColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0')
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Комиссия ВБ, руб',
        field: 'commission',
        width: 120,
        sortable: true,
        filter: 'agTextColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0')
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Эквайринг',
        field: 'acquiring',
        width: 120,
        sortable: true,
        filter: 'agTextColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0')
      },
      {
        columnGroupShow: 'closed',
        headerName: 'ДРР по заказам, %',
        field: 'drr',
        width: 80,
        sortable: true,
        filter: 'agTextColumnFilter',
        valueFormatter: v => unitPipe.transform(decimalPipe.transform(v.value, '1.0-1'), 'percent'),
        headerTooltip: 'Доля рекламных расходам по заказам'
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Реклама внутренняя',
        field: 'adv',
        width: 130,
        sortable: true,
        filter: 'agTextColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
        headerTooltip: 'Если данные в столбец не передаются, то они в составе прочих удержаний'
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Реклама внешняя',
        field: 'adv_external',
        width: 130,
        sortable: true,
        filter: 'agTextColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
        headerTooltip: 'Вносится на листе операции статья "Реклама таргет / блогеры"'
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Маркетинг',
        field: 'marketing',
        width: 130,
        sortable: true,
        filter: 'agTextColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
        headerTooltip: 'Вносится на листе операции статья "Дизайнеры / Фотографы"'
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Прочие удержания',
        field: 'other_deductions',
        width: 120,
        sortable: true,
        filter: 'agTextColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
        headerTooltip: 'Прочие прямые затраты которые можно распределить по артикулам'
      },
    ]
  },
  {
    headerName: 'АВС Оборачиваемость',
    children: [
      {
        headerName: 'Оборачиваемость по заказам, дней',
        field: 'turnover',
        width: 120,
        sortable: true,
        filter: 'agTextColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
        headerTooltip: 'За сколько дней продастся весь товар, считается по заказам в день. Считается на сегодня'
      },
      {
        headerName: 'Оборачиваемость по продажам, дней',
        field: 'turnover_sales',
        width: 120,
        sortable: true,
        filter: 'agTextColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
        headerTooltip: 'За сколько дней продастся весь товар с учетом % выкупа. Считается на сегодня'
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Среднее кол-во заказов в день на сегодня',
        field: 'avg_orders_day',
        width: 180,
        sortable: true,
        filter: 'agTextColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0')
      },
      {
        columnGroupShow: 'closed',
        headerName: 'Остатки TOTAL',
        field: 'total_stocks',
        width: 120,
        sortable: true,
        filter: 'agTextColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
        headerTooltip: 'Остаток на всех складах + товар, что занесен на лист Мои товары'
      },
    ]
  },
];

export function generateColumnDefs(isWbChecked: boolean): ColGroupDef[] {

  if(!isWbChecked) {
    return ABS_ANALYST_COLUMN_DEFS
  }

  return [
    {
      children: [
        {
          headerName: '',
          field: 'photo_new',
          width: 40,
          cellStyle: {paddingLeft: '0px', paddingRight: '0px'},
          pinned: 'left',
          cellRenderer: (params) => {
            if(params.data.photo_new === 'Итого'){
              return 'Итого'
            }
            if(!params.data.photo_new){
              return `<img style="height: 40px; width: 40px" src="/assets/images/nophoto.jpg" />`
            }
            return `<img style="height: 40px; width: 40px" src=${params.data.photo_new} />`
          } 
        },
        {
          headerName: 'Артикул МП',
          cellStyle: { textAlign: 'left' },
          field: 'nmID',
          width: 100,
          filter: 'agTextColumnFilter',
          pinned: 'left',
        },
        {
          headerName: 'Предмет',
          cellStyle: { textAlign: 'left' },
          field: 'object',
          width: 120,
          filter: 'agTextColumnFilter',
          pinned: 'left',
        },
        {
          headerName: 'Размер',
          cellStyle: { textAlign: 'left' },
          field: 'size',
          width: 80,
          filter: 'agTextColumnFilter',
          pinned: 'left',
        },
        {
          headerName: 'Артикул поставщика',
          cellStyle: { textAlign: 'left' },
          field: 'sku',
          width: 200,
          filter: 'agTextColumnFilter',
          pinned: 'left',
        },
        {
          headerName: 'ABC',
          field: 'abc',
          width: 70,
          filter: 'agTextColumnFilter',
          pinned: 'left',
          cellStyle: params => {
            return ABC_COLOR_MAP.get(params.value) || {'background-color': 'null'}
          }
        },
      ]
    },
    ABS_ANALYST_COLUMN_DEFS[1],
    ABS_ANALYST_COLUMN_DEFS[2],
    ABS_ANALYST_COLUMN_DEFS[3],
  ];

  // const storedDefs = localStorage.getItem('abc-column-defs');

  //   if(!storedDefs) {
  //       return ABS_ANALYST_COLUMN_DEFS
  //   }
  //   const parsedDefs = JSON.parse(storedDefs) as ColGroupDef[]
  //   const flattedChildren: ColDef[] = []
  //   ABS_ANALYST_COLUMN_DEFS.forEach(group => flattedChildren.push(...group.children))

  //   const newDefs: ColGroupDef[] = parsedDefs.map(d => {
  //     return {
  //       ...d,
  //       children: (d.children as ColDef[]).map(ch => flattedChildren.find(fch => fch.field === ch.field))
  //     }
  //   })
  //   return newDefs;
}
