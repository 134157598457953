<div class="d-flex">
  <div class="pr-0">
    <div class="widget-content p-0">
      <div class="widget-content-wrapper">
        <div class="widget-content-left">
          <div class="btn-group" ngbDropdown autoClose="outside" placement="bottom-right">
            <button type="button" style="position: relative;" class="btn btn-link p-0 mr-2" ngbDropdownToggle>
              <span>
              <div class="icon-wrapper icon-wrapper-alt" (click)="notificationClicked()">
                <img src="assets/images/envelope.png">
              </div>
            </span>
              <ng-container *ngIf="displayNewNotifications">
                <span class="notification-icon"></span>
              </ng-container>
            </button>
            <div class="card-body" style="padding: 0;">
              <div class="dropdown-menu-xl" style="height: 500px;" ngbDropdownMenu>
                <div class="dropdown-menu-header mb-0">
                  <div class="dropdown-menu-header-inner bg-deep-blue">
                    <div class="menu-header-image opacity-1 dd-header-bg-2"></div>
                    <div class="menu-header-content text-dark">
                      <h5 class="menu-header-title">Уведомления </h5>
                      <ng-container *ngIf="notificationMessages.length > 0 && newNotificationId > oldNotificationId; else oldNot">
                        <h6 class="menu-header-subtitle">У вас есть <b>{{notificationMessages.length}}</b> не просмотренных уведомлений</h6>
                      </ng-container>
                      <ng-template #oldNot>
                        <h6 class="menu-header-subtitle">У вас нет новых уведомлений</h6>
                      </ng-template>
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="notificationMessages.length > 0;">
                  <div class="nav-justified">
                    <div class="card-tabbed-header">
                      <ul ngbNav #nav="ngbNav" class="nav-tabs tabs-animated tabs-animated-shadow" justify="justified">
                        <li ngbNavItem>
<!--                          <a ngbNavLink><span>События</span></a>-->
                          <ng-template ngbNavContent>
                            <div class="scroll-gradient">
                              <div class="scroll-area-md shadow-overflow">
                                <perfect-scrollbar [autoPropagation]="true">
                                  <div
                                    class="vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                                    <div *ngFor="let message of notificationMessages; let i = index;"
                                         class="vertical-timeline-item vertical-timeline-element">
                                      <div><span class="vertical-timeline-element-icon bounce-in">
                                            <i class="badge badge-dot badge-dot-xl" [ngClass]="message.style"></i></span>
                                        <div class="vertical-timeline-element-content bounce-in">
                                          <h4 class="timeline-title">
                                            {{message.title}}
                                          </h4>
                                          <p>{{message.issue}}</p>
                                          <span class="vertical-timeline-element-date"></span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </perfect-scrollbar>
                              </div>
                            </div>
                          </ng-template>
                        </li>
                      </ul>
                      <div [ngbNavOutlet]="nav"></div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
