import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { SharedDataService } from '../../../../../shared/services/shared-data.service';
import { CustomCurrencyConverterRUB } from '../../../../../shared/utils/functions/custom-currency-converter-rub';
import { FIN_ACCOUNT_TYPES } from '../../accounts/accounts.mock';

@Component({
  selector: 'app-btn-cell-renderer',
  template: ` <span style="color: {{ color }}">{{ value }}</span> `,
  standalone: true,
})
export class PartnersSpecificCellsComponent
  implements ICellRendererAngularComp
{
  value: string;
  color = '';
  private params: any;

  constructor(private sharedServiceData: SharedDataService) {}

  agInit(params: any): void {
    this.params = params;
    this.value = params.value;
    this.color = '';
    if (!params.value) {
      this.value = '-';
    }
    if (params.colDef) {
      switch (params.colDef.field) {
        case 'count_operation':
          this.renderAmountOfOperation();
          break;
        case 'income_item':
        case 'expenses_item':
          if (this.value !== '-') {
            this.renderIssues();
          }
          break;
        case 'balance_init':
        case 'balance_current':
        case 'current_sum':
        case 'credit_line':
        case 'available_money_by_credit_line':
          if (this.value !== '-') {
            this.renderAccountPrices();
          }
          break;
        case 'sum_partners':
          if (this.value !== '-') {
            this.renderSumPartners();
          }
          break;
        case 'type':
          if (this.value !== '-') {
            this.renderAccountType();
          }
          break;
        default:
          break;
      }
    }
  }

  renderAmountOfOperation() {
    if (!this.value || this.value === '-') {
      this.value = '0';
    }
  }

  renderAccountPrices() {
    this.color = +this.value < 0 ? 'red' : 'black';
    this.value = CustomCurrencyConverterRUB(this.value);
  }

  renderSumPartners() {
    this.color =
      +this.value === 0 ? 'black' : +this.value < 0 ? 'red' : 'green';
    this.value = CustomCurrencyConverterRUB(this.value);
  }

  renderIssues() {
    this.sharedServiceData.getData('articles').subscribe(value => {
      if (value) {
        const filteredArticle = value.filter(x => +x.id === +this.value)[0];
        if (filteredArticle) {
          this.value = filteredArticle.name;
        } else {
          this.value = '-';
        }
      } else {
        this.value = '-';
      }
    });
  }

  renderAccountType() {
    const filteredValue = FIN_ACCOUNT_TYPES.filter(
      x => +x.id === +this.value
    )[0];
    if (filteredValue) {
      this.value = filteredValue.name;
    }
  }

  refresh(params: any): boolean {
    this.params = params;
    this.value = params.value;
    return true;
  }
}
