<div *ngIf="shop_id == 0" class="col-md-4">
  <div class="card-shadow-danger border mb-3 card card-body border-danger">
    <h5 class="card-title">Выберите поставщика</h5>Выбрать поставщика можно нажав на кнопку в правом верхнем углу
    <div>
      <div class="icon-wrapper icon-wrapper-alt rounded-circle">
        <div class="icon-wrapper-bg bg-success"></div>
        <i alt="Поставщики" class="pe-7s-users text-success"></i>
      </div>
    </div>
  </div>
</div>
<div *ngIf="shop_id != 0" class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <i class="fa fa-bar-chart icon-gradient bg-mean-fruit"></i>
      </div>
      <div>
        Сводка&nbsp;&nbsp;
      </div>
      <div class="btn-actions-pane-left">
        <input (change)="newValue()" [(ngModel)]="selected"
               [alwaysShowCalendars]="true"
               [isInvalidDate]="isInvalidDate" [linkedCalendars]="true" [locale]="{
                        applyLabel: 'применить',
                        direction: 'ltr',
                        format: 'DD.MM.YYYY',
                        'daysOfWeek': ['Вс','Пн','Вт','Ср','Чт','Пт','Сб'],
                        'customRangeLabel': 'Свой диапазон',
                        'firstDay': 1,
                        'monthNames': ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь']}"
               [maxDate]='maxDate'
               [minDate]='minDate' [ranges]="ranges" [showClearButton]="false"
               [showCustomRangeLabel]="true" ngxDaterangepickerMd placeholder="Выберите даты" type="text"/>
      </div>&nbsp;

      <div class="btn-actions-pane-left">
        <p-selectButton [style]="{ fontSize: '14px' }" (onChange)="changeType($event)" [(ngModel)]="value3" [options]="justifyOptions"
                        optionLabel="name" optionValue="value" styleClass="dark-panel"></p-selectButton>
      </div>&nbsp;

      <div class="btn-actions-pane-left">
        <p-dropdown (onChange)="selectTypeData($event)" [(ngModel)]="type_datamodel" [options]="type_data"
                    [showClear]="false" dataKey="1" emptyMessage="Список пуст" optionLabel="name"
                    placeholder="Тип данных"></p-dropdown>
      </div>&nbsp;

      <div class="btn-actions-pane-left">
        <p-multiSelect (onChange)="selectedBarcodesNmChange($event)" (onClear)="selectedBarcodesNmClear($event)"
                       [(ngModel)]="selectedBarcodesNm" [maxSelectedLabels]="1"
                       [options]="barcodesnm" [showClear]="true" defaultLabel="Выберите баркод - артикул"
                       emptyMessage="Баркодов не найдено"
                       itemSize="30" optionLabel="name"
                       selectedItemsLabel="Выбрано {0}"></p-multiSelect>
      </div>&nbsp;
      <div class="btn-actions-pane-left">
        <p-multiSelect (onChange)="selectedSubjectChange($event)" (onClear)="selectedSubjectClear($event)"
                       [(ngModel)]="selectedSubject"
                       [maxSelectedLabels]="1" [options]="subject"
                       [showClear]="true" defaultLabel="Выберите предмет" emptyMessage="Предметов не найдено"
                       optionLabel="name" selectedItemsLabel="Выбрано {0}"></p-multiSelect>
      </div>

    </div>

  </div>
</div>


<div *ngIf="shop_id != 0" class="card">

  <!--<p-table [columns]="cols" [value]="virtualCars" [scrollable]="true" [rows]="100" scrollHeight="76vh"
      [virtualScroll]="true" [virtualRowHeight]="40"  (onLazyLoad)="loadCarsLazy($event)"
      styleClass="p-datatable-gridlines"
      sortField="total" sortOrder="-1">-->
  <p-table [columns]="cols" [rows]="100" [scrollable]="true" [value]="virtualCars" [virtualScroll]="true"
           scrollHeight="76vh" sortField="total" sortOrder="-1" styleClass="p-datatable-gridlines">

    <ng-template let-columns pTemplate="header">

      <tr>
        <th class="frozen-col" pFrozenColumn style="min-width:95px;max-width:95px;height: 50px;"></th>
        <th class="frozen-col" pFrozenColumn style="min-width:150px;max-width:150px;height: 50px;"></th>
        <th class="frozen-col" pFrozenColumn style="min-width:150px;max-width:150px;height: 50px;"></th>
        <th class="frozen-col" pFrozenColumn style="min-width:150px;max-width:150px;height: 50px;">Всего</th>
        <th class="frozen-col" pFrozenColumn
            style="min-width:120px;max-width:150px;height: 50px;font-size:13px;">{{total | number: '1.0-0'}}</th>
        <th *ngFor="let col of columns" style="width:50px;width:80px;font-size:12px;">{{total_date[col.field] |
          number: '1.0-0'}}</th>

      </tr>
      <tr>
        <th class="frozen-col" pFrozenColumn pSortableColumn="abc" style="min-width:95px;max-width:95px;height: 50px;">
          ABC
          <p-sortIcon field="abc"></p-sortIcon>
        </th>
        <th class="frozen-col" pFrozenColumn pSortableColumn="barcode"
            style="min-width:150px;max-width:150px;height: 50px;">Barcode
          <p-sortIcon field="barcode"></p-sortIcon>
        </th>
        <th class="frozen-col" pFrozenColumn style="min-width:150px;max-width:150px;height: 50px;">Товар</th>
        <th class="frozen-col" pFrozenColumn pSortableColumn="balance"
            style="min-width:150px;max-width:150px;height: 50px;">Остатки
          <p-sortIcon field="balance"></p-sortIcon>
        </th>
        <th class="frozen-col" pFrozenColumn pSortableColumn="total" style="min-width:120px;width:120px">Итого
          <p-sortIcon field="total"></p-sortIcon>
        </th>
        <th *ngFor="let col of columns" style="width:50px;width:80px;font-size:11px;">{{col.header}}</th>
      </tr>
    </ng-template>

    <ng-template let-columns="columns" let-rowData pTemplate="body">

      <tr>
        <td class="frozen-col" pFrozenColumn style="min-width:95px;max-width:95px;height:40px;">{{rowData.abc}}</td>
        <td class="frozen-col" pFrozenColumn
            style="min-width:150px;max-width:150px;height:40px;">{{rowData.barcode}}</td>
        <!--<td style="min-width:150px;max-width:150px;height:40px;font-size:10px" pFrozenColumn class="frozen-col">{{rowData.name}}</td>-->
        <td class="frozen-col" pFrozenColumn style="min-width:150px;max-width:150px;height:40px;font-size:11px"><abbr
          title="{{rowData.name}}">{{rowData.name?.substr(0, 30)}}...</abbr></td>
        <td class="frozen-col" pFrozenColumn
            style="min-width:150px;max-width:150px;height:40px;">{{rowData.balance}}</td>
        <td [ngStyle]="{'background-color': rowData.total_color}" class="frozen-col"
            pFrozenColumn style="min-width:120px;max-width:150px;height:40px;">{{rowData.total | number: '1.0-0'}}</td>
        <td *ngFor="let col of columns" style="width:50px;width:80px;">
          <div
            [ngClass]="{'outofstock': rowData[col.field] === 0, 'lowstock': (rowData[col.field] > 0 && rowData[col.field] < 1),'instock': rowData[col.field] > 1}">
            {{rowData[col.field] | number: '1.0-0'}}
          </div>
        </td>
      </tr>
    </ng-template>

    <ng-template let-columns="columns" pTemplate="loadingbody">
      <tr style="height:40px">
        <td *ngFor="let col of columns">
          <div class="loading-text"></div>
          <p-skeleton [ngStyle]="{'width': '100%'}"></p-skeleton>
        </td>
      </tr>
    </ng-template>

  </p-table>
</div>
