<app-page-header-notification-bar
  *ngIf="text || subtext"
  [headerTitle]="text"
  [barText]="subtext" />
<div *ngIf="isDisplayGoogleTableInfo" class="col-sm-6 col-md-6">
  <div class="mb-3 card text-white card-body bg-warning">
    <h5 class="card-title">
      Обращаем внимание, веб сервис и гугл таблица - разные продукты от MP Surf
    </h5>
    <div class="col-sm-4 col-md-4">
      <button
        (click)="closeGoogleTableMsg(1)"
        class="btn btn-primary mt-2 mr-2">
        Все понятно
      </button>
    </div>
  </div>
  <br />
</div>
<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon m-0">
        <i class="fa fa-bar-chart icon-gradient bg-mean-fruit"></i>
      </div>
      Отчет о движении денежных средств

      <app-mp-ngb-range-datepicker
        [initialData]="selectedDate"
        [rangeType]="ranges"
        (selectedDateEmitter)="dateChanged($event)" />
      <app-mp-video-play [videoLink]="getVideoLinkByPage('fin/dds')" />

      <p-selectButton
        (onChange)="changeType($event)"
        [(ngModel)]="selectedDDSType"
        [options]="selectOptions"
        [style]="{ height: '40px', marginTop: '4px', fontSize: '14px' }"
        optionLabel="name"
        optionValue="value"
        styleClass="dark-panel required-height">
      </p-selectButton>
      <!--          TODO: Hide for works on DDS-->
      <!--        <div class="block-order-ftype">-->
      <!--          <div class="btn-actions-pane-left">-->
      <!--            <p-multiSelect (onChange)="selectParameter()"-->
      <!--                           (onClear)="clearFilters(1)"-->
      <!--                           [(ngModel)]="selectedAcc" [options]="accountsList" [showClear]="true"-->
      <!--                           [style]="{ display: 'flex', alignItems: 'center', height: '37px', maxWidth: '250px' }"-->
      <!--                           optionLabel="name" placeholder="Юр. лица и счета"></p-multiSelect>-->
      <!--            <p-multiSelect (onChange)="selectParameter()"-->
      <!--                           (onClear)="clearFilters(2)"-->
      <!--                           [(ngModel)]="selectedPartners" [options]="partnersList" [showClear]="true"-->
      <!--                           [style]="{ display: 'flex', alignItems: 'center', height: '37px', maxWidth: '250px' }"-->
      <!--                           optionLabel="name" placeholder="Контрагенты"></p-multiSelect>-->
      <!--          </div>-->
      <!--        </div>-->
    </div>
  </div>
</div>
<ngb-accordion>
  <ngb-panel>
    <ng-template ngbPanelTitle>
      <span>Развернуть / Свернуть график</span>
    </ng-template>
    <ng-template ngbPanelContent>
      <apx-chart
        *ngIf="chartOptions"
        [series]="chartOptions.series"
        [chart]="chartOptions.chart"
        [xaxis]="chartOptions.xaxis"
        [yaxis]="chartOptions.yaxis"
        [dataLabels]="chartOptions.dataLabels"
        [stroke]="chartOptions.stroke"
        [legend]="chartOptions.legend" />
    </ng-template>
  </ngb-panel>
</ngb-accordion>
<div class="card p-treetable" [style.width.px]="(ddsDataTable?.columns.length) * 150 + 400" [style.maxWidth]="'100%'">
  <p-treeTable
    [columns]="ddsDataTable?.columns"
    [frozenColumns]="[{ field: 'name', header: 'Статья' }]"
    [metaKeySelection]="true"
    [scrollable]="true"
    [value]="ddsDataTable?.data"
    frozenWidth="320px"
    selectionMode="single"
    styleClass="p-treetable-sm">
    <ng-template let-columns pTemplate="colgroup">
      <colgroup>
        <col *ngFor="let col of columns" style="width: 150px" />
      </colgroup>
    </ng-template>
    <ng-template let-columns pTemplate="header">
      <tr style="height: 50px">
        <th
          *ngFor="let col of columns"
          style="font-size: 12px; text-align: center">
          {{ col.header }}
        </th>
      </tr>
    </ng-template>
    <ng-template let-columns="columns" let-rowData="rowData" pTemplate="body">
      <tr>
        <td
          *ngFor="let col of columns; let i = index"
          class="columns-wrap rowGray"
          style="text-align: center; font-size: 14px">
          <span
            [ngStyle]="{
              color:
                rowData[col.field] === 0 ||
                rowData[col.field]?.toString()?.includes('%')
                  ? 'black'
                  : rowData[col.field] < 0
                    ? 'red'
                    : 'green',
              'font-weight':
                rowData[col.field] === 0 ||
                rowData[col.field]?.toString()?.includes('%')
                  ? '500'
                  : rowData[col.field] < 0
                    ? '700'
                    : specialHeaders.includes(rowData.name)
                      ? '700'
                      : '500',
            }">
            {{
              (rowData &&
                (rowData[col.field]?.toString()?.includes('%')
                  ? rowData[col.field]
                  : (rowData[col.field] | currency: '' : ''))) ||
                '-'
            }}
          </span>
        </td>
      </tr>
    </ng-template>
    <ng-template let-rowData="rowData" let-rowNode pTemplate="frozenbody">
      <tr [ngClass]="{ height: '50px' }">
        <td class="flex items-center">
          <p-treeTableToggler [rowNode]="rowNode" />
          <span
            [pTooltip]="rowData.name"
            class="special-headers"
            style="font-size: 14px"
            [ngStyle]="{
              'font-weight': specialHeaders.includes(rowData.name)
                ? '600'
                : '400',
              'font-size': specialHeaders.includes(rowData.name)
                ? '15px'
                : '14px',
            }">
            {{ rowData.name }}
          </span>
        </td>
      </tr>
    </ng-template>
  </p-treeTable>
</div>
