import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AuthGuard } from '../../shared/guards/auth.guard';
import { ShopComponent } from './shop/shop.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ButtonModule } from 'primeng/button';
import { ModalModule } from '../../shared/common-components/modal/modal.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { InputNumberModule } from 'primeng/inputnumber';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PanelModule } from 'primeng/panel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { UiComponentsModule } from '../../shared/ui/components/ui-components.module';
import { TableModule } from 'primeng/table';

const routes: Routes = [
  {
    path: 'shop',
    component: ShopComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
];

@NgModule({
  declarations: [ShopComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    HttpClientModule,
    FontAwesomeModule,
    ProgressSpinnerModule,
    ToastModule,
    InputMaskModule,
    FormsModule,
    InputTextModule,
    InputTextareaModule,
    ButtonModule,
    ModalModule,
    NgSelectModule,
    InputNumberModule,
    MatTooltipModule,
    PanelModule,
    SelectButtonModule,
    UiComponentsModule,
    TableModule
  ]
})
export class SettingsModule {}