<div [class]="'app-container app-theme-white'"
     [ngClass]="{'closed-sidebar' : globals.toggleSidebar,
                 'closed-sidebar-md' : globals.toggleSidebarMobile,
                 'settings-open' : globals.toggleThemeOptions,
                 'closed-sidebar-open': globals.sidebarHover || globals.toggleSidebarMobile,
                 'header-menu-open' : globals.toggleHeaderMobile,
                 'drawer-open' : globals.toggleDrawer,
                 'fixed-footer' : globals.toggleFixedFooter}">
  <div class="hint-block-dark"></div>
  <app-header></app-header>
  <app-sidebar></app-sidebar>
  <div class="app-main__outer">
    <div class="app-main__inner">
      <div>
        <div class="overlay" *ngIf="$pending | async"></div>
        <router-outlet></router-outlet>
        <p-progressSpinner *ngIf="$pending | async"
                           [style]="{position: 'absolute', top: '40%', left: '50%', zIndex: '10000'}" />
      </div>
    </div>
    <app-footer></app-footer>
  </div>
  <app-drawer></app-drawer>
  <div (click)="toggleSidebarMobile()" class="sidebar-menu-overlay"></div>
</div>
<ngx-loading-bar [color]="'#3f6ad8'"></ngx-loading-bar>