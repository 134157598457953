import { ICardWidget } from "../main-test/main-test.interface";
import { IAbcRow } from "./abs-analyst.interface";

export function generateCardData(data: IAbcRow[]): ICardWidget[] {

    const TOTAL_SALES_AMOUNT = data.map(row => row.sales_count).reduce((a, b) => {
        const safeA = !isNaN(+a) ? +a : 0;
        const safeB = !isNaN(+b) ? +b : 0;
        return safeA + safeB
    }, 0)

    const TOTAL_SALES = data.map(row => row.sales).reduce((a, b) => {
        const safeA = !isNaN(+a) ? +a : 0;
        const safeB = !isNaN(+b) ? +b : 0;
        return safeA + safeB
    }, 0)

    const TOTAL_PROFIT = data.map(row => row.profit).reduce((a, b) => {
        const safeA = !isNaN(+a) ? +a : 0;
        const safeB = !isNaN(+b) ? +b : 0;
        return safeA + safeB
    }, 0)

    const AAA_ROWS = data.filter(row => row.abc === 'AAA');

    const CCC_ROWS = data.filter(row => row.abc === 'CCC');

    const ABC_ROWS = data.filter(row => row.abc !== 'CCC' && row.abc !== 'AAA');

    const REDUCED_AAA_ROWS = AAA_ROWS
    .reduce((acc, curr) => {
        return {
            ...acc,
            itemsCount: acc.itemsCount += 1,
            salesAmount: acc.salesAmount += (!isNaN(+curr.sales_count) ? +curr.sales_count : 0),
            sales: acc.sales += (!isNaN(+curr.sales) ? +curr.sales : 0),
            profit: acc.profit += (!isNaN(+curr.profit) ? +curr.profit : 0),
        }
    }, {
        itemsCount: 0,
        salesAmount: 0,
        sales: 0,
        profit: 0,
    })

    const REDUCED_ABC_ROWS = ABC_ROWS
    .reduce((acc, curr) => {
        return {
            itemsCount: acc.itemsCount += 1,
            salesAmount: acc.salesAmount += (!isNaN(+curr.sales_count) ? +curr.sales_count : 0),
            sales: acc.sales += (!isNaN(+curr.sales) ? +curr.sales : 0),
            profit: acc.profit += (!isNaN(+curr.profit) ? +curr.profit : 0),
        }
    }, {
        itemsCount: 0,
        salesAmount: 0,
        sales: 0,
        profit: 0,
    })

    const REDUCED_CCC_ROWS = CCC_ROWS
    .reduce((acc, curr) => {
        return {
            itemsCount: acc.itemsCount += 1,
            salesAmount: acc.salesAmount += (!isNaN(+curr.sales_count) ? +curr.sales_count : 0),
            sales: acc.sales += (!isNaN(+curr.sales) ? +curr.sales : 0),
            profit: acc.profit += (!isNaN(+curr.profit) ? +curr.profit : 0),
        }
    }, {
        itemsCount: 0,
        salesAmount: 0,
        sales: 0,
        profit: 0,
    })

    return [
        {
            title: 'Товарная группа AAA',
            subfields: [
            {
                name: 'Количество товаров',
                value: REDUCED_AAA_ROWS.itemsCount,
                unit: 'item',
                ratioInPercents: Math.floor((REDUCED_AAA_ROWS.itemsCount/data.length) * 100)
            },
            {
                name: 'Количество продаж',
                value: REDUCED_AAA_ROWS.salesAmount,
                unit: 'item',
                ratioInPercents: Math.floor((REDUCED_AAA_ROWS.salesAmount/TOTAL_SALES_AMOUNT) * 100)
            },
            {
                name: 'Выручка',
                value: REDUCED_AAA_ROWS.sales,
                unit: 'rubles',
                ratioInPercents: Math.floor((REDUCED_AAA_ROWS.sales/TOTAL_SALES) * 100)
            },
            {
                name: 'Валовая прибыль',
                value: REDUCED_AAA_ROWS.profit,
                unit: 'rubles',
                ratioInPercents: Math.floor((REDUCED_AAA_ROWS.profit/TOTAL_PROFIT) * 100),
                description :'Продажи минус удержания которые можно распределить на артикул (например, штрафы, подписки и др. по API не распределяются по артикулам)'
            },
            ],
            description: 'Наиболее ценные позиции. Они хорошо оборачиваются, приносят наибольшую прибыль, но при этом на них тратится меньше ресурсов',
            totalCount: null,
            totalSum: null,
        },
        {
            title: 'Товарная группа ABA-CBC',
            subfields: [
            {
                name: 'Количество товаров',
                value: REDUCED_ABC_ROWS.itemsCount,
                unit: 'item',
                ratioInPercents: Math.floor((REDUCED_ABC_ROWS.itemsCount/data.length) * 100)
            },
            {
                name: 'Количество продаж',
                value: REDUCED_ABC_ROWS.salesAmount,
                unit: 'item',
                ratioInPercents: Math.floor((REDUCED_ABC_ROWS.salesAmount/TOTAL_SALES_AMOUNT) * 100)
            },
            {
                name: 'Выручка',
                value: REDUCED_ABC_ROWS.sales,
                unit: 'rubles',
                ratioInPercents: Math.floor((REDUCED_ABC_ROWS.sales/TOTAL_SALES) * 100)
            },
            {
                name: 'Валовая прибыль',
                value: REDUCED_ABC_ROWS.profit,
                unit: 'rubles',
                ratioInPercents: Math.floor((REDUCED_ABC_ROWS.profit/TOTAL_PROFIT) * 100),
                description :'Продажи минус удержания которые можно распределить на артикул (например, штрафы, подписки и др. по API не распределяются по артикулам)'
            },
            ],
            description: 'Промежуточные позиции. Эти товары не приносят большую прибыль, но не слишком дорого обходятся бизнесу',
            totalCount: null,
            totalSum: null,
        },
        {
            title: 'Товарная группа CCC',
            subfields: [
            {
                name: 'Количество товаров',
                value: REDUCED_CCC_ROWS.itemsCount,
                unit: 'item',
                ratioInPercents: Math.floor((REDUCED_CCC_ROWS.itemsCount/data.length) * 100)
            },
            {
                name: 'Количество продаж',
                value: REDUCED_CCC_ROWS.salesAmount,
                unit: 'item',
                ratioInPercents: Math.floor((REDUCED_CCC_ROWS.salesAmount/TOTAL_SALES_AMOUNT) * 100)
            },
            {
                name: 'Выручка',
                value: REDUCED_CCC_ROWS.sales,
                unit: 'rubles',
                ratioInPercents: Math.floor((REDUCED_CCC_ROWS.sales/TOTAL_SALES) * 100)
            },
            {
                name: 'Валовая прибыль',
                value: REDUCED_CCC_ROWS.profit,
                unit: 'rubles',
                ratioInPercents: Math.floor((REDUCED_CCC_ROWS.profit/TOTAL_PROFIT) * 100),
                description :'Продажи минус удержания которые можно распределить на артикул (например, штрафы, подписки и др. по API не распределяются по артикулам)'
            },
            ],
            description: 'Наименее ценные позиции. Товары, которые не приносят ощутимого дохода',
            totalCount: null,
            totalSum: null,
        },
    ]
}