import { Component, inject } from '@angular/core';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';
import { ThemeOptions } from '../../shared/theme-options/theme-options';
import { PendingService } from "../../shared/services/pending.service";
@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.scss']
})
export class BaseLayoutComponent {
  @select('config') public config$: Observable<any>;

  private readonly _pendingService = inject(PendingService);

  $pending = this._pendingService.pending$;

  constructor(
    public globals: ThemeOptions,
  ) {}

  toggleSidebarMobile() {
    this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
  }
}