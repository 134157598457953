import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../shared/guards/auth.guard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SettingsComponent } from './settings/settings.component';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { TariffComponent } from './tariff/tariff.component';
import { ToastModule } from 'primeng/toast';
import { BillsComponent } from './bills/bills.component';
import { UsersComponent } from './users/users.component';
import { ModalModule } from '../../shared/common-components/modal/modal.module';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { SharedUiPipesModule } from '../../shared/ui/pipes/ui-pipes.module';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TooltipModule } from 'primeng/tooltip';
import { UiComponentsModule } from '../../shared/ui/components/ui-components.module';
import { TariffItemComponent } from './tariff/components/tariff-item/tariff-item.component';
import { LogoPipe } from './tariff/pipes/logo.pipe';
import { ButtonTextPipe } from './tariff/pipes/button-text.pipe';
import { ButtonDisabledPipe } from './tariff/pipes/button-disabled.pipe';
import { CheckboxModule } from 'primeng/checkbox';
import { PartnersComponent } from './partners/partners.component';
import { AgGridModule } from 'ag-grid-angular';
import { TitlePipe } from './tariff/pipes/title.pipe';

const routes: Routes = [
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'tariff',
    component: TariffComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'bill',
    component: BillsComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'partners',
    component: PartnersComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    UsersComponent,
    BillsComponent,
    SettingsComponent,
    TariffComponent,
    TariffItemComponent,
    LogoPipe,
    ButtonTextPipe,
    ButtonDisabledPipe,
    PartnersComponent,
    TitlePipe
  ],
  imports: [
    AgGridModule,
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    HttpClientModule,
    NgbNavModule,
    ToastModule,
    ModalModule,
    InputMaskModule,
    InputTextModule,
    RadioButtonModule,
    FormsModule,
    ProgressSpinnerModule,
    DropdownModule,
    ButtonModule,
    SharedUiPipesModule,
    ConfirmDialogModule,
    TooltipModule,
    UiComponentsModule,
    CheckboxModule
  ],
})
export class ProfileModule {}