import { ColDef, ColGroupDef } from 'ag-grid-community';
import {
    BtnCellRendererFinoperationComponent
} from './components/aggrid-components/btn-cell-renderer-finoperation.component';
import {
    FinoperationSpecificRenderCellsComponent
} from './components/aggrid-components/finoperation-specific-render-cells.component';
import { NumberSortFunc } from '../../../shared/utils/functions/number-sort-func';
import { NumberSpacesFormatter } from '../../../shared/utils/functions/number-space-formatter';
import { ValueGetterForDate } from '../../../shared/utils/functions/value-getter-for-date';
import { ValueFormatterForDate } from '../../../shared/utils/functions/value-formatter-for-date';

export const FIN_OPERATION_COLUMN_DEFS: (ColDef | ColGroupDef)[] = [
    {
        field: 'selection',
        headerName: '',
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        filter: false,
        width: 80,
        checkboxSelection: true,
    },
    {
        headerName: 'Дата оплаты (для ДДС)',
        field: 'date_payment',
        width: 150,
        filter: 'agDateColumnFilter',
        flex: 0,
        suppressSizeToFit: true,
        valueGetter: (params) => ValueGetterForDate(params, 'date_payment'),
        valueFormatter: (params) => ValueFormatterForDate(params),
    },
    {
        headerName: 'Дата начисления (для ОПиУ)',
        field: 'date_accrual',
        width: 170,
        filter: 'agDateColumnFilter',
        suppressSizeToFit: true,
        flex: 0,
        valueGetter: (params) => ValueGetterForDate(params, 'date_accrual'),
        valueFormatter: (params) => ValueFormatterForDate(params),
    },
    {
        headerName: 'Дата добавления',
        field: 'created_at',
        width: 170,
        filter: 'agDateColumnFilter',
        suppressSizeToFit: true,
        flex: 0,
        valueGetter: (params) => ValueGetterForDate(params, 'created_at'),
        valueFormatter: (params) => ValueFormatterForDate(params),
    },
    {
        headerName: 'Счет',
        field: 'account_id',
        width: 150,
        filter: 'agTextColumnFilter',
    },
    {
        headerName: 'Тип операции',
        field: 'type_operation_title',
        width: 180,
        filter: 'agTextColumnFilter',
    },
    {
        headerName: 'Артикул',
        field: 'product_code',
        width: 180,
        filter: 'agTextColumnFilter',
    },
    {
        headerName: 'Контрагент',
        field: 'partner_id',
        width: 180,
        filter: 'agTextColumnFilter',
    },
    {
        headerName: 'Статья',
        field: 'name_item',
        width: 180,
        filter: 'agTextColumnFilter',
    },
    {
        headerName: 'Сумма',
        field: 'amount',
        width: 180,
        valueFormatter: NumberSpacesFormatter,
        comparator: NumberSortFunc,
        cellRenderer: FinoperationSpecificRenderCellsComponent,
    },
    {
        headerName: 'Кабинет',
        field: 'shop_name',
        width: 180,
    },
    {
        headerName: 'Комментарий',
        field: 'description',
        width: 200,
        filter: 'agTextColumnFilter',
    },
    // TODO: For a while
    // {
    //   headerName: 'Проект',
    //   field: 'project',
    //   width: 100,
    //   filter: 'agTextColumnFilter',
    //   flex: 0,
    // },
    {
        headerName: 'Действия',
        headerClass: 'header-no-filter',
        icons: {
            sortAscending: '<i class="fa fa-sort-alpha-up"/>',
            sortDescending: '<i class="fa fa-sort-alpha-down"/>',
        },
        field: '',
        sortable: false,
        filter: false,
        cellRenderer: BtnCellRendererFinoperationComponent,
        width: 150,
    },
];

export const MY_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'LL',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

export const LOCALE_RU_FILTERS: any = {
    // Number Filter & Text Filter
    filterOoo: 'Фильтр...',
    equals: 'Равно',
    notEqual: 'не равно',
    empty: 'Выбери один',
    /* for number filter */
    lessThan: 'Меньше чем',
    greaterThan: 'Больше чем',
    lessThanOrEqual: 'Меньше или равно',
    greaterThanOrEqual: 'Больше или равно',
    inRange: 'В промежутке',
    inRangeStart: 'от',
    inRangeEnd: 'до',
    // Text Filter
    contains: 'Содержит',
    notContains: 'Не Содержит',
    startsWith: 'Начинается с',
    endsWith: 'Заканчиваеться на',
    // Date Filter
    dateFormatOoo: 'dd.mm.yyyy',
    // Filter Conditions
    andCondition: 'И',
    orCondition: 'ИЛИ',
    // Filter Buttons
    resetFilter: 'Очистить',
    applyFilter: 'Применить',
    clearFilter: 'Clear',
    cancelFilter: 'Cancel',
    // Filter Titles
    textFilter: 'Text Filter',
    numberFilter: 'Number Filter',
    dateFilter: 'Date Filter',
    setFilter: 'Set Filter',
};
