import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'mpThousandSeparator',
})
export class ThousandSeparatorPipe implements PipeTransform {
    transform(value: number | string | undefined | null): string {
        if (!value) {
            return '0';
        }
        const parts = Number(value).toString().split('.');
        let intPart = parts[0];
        const floatPart = parts[1];
        const regExp = /(\d+)(\d{3})/;
        while (regExp.test(intPart)) {
            intPart = intPart.replace(regExp, '$1' + ' ' + '$2');
        }
        return '' + intPart + (floatPart ? `,${+floatPart < 10 ? floatPart + '0' : floatPart}` : '');
    }
}
