export const ADV_COLUMN_DEFAULT_DEF = {
    flex: 1,
    minWidth: 20,
    maxWidth: 400,
    resizable: true,
    suppressSizeToFit: true,
    suppressResize: true,
    sortable: true,
    filter: 'agTextColumnFilter',
    wrapHeaderText: true,
    autoHeaderHeight: true,
};
