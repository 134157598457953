<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="block-order-ftype" style="display: flex; align-items: center;">
        <div class="page-title-icon">
          <i class="pe-7s-albums icon-gradient bg-mean-fruit"></i>
        </div>
        <div class="icons-wrapper">
          Мониторинг &nbsp;&nbsp;
        </div>
        <div class="btn-actions-pane-left">
          <input (change)="newValue()"
                 [(ngModel)]="DatePicker.selectedData"
                 [alwaysShowCalendars]="true"
                 [disabled]="DatePicker.disableData"
                 [isInvalidDate]="DatePicker.isInvalidDate"
                 [linkedCalendars]="true" [locale]="datePickerLocale"
                 [maxDate]='DatePicker.maxDate'
                 [minDate]='DatePicker.minDate'
                 [ranges]="datePickerRange"
                 [showClearButton]="false"
                 [showCustomRangeLabel]="true"
                 ngxDaterangepickerMd
                 placeholder="Выберите даты"
                 type="text"/>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="main-card mb-3 card">
  <div *ngIf="loading" style="width: 100%;height: 75vh;">
    <ag-grid-angular
      (gridReady)="onGridReady($event)"
      [columnDefs]="dataForColumnDefs"
      [defaultColDef]="defaultColDef"
      [enableRangeSelection]="true"
      [gridOptions]="gridOptions"
      [pagination]="true"
      [rowData]="rowData"
      [rowHeight]="35"
      overlayNoRowsTemplate="Нет данных для отображения"
      class="ag-theme-alpine"
      style="width: 100%; height: 100%;">
    </ag-grid-angular>
  </div>
</div>
