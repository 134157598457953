import { Component, HostListener, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../shared/services/api.service';
import { AuthService } from '../../../shared/services/auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ILoginResponse, IUserData } from './login.interface';
import { finalize } from 'rxjs/operators';
import { ConfirmationService, MessageService } from 'primeng/api';
import { PopUpMessages } from '../../../shared/mocks/pop-up-messages.mock';
import { AbstractGeneralTabComponent } from '../../abstract-general-tab.component';
import { AbstractMenuTabComponent } from '../../../menu-tabs/abstract-menu-tab.component';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass'],
  providers: [MessageService, ConfirmationService],
})
export class LoginComponent extends AbstractMenuTabComponent implements OnInit {
  errorMessage: string;

  readonly LoginForm = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.maxLength(100),
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
    ]),
  });

  readonly RegisterForm = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
    phone: new FormControl(null, [
      Validators.required,
      Validators.minLength(12),
    ]),
    agreed: new FormControl(false, [Validators.requiredTrue]),
  });

  private _authService = inject(AuthService);
  private _api = inject(ApiService);

  constructor() {
    super();
  }

  @HostListener('document:keydown.enter', ['$event'])
  keyDownEnter() {
    if (this.LoginForm.valid) {
      this.logIn();
    }
  }

  ngOnInit(): void {
    sessionStorage.clear();
    this._route.queryParams.subscribe(params => {
      if (params.lostpassword) {
        this._router.navigate(['/auth/register']);
      }
    });
  }

  logIn() {
    this.isLoading = true;
    const body = this.LoginForm.getRawValue();
    body.email = body.email.trim();
    body.password = body.password.trim();
    this._api
      .userRegister('login', localStorage.getItem('token'), body)
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: ILoginResponse) => {
          if (+response.is_error === 1) {
            this.showPopUpMessage(
              'error',
              'Ошибка входа',
              'Неверно введен логин или пароль'
            );
            this.errorMessage = response.msg;
            this.isLoading = false;
          }
          if (+response.is_error === 0) {
            localStorage.setItem('token', response.token);
            this._authService.setLoggedIn(true);
            this.loadUserData();

            response.is_admin
              ? localStorage.setItem('is_admin', '1')
              : localStorage.setItem('is_admin', '0');
            const isTestStatus = window.location.host.includes('localhost')
              ? 1
              : response.is_test;
            localStorage.setItem('is_test', JSON.stringify(isTestStatus));
          }
        },
        () => {
          this.showPopUpMessage(
            'error',
            'Ошибка входа',
            'Проверьте введенные данные и повторите попытку!'
          );
          this.isLoading = false;
        }
      );
  }

  private loadUserData(): void {
    this.isLoading = true;
    this._api
      .userRegister('getUserData', localStorage.getItem('token'), '')
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(
        (data: IUserData) => {
          if (+data.is_error === 0) {
            this._router.navigate(['/supplier-selection']);
            localStorage.setItem('profileName', JSON.stringify(data.data.name));
            localStorage.setItem(
              'profilePhone',
              JSON.stringify(data.data.phone)
            );
            localStorage.setItem(
              'profilePhoneNumber',
              JSON.stringify(data.data.phonenumber)
            );
            localStorage.setItem(
              'profileEmail',
              JSON.stringify(data.data.email)
            );
            localStorage.setItem(
              'profileDateSubscribe',
              JSON.stringify(data.data.subscribe)
            );
            localStorage.setItem(
              'updateProfile',
              JSON.stringify(Math.trunc(Date.now() / 1000))
            );
          } else {
            this.showPopUpMessage(
              'error',
              PopUpMessages.loadFailedSummary,
              PopUpMessages.loadFailedMessages
            );
          }
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }

  openRegisterBot(): void {
    this._mpSurfService
      .createOrUpdate(this.RegisterForm.getRawValue(), 'users')
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.closeModal();
          window.open('https://t.me/mpsurfreg_bot', '_blank');
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.createFailedSummary,
            PopUpMessages.createFailedMessage
          );
        }
      );
  }
}
