import { ThousandSeparator } from '../../../shared/utils/functions/number-space-formatter';
import { ChartOptions } from './myorders.mock';
import { IGetChartOrders } from "./myorders.interface";

export function GenerateOrdersChartData(data: IGetChartOrders): Partial<ChartOptions> {
    return {
        series: [
            {name: 'Заказы шт.', type: 'column', data: data.order_qty},
            {name: 'Отмены шт.', type: 'column', data: data.order_cancel},
            {name: 'Заказы ₽', type: 'line', data: data.order_money},
        ],
        chart: {
            type: 'area', height: 480, stacked: false, animations: {
                enabled: true,
            },
        },
        dataLabels: {enabled: false},
        //@ts-ignore
        colors: ['#1A74E8', '#B33824', '#B22884'],
        stroke: {curve: 'smooth'},
        legend: {horizontalAlign: 'left', offsetX: 40},
        xaxis: {type: 'category', categories: data.category},
        yaxis: [
            {
                seriesName: 'Заказы шт.',
                floating: false,
                decimalsInFloat: 1,
                forceNiceScale: true,
                tickAmount: 3,
                min: 0,
                labels: {
                    formatter: function (value: number): string {
                        return ThousandSeparator(Math.floor(value));
                    },
                },
            },
            {
                seriesName: 'Заказы шт.',
                show: false
            },
            {
                seriesName: 'Заказы ₽',
                forceNiceScale: true,
                floating: false,
                opposite: true,
                labels: {
                    formatter: function (value: number): string {
                        return ThousandSeparator(Math.floor(value));
                    },
                },
            },
        ],
    };
}

export const YAXIS_RETURNS = [
    {
        seriesName: 'Отмены шт.',
        floating: false,
        decimalsInFloat: 1,
        forceNiceScale: true,
        tickAmount: 4,
        min: 0,
        labels: {
            formatter: function (value: number): string {
                return ThousandSeparator(Math.floor(value));
            },
        },
    },
]