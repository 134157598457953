<div class="playIcon" (click)="openVideoModal(modalContent)">
  <fa-icon style="font-size: 14px;" [icon]="playIcon"></fa-icon>
  <span class="playIcon_desc">Видео</span>
</div>

<ng-template #modalContent>
  <iframe
    [src]="link"
    allow="autoplay"
    width="720"
    height="400"
    frameborder="0"
    webkitallowfullscreen
    mozallowfullscreen
    allowfullscreen
  >
  </iframe>
</ng-template>

<p-toast/>