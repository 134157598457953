<p-progressSpinner
  *ngIf="isLoading"
  [style]="{ position: 'absolute', top: '40%', left: '50%' }">
</p-progressSpinner>

<p-toast></p-toast>

<div [ngStyle]="{ display: isLoading ? 'none' : 'block' }">
  <div class="col-sm-12 col-md-12">
    <div class="mb-3 card text-white card-body bg-warning">
      <h5 class="card-title">Идет Beta тестирование</h5>
    </div>
    <br />
  </div>
  <div class="app-page-title mb-0">
    <div class="page-title-wrapper d-flex justify-content-between">
      <div class="page-title-heading">
        <div class="page-title-icon">
          <i class="pe-7s-junk icon-gradient bg-mean-fruit"></i>
        </div>
        <div>Брак, утиль</div>
      </div>
      <div class="d-flex flex-wrap gap-2">
        <p-fileUpload
          #uploadedFiles
          (onError)="onBasicUploadError($event, uploadedFiles)"
          (onSend)="onSendData()"
          (onUpload)="onBasicUploadAuto($event, uploadedFiles)"
          [auto]="true"
          [maxFileSize]="10000000"
          [style]="{ height: '37px', fontSize: '12px' }"
          accept=".xls, .xlsx, .csv"
          chooseLabel="Загрузить из excel"
          class="mr-4 inline-block"
          label="Import"
          mode="basic"
          name="file"
          url="{{ URL_NEW }}defect/uploaded?token={{ TOKEN }}&shop_id={{
            SHOP_ID
          }}"></p-fileUpload>

        <p-button
          (click)="openModal()"
          [style]="{ height: '37px', fontSize: '12px' }"
          label="Добавить Брак"
          styleClass="p-button-success">
        </p-button>
      </div>
    </div>
  </div>
  <div class="main-card mb-3 card" style="height: calc(82vh - 153px)">
    <div style="width: 100%; height: 80vh">
      <ag-grid-angular
        #agGrid
        (gridReady)="onGridReady($event)"
        [columnDefs]="colDefs"
        [defaultColDef]="defaultColDef"
        [enableRangeSelection]="true"
        [gridOptions]="gridOptions"
        [localeText]="localeTextForFilters"
        [pagination]="true"
        [rowData]="rowData"
        [rowHeight]="rowHeight"
        overlayNoRowsTemplate="Нет данных для отображения"
        class="ag-theme-alpine"
        headerHeight="28"
        style="width: 100%; height: 100%">
      </ag-grid-angular>
    </div>
  </div>
  <ng-template #defectGoodModal>
    <app-header-modal
      (closeEmitter)="closeModal()"
      [modalTitle]="isEdit ? 'Изменение' : 'Добавление' + ' брака'">
    </app-header-modal>
    <form [formGroup]="DefectGoodsForm">
      <div class="modal-body d-flex flex-column gap-2">
        <!--        TODO: Help autoopen calendar. Remove in next solution-->
        <p-calendar dateFormat="dd.mm.yy" style="display: none"> </p-calendar>
        <div class="card__row">
          <h5>Дата</h5>
          <p-calendar
            [style]="{ width: '434px' }"
            dateFormat="dd.mm.yy"
            formControlName="date">
          </p-calendar>
        </div>
        <div class="card__row">
          <h5>Баркод - Артикул поставщика</h5>
          <div>
            <p-dropdown
              (onChange)="loadBatchesList()"
              [filter]="true"
              [options]="barcodesList"
              [showClear]="true"
              [style]="{ width: '434' }"
              class="me-1"
              filterBy="label"
              formControlName="barcode"
              optionLabel="label"
              optionValue="value"
              placeholder="Выберите баркод"></p-dropdown>
          </div>
        </div>
        <div class="card__row">
          <h5>Партия №</h5>
          <div>
            <p-dropdown
              [options]="batchesList"
              [style]="{ width: '434' }"
              class="me-1"
              formControlName="batch"></p-dropdown>
          </div>
        </div>
        <div class="card__row">
          <h5>Штук</h5>
          <p-inputNumber
            [style]="{ width: '434px' }"
            [useGrouping]="false"
            formControlName="qty"
            inputId="withoutgrouping"
            mode="decimal">
          </p-inputNumber>
        </div>
        <div class="card__row">
          <h5>ШК посылки</h5>
          <p-inputNumber
            [style]="{ width: '434px' }"
            [useGrouping]="false"
            formControlName="shk_parcels"
            inputId="withoutgrouping"
            mode="decimal">
          </p-inputNumber>
        </div>
        <div class="card__row">
          <h5>Комментарий</h5>
          <div>
            <textarea
              [style]="{ width: '434px' }"
              cols="30"
              formControlName="comment"
              pInputTextarea
              rows="5">
            </textarea>
          </div>
        </div>
      </div>
      <app-footer-modal
        (submitEmitter)="createUpdateDefectGood('create')"
        [isCreate]="!isEdit"
        [isDisabled]="DefectGoodsForm.invalid">
      </app-footer-modal>
    </form>
  </ng-template>
</div>
