<app-page-header-notification-bar
  *ngIf="text || subtext"
  [headerTitle]="text"
  [barText]="subtext" />

<div class="app-page-title mb-0">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <i class="pe-7s-box2 icon-gradient bg-mean-fruit"></i>
      </div>
      <div>Список контрагентов&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>

      <app-mp-video-play
        [videoLink]="getVideoLinkByPage('fin/settings/partners')" />
    </div>
    <div class="page-title-actions">
      <button
        (click)="openModalCreatePartner(createPartnerModal)"
        class="btn-shadow d-inline-flex align-items-center btn btn-success"
        type="button">
        Добавить контрагента
      </button>
    </div>
  </div>
</div>
<div>
  <div *ngIf="rowsForRemoving.length > 0" class="button-wrapp">
    <p>Выбрано: {{ rowsForRemoving.length }}</p>
    <button (click)="removeSelected()" class="icon-button" label="">
      <i class="lnr-trash"></i>
      Удалить
    </button>
  </div>
  <div *ngIf="rowData?.length !== 0" class="main-card mb-3 card">
      <ag-grid-angular
        (rowSelected)="onRowSelected($event)"
        [columnDefs]="finPartnersColumnDF"
        [defaultColDef]="defaultColDef"
        [enableRangeSelection]="true"
        [gridOptions]="gridOptions"
        [localeText]="localeTextForFilters"
        [pagination]="true"
        [paginationPageSize]="30"
        [domLayout]="'autoHeight'"
        [rowData]="rowData"
        overlayNoRowsTemplate="Нет данных для отображения"
        class="ag-theme-alpine"
        rowSelection="multiple"
        style="width: 100%;">
      </ag-grid-angular>
  </div>
</div>

<ng-template #createPartnerModal>
  <app-header-modal
    (closeEmitter)="closeModal()"
    [modalTitle]="isEdit ? 'Изменить' : 'Добавить' + ' контрагента'" />
  <form
    [formGroup]="partnerForm"
    class="needs-validation"
    autocomplete="off"
    novalidate>
    <p-toast></p-toast>
    <div class="modal-body">
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label"
          >Наименование<span style="color: red">*</span></label
        >
        <div class="col-sm-9">
          <input
            class="form-control"
            formControlName="name"
            name="name"
            placeholder="Введите наименование"
            type="text" />
          <div
            *ngIf="
              partnerForm.controls['name'].invalid &&
              partnerForm.controls['name'].touched
            "
            class="alert alert-danger">
            Не корректно заполнено поле
          </div>
        </div>
      </div>

      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label">ИНН</label>
        <div class="col-sm-9">
          <input
            class="form-control"
            formControlName="inn"
            name="inn"
            placeholder="ИНН"
            type="text" />
          <div
            *ngIf="
              partnerForm.controls['inn'].invalid &&
              partnerForm.controls['inn'].touched
            "
            class="alert alert-danger">
            Введите только цифры
          </div>
        </div>
      </div>

      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="kpp_lable">КПП</label>
        <div class="col-sm-9">
          <input
            class="form-control"
            formControlName="kpp"
            id="kpp_lable"
            name="kpp"
            placeholder="КПП"
            type="text" />
          <div
            *ngIf="
              partnerForm.controls['kpp'].invalid &&
              partnerForm.controls['kpp'].touched
            "
            class="alert alert-danger">
            Введите только цифры
          </div>
        </div>
      </div>

      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label">Р/С</label>
        <div class="col-sm-9">
          <input
            class="form-control"
            formControlName="checking_account"
            name="checking_account"
            placeholder="Расчетный счет"
            type="text" />
          <div
            *ngIf="
              partnerForm.controls['checking_account'].invalid &&
              partnerForm.controls['checking_account'].touched
            "
            class="alert alert-danger">
            Не корректно заполнено поле
          </div>
        </div>
      </div>
      <!--      <div class="position-relative row form-group">-->
      <!--        <label class="col-sm-3 col-form-label" for="income_item">Доходная статья</label>-->
      <!--        <div class="col-sm-9" style="margin-top: 4px;">-->
      <!--          <p-dropdown [filter]="true" [options]="itemsIncomeList" [showClear]="true"-->
      <!--                      [style]="{ display: 'flex', alignItems: 'center', height: '34px', fontSize: '13px'}"-->
      <!--                      formControlName="income_item"-->
      <!--                      id="income_item"-->
      <!--                      optionLabel="name" optionValue="id"-->
      <!--                      placeholder="Нераспределенный доход" />-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="position-relative row form-group">-->
      <!--        <label class="col-sm-3 col-form-label" for="expenses_item">Расходная статья</label>-->
      <!--        <div class="col-sm-9" style="margin-top: 4px;">-->
      <!--          <p-dropdown [filter]="true" [options]="itemsExpensesList"-->
      <!--                      [showClear]="true" [style]="{ display: 'flex', alignItems: 'center', height: '34px'}"-->
      <!--                      formControlName="expenses_item"-->
      <!--                      id="expenses_item"-->
      <!--                      optionLabel="name" optionValue="id"-->
      <!--                      placeholder="Нераспределенный расход" />-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label">Комментарий</label>
        <div class="col-sm-9">
          <textarea
            class="form-control"
            formControlName="description"
            name="description">
          </textarea>
          <div
            *ngIf="
              partnerForm.controls['description'].invalid &&
              partnerForm.controls['description'].touched
            "
            class="alert alert-danger">
            Не корректно заполнено поле
          </div>
        </div>
      </div>
    </div>
    <app-footer-modal
      (cancelEmitter)="closeModal()"
      (submitEmitter)="createUpdateFinPartner()"
      [isClose]="true"
      [isCreate]="!isEdit"
      [isDisabled]="partnerForm.invalid || partnerForm.disabled" />
  </form>
</ng-template>

<p-toast />
