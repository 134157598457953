import { AfterViewInit, Component, inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { ApiService } from '../../../shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { NgbCalendar, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import 'moment/locale/ja';
import 'moment/locale/fr';
import 'moment/locale/ru';
import { ISuppliersList } from '../../guides/finpurchase/finpurchase.interface';
import { FIN_OPERATION_COLUMN_DEFS, MY_FORMATS } from './finoperations.mock';
import { ColDef, ColGroupDef, GridReadyEvent } from 'ag-grid-community';
import { DragStoppedEvent, GridOptions } from 'ag-grid';
import { AgGridAngular } from 'ag-grid-angular';
import {
    IFinOperationData,
    IFinOperationExpensesFB,
    IFinOperationFinAccountsList,
    IFinOperationFinPartnersList,
    IFinOperationsShopList,
    IFormDataGeneral,
    OperationType,
} from './finoperations.interface';
import { PaymentTypesWithOperations } from './components/finoperations-excel/finoperations-excel.mock';
import { FinoperationsExcelComponent } from './components/finoperations-excel/finoperations-excel.component';
import { NgxMetrikaService } from '@kolkov/ngx-metrika';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    FinoperationExcelRenderCellsComponent
} from './components/aggrid-components/finoperation-excel-render-cells.component';
import { concatMap, finalize, subscribeOn, takeUntil } from 'rxjs/operators';
import { ConfirmationService, MessageService } from 'primeng/api';
import { PopUpMessages } from '../../../shared/mocks/pop-up-messages.mock';
import { CommonResponseGeneral, INmidCommon } from '../../../shared/interfaces/common.interface';
import { AbstractMenuTabComponent } from '../../abstract-menu-tab.component';
import { IResponseCommon } from '../../settings/shop/shop.interface';
import * as moment from 'moment';
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
import { FinItemsList1 } from "../../../shared/interfaces/mp-common.interface";
import * as FileSaver from 'file-saver';

@UntilDestroy({checkProperties: true})
@Component({
    selector: 'app-finoperations',
    templateUrl: './finoperations.component.html',
    styleUrls: ['./finoperations.component.sass'],
    providers: [
        MessageService,
        ConfirmationService,
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ],
})
export class FinoperationsComponent extends AbstractMenuTabComponent implements OnInit, AfterViewInit {
    protected readonly ngbCalendar = inject(NgbCalendar);
    protected readonly fb = inject(FormBuilder);

    readonly dataForColumnDefs: (ColDef | ColGroupDef)[] = FIN_OPERATION_COLUMN_DEFS;
    readonly FormMoving: FormGroup;
    readonly FormArrival: FormGroup;
    readonly FormExpenses: FormGroup;
    readonly FormRetention: FormGroup;
    readonly FormNewAgent: FormGroup;
    readonly FormNewAccount: FormGroup;
    readonly FormLoanRepayment: FormGroup;

    isOzon = JSON.parse(localStorage.getItem('mpall_shop')).marketPlace === 2;
    finAccountsList: IFinOperationFinAccountsList[] = [];
    finAccountsDebitList: IFinOperationFinAccountsList[] = [];
    finAccountsCreditList: IFinOperationFinAccountsList[] = [];
    finAccountsListStatic: IFinOperationFinAccountsList[] = [];
    finPartnersList: IFinOperationFinPartnersList[] = [];
    suppliersList: ISuppliersList[] = [];
    shopsList: IFinOperationsShopList[] = [];
    itemsList: FinItemsList1[] = [];
    itemsIncomeList: any[] = [];
    itemsExpensesList: any[] = [];
    commonArticlesList: any[] = [];
    finArticlesNmList: any[] = [];
    cabinets: {shop_name: string, id: number}[] = [];

    selectedDateAccrual: {
        month: number;
        year: number;
        day: number;
    };
    selectedDatePayment: {
        month: number;
        year: number;
        day: number;
    };

    isCreate = true;

    showAgGridExcel = false;
    displaySeparateSum = false;
    displaySeparation = false;

    actionUID: number | undefined = undefined;
    shop_id = this.SHOP_ID

    finOperationData: IFinOperationData[] = [];
    finOperationsForRemoving: string[] = [];
    modalReference: NgbModalRef;

    text = ''
    subtext = ''

    @ViewChild(FinoperationsExcelComponent) finoperationsExcelComponent: FinoperationsExcelComponent;
    @ViewChild('agGrid', {static: true}) agGrid: AgGridAngular;
    @ViewChild('expenses', {read: TemplateRef}) modalExpenses: TemplateRef<any>;
    @ViewChild('receipt', {read: TemplateRef}) modalReceipt: TemplateRef<any>;
    @ViewChild('moving', {read: TemplateRef}) modalMoving: TemplateRef<any>;
    @ViewChild('retention', {read: TemplateRef}) modalRetention: TemplateRef<any>;
    @ViewChild('newAgent', {read: TemplateRef}) newAgent: TemplateRef<any>;
    @ViewChild('newAccount', {read: TemplateRef}) newAccount: TemplateRef<any>;

    get creditLimitSelected(): boolean {
        return !!(this.FormNewAccount.controls.type.value && this.FormNewAccount.controls.type.value === 6);
    }

    get creditLoanSelected(): boolean {
        return !!(this.FormNewAccount.controls.type.value && this.FormNewAccount.controls.type.value === 5);
    }

    constructor() {
        super();
        // Форма прихода
        this.FormArrival = this.fb.group({
            date_payment: new FormControl(null, [Validators.required]),
            date_accrual: new FormControl(null, [Validators.required]),
            account_id: new FormControl(null, [Validators.required]),
            amount: new FormControl(null, [Validators.required, Validators.maxLength(10), Validators.min(1)]),
            item_id: new FormControl(null, [Validators.required]),
            product_code: new FormControl(null),
            insure_arrival_payment: new FormControl(false),
            insure_arrival_income: new FormControl(false),
            description: new FormControl(null),
            project: new FormControl(null),
            partner_id: new FormControl(0),
            operation_shop_id: new FormControl(null),
        });
        this.FormExpenses = this.fb.group({
            date_payment: new FormControl(this.ngbCalendar.getToday(), [Validators.required]),
            date_accrual: new FormControl(this.ngbCalendar.getToday(), [Validators.required]),
            account_id: new FormControl(null, [Validators.required]),
            amount: new FormControl(null, [Validators.required, Validators.maxLength(15)]),
            item_id: new FormControl(null, [Validators.required]),
            insure_arrival_payment: new FormControl(null),
            insure_arrival_income: new FormControl(null),
            product_code: new FormControl(''),
            supplier_id: new FormControl('123'),
            project: new FormControl(null),
            partner_id: new FormControl(''),
            operation_shop_id: new FormControl(0),
            separate_payment: new FormControl(1),
            description: new FormControl(null)
        });
        // Форма перемещения
        this.FormMoving = this.fb.group({
            amount: new FormControl(null, [Validators.required, Validators.maxLength(10)]),
            date_payment: new FormControl(null, [Validators.required]),
            date_payment2: new FormControl(null),
            account_id: new FormControl(null, [Validators.required]),
            account_id_2: new FormControl(null, [Validators.required]),
            description: new FormControl(null),
        });
        // Форма Удержания WB
        this.FormRetention = this.fb.group({
            date_accrual: new FormControl(this.ngbCalendar.getToday(), [Validators.required]),
            amount: new FormControl(null, [Validators.required]),
            item_id: new FormControl(null, [Validators.required]),
            account_id: new FormControl(null),
            description: new FormControl(null),
            insure_arrival_payment: new FormControl(true),
            insure_arrival_income: new FormControl(null),
            product_code: new FormControl(null),
            supplier_id: new FormControl('123'),
            project: new FormControl(null),
            partner_id: new FormControl(null),
        });
        // Форму Кредита/Займа
        this.FormLoanRepayment = this.fb.group({
            date_payment: new FormControl('', [Validators.required]),
            date_accrual: new FormControl(''),
            account_id: new FormControl('', [Validators.required]),
            credit_loan: new FormControl('', [Validators.required]),
            amount_of_payment: new FormControl('', [Validators.required]),
            debt_body: new FormControl('', [Validators.required, Validators.maxLength(10), Validators.min(1)]),
            percentages: new FormControl('', [Validators.required, Validators.maxLength(10), Validators.min(1)]),
            description: new FormControl(''),
        });
        this.FormNewAgent = this.fb.group({
            shop: new FormControl(null),
            name: new FormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(500)]),
            inn: new FormControl(null, [Validators.pattern('^[0-9]*$')]),
            kpp: new FormControl(null, [Validators.pattern('^[0-9]*$')]),
            checking_account: new FormControl(null),
            income_item: new FormControl(null),
            expenses_item: new FormControl(null),
            description: new FormControl(null),
        });
        this.FormNewAccount = this.fb.group({
            name: new FormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
            type: new FormControl(null),
            account_bik: new FormControl(null),
            account_bank: new FormControl(null),
            account_settlement: new FormControl(null),
            account_corrective: new FormControl(null),
            current_sum: new FormControl(null),
            credit_limit: new FormControl(null),
            is_visible: new FormControl(null),
            is_main_account: new FormControl(null),
            date_balance_init: new FormControl(null, [Validators.required]),
            balance_init: new FormControl(null, [
                Validators.required,
                Validators.pattern('^[+]?([0-9]{0,})*[.]?([0-9]{0,2})?$'),
            ]),
            description: new FormControl(null),
        });

        this.gridOptions = {
            context: {
                componentParent: this,
            },
            onDragStopped: (event: DragStoppedEvent) => this.onColumnMoved(event),
            enableCellTextSelection: true,
            suppressRowClickSelection: true,
            frameworkComponents: {
                customFooterComponent: FinoperationExcelRenderCellsComponent,
            },
        } as GridOptions;

        this.countAmountOfPayment();
    }

    getRowStyle = (params: CommonResponseGeneral<IFinOperationFinAccountsList>) => {
        const dateCreate = params.data?.date_create;
        if (params.data && dateCreate && moment().diff(dateCreate, 'seconds') < 30) {
            return {background: '#e1f5fe'};
        }
        return {background: 'transparent'};
    };

    ngOnInit(): void {
        this.getFinOperationManageData();
        this.getSupplierForModal();
        this.createSeparateOperationGroup();
        this.loadArticlesNM();
        this.loadCabinets();
        
        this.FormExpenses.controls['amount']?.valueChanges.subscribe((value: number) => {
            this.displaySeparation = value > 2;
        });

        this._mpSurfService
        .loadMetaData(this.SHOP_ID, this.TOKEN)
        .pipe(untilDestroyed(this))
        .subscribe(res => {
            //@ts-ignore
            const { die_text, die_title } = res.data.tutorialVideoLink.find(item => item.pageTitle === 'fin/operations') || { die_text: null, die_title: null }
            this.text = die_title
            this.subtext = die_text
        })
    }

    ngAfterViewInit() {
        if (this.agGrid) {
            this.gridApi = this.agGrid.api;
        }
        this.finoperationsExcelComponent.dataIsSaved.pipe(untilDestroyed(this)).subscribe((value: boolean) => {
            this.showAgGridExcel = !value;
        });
    }

    // AG-GRID
    onGridReady(params?: GridReadyEvent<any>) {
        this.gridApi = params.api;
        const columnState = localStorage.getItem('myColumnState');
        if (columnState) {
            const columnStateJSON = JSON.parse(columnState);
            // params.columnApi.setColumnState(columnStateJSON);
            params.columnApi.applyColumnState(columnStateJSON);
        }
    }

    onColumnMoved(params: DragStoppedEvent) {
        const columnState = JSON.stringify(params.columnApi.getColumnState());
        localStorage.setItem('myColumnState', columnState);
    }

    onRowSelected(event: any) {
        const selectedUID = event.data.UID;
        const selectedStatus = event.node.selected;
        if (selectedStatus) {
            this.finOperationsForRemoving.push(selectedUID);
        } else {
            this.finOperationsForRemoving = this.finOperationsForRemoving.filter((row) => row !== selectedUID);
        }
    }

    removeSelected() {
        if (confirm('Вы уверены, что хотите удалить запись ?')) {
            const requests = this.finOperationsForRemoving.map((uid) => this._mpSurfService.delete({uid}, 'fin-operation'));
            forkJoin(requests)
                .pipe(untilDestroyed(this))
                .subscribe(
                    (response) => {
                        this.finOperationsForRemoving.length = 0;
                        this.getFinOperationsList();
                        this.showPopUpMessage('success', 'Операции удалены', '');
                    },
                    (error: HttpErrorResponse) => {
                        this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
                    },
                );
        }
    }

    updateFinOperationEmitter(operationData: any) {
        const operationTypeId = PaymentTypesWithOperations[operationData.type_operation_title];
        switch (+operationTypeId) {
            case 1:
                this.openUpdateOperationModal(this.modalReceipt, operationTypeId, operationData.UID);
                break;
            case 2:
                this.openUpdateOperationModal(this.modalExpenses, operationTypeId, operationData.UID);
                break;
            case 3:
                this.openUpdateOperationModal(this.modalMoving, operationTypeId, operationData.UID);
                break;
            case 4:
                this.openUpdateOperationModal(this.modalRetention, operationTypeId, operationData.UID);
                break;
            default:
                break;
        }
    }

    deleteFinOperationEmitter(finOperationData: any) {
        if (confirm('Вы уверены, что хотите удалить запись ?')) {
            this._mpSurfService
                .delete({uid: finOperationData.UID}, 'fin-operation')
                .pipe(untilDestroyed(this))
                .subscribe(
                    (data: any) => {
                        if (+data.is_error === 0) {
                            this.getFinOperationsList();
                            this.showPopUpMessage('success', PopUpMessages.deleteSuccessSummary, PopUpMessages.deleteSuccessMessage);
                        } else {
                            this.showPopUpMessage('warning', PopUpMessages.deleteFailedSummary, PopUpMessages.deleteFailedMessage);
                        }
                    },
                    () => {
                        this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
                    },
                );
        }
    }

    openCreateOperationModal(modal: TemplateRef<any>, productParameter: OperationType) {
        this.isCreate = true;
        this.actionUID = undefined;
        this.displaySeparation = false;
        this.selectedDatePayment = this.ngbCalendar.getToday();
        this.selectedDateAccrual = this.ngbCalendar.getToday();
        switch (productParameter) {
            case 'arrival':
                this.setterArrivalCreateModal();
                break;
            case 'expenses':
                this.setterExpensesCreateModal();
                break;
            case 'moving':
                this.setterMovingCreateModal();
                break;
            case 'retention':
                this.setterRetentionCreateModal();
                break;
        }
        this._modalService.open(modal, {backdrop: 'static'});
    }

    openUpdateOperationModal(modalContent: any, operationType: any, uid: any) {
        this.isCreate = false;
        this._mpSurfService
            .load({uid}, 'fin-operation')
            .pipe(untilDestroyed(this))
            .subscribe(
                (response: any) => {
                    if (+response.is_error === 0 && +response.cnt === 1) {
                        const operationData = response.data;
                        this.actionUID = operationData.UID;
                        this.setterForTime(new Date(operationData.date_payment), new Date(operationData.date_accrual));
                        this.setterDataUpdateModal(+operationType, operationData);
                        this.getArticlesListOfExpensesAndArrivalStatic(+operationType);
                        this._modalService.open(modalContent, {backdrop: 'static'});
                    }
                },
                () => {
                    this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
                },
            );
    }

    closeSecondModal(): void {
        this.modalReference.close();
    }

    createUpdateOperation(operationParameter: OperationType) {
        const formData = this.getFormData(operationParameter);
        if(formData.data.product_code && this.isOzon) {
            formData.data.product_code = formData.data.product_code.split(' - ')[1]
        }
        if (operationParameter === 'expenses' && this.displaySeparateSum) {
            this.manageExpensesUpdateOperation({...formData.data} as IFinOperationExpensesFB);
        } else {
            formData.data.uid = this.actionUID ?? undefined;
            this._mpSurfService
                .createOrUpdate(formData.data, `fin-operation/${formData.url}`)
                .pipe(untilDestroyed(this))
                .subscribe(
                    () => {
                        this.showPopUpMessage(
                            'success',
                            this.isCreate ? PopUpMessages.createSuccessSummary : PopUpMessages.updateSuccessSummary,
                            `Операция ${this.isCreate ? 'добавлена' : 'изменена'}`,
                        );
                        this.postClearModal();
                        this.getFinOperationsList();
                    },
                    () => {
                        this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
                    },
                );
        }
    }

    manageExpensesUpdateOperation(fgData: IFinOperationExpensesFB) {

        this._mpSurfService.createOrUpdate(fgData, 'fin-operation/add-order')
            .pipe(
                untilDestroyed(this)
            )
            .subscribe(
                () => {
                    this._modalService.dismissAll();
                    this.getFinOperationsList();
                    this.showPopUpMessage(
                        'success',
                        this.isCreate ? PopUpMessages.createSuccessSummary : PopUpMessages.updateSuccessSummary,
                        `Операция ${this.isCreate ? 'добавлена' : 'изменена'}`,
                    );
                },
                () => {
                    this.showPopUpMessage('error', PopUpMessages.createFailedSummary, PopUpMessages.createFailedMessage);
                },
            );
    }

    createNewAgent(): void {
        this._mpSurfService
            .createOrUpdate(this.FormNewAgent.getRawValue(), 'fin-partners')
            .pipe(untilDestroyed(this),
                concatMap((data: IResponseCommon) => {
                    if (+data.is_error === 0) {
                        this.modalReference.close();
                        this.showPopUpMessage('success', PopUpMessages.createSuccessSummary, 'Контрагент добавлен');
                        this.FormNewAgent.reset();
                    } else {
                        this.showPopUpMessage('error', data.msg, '');
                    }
                    return this.getFinPartners();
                }))
            .subscribe(
                (responseFinPartners: any) => {
                    this.finPartnersList = responseFinPartners;
                },
                () => {
                    this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
                },
            );
    }

    createNewAccount(): void {
        this._mpSurfService
          .createOrUpdate(this.FormNewAccount.getRawValue(), 'fin-account')
          .pipe(untilDestroyed(this),
            concatMap((data: IResponseCommon) => {
                if (+data.is_error === 0) {
                    this.modalReference.close();
                    this.showPopUpMessage('success', PopUpMessages.createSuccessSummary, 'Контрагент добавлен');
                    this.FormNewAccount.reset();
                } else {
                    this.showPopUpMessage('error', data.msg, '');
                }
                return this.getFinAccounts();
            }))
          .subscribe((responseFinAccounts: any) => {
                this.finAccountsList = responseFinAccounts;
            },
            () => {
                this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
            },
          );
    }


    // TODO - ANOTHER METHODS STARTS
    getTypeAccount(type: number) {
        switch (type) {
            case 1:
                return 'Наличный';
            case 2:
                return 'Безналичный';
            case 3:
                return 'Карта физ лица';
            case 4:
                return 'Дебет';
            case 5:
                return 'Кредит / Займ';
            case 6:
                return 'Кредитная линия с лимитом';
            default:
                return '';
        }
    }

    onBasicUploadAuto(excelData: any, fileUpload) {
        this.showPopUpMessage('success', 'Файл загружен. Данные обновлены', '');
        const finOperationExcelData = excelData.originalEvent.body as Array<any>;
        this.finoperationsExcelComponent.updateRowData(finOperationExcelData);
        this.showAgGridExcel = true;
        fileUpload.clear();
    }

    onBasicUploadError(event: any, fileUpload) {
        this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, 'Ошибка загрузки файла');
        fileUpload.clear();
    }

    isAccountItemBusy(controlName: string) {
        if (controlName) {
            this.finAccountsList = [...this.finAccountsListStatic];
            this.finAccountsList.forEach((elem) => {
                if (elem.disabled) {
                    elem.disabled = false;
                }
            });
            const controlValue = this.FormMoving.get(controlName).value;
            if (controlValue) {
                this.finAccountsList.forEach((acc) => {
                    if (acc.UID === controlValue) {
                        acc.disabled = true;
                    }
                });
            }
        }
    }

    saveAsExcelFile(buffer: any, fileName: string): void {
        const EXCEL_TYPE =
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
          type: EXCEL_TYPE,
        });
        FileSaver.saveAs(
          data,
          fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
        );
      }

    async exportInExcel() {
        const data = await this._mpSurfService.load({}, 'fin-operation/export').toPromise()
        console.log(data)

        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(data as unknown[]);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer: any = xlsx.write(workbook, {
              bookType: 'xlsx',
              type: 'array',
            });
            this.saveAsExcelFile(excelBuffer, 'Fin-Operation');
        });
    }

    addSeparateSumOperation(): void {
        this.displaySeparateSum = true;
    }


    removeSeparateSum(): void {
        this.displaySeparateSum = false;
        this.FormExpenses.controls['separate_payment'].patchValue(1);
    }

    openNewAgentModal() {
        this.FormNewAgent.reset();
        this.modalReference = this._modalService.open(this.newAgent);
    }

    openNewAccountModal() {
        this.FormNewAccount.reset();
        this.FormNewAccount.controls['date_balance_init']?.setValue(this.ngbCalendar.getToday())
        this.modalReference = this._modalService.open(this.newAccount);
    }

    // TODO - MODALS END
    private countAmountOfPayment(): void {
        this.FormLoanRepayment.valueChanges.pipe(untilDestroyed(this)).subscribe((controlsValue) => {
            const sum = controlsValue.debt_body + controlsValue.percentages;
            this.FormLoanRepayment.controls.amount_of_payment.patchValue(sum, {emitEvent: false});
        });
    }

    private setterArrivalCreateModal(): void {
        this.getArticlesListOfExpensesAndArrivalStatic(1);
        this.FormArrival.reset();
        this.FormArrival.get('insure_arrival_payment').setValue(true);
    }

    private setterExpensesCreateModal(): void {
        this.getArticlesListOfExpensesAndArrivalStatic(2); // подгружаем тип приход ДС
        const supplierMain = JSON.parse(localStorage.getItem('mpall_shop'));
        this.FormExpenses.reset();
        this.FormExpenses.get('date_payment').setValue(this.ngbCalendar.getToday());
        this.FormExpenses.get('date_accrual').setValue(this.ngbCalendar.getToday());
        this.FormExpenses.get('insure_arrival_payment').setValue(true);
        this.FormExpenses.get('separate_payment').setValue(1);
    }

    private setterMovingCreateModal(): void {
        this.FormMoving.reset();
        this.finAccountsList = this.finAccountsListStatic;
    }

    private setterRetentionCreateModal(): void {
        this.getArticlesListOfExpensesAndArrivalStatic(4);
        const supplierMain = JSON.parse(localStorage.getItem('mpall_shop'));
        this.FormRetention.reset();
        // this.FormRetention.get('date_payment').setValue(this.ngbCalendar.getToday());
        this.FormRetention.get('date_accrual').setValue(this.ngbCalendar.getToday());
        this.FormRetention.get('insure_arrival_payment').setValue(true);
    }

    private setterDataUpdateModal(operationType: number, operationData: any): void {
        let formGroup;
        switch (operationType) {
            case 1:
                formGroup = this.FormArrival as FormGroup;
                break;
            case 2:
                formGroup = this.FormExpenses as FormGroup;
                break;
            case 3:
                formGroup = this.FormMoving as FormGroup;
                break;
            case 4:
                formGroup = this.FormRetention as FormGroup;
                break;
            default:
                this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
        }
        formGroup.patchValue(operationData);

        if ([1, 2].includes(operationType)) {
            formGroup.controls.date_payment.setValue(this.selectedDatePayment);
            formGroup.controls.date_accrual.setValue(this.selectedDateAccrual);
        }
        if(operationType === 4) {
            formGroup.controls.date_accrual.setValue(this.selectedDateAccrual);
        }

        if(operationType === 3) {
            formGroup.controls.date_payment.setValue(this.selectedDatePayment);
        }
    }

    private getFormData(productParameter: string): IFormDataGeneral {
        const fgData: any = {data: {}, url: ''};
        switch (productParameter) {
            case 'arrival':
                fgData.data = this.FormArrival.getRawValue();
                fgData.data.type_operation = 1;
                fgData.url = this.isCreate ? 'add-receipt' : 'update-receipt';

                break;
            case 'expenses':
                fgData.data = this.FormExpenses.getRawValue();
                fgData.data.type_operation = 2;
                fgData.url = this.isCreate ? 'add-order' : 'update-order';
                break;
            case 'moving':
                fgData.data = this.FormMoving.getRawValue();
                fgData.data.date_payment2 = fgData.data.date_payment;
                fgData.data.type_operation = 3;
                fgData.url = 'add-moving';
                break;
            case 'retention':
                fgData.data = this.FormRetention.getRawValue();
                fgData.data.type_operation = 4;
                fgData.url = this.isCreate ? 'add-order' : 'update-order';
                break;
            case 'repayment':
                fgData.data = this.FormLoanRepayment.getRawValue();
                fgData.data.type_operation = 5;
                fgData.url = 'add-load-repayment';
        }
        return fgData;
    }

    private postClearModal(): void {
        this._modalService.dismissAll();
        this.FormExpenses.reset();
        this.FormRetention.reset();
        this.FormArrival.reset();
        this.FormMoving.reset();
    }

    // TODO - LOAD DATA STARTS
    private getFinOperationManageData(): void {
        forkJoin([
            this.getFinAccounts(),
            this.getFinPartners(),
            this.getArticlesListOfExpensesAndArrival(),
            this.getFinOperation(),
        ])
            .pipe(untilDestroyed(this))
            .subscribe(
                ([responseFinAccounts, responseFinPartners, articles, responseOperation]) => {
                    this.finoperationsExcelComponent.setupFinAccounts(responseFinAccounts);
                    this.finoperationsExcelComponent.setUpFinPartners(responseFinPartners);
                    this.finoperationsExcelComponent.setupFinItems(articles);
                    this.setterValues(articles);
                    this.finAccountsListStatic = responseFinAccounts;
                    this.finAccountsList = [...this.finAccountsListStatic];
                    this.finAccountsDebitList = this.finAccountsListStatic.filter((a) => +a.type === 4);
                    this.finAccountsCreditList = this.finAccountsListStatic.filter((a) => [5, 6].includes(+a.type));
                    this.finPartnersList = responseFinPartners;
                    this.commonArticlesList = [...articles];
                    this.manageFinOperationList(responseOperation);
                },
                () => {
                    this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
                },
            );
    }

    private getFinOperationsList() {
        this.getFinOperation()
            .pipe(untilDestroyed(this))
            .subscribe(
                (response: IFinOperationData[]) => {
                    this.manageFinOperationList(response);
                },
                () => {
                    this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
                },
            );
    }

    private manageFinOperationList(response: IFinOperationData[]): void {
        this.finOperationData = response.map((finOperation: IFinOperationData) => {
            finOperation.account_id = this.finAccountsList.find((finAcc) => finOperation.account_id === finAcc.UID)?.name || '';
            finOperation.partner_id = this.finPartnersList.find((finPart) => finOperation.partner_id === finPart.UID)?.name || '';

            return finOperation;
        });
        // this.finOperationData = formattedOperationList;
    }

    private setterForTime(dateOfPayment: any, dateOfAccrual: any) {
        this.selectedDatePayment = {
            year: parseInt(moment(dateOfPayment).format('YYYY')),
            month: parseInt(moment(dateOfPayment).format('M')),
            day: parseInt(moment(dateOfPayment).format('D')),
        };
        this.selectedDateAccrual = {
            year: parseInt(moment(dateOfAccrual).format('YYYY')),
            month: parseInt(moment(dateOfAccrual).format('M')),
            day: parseInt(moment(dateOfAccrual).format('D')),
        };
    }

    private setterValues(finItems: any): void {
        this.itemsIncomeList = finItems.filter((item) => +item.item_type === 1);
        this.itemsExpensesList = finItems.filter((item) => +item.item_type === 2);
    }

    private loadArticlesNM() {
        this._mpSurfService.load({}, 'products/important').subscribe(
            (response: INmidCommon[]) => {
                this.finArticlesNmList = response.map((a) => this.isOzon ? `${a.nmid} - ${a.SKU}` : a.nmid);
            },
            () => {
                this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
            },
        );
    }

    private getSupplierForModal() {
        const body = {shop_id: this.SHOP_ID};
        this._mpSurfService.loadByOld(body, 'getDataShopWBList', 'data')
            .pipe(untilDestroyed(this))
            .subscribe(
                (response: any[]) => {
                    this.finoperationsExcelComponent.setupDataShopWBList(response);
                    this.shopsList = response;
                    this.suppliersList = response;
                },
                () => {
                    this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
                },
            );
    }

    // TODO - LOAD DATA ENDS

    // TODO SEPARATE OPERATIONS PART STARTS
    private createSeparateOperationGroup(): FormGroup {
        return new FormGroup({
            datePaymentSeparate: new FormControl(this.ngbCalendar.getToday()),
            dateAccrualSeparate: new FormControl(this.ngbCalendar.getToday()),
            supplierIdSeparate: new FormControl(null),
            articleSeparate: new FormControl(''),
            projectSeparate: new FormControl(''),
            accountIdSeparate: new FormControl(''),
            amountSeparate: new FormControl('', [
                Validators.pattern('^[+]?([0-9]{0,})*[.]?([0-9]{0,2})?$'),
                Validators.maxLength(10),
            ]),
            partnerIdSeparate: new FormControl(''),
            itemSeparate: new FormControl(''),
            descriptionSeparate: new FormControl(''),
        });
    }

    private createSeparateSumOperationGroup(): FormGroup {
        return new FormGroup({
            datePaymentSeparate: new FormControl(this.ngbCalendar.getToday()),
            sumPaymentSeparate: new FormControl(0),
        });
    }

    // TODO - API REQUESTS STARTS
    private getArticlesListOfExpensesAndArrival(): Observable<any> {
        return this._mpSurfService.load({}, 'fin-items');
    }

    private getArticlesListOfExpensesAndArrivalStatic(type: number) {
        this._mpSurfService
            .load({type}, 'fin-items/item-list')
            .pipe(untilDestroyed(this))
            .subscribe(
                (data: FinItemsList1[]) => {
                    this.itemsList = data;
                },
                () => {
                    this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
                },
            );
    }

    private getFinOperation(): Observable<any> {
        return this._mpSurfService.load({}, 'fin-operation');
    }

    private getFinPartners(): Observable<any> {
        return this._mpSurfService.load({}, 'fin-partners');
    }

    private getFinAccounts(): Observable<any> {
        return this._mpSurfService.load({}, 'fin-account');
    }

    private loadCabinets() {
        return this._mpSurfService.load({}, 'shops/list-user-shops')
            .pipe(untilDestroyed(this))
            .subscribe((cabinets: {shop_name: string, id: number}[]) => {
                this.cabinets = [{shop_name: 'Все кабинеты', id: 0}, ...cabinets];
                this.FormArrival.controls['operation_shop_id'].setValue(this.cabinets[0])
                this._cdr.markForCheck()
            })
    }
}