<div class="footer-wrapper">
  <div class="footer-wrapper__left">
    <section style="margin-left: 2px;">
      <a href="https://t.me/+C-XyQFXtDR5mMDI6">
        <img src="assets/images/Telegram_logo.svg" height="20" width="20" alt="telegram logo" >
        <span style="margin-left: 6px;">
          <strong>Следи за нашими новостями</strong>
        </span>
      </a>
    </section>
    <section class="flex-centered">
        <i class="pe-7s-clock"></i>
        <span style="margin-left: 6px;">
          Тех. поддержка: Пн - Сб с 9:00 до 19:00
        </span>
    </section>
  </div>
  <p class="m-0" style="align-self: flex-end;">2020 - {{ year }} Copyright © MpSurf. Все права защищены.</p>
</div>
