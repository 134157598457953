import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {
    accountsListOriginal,
    accountType,
    agentList,
    formatValue
} from '../finoperations-excel/finoperations-excel.mock';

@Component({
    selector: 'app-btn-cell-specific-renderer',
    template: `
        <span [ngStyle]="{'color': color}" [pTooltip]="tooltipMessage">
      {{value}}
    </span>`,
})
export class FinoperationExcelRenderCellsComponent implements ICellRendererAngularComp {
    value: string;
    color = '';
    tooltipMessage = '';
    private params: any;

    constructor() {
    }

    agInit(params: any): void {
        this.params = params;
        if (params.data) {
            const currentField = String(params.colDef.field).split('.')[0];
            this.value = params.value;
            this.tooltipMessage = params.data?.[currentField]?.warningTooltip ?? '';
            switch (currentField) {
                case 'finOperationExcelItem':
                case 'finOperationExcelSum':
                case 'finOperationExcelComment':
                case 'finOperationExcelShop':
                    this.renderStaticValues(params, currentField);
                    break;
                case 'finOperationExcelTypeOperation':
                    this.renderDropdownValues(params, currentField, 'TypeOperation');
                    break;
                case 'finOperationExcelAccount':
                    this.renderDropdownValues(params, currentField, 'AccountCart');
                    break;
                case 'finOperationExcelArticle':
                    this.renderStaticValues(params, currentField);
                    break;
                case 'finOperationExcelContrAgent':
                    this.renderDropdownValues(params, currentField, 'ContrAgent');
                    break;
            }
        }
    }

    refresh(params: any): boolean {
        this.params = params;
        this.value = params.value;
        return true;
    }

    private renderStaticValues(params, currentField) {
        this.color = params.data?.[currentField]?.style ?? '';
        this.tooltipMessage = this.color !== 'red' ? '' : this.tooltipMessage;
    }

    private renderDropdownValues(params, currentField, prop) {
        if (params.data[currentField]) {
            const value = formatValue(params.data[currentField].value + '');
            if (this.isObjectHasProperty(prop, value)) {
                this.color = 'black';
                this.tooltipMessage = '';
            } else {
                this.color = params.data?.[currentField]?.style ?? '';
            }
        }
    }

    private isObjectHasProperty(prop: string, value: string): boolean {
        let result = false;
        switch (prop) {
            case 'TypeOperation':
                result = accountType.includes(value);
                break;
            case 'AccountCart':
                result = accountsListOriginal.includes(value);
                break;
            case 'ContrAgent':
                result = agentList.includes(value);
                break;
        }
        return result;
    }
}


