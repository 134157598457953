import {
  Component,
  inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  ConfirmationService,
  MessageService,
  PrimeNGConfig,
  TreeDragDropService,
  TreeNode,
} from 'primeng/api';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonValueWithID } from '../../../../shared/interfaces/common.interface';
import { ListOfGroupDDS } from './items.mock';
import { AbstractMenuTabComponent } from '../../../abstract-menu-tab.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs/operators';
import { PopUpMessages } from '../../../../shared/mocks/pop-up-messages.mock';
import { IResponseCommon } from '../../../settings/shop/shop.interface';
import { IFinItem, TreeNodeFinItems } from './items.interface';

@UntilDestroy({ checkProperties: this })
@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.sass'],
  providers: [TreeDragDropService, ConfirmationService, MessageService],
})
export class ItemsComponent extends AbstractMenuTabComponent implements OnInit {
  private readonly _primengConfig = inject(PrimeNGConfig);
  readonly itemsForm = new FormGroup({
    name: new FormControl(null, [
      Validators.required,
      Validators.maxLength(100),
    ]),
    d_l: new FormControl(true),
    activity: new FormControl(null),
    pnl_id: new FormControl(null),
    p_l: new FormControl(false),

    item_type: new FormControl(null),
    description: new FormControl(null),
  });

  listOfGroupsPnlReceipt: CommonValueWithID[] = [];
  listOfGroupsPnlSpisanie: CommonValueWithID[] = [];
  listOfGroupsDds: CommonValueWithID[] = ListOfGroupDDS;
  finItemsMain: TreeNode[] = [];
  isReceiptActive = false;
  activeReceiptID = 0;
  activeOrderID = 0;
  text = ''
  subtext = ''

  @ViewChild('item', { read: TemplateRef }) modalItem: TemplateRef<any>;

  isHideRowByControl(controlName: string): boolean {
    return this.itemsForm.controls[controlName]?.getRawValue() as boolean;
  }

  get btnDisabled(): boolean {
    return (
      this.itemsForm.invalid ||
      !(
        this.itemsForm.controls['d_l'].value ||
        this.itemsForm.controls['p_l'].value
      )
    );
  }

  constructor() {
    super();
  }

  ngOnInit() {
    this._primengConfig.ripple = true;
    this.loadItemGroupPNLReceipt();
    this.loadItemGroupPNLSpisanie();
    this.loadItems();

    this.itemsForm.controls['d_l']?.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.handleControlChanges(this.itemsForm.controls['activity'], value);
      });

    this.itemsForm.controls['p_l']?.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.handleControlChanges(this.itemsForm.controls['pnl_id'], value);
      });
    this._mpSurfService
      .loadMetaData(this.SHOP_ID, this.TOKEN)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        //@ts-ignore
        const { die_text, die_title } = res.data.tutorialVideoLink.find(item => item.pageTitle === 'fin/settings/items') || { die_text: null, die_title: null }
        this.text = die_title
        this.subtext = die_text
      })
  }

  handleControlChanges(control: FormControl, value: boolean) {
    if (value) {
      control.setValidators([Validators.required]);
    } else {
      control.clearValidators();
    }
    control.updateValueAndValidity();
  }

  checkIsNodeWithChildren(node: TreeNode<TreeNodeFinItems>): boolean {
    return node.hasOwnProperty('children');
  }

  getNameForNode(node: TreeNodeFinItems): string {
    if (node.d_l === 1 && node.p_l === 0) {
      return '[ДДС]';
    } else if (node.d_l === 1 && node.p_l === 1) {
      return '[ДДС/ОПиУ]';
    } else if (node.d_l === 0 && node.p_l === 1) {
      return '[ОПиУ]';
    }
  }

  openModal(content: TemplateRef<any>, itemCode: 'receipt' | 'order'): void {
    this.isReceiptActive = itemCode === 'receipt';
    this.isEditModal = false;
    this.itemsForm.reset();
    this.itemsForm.controls['p_l'].setValue(false);
    this.itemsForm.controls['d_l'].setValue(true);
    this.activeReceiptID = 0;
    this._modalService.open(content, { size: 'lg' });
  }

  modalEditItem(fileData: any): void {
    this.isLoading = true;
    this._mpSurfService
      .load({}, `fin-items/${fileData.key}`)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (data: IFinItem) => {
          this.itemsForm.patchValue({
            ...data,
            p_l: +data.p_l === 1,
            d_l: +data.d_l === 1,
          });

          this.isEditModal = true;
          if (+data.item_type === 1) {
            this.activeReceiptID = fileData.key;
            this.isReceiptActive = true;
          }
          if (+data.item_type === 2) {
            this.activeOrderID = fileData.key;
            this.isReceiptActive = false;
          }
          this._modalService.open(this.modalItem, { size: 'lg' });
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }

  createUpdateItem(activeItem: 'receipt' | 'order'): void {
    this.isLoading = true;

    ['p_l', 'd_l'].forEach(a => {
      const control = this.itemsForm.get(a) as FormControl;
      control.setValue(control.value ? 1 : 0);
    });

    let url = 'fin-items';
    const body = this.itemsForm.getRawValue();

    //RECEIPT - 1 | ORDER - 2
    activeItem === 'receipt' ? (body.item_type = 1) : (body.item_type = 2);

    if (this.isEditModal) {
      activeItem === 'receipt'
        ? (url += `/${this.activeReceiptID}`)
        : (url += `/${this.activeOrderID}`);
    }

    this._mpSurfService
      .createOrUpdate(body, url)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (response: IResponseCommon) => {
          if (+response.is_error === 0) {
            this._modalService.dismissAll();
            this.itemsForm.reset();
            this.showPopUpMessage(
              'success',
              PopUpMessages.createSuccessSummary,
              PopUpMessages.createSuccessMessage
            );
            this.loadItems();
          }
          if (+response.is_error === 1) {
            this.showPopUpMessage(
              'error',
              PopUpMessages.createFailedSummary,
              PopUpMessages.createFailedMessage
            );
          }
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.createFailedSummary,
            PopUpMessages.createFailedMessage
          );
        }
      );
  }

  deleteItem(fileData: any): void {
    this._confirmationService.confirm({
      message: 'Вы уверены, что хотите удалить статью?',
      header: 'Подтверждение удаления',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Да',
      rejectLabel: 'Нет',
      accept: () => {
        this.isLoading = true;
        this._mpSurfService
          .delete({}, `fin-items/${fileData.key}`)
          .pipe(
            untilDestroyed(this),
            finalize(() => {
              this.isLoading = false;
            })
          )
          .subscribe(
            (response: IResponseCommon) => {
              if (response.is_error === 0) {
                this.showPopUpMessage(
                  'success',
                  PopUpMessages.deleteSuccessSummary,
                  PopUpMessages.deleteSuccessMessage
                );
                this.loadItems();
              }
              if (response.is_error === 1) {
                this.showPopUpMessage(
                  'error',
                  PopUpMessages.deleteFailedSummary,
                  PopUpMessages.deleteFailedMessage
                );
              }
            },
            () => {
              this.showPopUpMessage(
                'error',
                PopUpMessages.deleteFailedSummary,
                PopUpMessages.deleteFailedMessage
              );
            }
          );
      },
      reject: () => {},
    });
  }

  private loadItems(): void {
    this.isLoading = true;
    this._mpSurfService
      .load({}, 'fin-items')
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(
        (response: TreeNode<TreeNodeFinItems>[]) => {
          this.finItemsMain = response.slice();
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }

  private loadItemGroupPNLReceipt(): void {
    this.isLoading = true;
    this._mpSurfService
      .load({}, 'fin-items/pnl-receipt')
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(
        (response: CommonValueWithID[]) => {
          this.listOfGroupsPnlReceipt = response;
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }

  private loadItemGroupPNLSpisanie(): void {
    this.isLoading = true;
    this._mpSurfService
      .load({}, 'fin-items/pnl-spisanie')
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(
        (response: CommonValueWithID[]) => {
          this.listOfGroupsPnlSpisanie = response;
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }

  // private adjustTreeForRequest(): TreeNode<any>[] {
  //     return this.finItemsMain.map((item) => {
  //         const clonedItem: TreeNode = {
  //             key: item.key,
  //             label: item.label,
  //             data: item.data,
  //             icon: item.icon,
  //             expanded: item.expanded,
  //             children:
  //                 item.children?.map((child) => ({
  //                     key: child.key,
  //                     label: child.label,
  //                     data: child.data,
  //                     icon: child.icon,
  //                 })) || [],
  //             parent: item.parent,
  //         };
  //         return clonedItem;
  //     });
  // }

  // onNodeDropped(event: any): void {
  //     const draggedNode = event.dragNode;
  //     const droppedNode = event.dropNode;
  //     if (
  //         draggedNode.parent &&
  //         droppedNode.parent &&
  //         draggedNode.parent.label === droppedNode.parent.label &&
  //         !droppedNode?.children
  //     ) {
  //         this.updateFinItemsOrderByPositionEvent();
  //         return;
  //     } else if (
  //         typeof droppedNode.parent !== 'undefined' ||
  //         (typeof draggedNode.parent === 'undefined' && draggedNode?.children.length > 0)
  //     ) {
  //         this.showPopUpMessage('error', 'Позиция не изменена', '');
  //         this.loadItems();
  //         return;
  //     } else {
  //         this.showPopUpMessage('success', 'Позиция изменена', '');
  //         this.updateFinItemsOrderByPositionEvent();
  //         return;
  //     }
  // }

  // private updateFinItemsOrderByPositionEvent(): void {
  //     this.isLoading = true;
  //     const clonedItems = this.adjustTreeForRequest() as TreeNode[];
  //     this._mpSurfService
  //         .createOrUpdate(clonedItems, 'fin-items/update-posisiton')
  //         .pipe(untilDestroyed(this), finalize(() => (this.isLoading = false)))
  //         .subscribe(
  //             () => {
  //                 this.showPopUpMessage('success', 'Cтатья перемещена', '');
  //             },
  //             () => {
  //                 this.showPopUpMessage(
  //                     'error',
  //                     'Ошибка перемещения',
  //                     'Не удалось переместить статью. Проверьте правильность и повторите попытку',
  //                 );
  //             },
  //         );
  // }
}
