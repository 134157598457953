<app-page-header-notification-bar
  *ngIf="text || subtext"
  [headerTitle]="text"
  [barText]='subtext'
/>
<div *ngIf="isDisplayGoogleTableInfo" class="col-sm-6 col-md-6">
  <div class="mb-3 card text-white card-body bg-warning">
    <h5 class="card-title">
      Обращаем внимание, веб сервис и гугл таблица - разные продукты от MP Surf
    </h5>
    <div class="col-sm-4 col-md-4">
      <button
        (click)="closeGoogleTableMsg(1)"
        class="btn btn-primary mt-2 mr-2">
        Все понятно
      </button>
    </div>
  </div>
  <br />
</div>
<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="block-order-ftype">
        <div class="m-0 page-title-icon">
          <i class="pe-7s-wallet icon-gradient bg-mean-fruit"></i>
        </div>
        <div>
          Мои продажи
          <i
            class="fa fa-question-circle"
            aria-hidden="true"
            pTooltip="Информация на листе основана на оперативных данных и является предварительной.">
          </i>
        </div>
        <div class="btn-actions-pane-left flex">

          <app-mp-video-play
            [videoLink]="getVideoLinkByPage('dashboard/sales')" />
          <app-mp-ngb-range-datepicker
            [rangeType]="ranges"
            [initialData]="initialDate"
            (selectedDateEmitter)="dateChanged($event)" />
        </div>
        <div class="btn-actions-pane-left">
          <button
            *ngIf="visibleBtn === 1"
            (click)="switchCancelAllStatus(1)"
            class="btn-action-elem mr-2 btn btn-danger">
            Показать только возвраты
          </button>
          <button
            *ngIf="visibleBtn === 0"
            (click)="switchCancelAllStatus(0)"
            class="btn-action-elem mr-2 btn btn-success">
            Показать все
          </button>
        </div>
      </div>
      <div *ngIf="!isOzon" class="d-flex align-items-center gap-3 filterBlock">
        <p-multiSelect
          (onChange)="selectedParameters()"
          (onClear)="onClearParameters('article')"
          [(ngModel)]="selectedNm"
          [maxSelectedLabels]="1"
          [options]="nmidList"
          [showClear]="true"
          [style]="{ display: 'flex', alignItems: 'center', height: '32px' }"
          defaultLabel="Товар"
          emptyMessage="Товаров не найдено"
          itemSize="30"
          optionLabel="name"
          selectedItemsLabel="Выбрано {0}">
        </p-multiSelect>
        <p-multiSelect
          (onChange)="selectedParameters()"
          (onClear)="onClearParameters('warehouse')"
          [(ngModel)]="selectedWH"
          [maxSelectedLabels]="1"
          [options]="wareHousesList"
          [showClear]="true"
          [style]="{ display: 'flex', alignItems: 'center', height: '32px' }"
          defaultLabel="Склад"
          emptyMessage="Складов не найдено"
          itemSize="30"
          optionLabel="name"
          selectedItemsLabel="Выбрано {0}">
        </p-multiSelect>
      </div>
      <div *ngIf="!isOzon" class="d-flex align-items-center gap-3 filterBlock">
        <p-multiSelect
          (onChange)="selectedParameters()"
          (onClear)="onClearParameters('category')"
          [(ngModel)]="selectedCategory"
          [maxSelectedLabels]="1"
          [options]="categoryList"
          [showClear]="true"
          [style]="{ display: 'flex', alignItems: 'center', height: '32px' }"
          defaultLabel="Категория"
          emptyMessage="Категорий не найдено"
          itemSize="30"
          optionLabel="name"
          selectedItemsLabel="Выбрано {0}">
        </p-multiSelect>
        <p-multiSelect
          (onChange)="selectedParameters()"
          (onClear)="onClearParameters('subject')"
          [(ngModel)]="selectedSubject"
          [maxSelectedLabels]="1"
          [options]="subjectList"
          [showClear]="true"
          [style]="{ display: 'flex', alignItems: 'center', height: '32px' }"
          defaultLabel="Предмет"
          emptyMessage="Предметов не найдено"
          itemSize="30"
          optionLabel="name"
          selectedItemsLabel="Выбрано {0}">
        </p-multiSelect>
      </div>
    </div>
  </div>
</div>
<div class="main-card mb-3 card">
  <div *ngIf="isLoading" class="font-icon-wrapper float-left mr-12 mb-12">
    <div class="col-md-12 col-lg-12 col-xl-12">
      <div class="main-card mb-3 card">
        <div
          class="loader-wrapper d-flex justify-content-center align-items-center">
          <div class="loader">
            <div class="ball-pulse">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="!isLoading">
    <div class="card-header">
      <span class="me-2">
        {{
          visibleBtn === 0
            ? 'Возвраты (' + salesReturnQTY
            : 'Продаж (' + salesCount
        }}
        шт)
      </span>
      <span>
        среднее количество в день:
        {{ visibleBtn === 0 ? salesReturnQTYAverage : salesItemsAverageByDay }}
      </span>
    </div>
    <div class="row">
      <div class="col-sm-10 col-md-10">
        <div class="card-body">
          <div id="chart">
            <apx-chart
              *ngIf="salesChartData"
              [chart]="salesChartData?.chart"
              [dataLabels]="salesChartData?.dataLabels"
              [series]="
                visibleBtn === 1
                  ? salesChartData?.series
                  : [salesChartData?.series[1]]
              "
              [stroke]="salesChartData?.stroke"
              [xaxis]="salesChartData?.xaxis"
              [yaxis]="
                visibleBtn === 1 ? salesChartData?.yaxis : YAXIS_RETURNS
              " />
          </div>
        </div>
      </div>
      <div class="col-sm-2 col-md-2">
        <div class="col-sm-12 col-md-12">
          <div
            class="card-shadow-primary mb-3 widget-chart widget-chart2 text-left card">
            <div class="widget-chat-wrapper-outer">
              <div class="widget-chart-content">
                <h6 class="widget-subheading block-chart-title-amounts">
                  Продажи
                </h6>
                <div class="widget-chart-flex">
                  <div class="widget-numbers mb-0 w-100">
                    <div class="widget-chart-flex">
                      <div class="priceAmount">
                        <small class="opacity-5">₽</small>
                        {{ salesMoneyTotal | number: '1.0-0' }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12">
          <div
            class="card-shadow-primary mb-3 widget-chart widget-chart2 text-left card">
            <div class="widget-chat-wrapper-outer">
              <div class="widget-chart-content">
                <h6 class="widget-subheading block-chart-title-amounts">
                  Продажи
                </h6>
                <div class="widget-chart-flex">
                  <div class="widget-numbers mb-0 w-100">
                    <div class="widget-chart-flex">
                      <div class="priceAmount">
                        <small class="opacity-5">ШТ</small>
                        {{ salesQtyTotal | number: '1.0-0' }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12">
          <div
            class="card-shadow-primary mb-3 widget-chart widget-chart2 text-left card">
            <div class="widget-chat-wrapper-outer">
              <div class="widget-chart-content">
                <h6 class="widget-subheading block-chart-title-amounts">
                  Возвраты
                </h6>
                <div class="widget-chart-flex">
                  <div class="widget-numbers mb-0 w-100">
                    <div class="widget-chart-flex">
                      <div class="priceAmount">
                        <small class="opacity-5">₽</small>
                        {{ salesReturnMoneyTotal | number: '1.0-0' }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12">
          <div
            class="card-shadow-primary mb-3 widget-chart widget-chart2 text-left card">
            <div class="widget-chat-wrapper-outer">
              <div class="widget-chart-content">
                <h6 class="widget-subheading block-chart-title-amounts">
                  Возвраты
                </h6>
                <div class="widget-chart-flex">
                  <div class="widget-numbers mb-0 w-100">
                    <div class="widget-chart-flex">
                      <div class="priceAmount">
                        <small class="opacity-5">ШТ</small>
                        {{ salesReturnQTY | number: '1.0-0' }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div *ngIf="isLoading" class="font-icon-wrapper float-left mr-12 mb-12">
    <div class="col-md-12 col-lg-12 col-xl-12">
      <div class="main-card mb-3 card">
        <div
          class="loader-wrapper d-flex justify-content-center align-items-center">
          <div class="loader">
            <div class="ball-pulse">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!isLoading" class="table-responsive">
    <table
      class="align-middle mb-0 table table-borderless table-striped table-hover">
      <thead>
        <tr>
          <th class="text-center">Фото</th>
          <th class="text-center">Название</th>
          <th class="text-center">Артикул</th>
          <th class="text-center"> {{isOzon ? 'Дата заказа' : 'Дата продажи'}}</th>
          <th class="text-center">Дата возврата</th>
          <th class="text-center">Цена продажи</th>
          <th class="text-center">Суммы</th>
          <th class="text-center">Склад</th>
          <th class="text-center">Регион доставки</th>
          <th class="text-center">№ Продажи</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let item of salesTableData; let i = index">
          <tr *ngIf="item.return_record == 1" style="background-color: #ffcccc">
            <td *ngIf="!item.photo_new" class="text-center text-muted">
              <img alt="good-photo" width="40" />{{ item.photo_new }}
            </td>
            <td
              *ngIf="item.photo_new?.length > 10"
              class="text-center text-muted">
              <img alt="good-photo" src="{{ item.photo_new }}" width="40" />
            </td>
            <td>
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left flex2">
                    <div class="widget-subheading opacity-7">
                      <b>Предмет: {{ item.subject }}</b>
                    </div>
                    <div *ngIf="!isOzon" class="widget-subheading opacity-7">
                      Размер: {{ item.techSize }}
                    </div>
                    <div class="widget-subheading opacity-7">
                      Категория: {{ item.category }}
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left flex2">
                    <div class="widget-heading">SKU: {{ item.nmId }}</div>
                    <div *ngIf="!isOzon" class="widget-subheading opacity-7">
                      Баркод: {{ item.barcode }}
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td class="text-center">
              {{ item.sale_date | date: 'dd.MM.yyyy HH:mm' }}
            </td>
            <td class="text-center">
              {{ item.return_date | date: 'dd.MM.yyyy HH:mm' }}
            </td>
            <td class="text-center">
              {{ item.priceWithDisc | number: '1.0-0' }}
            </td>
            <td>
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left flex2">
                    <div class="widget-heading">
                      Закупка: {{ item.cost_price }}
                    </div>
                    <div class="widget-subheading opacity-7">
                      Поставщику: {{ item.forPay | mpThousandSeparator }}
                    </div>
                    <div class="widget-subheading opacity-7">
                      Заплатил клиент:
                      {{ item.finishedPrice | mpThousandSeparator }}
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td class="text-center">{{ item.warehouseName }}</td>
            <td class="text-center">{{ item.regionName }}</td>
            <td class="text-left">
              № {{ item.saleID }}<br />№ {{ item.odid }}
            </td>
          </tr>

          <tr *ngIf="item.return_record == 0">
            <td *ngIf="!item.photo_new" class="text-center text-muted">
              <img alt="good-photo" width="40" />{{ item.photo_new }}
            </td>
            <td
              *ngIf="item.photo_new?.length > 10"
              class="text-center text-muted">
              <img alt="good-photo" src="{{ item.photo_new }}" width="40" />
            </td>
            <td>
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left flex2">
                    <div class="widget-subheading opacity-7">
                      <b>Предмет: {{ item.subject }}</b>
                    </div>
                    <div *ngIf="!isOzon" class="widget-subheading opacity-7">
                      Размер: {{ item.techSize }}
                    </div>
                    <div class="widget-subheading opacity-7">
                      Категория: {{ item.category }}
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left flex2">
                    <div class="widget-heading">SKU: {{ item.nmId }}</div>
                    <div *ngIf="!isOzon" class="widget-subheading opacity-7">
                      Баркод: {{ item.barcode }}
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td class="text-center">
              {{ item.sale_date | date: 'dd.MM.yyyy HH:mm' }}
            </td>
            <td class="text-center">
              {{ item.return_date | date: 'dd.MM.yyyy HH:mm' }}
            </td>
            <td class="text-center">
              {{ item.priceWithDisc | number: '1.0-0' }}
            </td>
            <td style="width: 13%">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left flex2">
                    <div class="widget-heading">
                      Закупка: {{ item.cost_price }}
                    </div>
                    <div class="widget-subheading opacity-7">
                      Поставщику: {{ item.forPay | mpThousandSeparator }}
                    </div>
                    <div *ngIf="!isOzon" class="widget-subheading opacity-7">
                      Заплатил клиент:
                      {{ item.finishedPrice | mpThousandSeparator }}
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td class="text-center">{{ item.warehouseName }}</td>
            <td class="text-center">{{ item.regionName }}</td>
            <td class="text-left">
              № {{ item.saleID }}<br />№ {{ item.odid }}
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div class="d-block text-center card-footer">
    <div class="card-body">
      <ngb-pagination
        (pageChange)="selectedParameters()"
        [(page)]="paginationPage"
        [boundaryLinks]="true"
        [collectionSize]="salesCount"
        [maxSize]="10"
        [pageSize]="100"
        [rotate]="true" />
    </div>
  </div>
</div>
<p-toast />
