<app-page-header-notification-bar
  *ngIf="text || subtext"
  [headerTitle]="text"
  [barText]="subtext" />
<section class="main-test__wrapper">
  <span class="topmost-btn-group app-page-title">
    <div class="icon">
      <i class="pe-7s-graph1 icon-gradient bg-mean-fruit"></i>
    </div>
    <div>
      <div>
        Общие показатели
        <i
          class="fa fa-question-circle"
          aria-hidden="true"
          [pTooltip]="
            'Строится на основе реализации и оперативных данных. Отчет по реализации доступен до ' +
            lastReportDate
          ">
        </i>
      </div>
      <span
        routerLink="../../guides/products"
        style="
          color: #5046e5;
          font-size: 0.9rem;
          cursor: pointer;
          font-style: italic;
        "
        >Добавить себестоимость</span
      >
    </div>
    <app-mp-video-play [videoLink]="getVideoLinkByPage('dashboard/rates')" />
    <app-mp-ngb-range-datepicker
      [initialData]="selectedDate"
      [rangeType]="presets"
      (selectedDateEmitter)="onDateChange($event)" />
  </span>
  <div class="d-flex main-test__cards">
    <app-mp-card-info
      *ngFor="let card of cards"
      [card]="card"
    >
    </app-mp-card-info>
  </div>
  <div *ngIf="chartOptions" class="main-test__graph">
    <div id="chart">
      <apx-chart
        [series]="chartOptions.series"
        [chart]="chartOptions.chart"
        [yaxis]="chartOptions.yaxis"
        [xaxis]="chartOptions.xaxis"
        [stroke]="chartOptions.stroke"
        [dataLabels]="chartOptions.dataLabels" />
    </div>
  </div>
  <div class="main-test__table">
    <div class="btn-group" role="group">
      <button
        (click)="groupTable(tableGroupingEnum.BY_DATE)"
        type="button"
        class="btn"
        [class.btn-danger]="tableGroupingType === tableGroupingEnum.BY_DATE">
        По дате
      </button>
      <button
        (click)="groupTable(tableGroupingEnum.BY_PRODUCT)"
        type="button"
        class="btn"
        [class.btn-danger]="tableGroupingType === tableGroupingEnum.BY_PRODUCT">
        По товарам
      </button>
    </div>
    <ag-grid-angular
      (gridReady)="onGridReady($event)"
      [columnDefs]="
        tableGroupingType === tableGroupingEnum.BY_DATE
          ? columnDefsByDate
          : columnDefsProduct
      "
      [defaultColDef]="defaultDef"
      [rowData]="
        tableGroupingType === tableGroupingEnum.BY_DATE
          ? tableDataByDate
          : tableDataByProduct
      "
      [pinnedBottomRowData]="
        tableGroupingType === tableGroupingEnum.BY_DATE
          ? footerDataForDate
          : footerDataForProduct
      "
      [pagination]="true"
      [paginationPageSize]="10"
      [domLayout]="'autoHeight'"
      class="ag-theme-alpine"
      style="width: 100%;"
      overlayNoRowsTemplate="Нет данных для отображения" 
    />
    </div>
  <!-- <div *ngIf="dataForSalesStructureChart" class="main-test__charts">
    <section class="main-test__charts_histogram">
      <div class="main-test__card-header">Количество продаж по областям</div>
      <apx-chart
        [series]="dataForSalesStructureChart.series"
        [plotOptions]="plotOptions"
        [chart]="{ height: '400', type: 'bar' }"
        [xaxis]="dataForSalesStructureChart.xaxis"
        [dataLabels]="dataForSalesStructureChart.dataLabels"
        [colors]="['#BCE9DE']"
      >
      </apx-chart>
    </section>
    <section class="main-test__charts_donuts">
      <div>
        <div class="main-test__card-header">Количество продаж по брендам</div>
        <div class="card-body">
          <apx-chart
            [series]="seriesChartSaleStructureByBrand"
            [labels]="rowSalesSubjectChartTitlesByBrand"
            [chart]="{
              height: '300',
              type: 'donut',
              width: '300'
            }"
            [colors]="['#ace6bd', '#a7c6eb']"
            [dataLabels]="{
              style:{
                colors: ['#000000'],
                fontWeight: 100
              }
            }"
            [legend]="{
              position: 'bottom'
            }"
          >
          </apx-chart>
        </div>
      </div>
      <div>
        <div class="main-test__card-header">Количество продаж по предметам</div>
        <div class="card-body">
          <apx-chart
            [series]="seriesChartSaleStructureBySubjects"
            [labels]="rowSalesSubjectChartTitlesBySubjects"
            [chart]="{
              height: '300',
              type: 'donut',
              width: '300'
            }"
            [colors]="['#ace6bd', '#a7c6eb', '#eba7a7', '#ebd7a7']"
            [dataLabels]="{
              style:{
                colors: ['#000000'],
                fontWeight: 100
              }
            }"
            [legend]="{
              position: 'bottom'
            }"
          >
          </apx-chart>
        </div>
      </div>
    </section>
  </div> -->
</section>

<ng-template #underConstructionTpl>
  <section>В разработке</section>
</ng-template>
