<div class="wrapper">
  <div class="app-page-title">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div class="page-title-icon">
          <i class="lnr-cart icon-gradient bg-mean-fruit"></i>
        </div>
        <div>ПЛАН-ФАКТ &nbsp;&nbsp;</div>
        <div class="btn-actions-pane-left">
          <input
            [disabled]="date_dis"
            type="text"
            ngxDaterangepickerMd
            [locale]="{
              applyLabel: 'применить',
              direction: 'ltr',
              format: 'DD.MM.YYYY',
              daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
              customRangeLabel: 'Свой диапазон',
              firstDay: 1,
              monthNames: [
                'Январь',
                'Февраль',
                'Март',
                'Апрель',
                'Май',
                'Июнь',
                'Июль',
                'Август',
                'Сентябрь',
                'Октябрь',
                'Ноябрь',
                'Декабрь',
              ],
            }"
            [(ngModel)]="selected"
            [showCustomRangeLabel]="true"
            [alwaysShowCalendars]="true"
            [ranges]="ranges"
            [linkedCalendars]="true"
            [isInvalidDate]="isInvalidDate"
            [showClearButton]="false"
            [maxDate]="maxDate"
            [minDate]="minDate"
            (change)="newValue()"
            placeholder="Выберите даты" />
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <div class="btn-actions-pane-left">
          <button
            *ngIf="visible_btn == 1"
            (click)="view_only_cancel(1)"
            class="mr-2 btn btn-danger">
            Исключить самовыкупы из результата
          </button>
          <button
            *ngIf="visible_btn == 0"
            (click)="view_only_cancel(0)"
            class="mr-2 btn btn-success">
            Показать все
          </button>
        </div>
        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
      </div>
      <div></div>
    </div>
  </div>

  <div class="row">
    <div class="col-4" *ngFor="let item of dataFinance; let i = index">
      <div class="card-shadow-primary profile-responsive card-border mb-3 card">
        <div class="dropdown-menu-header">
          <div class="dropdown-menu-header-inner bg-info">
            <div class="menu-header-content btn-pane-right">
              <div style="margin: 0 auto">
                <h5 class="menu-header-title" style="text-align: center">
                  {{ titleDataFinance[i] }}
                </h5>
                <hr style="width: 360px" />
                &nbsp;&nbsp;
                <div class="row justify-content-between">
                  <div class="col" style="text-align: center">ЗАКАЗЫ</div>
                  &nbsp;&nbsp;
                  <div class="col" style="text-align: center">ПРОДАЖИ</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-content">
          <div class="tab-pane active show" id="tab-example-161">
            <ul class="list-group list-group-flush">
              <li class="p-0 list-group-item">
                <div class="grid-menu grid-menu-2col overflow-hidden">
                  <div class="g-0 row">
                    <ul
                      ngbNav
                      #nav="ngbNav"
                      class="nav-tabs tabs-animated tabs-animated-shadow">
                      <li ngbNavItem>
                        <ng-template ngbNavContent>
                          <div class="graphics-block">
                            <div class="row">
                              <div class="col">
                                <div class="card main-card mb-3">
                                  <div class="card-body">
                                    <mwl-gauge
                                      class="three"
                                      [max]="100"
                                      [value]="item.diagram"
                                      [animated]="true"
                                      [animationDuration]="1.5">
                                    </mwl-gauge>
                                  </div>
                                </div>
                              </div>

                              <div class="col">
                                <div class="card main-card mb-3">
                                  <div class="card-body">
                                    <mwl-gauge
                                      class="three"
                                      [max]="100"
                                      [value]="item.diagram2"
                                      [animated]="true"
                                      [animationDuration]="1.5">
                                    </mwl-gauge>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-template>
                      </li>
                    </ul>
                    <div [ngbNavOutlet]="nav"></div>
                  </div>
                </div>
              </li>
              <li class="list-group-item">
                <div class="widget-content">
                  <div class="text-center">
                    <div class="row">
                      <div class="col">
                        <p>план, руб: {{ item.plan }}</p>
                        <p>факт, руб: {{ item.fact }} ✖</p>
                        <p>%: {{ item.percent }}</p>
                      </div>

                      <div class="col">
                        <p>план, руб: {{ item.plan2 }}</p>
                        <p>факт, руб: {{ item.fact2 }} ✖</p>
                        <p>%: {{ item.percent2 }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="main-card mb-3 card">
    <div>
      <h3 style="padding: 7px; font-weight: 400">Месяц по апи реализации</h3>
    </div>
    <div style="width: 100%; height: 75vh">
      <ag-grid-angular
        style="width: 100%; height: 100%; text-align: center"
        class="ag-theme-alpine"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="rowData"
        (gridReady)="onGridReady($event)"
        (cellValueChanged)="onCellValueChanged($event)"
        (rowSelected)="onRowSelected($event)"
        (selectionChanged)="onSelectionChanged($event)"
        overlayNoRowsTemplate="Нет данных для отображения"></ag-grid-angular>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <div class="card main-card mb-3">
        <div class="card-header">
          <div>
            <p>Заказы и продажи в РУБЛЯХ за 14 дней</p>
          </div>
        </div>
        <div class="card-body">
          <apx-chart
            [series]="dataChart.left?.data"
            [xaxis]="dataChart.left?.data_categories"
            [chart]="{
              height: '350',
              type: 'area',
            }"></apx-chart>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="card main-card mb-3">
        <div class="card-header">
          <div>
            <p>Заказы и продажи в РУБЛЯХ за 14 дней</p>
          </div>
        </div>
        <div class="card-body">
          <apx-chart
            [series]="dataChart.right?.data"
            [xaxis]="dataChart.right?.data_categories"
            [chart]="{
              height: '350',
              type: 'area',
            }"></apx-chart>
        </div>
      </div>
    </div>
  </div>
</div>
