<form [formGroup]="form">
  <div [class]="'app-header header-shadow ' + (config$ | async).headerTheme">
    <div *ngIf="!checkWidthLogo()" routerLink="dashboard/rates" class="logo-src"></div>
    <div class="app-header__content">
      <div class="app-header-left">
        <app-mega-menu />
      </div>
      <div class="app-header-right">
        <div *ngIf="allSuppliers && !checkWidth()" class="wrapper-dropdown">
          <p-dropdown
            (onChange)="onSelectedChange()"
            [options]="allSuppliers"
            [showClear]="false"
            [style]="{ display: 'flex', alignItems: 'center', height: '37px' }"
            emptyMessage="Нет добавленных поставщиков"
            formControlName="activeSupplier"
            placeholder="Поставщик"
            styleClass="inner-dropdown">
            <ng-template let-supplier pTemplate="item">
              <div class="flex items-center" [pTooltip]="supplier.label">
                <img
                  [src]="
                    supplier.value?.marketplace === 2
                      ? 'assets/images/ozon-logo.webp'
                      : 'assets/images/wb-logo.jpg'
                  "
                  alt="Supplier Image"
                  style="
                    width: 25px;
                    margin-right: 13px;
                    border-radius: 10px;
                  " />
                <span class="threeDots">
                  {{ supplier.label }}
                </span>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
        <img
          [src]="
            supplierMarket === 2
              ? 'assets/images/ozon-logo.webp'
              : 'assets/images/wb-logo.jpg'
          "
          class="marketIcon"
          alt="logo" />
        <app-notification-dots></app-notification-dots>
        <app-user-box></app-user-box>
      </div>
    </div>

    <div class="app-header__mobile-menu">
      <button
        (click)="toggleSidebarMobile()"
        [ngClass]="{ 'is-active': globals.toggleSidebarMobile }"
        style="margin-top: 6px;"
        class="hamburger close-sidebar-btn hamburger--elastic"
        type="button">
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>

    <div class="app-header__menu">
      <div class="wrapper-dropdown">
        <p-dropdown
          (onChange)="onSelectedChange()"
          *ngIf="checkWidth()"
          [options]="allSuppliers"
          [showClear]="false"
          [style]="{ display: 'flex', alignItems: 'center', height: '37px' }"
          emptyMessage="Нет добавленных поставщиков"
          formControlName="activeSupplier"
          placeholder="Поставщик"
          styleClass="inner-dropdown" />
      </div>
      <span>
        <button
          (click)="toggleHeaderMobile()"
          [ngClass]="{ 'is-active': globals.toggleHeaderMobile }"
          class="btn-icon btn-icon-only btn btn-primary">
          <div class="btn-icon-wrapper">
            <fa-icon [icon]="faEllipsisV" />
          </div>
        </button>
      </span>
    </div>
  </div>
</form>
