import { DecimalPipe } from "@angular/common";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { UnitPipe } from "../../../shared/pipes/unit.pipe";
import { NumberSortFunc } from "app/shared/utils/functions/number-sort-func";

const decimalPipe = new DecimalPipe('ru-RU');
const unitPipe = new UnitPipe();

export const SALES_TABLE_COLUMN_DEFS: (ColDef | ColGroupDef)[] = [
  {
    field: 'photo',
    headerName: '',
    width: 60,
    cellStyle: {paddingLeft: '0px', paddingRight: '0px'},
    pinned: 'left',
    cellRenderer: (params) => {
      if(params.data.photo === 'Итого'){
        return 'Итого'
      }
      if(!params.data.photo){
        return `<img style="height: 30px; width: 30px" src="/assets/images/nophoto.jpg" />`
      }
      return `<img style="height: 30px; width: 30px" src=${params.data.photo} />`
    }
  },
  {
    field: 'nmID',
    headerName: 'Артикул МП',
    filter: 'agTextrColumnFilter',
    cellRenderer: (params) => {
      if(params.data.nmID){
        return `<strong><a href="https://www.wildberries.ru/catalog/${params.data.nmID}/detail.aspx?targetUrl=SP" target="blank">${params.data.nmID}</a></strong>`
      }
    }
  },
  {
    field: 'subject',
    headerName: 'Предмет',
    filter: 'agTextrColumnFilter',
  },
  {
    field: 'barcode',
    headerName: 'Баркод',
    filter: 'agTextrColumnFilter',
  },
  {
    field: 'supplierArticle',
    headerName: 'Артикул поставщика',
    filter: 'agTextrColumnFilter',
  },
  {
    field: 'sale_date',
    headerName: 'Дата продажи',
  },
  {
    field: 'return_date',
    headerName: 'Дата возврата',
  },
  {
    field: 'seller_sum',
    headerName: 'Сумма в ценах поставщика',
    width: 120,
    sortable: true,
    filter: 'agNumberColumnFilter',
    valueFormatter: v => unitPipe.transform(decimalPipe.transform(v.value, '1.0-1'), 'rubles'),
    comparator: NumberSortFunc
  },
  {
    field: 'mp_sum',
    headerName: 'Сумма в ценах МП',
    width: 120,
    sortable: true,
    filter: 'agNumberColumnFilter',
    valueFormatter: v => unitPipe.transform(decimalPipe.transform(v.value, '1.0-1'), 'rubles'),
    comparator: NumberSortFunc
  },
  {
    field: 'spp',
    headerName: 'СПП - только у ВБ',
    width: 120,
    sortable: true,
    filter: 'agNumberColumnFilter',
    valueFormatter: v => unitPipe.transform(decimalPipe.transform(v.value, '1.0-1'), 'percent'),
  },
  {
    field: 'forPay',
    headerName: 'К перечислению - только у ВБ',
    width: 120,
    sortable: true,
    filter: 'agNumberColumnFilter',
    valueFormatter: v => unitPipe.transform(decimalPipe.transform(v.value, '1.0-1'), 'rubles'),
    comparator: NumberSortFunc
  },
  {
    field: 'warehouseName',
    headerName: 'Склад',
    width: 120,
    sortable: true,
    filter: 'agTextrColumnFilter',
  },
  {
    field: 'regionName',
    headerName: 'Регион доставки',
    width: 120,
    sortable: true,
    filter: 'agTextrColumnFilter',
  },
  {
    field: 'delivery_type',
    headerName: 'Способ доставки',
    width: 120,
    sortable: true,
    filter: 'agTextrColumnFilter',
  },
  {
    field: 'orderType',
    headerName: 'Тип операции - только у ВБ',
    width: 120,
    sortable: true,
    filter: 'agTextrColumnFilter',
  },
  {
    field: 'srid',
    headerName: '№ операции',
    width: 120,
    sortable: true,
    filter: 'agTextrColumnFilter',
  },
  {
    field: 'self_buyouts',
    headerName: 'СМВ',
    width: 120,
    sortable: true,
    filter: 'agTextrColumnFilter',
  },
];

