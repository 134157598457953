<p-progressSpinner
  *ngIf="isLoading"
  [style]="{
    position: 'absolute',
    top: '40%',
    left: '50%',
  }"></p-progressSpinner>
<p-toast></p-toast>
<div [ngStyle]="{ display: isLoading ? 'none' : 'block' }">
  <div class="app-page-title">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div class="page-title-icon">
          <i class="lnr-cart icon-gradient bg-mean-fruit"></i>
        </div>
        <div class="mt-3 me-3">
          Сводка по товару {{ SKU_ID }} за
          <p class="page-title-subheading">
            <a [routerLink]="['/guides/products/']">к списку товаров</a>
          </p>
        </div>
        <div class="btn-actions-pane-left d-flex align-items-center gap-3">
          <input
            (change)="changeDate()"
            [(ngModel)]="selected"
            [alwaysShowCalendars]="true"
            [isInvalidDate]="DatePickerCommon.isInvalidDate"
            [linkedCalendars]="true"
            [locale]="DatePickerCommon.localParamsRU"
            [maxDate]="DatePickerCommon.maxDate"
            [minDate]="DatePickerCommon.minDate"
            [ranges]="DatePickerRanges"
            [showClearButton]="false"
            [showCustomRangeLabel]="true"
            ngxDaterangepickerMd
            placeholder="Выберите даты"
            type="text" />
          <p-selectButton
            (onChange)="switchOrdersType($event)"
            [style]="{ fontSize: '14px' }"
            [(ngModel)]="selectedOrdersType"
            [options]="ORDERS_OPTIONS"
            optionLabel="name">
          </p-selectButton>
          <button (click)="switchBuyouts()" class="mr-2 btn btn-danger">
            {{ buyoutId == 1 ? 'Убрать' : 'Показать' }} самовыкупы
          </button>
        </div>
      </div>
    </div>
  </div>
  <app-product-id-orders-chart
    (stopLoading)="stopLoading()"></app-product-id-orders-chart>
  <div class="card mb-3">
    <div class="card-header">
      <div>
        <h5 class="menu-header-title text-capitalize text-primary">
          Оперативные данные за сегодня/вчера
        </h5>
      </div>
    </div>
    <div class="g-0 row">
      <div class="col-md-12 col-lg-3">
        <ul class="list-group list-group-flush">
          <li class="bg-transparent list-group-item">
            <div class="widget-content p-0">
              <div class="widget-content-outer">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">Заказов сегодня</div>
                    <div class="widget-subheading">
                      последний: {{ nm_static?.orders?.today?.max_time }}
                    </div>
                  </div>
                  <div class="widget-content-right">
                    <div
                      [ngClass]="{
                        'widget-numbers text-danger':
                          (nm_static?.orders?.today?.qty * 100) /
                            nm_static?.orders?.yesterday?.qty <
                          50,
                        'widget-numbers text-warning':
                          (nm_static?.orders?.today?.qty * 100) /
                            nm_static?.orders?.yesterday?.qty >=
                            50 &&
                          (nm_static?.orders?.today?.qty * 100) /
                            nm_static?.orders?.yesterday?.qty <=
                            84,
                        'widget-numbers text-success':
                          (nm_static?.orders?.today?.qty * 100) /
                            nm_static?.orders?.yesterday?.qty >
                          85,
                      }"
                      style="font-size: 1rem">
                      {{ nm_static?.orders?.today?.qty }} шт ({{
                        nm_static?.orders?.today?.price | number: '1.2'
                      }}
                      ₽)
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>

          <li class="bg-transparent list-group-item">
            <div class="widget-content p-0">
              <div class="widget-content-outer">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">Заказов вчера</div>
                    <div class="widget-subheading">
                      последний: {{ nm_static?.orders?.yesterday?.max_time }}
                    </div>
                  </div>
                  <div class="widget-content-right">
                    <div
                      class="widget-numbers text-alt"
                      style="font-size: 1rem">
                      {{ nm_static?.orders?.yesterday?.qty }} шт ({{
                        nm_static?.orders?.yesterday?.price | number: '1.2'
                      }}
                      ₽)
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>

          <li class="bg-transparent list-group-item">
            <div class="widget-content p-0 w-100">
              <div class="widget-content-outer">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left pe-2 fsize-1">
                    <div
                      [ngClass]="{
                        'widget-numbers mt-0 fsize-3 text-danger':
                          (nm_static?.orders?.today?.qty * 100) /
                            nm_static?.orders?.yesterday?.qty <
                          50,
                        'widget-numbers mt-0 fsize-3 text-warning':
                          (nm_static?.orders?.today?.qty * 100) /
                            nm_static?.orders?.yesterday?.qty >=
                            50 &&
                          (nm_static?.orders?.today?.qty * 100) /
                            nm_static?.orders?.yesterday?.qty <=
                            84,
                        'widget-numbers mt-0 fsize-3 text-success':
                          (nm_static?.orders?.today?.qty * 100) /
                            nm_static?.orders?.yesterday?.qty >
                          85,
                      }">
                      {{
                        (nm_static?.orders?.today?.qty * 100) /
                          nm_static?.orders?.yesterday?.qty | number: '1.0'
                      }}
                      %
                    </div>
                  </div>
                  <div class="widget-content-right w-100">
                    <div class="progress-bar-xs progress">
                      <div
                        [ngClass]="{
                          'progress-bar bg-danger':
                            (nm_static?.orders?.today?.qty * 100) /
                              nm_static?.orders?.yesterday?.qty <
                            50,
                          'progress-bar bg-warning':
                            (nm_static?.orders?.today?.qty * 100) /
                              nm_static?.orders?.yesterday?.qty >=
                              50 &&
                            (nm_static?.orders?.today?.qty * 100) /
                              nm_static?.orders?.yesterday?.qty <=
                              84,
                          'progress-bar bg-success':
                            (nm_static?.orders?.today?.qty * 100) /
                              nm_static?.orders?.yesterday?.qty >
                            85,
                        }"
                        [style.width]="
                          (nm_static?.orders?.today?.qty * 100) /
                            nm_static?.orders?.yesterday?.qty +
                          '%'
                        "
                        aria-valuemax="nm_static?.orders?.yesterday?.qty"
                        aria-valuemin="0"
                        aria-valuenow="nm_static?.orders?.today?.qty"
                        role="progressbar"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="col-md-12 col-lg-3">
        <ul class="list-group list-group-flush">
          <li class="bg-transparent list-group-item">
            <div class="widget-content p-0">
              <div class="widget-content-outer">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">Отмен сегодня</div>
                    <div class="widget-subheading">
                      последний: {{ nm_static?.orders_cancel?.today?.max_time }}
                    </div>
                  </div>
                  <div class="widget-content-right">
                    <div
                      [ngClass]="{
                        'widget-numbers text-danger':
                          (nm_static?.orders_cancel?.today?.qty * 100) /
                            nm_static?.orders_cancel?.yesterday?.qty >
                          1,
                        'widget-numbers text-success':
                          (nm_static?.orders_cancel?.today?.qty * 100) /
                            nm_static?.orders_cancel?.yesterday?.qty <=
                          0,
                      }"
                      style="font-size: 1rem">
                      {{ nm_static?.orders_cancel?.today?.qty }} шт ({{
                        nm_static?.orders_cancel?.today?.price | number: '1.2'
                      }}
                      ₽)
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>

          <li class="bg-transparent list-group-item">
            <div class="widget-content p-0">
              <div class="widget-content-outer">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">Отмен вчера</div>
                    <div class="widget-subheading">
                      последний:
                      {{ nm_static?.orders_cancel?.yesterday?.max_time }}
                    </div>
                  </div>
                  <div class="widget-content-right">
                    <div
                      [ngClass]="{
                        'widget-numbers text-danger':
                          (nm_static?.orders_cancel?.yesterday?.qty * 100) /
                            nm_static?.orders_cancel?.yesterday?.qty >
                          1,
                        'widget-numbers text-success':
                          (nm_static?.orders_cancel?.today?.qty * 100) /
                            nm_static?.orders_cancel?.yesterday?.qty <=
                          0,
                      }"
                      style="font-size: 1rem">
                      {{ nm_static?.orders_cancel?.yesterday?.qty }} шт ({{
                        nm_static?.orders_cancel?.yesterday?.price
                          | number: '1.2'
                      }}
                      ₽)
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>

          <li class="bg-transparent list-group-item">
            <div class="widget-content p-0 w-100">
              <div class="widget-content-outer">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left pe-2 fsize-1">
                    <div
                      [ngClass]="{
                        'widget-numbers mt-0 fsize-3 text-success':
                          (nm_static?.orders_cancel?.today?.qty * 100) /
                            nm_static?.orders_cancel?.yesterday?.qty <
                          10,
                        'widget-numbers mt-0 fsize-3 text-danger':
                          (nm_static?.orders_cancel?.today?.qty * 100) /
                            nm_static?.orders_cancel?.yesterday?.qty >=
                          10,
                      }">
                      {{
                        (nm_static?.orders_cancel?.today?.qty * 100) /
                          nm_static?.orders?.yesterday?.qty | number: '1.0-1'
                      }}
                      %
                    </div>
                  </div>
                  <div class="widget-content-right w-100">
                    <div class="progress-bar-xs progress">
                      <div
                        [ngClass]="{
                          'progress-bar bg-success':
                            (nm_static?.orders_cancel?.today?.qty * 100) /
                              nm_static?.orders_cancel?.yesterday?.qty <
                            10,
                          'progress-bar bg-danger':
                            (nm_static?.orders_cancel?.today?.qty * 100) /
                              nm_static?.orders_cancel?.yesterday?.qty >=
                            10,
                        }"
                        [style.width]="
                          (nm_static?.orders_cancel?.today?.qty * 100) /
                            nm_static?.orders_cancel?.yesterday?.qty +
                          '%'
                        "
                        aria-valuemax="nm_static?.orders_cancel?.yesterday?.qty"
                        aria-valuemin="0"
                        aria-valuenow="nm_static?.orders_cancel?.today?.qty"
                        role="progressbar"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="col-md-12 col-lg-3">
        <ul class="list-group list-group-flush">
          <li class="bg-transparent list-group-item">
            <div class="widget-content p-0">
              <div class="widget-content-outer">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">Выкупов сегодня</div>
                    <div class="widget-subheading">
                      последний: {{ nm_static?.sales?.today?.max_time }}
                    </div>
                  </div>
                  <div class="widget-content-right">
                    <div
                      [ngClass]="{
                        'widget-numbers text-danger':
                          (nm_static?.sales?.today?.sales_qty * 100) /
                            nm_static?.sales?.yesterday?.sales_qty <
                          50,
                        'widget-numbers text-warning':
                          (nm_static?.sales?.today?.sales_qty * 100) /
                            nm_static?.sales?.yesterday?.sales_qty >=
                            50 &&
                          (nm_static?.sales?.today?.sales_qty * 100) /
                            nm_static?.sales?.yesterday?.sales_qty <=
                            84,
                        'widget-numbers text-success':
                          (nm_static?.sales?.today?.sales_qty * 100) /
                            nm_static?.sales?.yesterday?.sales_qty >
                          85,
                      }"
                      style="font-size: 1rem">
                      {{ nm_static?.sales?.today?.sales_qty }} шт ({{
                        nm_static?.sales?.today?.sales_price | number: '1.2'
                      }}
                      ₽)
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>

          <li class="bg-transparent list-group-item">
            <div class="widget-content p-0">
              <div class="widget-content-outer">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">Выкупов вчера</div>
                    <div class="widget-subheading">
                      последний: {{ nm_static?.sales?.yesterday?.max_time }}
                    </div>
                  </div>
                  <div class="widget-content-right">
                    <div
                      class="widget-numbers text-alt"
                      style="font-size: 1rem">
                      {{ nm_static?.sales?.yesterday?.sales_qty }} шт ({{
                        nm_static?.sales?.yesterday?.sales_price | number: '1.2'
                      }}
                      ₽)
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>

          <li class="bg-transparent list-group-item">
            <div class="widget-content p-0 w-100">
              <div class="widget-content-outer">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left pe-2 fsize-1">
                    <div
                      [ngClass]="{
                        'widget-numbers mt-0 fsize-3 text-danger':
                          (nm_static?.sales?.today?.sales_qty * 100) /
                            nm_static?.sales?.yesterday?.sales_qty <
                          50,
                        'widget-numbers mt-0 fsize-3 text-warning':
                          (nm_static?.sales?.today?.sales_qty * 100) /
                            nm_static?.sales?.yesterday?.sales_qty >=
                            50 &&
                          (nm_static?.sales?.today?.sales_qty * 100) /
                            nm_static?.sales?.yesterday?.sales_qty <=
                            84,
                        'widget-numbers mt-0 fsize-3 text-success':
                          (nm_static?.sales?.today?.sales_qty * 100) /
                            nm_static?.sales?.yesterday?.sales_qty >
                          85,
                      }">
                      {{
                        (nm_static?.sales?.today?.sales_qty * 100) /
                          nm_static?.sales?.yesterday?.sales_qty
                          | number: '1.0-1'
                      }}
                      %
                    </div>
                  </div>
                  <div class="widget-content-right w-100">
                    <div class="progress-bar-xs progress">
                      <div
                        [ngClass]="{
                          'progress-bar bg-danger':
                            (nm_static?.sales?.today?.sales_qty * 100) /
                              nm_static?.sales?.yesterday?.sales_qty <
                            50,
                          'progress-bar bg-warning':
                            (nm_static?.sales?.today?.sales_qty * 100) /
                              nm_static?.sales?.yesterday?.sales_qty >=
                              50 &&
                            (nm_static?.sales?.today?.sales_qty * 100) /
                              nm_static?.sales?.yesterday?.sales_qty <=
                              84,
                          'progress-bar bg-success':
                            (nm_static?.sales?.today?.sales_qty * 100) /
                              nm_static?.sales?.yesterday?.sales_qty >
                            85,
                        }"
                        [style.width]="
                          (nm_static?.sales?.today?.sales_qty * 100) /
                            nm_static?.sales?.yesterday?.sales_qty +
                          '%'
                        "
                        aria-valuemax="nm_static?.sales?.yesterday?.sales_qty"
                        aria-valuemin="0"
                        aria-valuenow="nm_static?.sales?.today?.sales_qty"
                        role="progressbar"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="col-md-12 col-lg-3">
        <ul class="list-group list-group-flush">
          <li class="bg-transparent list-group-item">
            <div class="widget-content p-0">
              <div class="widget-content-outer">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">Возвратов сегодня</div>
                    <div class="widget-subheading"><br /></div>
                  </div>
                  <div
                    *ngIf="nm_static?.sales?.today?.return_qty == null"
                    class="widget-content-right">
                    <div
                      class="widget-numbers text-success"
                      style="font-size: 1rem">
                      0 шт (0 ₽)
                    </div>
                  </div>
                  <div
                    *ngIf="nm_static?.sales?.today?.return_qty != null"
                    class="widget-content-right">
                    <div
                      [ngClass]="{
                        'widget-numbers text-success':
                          (nm_static?.sales?.today?.return_qty * 100) /
                            nm_static?.sales?.today?.return_qty <
                          1,
                        'widget-numbers text-warning':
                          (nm_static?.sales?.today?.return_qty * 100) /
                            nm_static?.sales?.today?.return_qty >
                          1,
                      }"
                      style="font-size: 1rem">
                      {{ nm_static?.sales?.today?.return_qty }} шт ({{
                        nm_static?.sales?.today?.return_price | number: '1.2'
                      }}
                      ₽)
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>

          <li class="bg-transparent list-group-item">
            <div class="widget-content p-0">
              <div class="widget-content-outer">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">Возвратов вчера</div>
                    <div class="widget-subheading"><br /></div>
                  </div>
                  <div
                    *ngIf="nm_static?.sales?.yesterday?.return_qty == null"
                    class="widget-content-right">
                    <div
                      class="widget-numbers text-success"
                      style="font-size: 1rem">
                      0 шт (0 ₽)
                    </div>
                  </div>
                  <div
                    *ngIf="nm_static?.sales?.yesterday?.return_qty != null"
                    class="widget-content-right">
                    <div
                      [ngClass]="{
                        'widget-numbers text-success':
                          (nm_static?.sales?.yesterday?.return_qty * 100) /
                            nm_static?.sales?.yesterday?.return_qty <
                          1,
                        'widget-numbers text-warning':
                          (nm_static?.sales?.yesterday?.return_qty * 100) /
                            nm_static?.sales?.yesterday?.return_qty >
                          1,
                      }"
                      style="font-size: 1rem">
                      {{ nm_static?.sales?.yesterday?.return_qty }} шт ({{
                        nm_static?.sales?.yesterday?.return_price
                          | number: '1.2'
                      }}
                      ₽)
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>

          <li class="bg-transparent list-group-item">
            <div class="widget-content p-0 w-100">
              <div class="widget-content-outer">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left pe-2 fsize-1">
                    <div
                      [ngClass]="{
                        'widget-numbers mt-0 fsize-3 text-danger':
                          (nm_static?.sales?.today?.return_qty * 100) /
                            nm_static?.sales?.yesterday?.return_qty <
                          50,
                        'widget-numbers mt-0 fsize-3 text-warning':
                          (nm_static?.sales?.today?.return_qty * 100) /
                            nm_static?.sales?.yesterday?.return_qty >=
                            50 &&
                          (nm_static?.sales?.today?.return_qty * 100) /
                            nm_static?.sales?.yesterday?.return_qty <=
                            84,
                        'widget-numbers mt-0 fsize-3 text-success':
                          (nm_static?.sales?.today?.return_qty * 100) /
                            nm_static?.sales?.yesterday?.return_qty >
                          85,
                      }">
                      {{
                        (nm_static?.sales?.today?.return_qty * 100) /
                          nm_static?.sales?.yesterday?.return_qty
                          | number: '1.0-1'
                      }}
                      %
                    </div>
                  </div>
                  <div class="widget-content-right w-100">
                    <div class="progress-bar-xs progress">
                      <div
                        [ngClass]="{
                          'progress-bar bg-success':
                            (nm_static?.sales?.today?.return_qty * 100) /
                              nm_static?.sales?.yesterday?.return_qty <
                            50,
                          'progress-bar bg-warning':
                            (nm_static?.sales?.today?.return_qty * 100) /
                              nm_static?.sales?.yesterday?.return_qty >=
                              50 &&
                            (nm_static?.sales?.today?.return_qty * 100) /
                              nm_static?.sales?.yesterday?.return_qty <=
                              84,
                          'progress-bar bg-danger':
                            (nm_static?.sales?.today?.return_qty * 100) /
                              nm_static?.sales?.yesterday?.return_qty >
                            85,
                        }"
                        [style.width]="
                          (nm_static?.sales?.today?.return_qty * 100) /
                            nm_static?.sales?.yesterday?.return_qty +
                          '%'
                        "
                        aria-valuemax="nm_static?.sales?.yesterday?.return_qty"
                        aria-valuemin="0"
                        aria-valuenow="nm_static?.sales?.today?.return_qty"
                        role="progressbar"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4 col-lg-3">
      <div class="card-shadow-primary card-border mb-3 card">
        <div class="card-body">
          <ngx-slick-carousel
            #slickModal="slick-carousel"
            [config]="slideConfig5"
            class="slick-slider slick-dotted">
            <div *ngIf="total_nmid_agv?.photo_1" ngxSlickItem>
              <div class="slider-item" style="width: 170px; height: 190px">
                <img
                  height="190"
                  src="{{ total_nmid_agv.photo_1 }}"
                  width="170" />
              </div>
            </div>
            <div *ngIf="total_nmid_agv?.photo_2" ngxSlickItem>
              <div class="slider-item" style="width: 170px; height: 190px">
                <img
                  height="190"
                  src="{{ total_nmid_agv.photo_2 }}"
                  width="170" />
              </div>
            </div>
            <div *ngIf="total_nmid_agv?.photo_3" ngxSlickItem>
              <div class="slider-item" style="width: 170px; height: 190px">
                <img
                  height="190"
                  src="{{ total_nmid_agv.photo_3 }}"
                  width="170" />
              </div>
            </div>
            <div *ngIf="total_nmid_agv?.photo_4" ngxSlickItem>
              <div class="slider-item" style="width: 170px; height: 190px">
                <img
                  height="190"
                  src="{{ total_nmid_agv.photo_4 }}"
                  width="170" />
              </div>
            </div>
            <div *ngIf="total_nmid_agv?.photo_5" ngxSlickItem>
              <div class="slider-item" style="width: 170px; height: 190px">
                <img
                  height="190"
                  src="{{ total_nmid_agv.photo_5 }}"
                  width="170" />
              </div>
            </div>
          </ngx-slick-carousel>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-lg-3">
      <div class="card-hover-shadow-2x mb-3 card">
        <div class="card-header-tab card-header">
          <div
            class="card-header-title font-size-lg text-capitalize font-weight-normal">
            <i class="header-icon lnr-database icon-gradient bg-malibu-beach">
            </i
            >Доходы/налоги
          </div>
        </div>
        <div class="scroll-area-sm">
          <ul class="todo-list-wrapper list-group list-group-flush">
            <li class="list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">
                      Выручка
                      <div class="badge badge-danger ml-2"></div>
                    </div>
                  </div>
                  <div class="widget-content-right">
                    <div class="fsize-2 text-alt">
                      <small class="opacity-5 pr-1"></small>
                      <span
                        >{{
                          total_nmid_agv?.sales_sum_sales -
                            total_nmid_agv?.komiss -
                            total_nmid_agv?.log | number: '1.2'
                        }}
                        ₽</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">
                      Налог {{ total_nmid_agv?.tax_percent }}%
                      <div class="badge badge-danger ml-2"></div>
                    </div>
                  </div>
                  <div class="widget-content-right">
                    <div class="fsize-2 text-alt">
                      <small class="opacity-5 pr-1"></small>
                      <span
                        >{{ total_nmid_agv?.sales_tax | number: '1.2' }} ₽</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">
                      Валовая прибыль
                      <div class="badge badge-danger ml-2"></div>
                    </div>
                  </div>
                  <div class="widget-content-right">
                    <div class="fsize-2 text-alt">
                      <small class="opacity-5 pr-1"></small>
                      <span
                        >{{
                          total_nmid_agv?.sales_sum_sales -
                            total_nmid_agv?.komiss -
                            total_nmid_agv?.log -
                            total_nmid_agv?.sales_cost_price -
                            total_nmid_agv?.penalty_paid_acceptance -
                            total_nmid_agv?.penalty_all -
                            total_nmid_agv?.payment_doplata -
                            total_nmid_agv?.sales_tax | number: '1.2'
                        }}
                        ₽</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-lg-3">
      <div class="card-hover-shadow-2x mb-3 card">
        <div class="card-header-tab card-header">
          <div
            class="card-header-title font-size-lg text-capitalize font-weight-normal">
            <i class="header-icon lnr-database icon-gradient bg-malibu-beach">
            </i
            >Продажи
          </div>
        </div>
        <div class="scroll-area-sm">
          <ul class="todo-list-wrapper list-group list-group-flush">
            <li class="list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">
                      Количество
                      <div class="badge badge-danger ml-2"></div>
                    </div>
                  </div>
                  <div class="widget-content-right">
                    <div class="fsize-2 text-alt">
                      <small class="opacity-5 pr-1"></small>
                      <span>{{ total_nmid_agv?.sales_qty }} шт</span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">
                      Сумма
                      <div class="badge badge-danger ml-2"></div>
                    </div>
                  </div>
                  <div class="widget-content-right">
                    <div class="fsize-2 text-alt">
                      <small class="opacity-5 pr-1"></small>
                      <span
                        >{{
                          total_nmid_agv?.sales_sum_sales | number: '1.2'
                        }}
                        ₽</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">Комиссия</div>
                  </div>
                  <div
                    *ngIf="total_nmid_agv?.komiss > 0"
                    class="widget-content-right">
                    <div class="fsize-2 text-danger">
                      <small class="opacity-5 pr-1"></small>
                      <span
                        >{{ total_nmid_agv?.komiss | number: '1.2' }} ₽</span
                      >
                    </div>
                  </div>
                  <div
                    *ngIf="total_nmid_agv?.komiss <= 0"
                    class="widget-content-right">
                    <div class="fsize-2 text-success">
                      <small class="opacity-5 pr-1"></small>
                      <span
                        >{{ total_nmid_agv?.komiss | number: '1.2' }} ₽</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">Итого:</div>
                  </div>
                  <div class="widget-content-right">
                    <div class="fsize-2 text-alt">
                      <small class="opacity-5 pr-1"></small>
                      <span
                        ><b
                          >{{ total_nmid_agv?.sales_sum | number: '1.2' }} ₽</b
                        ></span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-lg-3">
      <div class="card-hover-shadow-2x mb-3 card">
        <div class="card-header-tab card-header">
          <div
            class="card-header-title font-size-lg text-capitalize font-weight-normal">
            <i class="header-icon lnr-database icon-gradient bg-malibu-beach">
            </i
            >Возвраты
          </div>
        </div>
        <div class="scroll-area-sm">
          <ul class="todo-list-wrapper list-group list-group-flush">
            <li class="list-group-item">
              <!--<div class="todo-indicator bg-warning"></div>-->
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">
                      Количество
                      <div class="badge badge-danger ml-2"></div>
                    </div>
                  </div>
                  <div class="widget-content-right">
                    <div class="fsize-2 text-alt">
                      <small class="opacity-5 pr-1"></small>
                      <span>{{ total_nmid_agv?.return_qty }} шт</span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">
                      сумма
                      <div class="badge badge-danger ml-2"></div>
                    </div>
                  </div>
                  <div class="widget-content-right">
                    <div class="fsize-2 text-alt">
                      <small class="opacity-5 pr-1"></small>
                      <span
                        >{{
                          total_nmid_agv?.return_sum | number: '1.2'
                        }}
                        ₽</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">Итого</div>
                  </div>
                  <div class="widget-content-right">
                    <div class="fsize-2 text-alt">
                      <small class="opacity-5 pr-1"></small>
                      <span
                        ><b
                          >{{ total_nmid_agv?.return_sum | number: '1.2' }} ₽</b
                        ></span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-lg-3">
      <div class="card-hover-shadow-2x mb-3 card">
        <div class="card-header-tab card-header">
          <div
            class="card-header-title font-size-lg text-capitalize font-weight-normal">
            <i class="header-icon lnr-database icon-gradient bg-malibu-beach">
            </i
            >Себестоимость товара
          </div>
        </div>
        <div class="scroll-area-lg">
          <ul class="todo-list-wrapper list-group list-group-flush">
            <li class="list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">
                      Количество
                      <div class="badge badge-danger ml-2"></div>
                    </div>
                  </div>
                  <div class="widget-content-right">
                    <div class="fsize-2 text-alt">
                      <small class="opacity-5 pr-1"></small>
                      <span>{{ total_nmid_agv?.sales_qty }} шт</span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">Цена закупки</div>
                    <div class="widget-subheading">
                      <div>
                        За 1 шт
                        <div class="badge bg-pill bg-info ml-2">
                          {{
                            total_nmid_agv?.sales_cost_price /
                              total_nmid_agv?.sales_qty | number: '1.2'
                          }}
                          ₽
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="widget-content-right">
                    <div class="fsize-2 text-alt">
                      <small class="opacity-5 pr-1"></small>
                      <span
                        >{{
                          total_nmid_agv?.sales_cost_price | number: '1.2'
                        }}
                        ₽</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">
                      Доставка до ФФ/склада
                      <div class="badge badge-danger ml-2"></div>
                    </div>
                  </div>
                  <div class="widget-content-right">
                    <div class="fsize-2 text-alt">
                      <small class="opacity-5 pr-1"></small>
                      <span
                        >{{
                          total_nmid_agv?.delivery_ff_wh | number: '1.2'
                        }}
                        ₽</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <!--<div class="todo-indicator bg-warning"></div>-->
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">Упаковка на фф</div>
                  </div>
                  <div class="widget-content-right">
                    <div class="fsize-2 text-alt">
                      <small class="opacity-5 pr-1"></small>
                      <span
                        >{{ total_nmid_agv?.packing | number: '1.2' }} ₽</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <!--<div class="todo-indicator bg-warning"></div>-->
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">Уп. материал</div>
                  </div>
                  <div class="widget-content-right">
                    <div class="fsize-2 text-alt">
                      <small class="opacity-5 pr-1"></small>
                      <span
                        >{{
                          total_nmid_agv?.packing_mat | number: '1.2'
                        }}
                        ₽</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <!--<div class="todo-indicator bg-warning"></div>-->
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">Прочие затраты</div>
                  </div>
                  <div class="widget-content-right">
                    <div class="fsize-2 text-alt">
                      <small class="opacity-5 pr-1"></small>
                      <span
                        >{{
                          total_nmid_agv?.other_payment | number: '1.2'
                        }}
                        ₽</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">Итого себес 1шт:</div>
                  </div>
                  <div class="widget-content-right">
                    <div class="fsize-2 text-alt">
                      <small class="opacity-5 pr-1"></small>
                      <span
                        ><b
                          >{{
                            (total_nmid_agv?.sales_cost_price +
                              total_nmid_agv?.delivery_ff_wh +
                              total_nmid_agv?.packing +
                              total_nmid_agv?.packing_mat +
                              total_nmid_agv?.other_payment) /
                              total_nmid_agv?.sales_qty | number: '1.2'
                          }}
                          ₽</b
                        ></span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">Итого себес:</div>
                  </div>
                  <div class="widget-content-right">
                    <div class="fsize-2 text-alt">
                      <small class="opacity-5 pr-1"></small>
                      <span
                        ><b
                          >{{
                            total_nmid_agv?.sales_cost_price +
                              total_nmid_agv?.delivery_ff_wh +
                              total_nmid_agv?.packing +
                              total_nmid_agv?.packing_mat +
                              total_nmid_agv?.other_payment | number: '1.2'
                          }}
                          ₽</b
                        ></span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-lg-3">
      <div class="card-hover-shadow-2x mb-3 card">
        <div class="card-header-tab card-header">
          <div
            class="card-header-title font-size-lg text-capitalize font-weight-normal">
            <i class="header-icon lnr-database icon-gradient bg-malibu-beach">
            </i
            >Логистика ВБ
          </div>
        </div>
        <div class="scroll-area-lg">
          <ul class="todo-list-wrapper list-group list-group-flush">
            <li class="list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">
                      Количество общее
                      <div class="badge badge-danger ml-2"></div>
                    </div>
                  </div>
                  <div class="widget-content-right">
                    <div class="fsize-2 text-alt">
                      <small class="opacity-5 pr-1"></small>
                      <span
                        >{{
                          total_nmid_agv?.log_delivery_qty +
                            total_nmid_agv?.log_return_qty
                        }}
                        шт</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li></li>
            <li class="list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">
                      Количество к клиенту
                      <div class="badge badge-danger ml-2"></div>
                    </div>
                  </div>
                  <div class="widget-content-right">
                    <div class="fsize-2 text-alt">
                      <small class="opacity-5 pr-1"></small>
                      <span>{{ total_nmid_agv?.log_delivery_qty }} шт</span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">Сумма к клиенту:</div>
                  </div>
                  <div class="widget-content-right">
                    <div class="fsize-2 text-alt">
                      <small class="opacity-5 pr-1"></small>
                      <span
                        >{{
                          total_nmid_agv?.log_delivery_sum | number: '1.2'
                        }}
                        ₽</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li></li>
            <li class="list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">
                      Количество от клиента
                      <div class="badge badge-danger ml-2"></div>
                    </div>
                  </div>
                  <div class="widget-content-right">
                    <div class="fsize-2 text-alt">
                      <small class="opacity-5 pr-1"></small>
                      <span>{{ total_nmid_agv?.log_return_qty }} шт</span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <!--<div class="todo-indicator bg-warning"></div>-->
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">Сумма от клиента:</div>
                  </div>
                  <div class="widget-content-right">
                    <div class="fsize-2 text-alt">
                      <small class="opacity-5 pr-1"></small>
                      <span
                        >{{
                          total_nmid_agv?.log_return_sum | number: '1.2'
                        }}
                        ₽</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">Итого на 1шт:</div>
                  </div>
                  <div class="widget-content-right">
                    <div class="fsize-2 text-alt">
                      <small class="opacity-5 pr-1"></small>
                      <span
                        ><b
                          >{{
                            (total_nmid_agv?.log_delivery_sum +
                              total_nmid_agv?.log_return_sum) /
                              (total_nmid_agv?.log_delivery_qty +
                                total_nmid_agv?.log_return_qty) | number: '1.2'
                          }}
                          ₽</b
                        ></span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">Итого:</div>
                  </div>
                  <div class="widget-content-right">
                    <div class="fsize-2 text-alt">
                      <small class="opacity-5 pr-1"></small>
                      <span
                        ><b
                          >{{
                            total_nmid_agv?.log_delivery_sum +
                              total_nmid_agv?.log_return_sum | number: '1.2'
                          }}
                          ₽</b
                        ></span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-lg-3">
      <div class="card-hover-shadow-2x mb-3 card">
        <div class="card-header-tab card-header">
          <div
            class="card-header-title font-size-lg text-capitalize font-weight-normal">
            <i class="header-icon lnr-database icon-gradient bg-malibu-beach">
            </i
            >Самовыкупы
          </div>
        </div>
        <div class="scroll-area-lg">
          <ul class="todo-list-wrapper list-group list-group-flush">
            <li class="list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">
                      Количество
                      <div class="badge badge-danger ml-2"></div>
                    </div>
                  </div>
                  <div class="widget-content-right">
                    <div class="fsize-2 text-alt">
                      <small class="opacity-5 pr-1"></small>
                      <span
                        ><b
                          >{{ total_nmid_agv?.buyout_sales_qty_new }} шт</b
                        ></span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">
                      Выкуплено на
                      <div class="badge badge-danger ml-2"></div>
                    </div>
                  </div>
                  <div class="widget-content-right">
                    <div class="fsize-2 text-alt">
                      <small class="opacity-5 pr-1"></small>
                      <span
                        >{{
                          total_nmid_agv?.buyout_sales_new | number: '1.2'
                        }}
                        ₽</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">Сумма услуги забор/выкуп</div>
                  </div>
                  <div class="widget-content-right">
                    <div class="fsize-2 text-alt">
                      <small class="opacity-5 pr-1"></small>
                      <span
                        >{{
                          total_nmid_agv?.buyout_cost | number: '1.2'
                        }}
                        ₽</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">
                      Комиссия ВБ
                      <div class="badge badge-danger ml-2"></div>
                    </div>
                  </div>
                  <div
                    *ngIf="total_nmid_agv?.buyout_komiss_new > 0"
                    class="widget-content-right">
                    <div class="fsize-2 text-danger">
                      <small class="opacity-5 pr-1"></small>
                      <span
                        >{{
                          total_nmid_agv?.buyout_komiss_new | number: '1.2'
                        }}
                        ₽</span
                      >
                    </div>
                  </div>
                  <div
                    *ngIf="total_nmid_agv?.buyout_komiss_new < 0"
                    class="widget-content-right">
                    <div class="fsize-2 text-success">
                      <small class="opacity-5 pr-1"></small>
                      <span
                        >{{
                          total_nmid_agv?.buyout_komiss_new | number: '1.2'
                        }}
                        ₽</span
                      >
                    </div>
                  </div>
                  <div
                    *ngIf="total_nmid_agv?.buyout_komiss_new == 0"
                    class="widget-content-right">
                    <div class="fsize-2 text-alt">
                      <small class="opacity-5 pr-1"></small>
                      <span
                        >{{
                          total_nmid_agv?.buyout_komiss_new | number: '1.2'
                        }}
                        ₽</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">Итого на 1 шт:</div>
                  </div>
                  <div class="widget-content-right">
                    <div class="fsize-2 text-alt">
                      <small class="opacity-5 pr-1"></small>
                      <span
                        ><b
                          >{{
                            total_nmid_agv?.buyout_cost /
                              total_nmid_agv?.buyout_sales_qty_new
                              | number: '1.2'
                          }}
                          ₽</b
                        ></span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <!--<div class="todo-indicator bg-warning"></div>-->
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">Итого:</div>
                  </div>
                  <div class="widget-content-right">
                    <div class="fsize-2 text-alt">
                      <small class="opacity-5 pr-1"></small>
                      <span
                        ><b
                          >{{
                            total_nmid_agv?.buyout_cost | number: '1.2'
                          }}
                          ₽</b
                        ></span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-lg-3">
      <div class="card-hover-shadow-2x mb-3 card">
        <div class="card-header-tab card-header">
          <div
            class="card-header-title font-size-lg text-capitalize font-weight-normal">
            <i class="header-icon lnr-database icon-gradient bg-malibu-beach">
            </i
            >Удержания ВБ
          </div>
        </div>
        <div class="scroll-area-sm">
          <ul class="todo-list-wrapper list-group list-group-flush">
            <li class="list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">
                      Приемка
                      <div class="badge badge-danger ml-2"></div>
                    </div>
                  </div>
                  <div class="widget-content-right">
                    <div class="fsize-2 text-alt">
                      <small class="opacity-5 pr-1"></small>
                      <span
                        ><b
                          >{{
                            total_nmid_agv?.penalty_paid_acceptance
                              | number: '1.2'
                          }}
                          ₽</b
                        ></span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <!--<div class="todo-indicator bg-warning"></div>-->
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">
                      Штрафы
                      <div class="badge badge-danger ml-2"></div>
                    </div>
                  </div>
                  <div class="widget-content-right">
                    <div class="fsize-2 text-alt">
                      <small class="opacity-5 pr-1"></small>
                      <span
                        ><b
                          >{{
                            total_nmid_agv?.penalty_all | number: '1.2'
                          }}
                          ₽</b
                        ></span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">Доплаты</div>
                  </div>
                  <div
                    *ngIf="total_nmid_agv?.payment_doplata > 0"
                    class="widget-content-right">
                    <div class="fsize-2 text-danger">
                      <small class="opacity-5 pr-1"></small>
                      <span
                        >{{ total_nmid_agv?.komiss | number: '1.2' }} ₽</span
                      >
                    </div>
                  </div>
                  <div
                    *ngIf="total_nmid_agv?.payment_doplata < 0"
                    class="widget-content-right">
                    <div class="fsize-2 text-success">
                      <small class="opacity-5 pr-1"></small>
                      <span
                        >{{
                          total_nmid_agv?.payment_doplata | number: '1.2'
                        }}
                        ₽</span
                      >
                    </div>
                  </div>
                  <div
                    *ngIf="total_nmid_agv?.payment_doplata == 0"
                    class="widget-content-right">
                    <div class="fsize-2 text-alt">
                      <small class="opacity-5 pr-1"></small>
                      <span
                        >{{
                          total_nmid_agv?.payment_doplata | number: '1.2'
                        }}
                        ₽</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">Итого:</div>
                  </div>
                  <div
                    *ngIf="
                      total_nmid_agv?.penalty_paid_acceptance +
                        total_nmid_agv?.penalty_all +
                        total_nmid_agv?.payment_doplata >
                      0
                    "
                    class="widget-content-right">
                    <div class="fsize-2 text-danger">
                      <small class="opacity-5 pr-1"></small>
                      <span
                        ><b
                          >{{
                            total_nmid_agv?.penalty_paid_acceptance +
                              total_nmid_agv?.penalty_all +
                              total_nmid_agv?.payment_doplata | number: '1.2'
                          }}
                          ₽</b
                        ></span
                      >
                    </div>
                  </div>
                  <div
                    *ngIf="
                      total_nmid_agv?.penalty_paid_acceptance +
                        total_nmid_agv?.penalty_all +
                        total_nmid_agv?.payment_doplata <
                      0
                    "
                    class="widget-content-right">
                    <div class="fsize-2 text-success">
                      <small class="opacity-5 pr-1"></small>
                      <span
                        ><b
                          >{{
                            total_nmid_agv?.penalty_paid_acceptance +
                              total_nmid_agv?.penalty_all +
                              total_nmid_agv?.payment_doplata | number: '1.2'
                          }}
                          ₽</b
                        ></span
                      >
                    </div>
                  </div>
                  <div
                    *ngIf="
                      total_nmid_agv?.penalty_paid_acceptance +
                        total_nmid_agv?.penalty_all +
                        total_nmid_agv?.payment_doplata ==
                      0
                    "
                    class="widget-content-right">
                    <div class="fsize-2 text-alt">
                      <small class="opacity-5 pr-1"></small>
                      <span
                        ><b
                          >{{
                            total_nmid_agv?.penalty_paid_acceptance +
                              total_nmid_agv?.penalty_all +
                              total_nmid_agv?.payment_doplata | number: '1.2'
                          }}
                          ₽</b
                        ></span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 col-lg-12">
      <div class="main-card mb-3 card">
        <div class="card-header">
          <div>
            <h5 class="menu-header-title text-capitalize text-primary">
              Детализация по баркодам
            </h5>
          </div>
        </div>
        <div class="table-responsive">
          <table
            class="align-middle mb-0 table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th class="text-center" rowspan="3">Прибыль</th>
                <th class="text-center" rowspan="3">Баркод</th>
                <th class="text-center" rowspan="3">Размер</th>
                <th class="text-center" colspan="4">продажи</th>
                <th class="text-center" colspan="2">Возвраты</th>
                <th class="text-center" colspan="5">Стоимость логистики</th>
                <th class="text-center" rowspan="3">Выручка</th>
                <th class="text-center" rowspan="3">Налог</th>
                <th class="text-center" colspan="4">Самовыкупы</th>
                <th class="text-center" rowspan="3">Приемка</th>
                <th class="text-center" rowspan="3">Штрафы</th>
                <th class="text-center" rowspan="3">Доплаты</th>
              </tr>
              <tr>
                <th class="text-center" rowspan="2">Сумма</th>
                <th class="text-center" rowspan="2">Кол-во</th>
                <th class="text-center" rowspan="2">Себес..</th>
                <th class="text-center" rowspan="2">Комиссия</th>
                <th class="text-center" rowspan="2">Сумма</th>
                <th class="text-center" rowspan="2">Кол-во</th>
                <th class="text-center" colspan="2">К клиенту</th>
                <th class="text-center" colspan="2">От клиента</th>
                <th class="text-center" rowspan="2">Общая</th>
                <th class="text-center" rowspan="2">Сумма</th>
                <th class="text-center" rowspan="2">кол-во</th>
                <th class="text-center" rowspan="2">затраты</th>
                <th class="text-center" rowspan="2">комиссия ВБ</th>
              </tr>
              <tr>
                <th class="text-center" rowspan="2">сумма</th>
                <th class="text-center" rowspan="2">кол-во</th>
                <th class="text-center" rowspan="2">сумма</th>
                <th class="text-center" rowspan="2">кол-во</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of detailingByBarcodesList; let i = index">
                <td>
                  {{
                    item.sales_sum_sales -
                      item.komiss -
                      item.log -
                      item.sales_cost_price -
                      item.penalty_paid_acceptance -
                      item.penalty_all -
                      item.payment_doplata -
                      item.sales_tax | number: '1.2'
                  }}
                </td>
                <td class="text-left text-muted">{{ item.barcode }}</td>
                <td class="text-center">{{ item.ts_name }}</td>
                <td class="text-center">
                  <b>{{ item.sales_sum_sales | number: '1.2' }}</b>
                </td>
                <td class="text-center">{{ item?.sales_qty }}</td>
                <td class="text-center">
                  {{ item.sales_cost_price | number: '1.2' }}
                </td>
                <td class="text-center">
                  <b>{{ item.komiss | number: '1.2' }}</b>
                </td>
                <td class="text-center">
                  {{ item.return_sum | number: '1.2' }}
                </td>
                <td class="text-center">{{ item.return_qty }}</td>
                <td class="text-center">
                  {{ item.log_delivery_sum | number: '1.2' }}
                </td>
                <td class="text-center">{{ item.log_delivery_qty }}</td>
                <td class="text-center">
                  {{ item.log_return_sum | number: '1.2' }}
                </td>
                <td class="text-center">{{ item.log_return_qty }}</td>
                <td class="text-center">
                  <b>{{ item.log | number: '1.2' }}</b>
                </td>
                <td class="text-center">
                  {{
                    item.sales_sum_sales - item.komiss - item.log
                      | number: '1.2'
                  }}
                </td>
                <td class="text-center">
                  {{ item.sales_tax | number: '1.2' }}
                </td>
                <td class="text-center">
                  {{ item.buyout_sales_new | number: '1.2' }}
                </td>
                <td class="text-center">{{ item.buyout_sales_qty_new }}</td>
                <td class="text-center">{{ item.buyout_cost }}</td>
                <td class="text-center">
                  {{ item.buyout_komiss_new | number: '1.2' }}
                </td>
                <td class="text-center">
                  {{ item.penalty_paid_acceptance | number: '1.2' }}
                </td>
                <td class="text-center">
                  {{ item.penalty_all | number: '1.2' }}
                </td>
                <td class="text-center">
                  {{ item.payment_doplata | number: '1.2' }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
