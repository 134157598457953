import { CommonValueWithIDName } from 'app/shared/interfaces/common.interface';

export type TariffPlane = 'base' | 'extended' | 'professional' | 'corporate';

export enum TariffPlansTitles {
  FREE = 'S',
  BASE = 'M',
  EXTENDED = 'L',
  PROFESSIONAL = 'XL',
}

export interface ICurrentTariff {
  name: string;
  period: string;
  expdate: string;
  days: string;
  color: string;
  code?: string;
  value?: number;
  onCash?: number;
  priceForUpcomingTariff?: number;
}

export interface ITariffPercents extends CommonValueWithIDName {
  selector: string;
  sale: string;
  days: number;
  months: number;
  ids?: {
    M: number;
    L: number;
    XL: number;
  };
}

export interface ITariffPrices {
  recalculation: ITariffPricesItem;
  one_month_selector: ITariffPricesItem;
  three_month_selector: ITariffPricesItem;
  six_month_selector: ITariffPricesItem;
  twelve_month_selector: ITariffPricesItem;
}

export interface ITariffTablePrices {
  title: string;
  saleType1: string;
  saleType2: string;
  saleType3: string;
  saleType4: string;
  saleType5: string;
}

export interface ITariffPricesItem {
  months?: number | null;
  base: number | null;
  extended: number | null;
  professional: number | null;
  corporate: number | null;
}

export interface ITariffItem {
  name: string,
  weight: number,
  rows: { title: string, tooltip?: string | null, logos?: string[] | null, description?: string | number | null }[],
  month_summary?: string | null,
  calculation_period?: string | null,
  calculation_result?: string | null,
  calculation_result_old?: string | null,
  button_text?: string | null
}