import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ColDef, DragStoppedEvent, GridReadyEvent } from 'ag-grid-community';
import { GridOptions } from 'ag-grid';
import { LOCALE_RU_FILTERS } from '../../finances/finoperations/finoperations.mock';
import { generateColumnDefs } from './remains.mock';
import { AbstractMenuTabComponent } from '../../abstract-menu-tab.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { map } from 'rxjs/operators';
import { RemainsInterface } from './remains.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-remains',
  templateUrl: './remains.component.html',
  styleUrls: ['./remains.component.scss'],
  providers: [MessageService, ConfirmationService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RemainsComponent
  extends AbstractMenuTabComponent
  implements OnInit
{
  isOzon = JSON.parse(localStorage.getItem('mpall_shop')).marketPlace === 2;
  dataForColumnDefs: ColDef[] = generateColumnDefs(this.isOzon);
  readonly defaultColDef: ColDef = {
    headerClass: 'header-centered',
    sortable: true,
    resizable: true,
    filter: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: 'center' },
  };
  public tooltipShowDelay = 0;
  public tooltipHideDelay = 2000;
  gridOptions: GridOptions = {};
  localRuFilters = LOCALE_RU_FILTERS;
  isLoadingStatus = false;
  whList = [];
  selectedWh = [];
  rowData = [];
  summaryData = [];

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.loadRemains()
    this.loadWH();
  }

  loadRemains(): void {
    const body = {
      warehouses: Array.isArray(this.selectedWh) ? this.selectedWh.map(s => s.name) : [],
    }
    this._mpSurfService
    .loadByPost(body, 'stocks/list')
    .pipe(untilDestroyed(this))
    .subscribe(v => {
      //@ts-ignore
      this.rowData = v.data;
      //@ts-ignore
      this.summaryData = [{...v.total, photo: 'Итого'}];
      this._cdr.markForCheck();
    })
  }

  loadWH(): void {
    this._mpSurfService
      .load({}, 'stocks/warehouses')
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: string[]) => {
          this.whList = response.map(r => ({name: r}));
          this._cdr.markForCheck()
        },
    );
  }

  onGridReady(params?: GridReadyEvent<any>) {
    this.gridApi = params.api;
  }

  onDragStopped(event: DragStoppedEvent) {
    localStorage.setItem('remains-column-defs', JSON.stringify(event.api.getColumnDefs()))
  }

  clearWareHouses(): void {
    this.selectedWh = [];
    this.loadRemains();
  };

  selectWareHouses(): void {
    this.loadRemains();
  }
}
