<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <i class="pe-7s-safe icon-gradient bg-mean-fruit"></i>
      </div>
      <div>Гео продаж</div>
      <div class="btn-actions-pane-left">
        <input
          [disabled]="date_dis"
          type="text"
          ngxDaterangepickerMd
          [locale]="{
            applyLabel: 'применить',
            direction: 'ltr',
            format: 'DD.MM.YYYY',
            daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
            customRangeLabel: 'Свой диапазон',
            firstDay: 1,
            monthNames: [
              'Январь',
              'Февраль',
              'Март',
              'Апрель',
              'Май',
              'Июнь',
              'Июль',
              'Август',
              'Сентябрь',
              'Октябрь',
              'Ноябрь',
              'Декабрь',
            ],
          }"
          [(ngModel)]="selected"
          [showCustomRangeLabel]="true"
          [alwaysShowCalendars]="true"
          [ranges]="datePickerRange"
          [linkedCalendars]="true"
          [isInvalidDate]="isInvalidDate"
          [showClearButton]="false"
          [maxDate]="maxDate"
          [minDate]="minDate"
          (change)="newValues()"
          placeholder="Выберите даты" />
      </div>
    </div>
  </div>
</div>
<div class="card block-sales-region">
  <div class="left-side-table">
    <h4 class="block-sales-title">Продажи по регионам</h4>
    <p-treeTable
      [value]="dataForRegionTable?.data"
      [columns]="dataForRegionTable?.columns"
      [(selection)]="selectedNodes"
      [frozenColumns]="frozenCols"
      [scrollable]="true"
      scrollHeight="75vh"
      frozenWidth="300px"
      styleClass="p-treetable-sm">
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <col *ngFor="let col of columns" style="width: 165px" />
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr style="height: 50px">
          <th *ngFor="let col of columns">
            {{ col.header }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData="rowData" let-columns="columns">
        <tr style="height: 50px">
          <td
            *ngFor="let col of columns; let i = index"
            style="font-size: 12px">
            {{ rowData[col.field] | currency: '' : '' }}
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="frozenbody" let-rowNode let-rowData="rowData">
        <tr style="height: 50px">
          <td>
            <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
            <span style="font-size: 12px">{{ rowData.name }}</span>
          </td>
        </tr>
      </ng-template>
    </p-treeTable>
  </div>
  <div class="rigth-side-table">
    <apx-chart
      [series]="[44, 55, 13, 43, 22]"
      [labels]="['Team A', 'Team B', 'Team C', 'Team D', 'Team E']"
      [chart]="{ width: '380', height: '320', type: 'pie' }">
    </apx-chart>
  </div>
</div>
<div class="card block-sales-subject">
  <div class="left-side-table-grid">
    <h4 class="block-sales-title">Продажи по предметам</h4>
    <ag-grid-angular
      style="width: 100%; height: 100%"
      class="ag-theme-alpine"
      [columnDefs]="dataForColumnSubject"
      [defaultColDef]="defaultColDefSubject"
      [rowData]="rowDataSubject"
      [rowHeight]="rowHeightSubject"
      (gridReady)="onGridReadySubject($event)"
      [pagination]="true"
      overlayNoRowsTemplate="Нет данных для отображения"
      [enableRangeSelection]="true">
    </ag-grid-angular>
  </div>
  <div class="rigth-side-table-grid">
    <apx-chart
      [series]="rowDataSubjectChartPercent"
      [labels]="rowDataSubjectChartTitles"
      [chart]="{ type: 'pie' }">
    </apx-chart>
  </div>
</div>
<div class="card block-sales-subject">
  <div class="left-side-table-grid">
    <h4 class="block-sales-title">Продажи по складам</h4>
    <ag-grid-angular
      style="width: 100%; height: 100%"
      class="ag-theme-alpine"
      [columnDefs]="dataForColumnWH"
      [defaultColDef]="defaultColDefWH"
      [rowData]="rowDataWH"
      [rowHeight]="rowHeightWH"
      (gridReady)="onGridReadyWH($event)"
      [pagination]="true"
      overlayNoRowsTemplate="Нет данных для отображения"
      [enableRangeSelection]="true">
    </ag-grid-angular>
  </div>
  <div class="rigth-side-table-grid">
    <apx-chart
      [series]="rowDataWHChartPercent"
      [labels]="rowDataWHChartTitles"
      [chart]="{ type: 'pie' }">
    </apx-chart>
  </div>
</div>
<app-maps-geo-sales></app-maps-geo-sales>
