export function actionCellRenderer(params) {
    let eGui = document.createElement('div');
  
    let editingCells = params.api.getEditingCells();
    // checks if the rowIndex matches in at least one of the editing cells
    let isCurrentRowEditing = editingCells.some(cell => {
      return cell.rowIndex === params.node.rowIndex;
    });
  
    if (isCurrentRowEditing) {
      eGui.innerHTML = `
        <button class="btn btn-light" data-action="update"> <span data-action="update" class="lnr lnr-checkmark-circle"></span>  </button>
        <button class="btn btn-light" data-action="cancel"> <span data-action="cancel" class="lnr lnr-undo"></span> </button>
        `;
    } else {
      eGui.innerHTML = `
        <button class="btn btn-light" data-action="edit"> <span data-action="edit" class="lnr lnr-pencil"></span>  </button>
        `;
    }
  
    return eGui;
  }