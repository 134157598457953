import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  DevToolsExtension,
  NgRedux,
  NgReduxModule,
} from '@angular-redux/store';
import { ArchitectUIState, rootReducer } from './shared/store';
import { ConfigActions } from './shared/store/config.actions';
import { AppRoutingModule } from './app-routing.module';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  NgbDateParserFormatter,
  NgbModule,
  NgbPopoverModule,
  NgbRatingModule,
} from '@ng-bootstrap/ng-bootstrap';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar';
import { LaddaModule } from 'angular2-ladda';
import { NgxLoadingModule } from 'ngx-loading';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { ToastrModule } from 'ngx-toastr';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CountUpModule } from 'countup.js-angular2';
import { AgmCoreModule } from '@agm/core';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { NouisliderModule } from 'ng2-nouislider';
import { NgSelectModule } from '@ng-select/ng-select';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TextMaskModule } from 'angular2-text-mask';
import { ClipboardModule } from 'ngx-clipboard';
import { TextareaAutosizeModule } from 'ngx-textarea-autosize';
import { ColorPickerModule } from 'ngx-color-picker';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { ChartsModule } from 'ng2-charts';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MAT_DATE_LOCALE,
  MatNativeDateModule,
  MatRippleModule,
} from '@angular/material/core';
import { NgbDateCustomParserFormatter } from './shared/adapters/date.adapter';
import { AuthGuard } from './shared/guards/auth.guard';
import { BaseLayoutComponent } from './general-components/base-layout/base-layout.component';
import { ThemeOptions } from './shared/theme-options/theme-options';
import { HeaderComponent } from './general-components/header/header.component';
import { UserBoxComponent } from './general-components/header/header-elements/user-box/user-box.component';
import { DrawerComponent } from './general-components/header/header-elements/drawer/drawer.component';
import { SidebarComponent } from './general-components/sidebar/sidebar.component';
import { FooterComponent } from './general-components/footer/footer.component';
import { RegisterComponent } from './general-components/auth/register/register.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { GaugeModule } from 'angular-gauge';
import { TrendModule } from 'ngx-trend';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { AgGridModule } from 'ag-grid-angular';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { A11yModule } from '@angular/cdk/a11y';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OverlayModule } from '@angular/cdk/overlay';
import { AngularTreeGridModule } from 'angular-tree-grid';
import localeDe from '@angular/common/locales/ru';
import localeDeExtra from '@angular/common/locales/extra/ru';
import { TreeTableModule } from 'primeng/treetable';
import { TreeModule } from 'primeng/tree';
import { ButtonModule } from 'primeng/button';
import { ContextMenuModule } from 'primeng/contextmenu';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { SelectButtonModule } from 'primeng/selectbutton';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { SliderModule } from 'primeng/slider';
import { MultiSelectModule } from 'primeng/multiselect';
import { DialogModule } from 'primeng/dialog';
import { ProgressBarModule } from 'primeng/progressbar';
import { InputTextModule } from 'primeng/inputtext';
import { BadgeModule } from 'primeng/badge';
import { ToolbarModule } from 'primeng/toolbar';
import { CalendarModule as CalendarModulepc } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { SkeletonModule } from 'primeng/skeleton';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { SupplierSelectionComponent } from './general-components/auth/components/supplier-selection/supplier-selection.component';
import { BtnCellRendererComponent } from './menu-tabs/guides/finpurchase/components/btn-cell-renderer.component';
import { NotificationDotsComponent } from './general-components/header/header-elements/notification-dots/notification-dots.component';
import { RippleModule } from 'primeng/ripple';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NumberFormatDirective } from './shared/directives/number-format-directive.directive';
import { InputNumberModule } from 'primeng/inputnumber';
import { CheckboxProductsRendererComponent } from './menu-tabs/guides/products/components/checkbox-products-renderer.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TagModule } from 'primeng/tag';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ListboxModule } from 'primeng/listbox';
import { NgxMetrikaModule } from '@kolkov/ngx-metrika';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MpSurfApiService } from './shared/services/mpsurf-api.service';

import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { ExternalAnalysisModule } from './menu-tabs/external-analysis/external-analysis.module';
import { AnalyticModule } from './menu-tabs/analytic/analytic.module';
import { AdminModule } from './menu-tabs/admin/admin.module';
import { AdvertModule } from './menu-tabs/advert/advert.module';
import { ModalModule } from './shared/common-components/modal/modal.module';
import { UiComponentsModule } from './shared/ui/components/ui-components.module';
import { GuidesModule } from './menu-tabs/guides/guides.module';
import { FinancesModule } from './menu-tabs/finances/finances.module';
import { DashboardModule } from './menu-tabs/dashboard/dashboard.module';
import { ProfileModule } from './general-components/profile/profile.module';
import { SettingsModule } from './menu-tabs/settings/settings.module';
import { ThousandSeparatorPipe } from './shared/ui/pipes/thousand-separator/mpThousandSeparator';
import { SharedUiPipesModule } from './shared/ui/pipes/ui-pipes.module';
import { HttpPendingInterceptor } from './shared/interceptors/http-pending.interceptor';
import { HttpErrorInterceptor } from 'app/shared/interceptors/http-error.interceptor';
import { MegamenuComponent } from './general-components/header/header-elements/mega-menu/mega-menu.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

registerLocaleData(localeDe, 'ru', localeDeExtra);

@NgModule({
  declarations: [
    AppComponent,
    MegamenuComponent,
    BaseLayoutComponent,

    SidebarComponent,

    //HEADER COMPONENTS

    HeaderComponent,
    NotificationDotsComponent,
    UserBoxComponent,

    FooterComponent,

    DrawerComponent,

    RegisterComponent,
    // LoginComponent,

    BtnCellRendererComponent,

    SupplierSelectionComponent,
    CheckboxProductsRendererComponent,
    // DIRECTIVES    NumberFormatDirective,
    NumberFormatDirective,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    NgReduxModule,
    CommonModule,
    LoadingBarRouterModule,
    PerfectScrollbarModule,
    NgbModule,
    FontAwesomeModule,
    LaddaModule,
    FormsModule,
    NgxDaterangepickerMd.forRoot(),
    ReactiveFormsModule,
    NgBootstrapFormValidationModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    RoundProgressModule,
    ToastrModule.forRoot(),
    SlickCarouselModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    CountUpModule,
    AgmCoreModule.forRoot({ apiKey: '' }),
    ImageCropperModule,
    NouisliderModule,
    NgSelectModule,
    SelectDropDownModule,
    NgMultiSelectDropDownModule.forRoot(),
    JwBootstrapSwitchNg2Module,
    AngularEditorModule,
    HttpClientModule,
    TextMaskModule,
    ClipboardModule,
    TextareaAutosizeModule,
    ColorPickerModule,
    DropzoneModule,
    ChartsModule,
    NgApexchartsModule,
    GaugeModule.forRoot(),
    TrendModule,
    MatCheckboxModule,
    MatTreeModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonToggleModule,
    MatIconModule,
    MatTableModule,
    AngularTreeGridModule,
    A11yModule,
    ClipboardModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    OverlayModule,
    PortalModule,
    ScrollingModule,
    AgGridModule,
    NgbPopoverModule,
    NgbRatingModule,
    TreeTableModule,
    TreeModule,
    ButtonModule,
    ContextMenuModule,
    ToastModule,
    ConfirmDialogModule,
    SelectButtonModule,
    DropdownModule,
    TableModule,
    SliderModule,
    MultiSelectModule,
    DialogModule,
    ProgressBarModule,
    InputTextModule,
    BadgeModule,
    SkeletonModule,
    ToolbarModule,
    CalendarModulepc,
    CheckboxModule,
    InputTextareaModule,
    FileUploadModule,
    InputMaskModule,
    NgxImageZoomModule,
    RippleModule,
    ProgressSpinnerModule,
    InputNumberModule,
    InputSwitchModule,
    RadioButtonModule,
    TagModule,
    ConfirmDialogModule,
    ConfirmPopupModule,
    ListboxModule,
    NgxMetrikaModule.forRoot({
      id: 94327509,
      defer: true,
      webvisor: true,
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      triggerEvent: true,
      trackPageViews: true,
    }),

    // Project LazyLoadingModules
    ExternalAnalysisModule,
    AnalyticModule,
    AdminModule,
    AdvertModule,
    ModalModule,
    UiComponentsModule,
    GuidesModule,
    FinancesModule,
    DashboardModule,
    ProfileModule,
    SettingsModule,

    SharedUiPipesModule,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'ru-RU',
    },
    {
      provide: LOCALE_ID,
      useValue: 'ru-RU',
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpPendingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
    AuthGuard,
    ConfigActions,
    ThemeOptions,

    MpSurfApiService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private ngRedux: NgRedux<ArchitectUIState>,
    private devTool: DevToolsExtension
  ) {
    this.ngRedux.configureStore(
      rootReducer,
      {} as ArchitectUIState,
      [],
      [this.devTool.isEnabled() ? this.devTool.enhancer() : f => f]
    );
  }
}
