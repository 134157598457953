import { Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../../shared/services/api.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IUserData, MASK_TYPE } from './user-box.interface';
import { CommonResponseGeneral1 } from '../../../../shared/interfaces/common.interface';
import { AbstractGeneralTabComponent } from '../../../abstract-general-tab.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { PopUpMessages } from '../../../../shared/mocks/pop-up-messages.mock';
import { IResponseCommon } from '../../../../menu-tabs/settings/shop/shop.interface';
import { LOCAL_STORAGE_KEYS } from '../../../../shared/contants/localStorage';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-user-box',
  templateUrl: './user-box.component.html',
  styleUrls: ['./user-box.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class UserBoxComponent
  extends AbstractGeneralTabComponent
  implements OnInit
{
  readonly mask: Array<string | RegExp> = MASK_TYPE;
  profileName = '';
  profilePhone = '';
  profileEmail = '';
  profileSubscribeExpiredDate = '';
  updateStatus: boolean = false;
  readonly formChangeName = new FormGroup({
    nameprofile: new FormControl(null, [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(30),
    ]),
  });
  readonly formChangePhone = new FormGroup({
    phoneprofile: new FormControl('', [
      Validators.required,
      Validators.pattern(
        '^((\\+7-?)|8)+(\\([0-9]{3}\\) )+([0-9]{3}-)+([0-9]{4})$'
      ),
    ]),
  });
  readonly formChangePassword = new FormGroup(
    {
      oldpass: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
      ]),
      newpass: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
      ]),
      newpassconfirm: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
      ]),
    },
    { validators: this.passwordMatchValidator }
  );
  private readonly _api = inject(ApiService);

  private readonly today = Date.now();

  constructor() {
    super();
  }

  ngOnInit() {
    this.loadLocalStorageData();
    this.loadUserData();
  }

  changeProfileName(operationType: string) {
    this.isLoading = true;
    this._api
      .userRegister(
        'updateDataUser',
        localStorage.getItem('token'),
        this.formChangeName.value
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        (data: IResponseCommon) => {
          if (data.is_error == 0) {
            this.updateStatus = true;
            this.profileName = this.formChangeName.get('nameprofile').value;

            localStorage.setItem(
              'profileName',
              JSON.stringify(this.profileName)
            );
            localStorage.setItem(
              'updateprofile',
              JSON.stringify(Math.trunc(this.today / 1000))
            );
            localStorage.setItem(
              'profileSubscribeExpiredDate',
              JSON.stringify(this.profileSubscribeExpiredDate)
            );

            this.delayUpdate('Имя пользователя успешно изменено');
            this.formChangeName.reset();
          } else {
            this.showPopUpMessage(
              'error',
              PopUpMessages.updateFailedSummary,
              PopUpMessages.updateFailedSummary
            );
          }
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.updateFailedSummary,
            PopUpMessages.updateFailedSummary
          );
        }
      );
  }

  changeProfilePhone(operationType: string): void {
    this._api
      .userRegister(
        'updateDataUser',
        localStorage.getItem('token'),
        this.formChangePhone.value
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        (data: IResponseCommon) => {
          if (data.is_error == 0) {
            this.updateStatus = true;
            this.profilePhone = this.formChangePhone.get('phoneprofile').value;

            localStorage.setItem(
              'profilePhone',
              JSON.stringify(this.profilePhone)
            );
            localStorage.setItem(
              'updateprofile',
              JSON.stringify(Math.trunc(this.today / 1000))
            );
            localStorage.setItem(
              'profileSubscribeExpiredDate',
              JSON.stringify(this.profileSubscribeExpiredDate)
            );

            this.delayUpdate('Номер телефона успешно изменен');
            this.formChangePhone.reset();
          } else {
            this.showPopUpMessage(
              'error',
              PopUpMessages.updateFailedSummary,
              PopUpMessages.updateFailedSummary
            );
          }
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.updateFailedSummary,
            PopUpMessages.updateFailedSummary
          );
        }
      );
  }

  changeProfilePassword(operationType: string): void {
    this._api
      .userRegister(
        'updateUserPass',
        localStorage.getItem('token'),
        this.formChangePassword.value
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        (data: any) => {
          if (data.is_error == 0) {
            this.updateStatus = true;
            localStorage.setItem(
              'updateprofile',
              JSON.stringify(Math.trunc(this.today / 1000))
            );
            this.delayUpdate('Пароль успешно изменен');
            this.formChangePassword.reset();
          } else {
            if (data.type == 'oldpass') {
              this.formChangePassword.controls['oldpass'].setErrors({
                invalidch: true,
              });
            }
            this.showPopUpMessage(
              'error',
              PopUpMessages.updateFailedSummary,
              'Ошибка изменения пароля'
            );
          }
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.updateFailedSummary,
            'Ошибка изменения пароля'
          );
        }
      );
  }

  logout() {
    localStorage.removeItem('profileSubscribeExpiredDate');
    localStorage.removeItem('profileEmail');
    localStorage.removeItem('mpall_shop');
    localStorage.removeItem('supplier');
    localStorage.removeItem('profileName');
    localStorage.removeItem('profilePhone');
    localStorage.removeItem('updateprofile');

    localStorage.removeItem(LOCAL_STORAGE_KEYS.IS_TEST);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.IS_ADMIN);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.TOKEN);

    localStorage.removeItem(LOCAL_STORAGE_KEYS.PROFILE_DATA_SUBSCRIBE);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.PROFILE_PHONE_NUMBER);

    localStorage.removeItem(LOCAL_STORAGE_KEYS.METADATA_VIDEO_LINKS);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.META_DATA_LAST_UPDATE_TIME);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.META_DATA_NOTIFICATIONS);

    localStorage.removeItem('token');
    this._router.navigate(['/auth/login']);
  }

  private passwordMatchValidator(g: FormGroup) {
    if (g.get('newpass').value === g.get('newpassconfirm').value) {
      g.controls['newpassconfirm'].setErrors(null);
      return null;
    } else {
      g.controls['newpassconfirm'].setErrors({ mustMatch: true });
      return { mustMatch: true };
    }
  }

  private loadLocalStorageData(): void {
    if (localStorage.getItem('profilePhone') != '') {
      this.profilePhone = JSON.parse(localStorage.getItem('profilePhone'));
    }
    if (localStorage.getItem('profileName') != '') {
      if (localStorage.getItem('profileName') == 'null') {
        this.profileName = '';
      } else {
        this.profileName = JSON.parse(localStorage.getItem('profileName'));
      }
    }
    if (localStorage.getItem('profileEmail') != '') {
      this.profileEmail = JSON.parse(localStorage.getItem('profileEmail'));
    }
    if (localStorage.getItem('profileSubscribeExpiredDate') != '') {
      this.profileSubscribeExpiredDate = JSON.parse(
        localStorage.getItem('profileSubscribeExpiredDate')
      );
    }
  }

  private loadUserData(): void {
    if (
      Math.trunc(this.today / 1000) >=
      Number(localStorage.getItem('updateprofile')) + 259200
    ) {
      this._api
        .userRegister('getUserData', localStorage.getItem('token'), '')
        .pipe(untilDestroyed(this))
        .subscribe(
          (data: CommonResponseGeneral1<IUserData>) => {
            if (!data.is_error) {
              this.profilePhone = data.data.phonenumber;
              this.profileName = data.data.name;
              this.profileEmail = data.data.email;
              this.profileSubscribeExpiredDate = data.data.subscribe;
              localStorage.setItem(
                'profileName',
                JSON.stringify(this.profileName)
              );
              localStorage.setItem(
                'profilePhone',
                JSON.stringify(this.profilePhone)
              );
              localStorage.setItem(
                'profileEmail',
                JSON.stringify(this.profileEmail)
              );
              localStorage.setItem(
                'profileSubscribeExpiredDate',
                JSON.stringify(this.profileSubscribeExpiredDate)
              );
              localStorage.setItem(
                'updateprofile',
                JSON.stringify(Math.trunc(this.today / 1000))
              );
            }
          },
          (error: any) => {
            console.dir(error);
          }
        );
    }
  }

  private delayUpdate(message: string) {
    setTimeout(() => {
      this._modalService.dismissAll();
      this.updateStatus = false;
      this.showPopUpMessage('success', 'Успешно', message);
    }, 3000);
  }
}
