import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../shared/guards/auth.guard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AgGridModule } from 'ag-grid-angular';
import { RemainsComponent } from './remains/remains.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';
import { DbmonthComponent } from './orders-dynamic/dbmonth.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { SelectButtonModule } from 'primeng/selectbutton';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { TableModule } from 'primeng/table';
import { SkeletonModule } from 'primeng/skeleton';
import { DbunitComponent } from './dbunit/dbunit.component';
import { DbstockComponent } from './dbstock/dbstock.component';
import { IndicatorsComponent } from './indicators/indicators.component';
import { ChartsModule } from 'ng2-charts';
import { Indicators2Component } from './indicators2/indicators2.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MyordersComponent } from './myorders/myorders.component';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { MysalesComponent } from './mysales/mysales.component';
import { GeoOrdersComponent } from './geo-orders/geo-orders.component';
import { MapsGeoOrdersModule } from './geo-orders/maps-geo-orders/maps-geo-orders.module';
import { TreeTableModule } from 'primeng/treetable';
import { GeoSalesComponent } from './geo-sales/geo-sales.component';
import { MapsGeoSalesModule } from './geo-sales/maps-geo-sales/maps-geo-sales.module';
import { RatesComponent } from './rates/rates.component';
import { AbcAnalystComponent } from './abc-analyst/abc-analyst.component';
import { HeatMapComponent } from './heat-map/heat-map.component';
import { AllsellersComponent } from './allsellers/allsellers.component';
import { AllsellersdetailsComponent } from './allsellersdetails/allsellersdetails.component';
import { AllbrandsComponent } from './allbrands/allbrands.component';
import { AllbrandsdetailsComponent } from './allbrandsdetails/allbrandsdetails.component';
import { OrdersAggregatorComponent } from './orders-aggregator/orders-aggregator.component';
import { ButtonModule } from 'primeng/button';
import { UiComponentsModule } from '../../shared/ui/components/ui-components.module';
import { SharedUiPipesModule } from '../../shared/ui/pipes/ui-pipes.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MainBetaComponent } from './main-test/main-test.component';
import { UnitPipeModule } from '../../shared/pipes/unit.pipe.module';
import { TooltipModule } from 'primeng/tooltip';
import { ClustersComponent } from './clusters/clusters.component';
import { CheckboxModule } from 'primeng/checkbox';

const routes: Routes = [
  {
    path: 'remains',
    component: RemainsComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'summary',
    component: DbmonthComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'unit',
    component: DbunitComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'stock',
    component: DbstockComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'indicators',
    component: IndicatorsComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'indicators2',
    component: Indicators2Component,
  },
  {
    path: 'orders',
    component: MyordersComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'sales',
    component: MysalesComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'geo/orders',
    component: GeoOrdersComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'geo/sales',
    component: GeoSalesComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'rates',
    component: MainBetaComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'abc',
    component: AbcAnalystComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'heatmap',
    component: HeatMapComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'all/sellers',
    component: AllsellersComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'all/sellers/:id',
    component: AllsellersdetailsComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'all/brends',
    component: AllbrandsComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'all/brends/:id',
    component: AllbrandsdetailsComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'orders-aggregator',
    component: OrdersAggregatorComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'clusters',
    component: ClustersComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    RemainsComponent,
    DbmonthComponent,
    DbunitComponent,
    DbstockComponent,
    IndicatorsComponent,
    Indicators2Component,
    MyordersComponent,
    MysalesComponent,
    GeoOrdersComponent,
    GeoSalesComponent,
    RatesComponent,
    AbcAnalystComponent,
    HeatMapComponent,
    AllsellersComponent,
    AllsellersdetailsComponent,
    AllbrandsComponent,
    AllbrandsdetailsComponent,
    OrdersAggregatorComponent,
    MainBetaComponent,
    ClustersComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    HttpClientModule,
    // AG-GRID
    AgGridModule,
    ProgressSpinnerModule,
    ToastModule,
    MultiSelectModule,
    DropdownModule,
    SelectButtonModule,
    NgxDaterangepickerMd,
    FormsModule,
    TableModule,
    SkeletonModule,
    ChartsModule,
    NgbModule,
    NgApexchartsModule,
    NgbPaginationModule,
    ChartsModule,

    MapsGeoOrdersModule,
    MapsGeoSalesModule,

    TreeTableModule,
    ButtonModule,
    UiComponentsModule,
    SharedUiPipesModule,
    FontAwesomeModule,
    UnitPipeModule,
    TooltipModule,
    CheckboxModule
  ],
  providers: [],
  exports: [],
})
export class DashboardModule {}
