export const converterMapping: Map<string, string> = new Map()
    .set('Бесплатный', 'S')
    .set('Базовый', 'M')
    .set('Расширенный', 'L')
    .set('Профессиональный', 'XL');

 export const converterMappingReverse: Map<string, string> = new Map()
    .set('S', 'Бесплатный')
    .set('M', 'Базовый')
    .set('L', 'Расширенный')
    .set('XL', 'Профессиональный');

export const weightMapping: Map<string, number> = new Map()
    .set('S', 2)
    .set('M', 3)
    .set('L', 4)
    .set('XL', 6);
