import { Component, OnInit, TemplateRef } from '@angular/core';
import { ColDef, ColGroupDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { LOCALE_RU_FILTERS } from '../../finances/finoperations/finoperations.mock';
import { ADVERT_MANAGEMENT_COLUMN_DEFS } from './advert-management.mock';
import { CustomTooltipComponent } from '../../guides/products/components/prdcustomTooltip';
import { Router } from '@angular/router';
import { CELL_CLASS } from '../../guides/products/products.mock';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ApiService } from '../../../shared/services/api.service';
import { AdvertManagementInterface } from './advert-management.interface';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MpSurfApiService } from '../../../shared/services/mpsurf-api.service';
import { PopUpStatus } from '../../../shared/interfaces/common.type';
import { MessageService } from 'primeng/api';
import { PopUpMessages } from '../../../shared/mocks/pop-up-messages.mock';
import { finalize } from 'rxjs/operators';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-advert-management',
  templateUrl: './advert-management.component.html',
  styleUrls: ['./advert-management.component.sass'],
  providers: [MessageService],
})
export class AdvertManagementComponent implements OnInit {
  dataForColumnDefs: (ColDef | ColGroupDef)[] = ADVERT_MANAGEMENT_COLUMN_DEFS;
  defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    tooltipComponent: CustomTooltipComponent,
    cellClass: CELL_CLASS,
    suppressSizeToFit: true,
    floatingFilter: true,
  };
  tooltipShowDelay = 0;
  tooltipHideDelay = 2000;
  gridOptions: any = {};
  localRuFilters = LOCALE_RU_FILTERS;
  isLoadingStatus = false;
  rowData: AdvertManagementInterface[] = [];
  shopId: string;
  CreateCompanyFG: FormGroup;
  cardType: number;
  lastClickTime = 0;
  doubleClickDelay = 300;
  private gridApi: GridApi;

  constructor(
    private router: Router,
    private api: ApiService,
    private mpService: MpSurfApiService,
    private messageService: MessageService,
    private modalService: NgbModal,
    private fb: FormBuilder,
  ) {
    this.CreateCompanyFG = fb.group({
      company_name: new FormControl(null, [Validators.required]),
      company_article: new FormControl(null, [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.shopId = JSON.parse(localStorage.getItem('mpall_shop') || '{}').shop;
    this.httpSetter();
  }

  onGridReady(params?: GridReadyEvent<any>) {
    this.gridApi = params.api;
    this.loadAdvertManagement();
  }

  onRowClicked(event: any) {
    const currentTime = new Date().getTime();
    if (currentTime - this.lastClickTime <= this.doubleClickDelay) {
      this.redirectToAdvertId(event);
    }
    this.lastClickTime = currentTime;
  }

  // LOAD DATA
  loadAdvertManagement(): void {
    this.isLoadingStatus = true;
    this.requestLoadAdvertManagement()
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoadingStatus = false)),
      )
      .subscribe(
        (responseAdvManagement) => {
          this.rowData = responseAdvManagement;
          this.rowData.forEach((x) => {
            x.status_title = this.manageRowData(x.status);
            x.photo_new = x.article_photo;
          });
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
        },
      );
  }

  // MODALS
  openModalCreateCompany(modal: TemplateRef<any>, type: number): void {
    this.cardType = type;
    this.modalService.open(modal, { backdrop: 'static' });
  }

  closeModal(): void {
    this.cardType = 0;
    this.modalService.dismissAll();
  }

  // MANAGE LOGIC
  createCompany() {
    this.httpSetter();
    const formsData = this.CreateCompanyFG.getRawValue();
    this.isLoadingStatus = true;
    const bodyRequest = {
      token: localStorage.getItem('token'),
      shop_id: this.shopId,
      campaignName: formsData.company_name,
      nms: [formsData.company_article],
      type: this.cardType,
    };
    this.requestCreateAdvertCompany(bodyRequest).subscribe(
      () => {
        this.closeModal();
        this.showPopUpMessage('success', PopUpMessages.createSuccessSummary, 'Компания создана!');
      },
      () => {
        this.showPopUpMessage('error', PopUpMessages.createFailedSummary, PopUpMessages.createFailedMessage);
      },
    );
  }

  //   TODO - REMOVE
  httpSetter() {
    if (window.location.protocol.indexOf('https') == 0) {
      var el = document.createElement('meta');
      el.setAttribute('http-equiv', 'Content-Security-Policy');
      el.setAttribute('content', 'upgrade-insecure-requests');
      document.head.append(el);
    }
  }

  private showPopUpMessage(severity: PopUpStatus, summary: string, message: string): void {
    this.messageService.add({
      severity: severity,
      summary: summary,
      detail: message,
    });
  }

  private redirectToAdvertId(event: any): void {
    const URL = this.router.serializeUrl(this.router.createUrlTree([`/adv/advert-management/${event.data.advertId}`]));
    window.open(URL, '_blank');
  }

  // REQUESTS

  private manageRowData(status: number): string {
    let value = '';
    switch (status) {
      case 7:
        value = 'Рекламная компания завершена';
        break;
      case 9:
        value = 'Идут показы';
        break;
      case 11:
        value = 'Рекламная компания на паузе';
        break;
    }
    return value;
  }

  private requestLoadAdvertManagement(): Observable<AdvertManagementInterface[]> {
    return this.mpService.loadAdverts({ token: localStorage.getItem('token'), shop_id: this.shopId }, 'adverts');
  }

  private requestCreateAdvertCompany(body): Observable<any> {
    return this.api.updateAdvertData(body, 'adverts');
  }
}
