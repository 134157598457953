import { DecimalPipe } from "@angular/common";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { actionCellRenderer } from "./stores.func";

const decimalPipe = new DecimalPipe('ru-RU')

export const STORES_COLUMN_DEFS: (ColDef | ColGroupDef)[] = [
    {
        headerName: 'Округ',
        field: 'district',
        width: 200,
        filter: 'agTextColumnFilter',
        editable: false,
    },
    {
        headerName: 'Склад',
        field: 'store',
        width: 200,
        filter: 'agTextColumnFilter',
        editable: false,
    },
    {
        headerName: 'Срок доставки до склада, дней',
        field: 'delivery_days',
        width: 200,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0')
    },
    {
        headerName: 'Срок доставки из того же кластера, дней',
        field: 'delivery_local_days',
        width: 200,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0')
    },
    {
        headerName: 'Срок доставки из другого кластера, дней',
        field: 'delivery_not_local_days',
        width: 200,
        filter: 'agNumberColumnFilter',
        valueFormatter: v => decimalPipe.transform(v.value, '1.0-0')
    },
    {
        headerName: "Изменить",
        minWidth: 150,
        cellRenderer: actionCellRenderer,
        editable: false,
        colId: "action"
    }
];

export const STORES_ROW_DATA = [
    {
        district: 'Центральный',
        store: 'Обухово',
        delivery_days: 4,
        delivery_local_days: 3,
        delivery_not_local_days: 2
    }
]
