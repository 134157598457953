<p-progressSpinner
  *ngIf="isLoadingStatus"
  [style]="{
    position: 'absolute',
    top: '40%',
    left: '50%',
  }"></p-progressSpinner>
<div [ngStyle]="{ display: isLoadingStatus ? 'none' : 'block' }">
  <div class="app-page-title">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div class="page-title-icon">
          <i class="pe-7s-edit icon-gradient bg-mean-fruit"></i>
        </div>
        <div>Юнит-экономика</div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <div class="btn-actions-pane-left">
          <input
            (change)="uploadNmidWithData()"
            [(ngModel)]="selected"
            [alwaysShowCalendars]="true"
            [disabled]="date_dis"
            [isInvalidDate]="DatePickerStatic.isInvalidDate"
            [linkedCalendars]="true"
            [locale]="DatePickerStatic.localParamsRU"
            [maxDate]="DatePickerStatic.maxDate"
            [minDate]="DatePickerStatic.minDate"
            [ranges]="ranges"
            [showClearButton]="false"
            [showCustomRangeLabel]="true"
            ngxDaterangepickerMd
            placeholder="Выберите даты"
            type="text" />
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
      <div style="display: flex; flex-wrap: wrap">
        <div class="btn-actions-pane-left">
          <p-multiSelect
            (onChange)="changedFilterParameter('Nm', $event)"
            (onClear)="clearFilterParameter({ barcode: null })"
            [(ngModel)]="selectedNmid"
            [maxSelectedLabels]="1"
            [options]="nmid"
            [showClear]="true"
            defaultLabel="Артикул"
            emptyMessage="Артикулов не найдено"
            itemSize="30"
            optionLabel="name"
            selectedItemsLabel="Выбрано {0}">
          </p-multiSelect>
          <p-dropdown
            (onChange)="changedFilterParameter('WareHouse', $event)"
            [(ngModel)]="selectedWareHouse"
            [options]="wareHouses"
            [showClear]="true"
            dataKey="1"
            emptyMessage="Складов не найдено"
            label="Выберите склад"
            optionLabel="name"
            placeholder="Склад">
          </p-dropdown>
        </div>
        <div class="btn-actions-pane-left">
          <p-dropdown
            (onChange)="changedFilterParameter('Tax', $event)"
            [(ngModel)]="selectedTaxes"
            [options]="typeOfTaxes"
            [showClear]="true"
            dataKey="1"
            emptyMessage="Список пуст"
            optionLabel="name"
            placeholder="Налоги">
          </p-dropdown>
          <p-dropdown
            (onChange)="changedFilterParameter('TaxPercent', $event)"
            [(ngModel)]="selectedTaxPercent"
            [options]="taxesPercentList"
            [showClear]="true"
            dataKey="1"
            placeholder="% налога">
          </p-dropdown>
        </div>
      </div>
    </div>
  </div>
  <p-toast></p-toast>
  <div class="main-card mb-3 card">
    <div style="width: 100%; height: 75vh">
      <ag-grid-angular
        (gridReady)="onGridReady($event)"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [enableRangeSelection]="true"
        [pagination]="true"
        [rowData]="rowData"
        [rowHeight]="rowHeight"
        overlayNoRowsTemplate="Нет данных для отображения"
        class="ag-theme-alpine"
        style="width: 100%; height: 100%">
      </ag-grid-angular>
    </div>
  </div>
</div>
