import { DecimalPipe } from '@angular/common';
import { NumberSortFunc } from '../../../../shared/utils/functions/number-sort-func';
import { ColDef } from 'ag-grid-community';

const decimalPipe = new DecimalPipe('ru-RU')

export const RECONCILIATION_COLUMNS_DEFS: ColDef[] = [
  {
    headerName: '№ отчета',
    field: 'report_id',
    width: 150,
    pinned: 'left',
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Дата начала',
    field: 'start_date',
    width: 150,
    pinned: 'left',
    filter: 'agDateColumnFilter',
  },
  {
    headerName: 'Дата конца',
    field: 'end_date',
    width: 150,
    pinned: 'left',
    filter: 'agDateColumnFilter',
  },
  {
    headerName: 'Продажа, руб',
    field: 'sales_rub',
    width: 150,
    filter: 'agNumberColumnFilter',
    headerClass: 'header-centered',
    valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
    comparator: NumberSortFunc
  },
  {
    headerName: 'К перечислению за товар, руб',
    field: 'pay_for_good_rub',
    width: 150,
    filter: 'agNumberColumnFilter',
    headerClass: 'header-centered',
    valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
    comparator: NumberSortFunc
  },
  {
    headerName: 'Логистика, руб',
    field: 'logistic',
    width: 150,
    filter: 'agNumberColumnFilter',
    headerClass: 'header-centered',
    valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
    comparator: NumberSortFunc
  },
  {
    headerName: 'Штрафы, руб',
    field: 'penalties_sum',
    width: 150,
    filter: 'agNumberColumnFilter',
    headerClass: 'header-centered',
    valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
    comparator: NumberSortFunc
  },
  {
    headerName: 'Доплаты, руб',
    field: 'allowances',
    width: 150,
    filter: 'agNumberColumnFilter',
    headerClass: 'header-centered',
    valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
    comparator: NumberSortFunc
  },

  {
    headerName: 'Стоимость хранения, руб',
    field: 'storage_cost_rub',
    width: 150,
    filter: 'agNumberColumnFilter',
    headerClass: 'header-centered',
    valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
    comparator: NumberSortFunc
  },
  {
    headerName: 'Стоимость платной приемки, руб',
    field: 'paid_acceptance_cost_rub',
    width: 150,
    filter: 'agNumberColumnFilter',
    headerClass: 'header-centered',
    valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
    comparator: NumberSortFunc
  },

  {
    headerName: 'Прочие удержания/выплаты, руб',
    field: 'other_withholding_rub',
    width: 150,
    filter: 'agNumberColumnFilter',
    headerClass: 'header-centered',
    valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
    comparator: NumberSortFunc
  },
  {
    headerName: 'Итого к оплате, руб',
    field: 'total_payable',
    width: 150,
    filter: 'agNumberColumnFilter',
    headerClass: 'header-centered',
    valueFormatter: v => decimalPipe.transform(v.value, '1.0-0'),
    comparator: NumberSortFunc
  },
];

export function generateColumnDefs(): ColDef[] {
  return RECONCILIATION_COLUMNS_DEFS;
  // const storedDefs = localStorage.getItem('reconciliation-column-defs');

  //   if(!storedDefs) {
  //       return RECONCILIATION_COLUMNS_DEFS
  //   }
  //   const newDefs: ColDef[] = []
  //   const parsedDefs = JSON.parse(storedDefs) as ColDef[]

  //   parsedDefs.forEach(def => newDefs.push(RECONCILIATION_COLUMNS_DEFS.find(d => d.field === def.field)))
  //   return newDefs;
}
