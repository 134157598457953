import { AfterViewInit, Component } from '@angular/core';
import { AbstractMenuTabComponent } from 'app/menu-tabs/abstract-menu-tab.component';
import { ISelectedNgbDate } from '../../../shared/interfaces/common.interface';
import { MessageService, ConfirmationService } from 'primeng/api';
import * as moment from 'moment';
import { CLUSTERS_BY_COUNTRIES_ROW_DATA, GRADES } from './clusters.mock';
import { GridReadyEvent } from 'ag-grid-community';
import { generate_clusters } from './clusters.func';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { forkJoin } from 'rxjs';
import { ICardWidget } from '../main-test/main-test.interface';
import { environment } from 'environments/environment';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-clusters',
  templateUrl: './clusters.component.html',
  styleUrls: ['./clusters.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class ClustersComponent extends AbstractMenuTabComponent implements AfterViewInit {

  selectedDate: ISelectedNgbDate = {
    startDate: moment().subtract(7, 'day'),
    endDate: moment().subtract(1, 'day'),
  };
  cards: ICardWidget[] = []
  countryData = CLUSTERS_BY_COUNTRIES_ROW_DATA
  grades = GRADES
  environment = environment
  
  constructor() {
    super();
  }

  ngAfterViewInit(): void {
    const elems = document.querySelectorAll('.pathComponent')
    elems.forEach(elem => elem.addEventListener('mouseover', () => {
      // console.log(elem.id)
    }))
  }

  onGridReady(params: GridReadyEvent<any>) {
    this.gridApi = params.api;
  }

  dateChanged($event: any) {
    this.selectedDate = $event;
    const params = {
      beginDate: this.selectedDate.startDate.format('YYYY-MM-DD'),
      endDate: this.selectedDate.endDate.format('YYYY-MM-DD'),
    }
    this.loadData(params)
  }

  loadData(params) {
    forkJoin([
      this._mpSurfService.load(params, 'clusters'),
      this._mpSurfService.load(params, 'indicators/cards')
    ])
    .pipe(untilDestroyed(this))
    .subscribe(([clusterData, cardData]) => {
      //@ts-ignore
      this.cards = cardData.splice(0,3)
      //@ts-ignore
      this.gridApi.setRowData(clusterData)
      this.gridApi.setColumnDefs(generate_clusters(clusterData[0]))
      this._cdr.markForCheck()

    })
  }
}