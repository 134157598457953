export const CLUSTERS_BY_COUNTRIES_ROW_DATA = [
  {
    country: 'Россия',
    bought: 12525,
    income: 6547,
    stake: 84,
  },
  {
    country: 'Беларусь',
    bought: 412,
    income: 325,
    stake: 7.5,
  },
];

export const GRADES = [
  { color: '', desc: '> 100 000' },
  { color: '', desc: '90 000 - 100 000' },
  { color: '', desc: '80 000 - 90 000' },
  { color: '', desc: '70 000 - 80 000' },
  { color: '', desc: '60 000 - 70 000' },
  { color: '', desc: '50 000 - 60 000' },
  { color: '', desc: '40 000 - 50 000' },
  { color: '', desc: '30 000 - 40 000' },
  { color: '', desc: '20 000 - 30 000' },
  { color: '', desc: '10 000 - 20 000' },
  { color: '', desc: '< 10 000' },
];
