import { ColDef, ColGroupDef } from 'ag-grid-community';
import { BtnAccountsCellRendererComponent } from './components/btn-accounts-cell-renderer.component';
import { PartnersSpecificCellsComponent } from '../partners/cell-component/partners-specific-cells.component';
import { CommonValueWithIDName } from '../../../../shared/interfaces/common.interface';
import { AccountsSpecificCellsComponent } from './components/accounts-specific-cells.component';
import { ValueGetterForDate } from '../../../../shared/utils/functions/value-getter-for-date';
import { ValueFormatterForDate } from '../../../../shared/utils/functions/value-formatter-for-date';

export const FIN_ACCOUNT_TYPES = [
  { id: 1, name: 'Наличный' },
  { id: 2, name: 'Безналичный' },
  { id: 3, name: 'Карта физ лица' },
  { id: 4, name: 'Дебет' },
  { id: 5, name: 'Кредит / Займ' },
  { id: 6, name: 'Кредитная линия с лимитом' },
];

export const ACCOUNT_TYPES: CommonValueWithIDName[] = [
  // {id: 1, name: 'Наличный'},
  // {id: 2, name: 'Безналичный'},
  // {id: 3, name: 'Карта физ лица'},
  { id: 4, name: 'Дебет' },
  { id: 5, name: 'Кредит / Займ' },
  { id: 6, name: 'Кредитная линия с лимитом' },
];

export const FINACCOUNTS_COLUMN_DEFS: (ColDef | ColGroupDef)[] = [
  {
    headerName: 'Название',
    field: 'name',
    width: 200,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Дата начального остатка',
    field: 'date_balance_init',
    width: 200,
    filter: 'agDateColumnFilter',
    valueGetter: params => ValueGetterForDate(params, 'date_balance_init'),
    valueFormatter: params => ValueFormatterForDate(params),
  },
  {
    headerName: 'Начальный остаток',
    field: 'balance_init',
    width: 200,
    filter: 'agTextColumnFilter',
    cellRenderer: PartnersSpecificCellsComponent,
  },
  {
    headerName: 'Текущий остаток',
    field: 'balance_current',
    width: 200,
    cellRenderer: PartnersSpecificCellsComponent,
  },
  {
    headerName: 'Описание',
    field: 'description',
    width: 200,
    filter: 'agStringColumnFilter',
    cellStyle: {
      display: 'block',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  {
    headerName: 'Действия',
    cellRenderer: BtnAccountsCellRendererComponent,
    autoHeaderHeight: true,
    floatingFilter: false,
    icons: {
      sortAscending: '<i class="fa fa-sort-alpha-up"/>',
      sortDescending: '<i class="fa fa-sort-alpha-down"/>',
    },
    filter: false,
    width: 180,
  },
];
