<p-progressSpinner
  *ngIf="isLoading"
  [style]="{
    position: 'absolute',
    top: '40%',
    left: '50%',
  }"></p-progressSpinner>
<p-toast></p-toast>
<div [ngStyle]="{ display: isLoading ? 'none' : 'block' }">
  <div *ngIf="rowsForRemoving.length > 0" class="button-wrapp-remove">
    <p>Выбрано: {{ rowsForRemoving.length }}</p>
    <button (click)="removeSelected()" class="icon-button" label="">
      <i class="lnr-trash"></i>
      Удалить
    </button>
  </div>
  <div class="main-card mb-3 card" style="height: 65vh">
    <ag-grid-angular
      #agGrid
      (gridReady)="onGridReadyExcel($event)"
      (rowSelected)="onRowSelected($event)"
      [columnDefs]="dataForColumnDefsExcel"
      [defaultColDef]="defaultColDefExcel"
      [enableRangeSelection]="true"
      [gridOptions]="gridOptions"
      [pagination]="true"
      [rowData]="rowDataSubject"
      [rowHeight]="40"
      overlayNoRowsTemplate="Нет данных для отображения"
      class="ag-theme-alpine"
      rowSelection="multiple"
      style="width: 100%; height: 100%">
    </ag-grid-angular>
  </div>
  <div class="button-wrapp">
    <p-toast></p-toast>
    <button
      (click)="discardChanges()"
      class="mb-2 btn btn-warning add-operation-btn"
      type="button">
      Выйти без сохранения
    </button>
    <button
      (click)="saveCurrentChanges()"
      class="mb-2 btn btn-success add-operation-btn"
      type="button">
      Сохранить
    </button>
  </div>
</div>
<ng-template #newAgent let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Добавить контрагента</h4>
    <button
      (click)="d('Cross click')"
      aria-label="Close"
      class="close"
      type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form
    (validSubmit)="createNewAgent()"
    [formGroup]="FormNewAgent"
    class="needs-validation"
    novalidate>
    <p-toast></p-toast>
    <div class="modal-body">
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="shop_lable">Магазин</label>
        <div class="col-sm-9">
          <select
            class="form-control"
            formControlName="shop"
            id="shop_lable"
            name="shop">
            <option *ngFor="let shop of shopsList" [ngValue]="shop.id">
              {{ shop.name }}
            </option>
          </select>
        </div>
        <div
          *ngIf="
            this.FormNewAgent.controls['shop'].invalid &&
            this.FormNewAgent.controls['shop'].touched
          "
          class="alert alert-danger">
          Не корректно заполнено поле
        </div>
      </div>
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="name_label"
          >Наименование<span style="color: red">*</span></label
        >
        <div class="col-sm-9">
          <input
            class="form-control"
            formControlName="name"
            id="name_label"
            name="name"
            placeholder="введите наименование"
            type="text" />
          <div
            *ngIf="
              this.FormNewAgent.controls['name'].invalid &&
              this.FormNewAgent.controls['name'].touched
            "
            class="alert alert-danger">
            Не корректно заполнено поле
          </div>
        </div>
      </div>
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="inn_label">ИНН</label>
        <div class="col-sm-9">
          <input
            class="form-control"
            formControlName="inn"
            id="inn_label"
            name="inn"
            placeholder="ИНН"
            type="text" />
          <div
            *ngIf="
              this.FormNewAgent.controls['inn'].invalid &&
              this.FormNewAgent.controls['inn'].touched
            "
            class="alert alert-danger">
            Введите только цифры
          </div>
        </div>
      </div>
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="kpp_lable">КПП</label>
        <div class="col-sm-9">
          <input
            class="form-control"
            formControlName="kpp"
            id="kpp_lable"
            name="kpp"
            placeholder="КПП"
            type="text" />
          <div
            *ngIf="
              this.FormNewAgent.controls['kpp'].invalid &&
              this.FormNewAgent.controls['kpp'].touched
            "
            class="alert alert-danger">
            Введите только цифры
          </div>
        </div>
      </div>
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="checking_account_lable"
          >Р/С</label
        >
        <div class="col-sm-9">
          <input
            class="form-control"
            formControlName="checking_account"
            id="checking_account_lable"
            name="checking_account"
            placeholder="Расчетный счет"
            type="text" />
          <div
            *ngIf="
              this.FormNewAgent.controls['checking_account'].invalid &&
              this.FormNewAgent.controls['checking_account'].touched
            "
            class="alert alert-danger">
            Не корректно заполнено поле
          </div>
        </div>
      </div>

      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="checking_account_lable"
          >Доходная статья</label
        >
        <div class="col-sm-9" style="margin-top: 4px">
          <p-dropdown
            [filter]="true"
            [options]="itemsIncomeList"
            [showClear]="true"
            [style]="{ display: 'flex', alignItems: 'center', height: '34px' }"
            formControlName="income_item"
            optionLabel="name"
            optionValue="id"
            placeholder="Нераспределенный доход"></p-dropdown>
        </div>
      </div>
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="checking_account_lable"
          >Расходная статья</label
        >
        <div class="col-sm-9" style="margin-top: 4px">
          <p-dropdown
            [filter]="true"
            [options]="itemsExpensesList"
            [showClear]="true"
            [style]="{ display: 'flex', alignItems: 'center', height: '34px' }"
            formControlName="expenses_item"
            optionLabel="name"
            optionValue="id"
            placeholder="Нераспределенный расход"></p-dropdown>
        </div>
      </div>

      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="description_l"
          >Описание</label
        >
        <div class="col-sm-9">
          <textarea
            class="form-control"
            formControlName="description"
            id="description_l"
            name="description"></textarea>
          <div
            *ngIf="
              this.FormNewAgent.controls['description'].invalid &&
              this.FormNewAgent.controls['description'].touched
            "
            class="alert alert-danger">
            Не корректно заполнено поле
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        (click)="c('Close click')"
        class="btn btn-secondary"
        type="button">
        Закрыть
      </button>
      <button
        [disabled]="FormNewAgent.invalid || FormNewAgent.disabled"
        class="btn btn-primary"
        type="submit">
        Добавить
      </button>
    </div>
  </form>
</ng-template>
<ng-template #newAccount let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Добавление счета</h4>
    <button
      (click)="d('Cross click')"
      aria-label="Close"
      class="close"
      type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form
    (validSubmit)="createNewAccount()"
    [formGroup]="FormNewAccount"
    class="needs-validation"
    novalidate>
    <div class="modal-body">
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="name_label"
          >Название<span style="color: red">*</span></label
        >
        <div class="col-sm-9">
          <input
            class="form-control"
            formControlName="name"
            id="name_label"
            name="name"
            placeholder="введите наименование"
            type="text" />
          <div
            *ngIf="
              FormNewAccount.controls['name'].invalid &&
              FormNewAccount.controls['name'].touched
            "
            class="alert alert-danger">
            Не корректно заполнено поле
          </div>
        </div>
      </div>
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="type_lable"
          >Тип счета<span style="color: red">*</span></label
        >
        <div class="col-sm-9">
          <select
            (change)="autoSetupCredentials($event.target.value)"
            class="form-control"
            formControlName="type"
            id="type_lable"
            name="type">
            <option *ngFor="let pf of accountTypes" [ngValue]="pf.id">
              {{ pf.name }}
            </option>
          </select>

          <div
            *ngIf="
              FormNewAccount.controls['type'].invalid &&
              FormNewAccount.controls['type'].touched
            "
            class="alert alert-danger">
            Не корректно заполнено поле
          </div>
        </div>
      </div>
      <span
        (click)="isShowBankCredentials = !isShowBankCredentials"
        *ngIf="isShowCredentialsTitle"
        class="credentials-header"
        >Реквизиты</span
      >
      <ng-container *ngIf="isShowBankCredentials">
        <div class="position-relative row form-group">
          <label class="col-sm-3 col-form-label" for="name_label">Бик</label>
          <div class="col-sm-9">
            <input
              class="form-control"
              formControlName="account_bik"
              id="name_label"
              name="name"
              placeholder="Введите Бик"
              type="text" />
          </div>
        </div>
        <div class="position-relative row form-group">
          <label class="col-sm-3 col-form-label" for="name_label">Банк</label>
          <div class="col-sm-9">
            <input
              class="form-control"
              formControlName="account_bank"
              id="name_label"
              name="name"
              placeholder="Введите Банк"
              type="text" />
          </div>
        </div>
        <div class="position-relative row form-group">
          <label class="col-sm-3 col-form-label" for="name_label"
            >Расч. счет №</label
          >
          <div class="col-sm-9">
            <input
              class="form-control"
              formControlName="account_settlement"
              id="name_label"
              name="name"
              placeholder="Введите расчетный счет"
              type="text" />
          </div>
        </div>
        <div class="position-relative row form-group">
          <label class="col-sm-3 col-form-label" for="name_label"
            >Кор. счет №</label
          >
          <div class="col-sm-9">
            <input
              class="form-control"
              formControlName="account_corrective"
              id="name_label"
              name="name"
              placeholder="Введите корректирующий счет"
              type="text" />
          </div>
        </div>
      </ng-container>
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="balance_label"
          >Начальный остаток<span style="color: red">*</span></label
        >
        <div class="col-sm-9" style="margin-top: 10px">
          <div class="column-date-part" style="display: flex">
            <input
              class="form-control"
              formControlName="balance_init"
              id="balance_label"
              name="balance_init"
              placeholder="Начальный остаток"
              type="text" />
            <input
              #d1="ngbDatepicker"
              (click)="d1.toggle()"
              autocomplete="off"
              class="form-control"
              formControlName="date_balance_init"
              id="date_balance_init"
              name="dp1"
              ngbDatepicker
              placeholder="DD.MM.YYYY" />
          </div>
          <div
            *ngIf="
              FormNewAccount.controls['balance_init'].invalid &&
              FormNewAccount.controls['balance_init'].touched
            "
            class="alert alert-danger">
            Ошибка заполнения. пример: 2500.15
          </div>
        </div>
      </div>
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="description_l"
          >Описание</label
        >
        <div class="col-sm-9">
          <textarea
            class="form-control"
            formControlName="description"
            id="description_l"
            name="description"></textarea>
          <div
            *ngIf="
              FormNewAccount.controls['description'].invalid &&
              FormNewAccount.controls['description'].touched
            "
            class="alert alert-danger">
            Не корректно заполнено поле
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          (click)="c('Close click')"
          class="btn btn-secondary"
          type="button">
          Закрыть
        </button>
        <button
          [disabled]="FormNewAccount.invalid || FormNewAccount.disabled"
          class="btn btn-primary"
          type="submit">
          Добавить
        </button>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #newAccount_v2>
  <app-header-modal
    (closeEmitter)="closeModal()"
    [modalTitle]="'Добавить счета'" />
  <form
    [formGroup]="FormNewAccount"
    autocomplete="off"
    class="needs-validation"
    novalidate>
    <div class="modal-body">
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="name_label1">
          Название
          <span style="color: red">*</span>
        </label>
        <div class="col-sm-9">
          <input
            class="form-control"
            formControlName="name"
            id="name_label1"
            name="name"
            placeholder="Введите название"
            type="text" />
        </div>
      </div>

      <div *ngIf="creditLoanSelected" class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="name_label1">
          Доступные средства
          <span style="color: red">*</span>
        </label>
        <div class="col-sm-9 mt-2">
          <input
            class="form-control"
            formControlName="credit_limit"
            name="name"
            placeholder="Введите сумму доступных средств"
            type="number" />
        </div>
      </div>
      <div *ngIf="creditLimitSelected" class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="name_label1">
          Кредитный лимит
          <span style="color: red">*</span>
        </label>
        <div class="col-sm-9 mt-2">
          <input
            class="form-control"
            formControlName="credit_limit"
            name="name"
            placeholder="Введите кредитный лимит"
            type="text" />
        </div>
      </div>

      <div *ngIf="creditLoanSelected" class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="balance_label">
          Основной долг
          <span style="color: red">*</span>
        </label>
        <div class="col-sm-9" style="margin-top: 10px">
          <div class="column-date-part" style="display: flex">
            <input
              class="form-control"
              formControlName="balance_init"
              name="balance_init"
              placeholder="Основной долг"
              type="text" />
            <input
              #d1="ngbDatepicker"
              (click)="d1.toggle()"
              autocomplete="off"
              class="form-control"
              formControlName="date_balance_init"
              id="date_balance_init"
              name="dp1"
              ngbDatepicker
              placeholder="DD.MM.YYYY" />
          </div>
        </div>
      </div>

      <div *ngIf="!creditLoanSelected" class="position-relative row form-group">
        <label class="col-sm-3 col-form-label">
          Начальный остаток
          <span style="color: red">*</span>
        </label>
        <div class="col-sm-9" style="margin-top: 10px">
          <div class="column-date-part" style="display: flex">
            <p-inputNumber
              [maxlength]="15"
              [style]="{ width: '200', height: '37px', fontSize: '12px' }"
              formControlName="balance_init"
              mode="decimal"
              locale="ru-RU"
              [minFractionDigits]="2" />
            <input
              #d1="ngbDatepicker"
              (click)="d1.toggle()"
              autocomplete="off"
              readonly
              class="form-control"
              formControlName="date_balance_init"
              name="dp1"
              ngbDatepicker
              placeholder="DD.MM.YYYY" />
          </div>
        </div>
      </div>
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="description_l">
          Описание
        </label>
        <div class="col-sm-9">
          <textarea
            class="form-control"
            formControlName="description"
            name="description">
          </textarea>
        </div>
      </div>
    </div>

    <app-footer-modal
      (submitEmitter)="createNewAccount()"
      [isCreate]="isCreate" />
  </form>
  <p-toast />
</ng-template>

<ng-template #newAgent_v2>
  <app-header-modal
    (closeEmitter)="closeModal()"
    [modalTitle]="'Добавить контрагента'">
  </app-header-modal>
  <form
    [formGroup]="FormNewAgent"
    class="needs-validation"
    autocomplete="off"
    novalidate>
    <div class="modal-body">
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="shop_lable">Юр. лицо</label>
        <div class="col-sm-9">
          <select
            class="form-control"
            formControlName="shop"
            id="shop_lable"
            name="shop">
            <option *ngFor="let shop of shopsList" [ngValue]="shop.id">
              {{ shop.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="name_label"
          >Наименование<span style="color: red">*</span></label
        >
        <div class="col-sm-9">
          <input
            class="form-control"
            formControlName="name"
            id="name_label"
            name="name"
            placeholder="Введите наименование"
            type="text" />
        </div>
      </div>
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="inn_label">ИНН</label>
        <div class="col-sm-9">
          <input
            class="form-control"
            formControlName="inn"
            id="inn_label"
            name="inn"
            placeholder="ИНН"
            type="text" />
        </div>
      </div>
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="kpp_lable">КПП</label>
        <div class="col-sm-9">
          <input
            class="form-control"
            formControlName="kpp"
            id="kpp_lable"
            name="kpp"
            placeholder="КПП"
            type="text" />
        </div>
      </div>
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="checking_account_lable"
          >Р/С</label
        >
        <div class="col-sm-9">
          <input
            class="form-control"
            formControlName="checking_account"
            id="checking_account_lable"
            name="checking_account"
            placeholder="Расчетный счет"
            type="text" />
        </div>
      </div>
      <div class="position-relative row form-group">
        <label class="col-sm-3 col-form-label" for="description"
          >Описание</label
        >
        <div class="col-sm-9">
          <textarea
            class="form-control"
            formControlName="description"
            id="description"
            name="description">
          </textarea>
        </div>
      </div>
    </div>
    <app-footer-modal
      (submitEmitter)="createNewAgent()"
      [isCreate]="isCreate"
      [isDisabled]="FormNewAgent.invalid" />
  </form>
  <p-toast />
</ng-template>
