import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from './api.service';
import { LOCAL_STORAGE_KEYS } from 'app/shared/contants/localStorage';
import { IStatisticAdvertTables } from 'app/menu-tabs/advert/advert-management/advert-management-card/advert-management-card.interface';

export type SharedTitle = 'articles' | 'notifications';

@Injectable({
  providedIn: 'root',
})
export class SharedDataService {
  issuesWithIncomeAndOutcomeArticles: BehaviorSubject<any[]> =
    new BehaviorSubject<any>(null);

  notifications$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  private advertManagementCashedSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
  private loadedSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  constructor(private api: ApiService) {}

  setUpData(data: any[], parameter: SharedTitle) {
    if (parameter === 'articles') {
      this.issuesWithIncomeAndOutcomeArticles.next(data);
    }

    if (parameter === 'notifications') {
      this.notifications$.next(data);
    }
  }

  getData(parameter: SharedTitle): Observable<any[]> {
    if (parameter === 'articles') {
      return this.issuesWithIncomeAndOutcomeArticles.asObservable();
    }

    if (parameter === 'notifications') {
      const notifications = localStorage.getItem(
        LOCAL_STORAGE_KEYS.META_DATA_NOTIFICATIONS
      );
      if (notifications) {
        this.notifications$.next(JSON.parse(notifications));
      }

      return this.notifications$.asObservable();
    }
  }

  loadAdvertManagementData(shopId: number, cardId: number): Observable<any> {
    if (
      !this.loadedSubject.value &&
      !this.advertManagementCashedSubject.value
    ) {
      this.loadedSubject.next(true);
      this.advertManagementCashedSubject.next([]);
      const bodyRequest = {
        token: localStorage.getItem('token'),
        shop_id: shopId,
        id: cardId,
      };
      this.api
        .getAdvertData<IStatisticAdvertTables>(
          bodyRequest,
          'statistics/optionally'
        )
        .subscribe(response => {
          this.advertManagementCashedSubject.next(response);
          return this.advertManagementCashedSubject.asObservable();
        });
    }
    return this.advertManagementCashedSubject.asObservable();
  }
}
