import { Component, inject, OnInit, TemplateRef } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { MpSurfApiService } from '../shared/services/mpsurf-api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Nullable, PopUpStatus } from '../shared/interfaces/common.type';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-abstract-general-tab',
  template: '',
  providers: [MessageService, ConfirmationService],
})
export abstract class AbstractGeneralTabComponent implements OnInit {
  isLoading = false;
  isEditModal = false;
  protected readonly _router = inject(Router);
  protected readonly _route = inject(ActivatedRoute);
  protected readonly _mpSurfService = inject(MpSurfApiService);
  protected readonly _messageService = inject(MessageService);
  protected readonly _confirmationService = inject(ConfirmationService);
  protected readonly _modalService = inject(NgbModal);
  protected SHOP_ID: Nullable<number> = null;

  protected constructor() {
    this.initShopID();
  }

  ngOnInit() {}

  protected initShopID(): void {
    this.SHOP_ID = JSON.parse(localStorage.getItem('mpall_shop'))?.shop;
  }

  openModal(modalContent: TemplateRef<any>, size = 'md'): void {
    this._modalService.open(modalContent, { size });
  }

  closeModal() {
    this._modalService.dismissAll();
  }

  protected showPopUpMessage(
    severity: PopUpStatus,
    summary: string,
    message: string
  ): void {
    this.isLoading = false;
    this._messageService.add({
      severity: severity,
      summary: summary,
      detail: message,
    });
  }
}
