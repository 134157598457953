import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AbstractMenuTabComponent } from '../../../menu-tabs/abstract-menu-tab.component';
import { ISelectedNgbDate } from '../../../shared/interfaces/common.interface';
import * as moment from 'moment';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ADV_COLUMN_DEFAULT_DEF } from './advcompany.mock';
import { DragStoppedEvent, GridReadyEvent } from 'ag-grid-community';
import { TAXES_PERCENTS_LIST } from '../../../menu-tabs/dashboard/dbunit/dbunit.mock';
import { genarateColumnDefs } from './advcompany.func';
import { IArticlesPurchase } from '../../../menu-tabs/guides/finpurchase/finpurchase.interface';
import { PopUpMessages } from '../../../shared/mocks/pop-up-messages.mock';
import { MpSurfApiService } from '../../../shared/services/mpsurf-api.service';
import { untilDestroyed } from '@ngneat/until-destroy';
import { IAdvStatRow } from './advcompany.interface';
import { forkJoin, of } from 'rxjs';
import { ICardWidget } from '../../../menu-tabs/dashboard/main-test/main-test.interface';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-advcompany',
  templateUrl: './advcompany.component.html',
  styleUrls: ['./advcompany.component.scss'],
  providers: [MessageService, ConfirmationService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdvcompanyComponent extends AbstractMenuTabComponent implements OnInit {

  selectedDate: ISelectedNgbDate = {
    startDate: moment().subtract(7, 'day'),
    endDate: moment().subtract(1, 'day'),
  };

  cards = []
  columnDefs = null
  defaultDef = ADV_COLUMN_DEFAULT_DEF
  rowData = []
  articles = []
  taxesPercentList = TAXES_PERCENTS_LIST;
  selectedPercent: string | null = null
  threshold = 15
  selectedNm = []
  dateParams = null

  text = ''
  subtext = ''

  constructor(private mpSurfApi: MpSurfApiService) {
    super();
  }

  ngOnInit(): void {
    this.loadArticles()
    this._mpSurfService
      .loadMetaData(this.SHOP_ID, this.TOKEN)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        //@ts-ignore
        const { die_text, die_title } = res.data.tutorialVideoLink.find(item => item.pageTitle === 'adv/company') || { die_text: null, die_title: null }
        this.text = die_title
        this.subtext = die_text
      })
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setColumnDefs(genarateColumnDefs(this.threshold))
  }

  dateChanged($event: any) {
    this.selectedDate = $event;
    this.dateParams = {
      beginDate: this.selectedDate.startDate.format('YYYY-MM-DD'),
      endDate: this.selectedDate.endDate.format('YYYY-MM-DD'),
    }
    this.loadData({...this.dateParams, nmId: this.selectedNm})
  }

  onDrrPercentChange(value): void {
    this.gridApi.setColumnDefs(genarateColumnDefs(value))
  }

  onChange(event: { value: {name: string, code: string}[]}) {
    console.log(this.selectedNm)
    this.loadData({...this.dateParams, articles: Array.isArray(this.selectedNm) ?  this.selectedNm.map(item => +item.code) : [],})
  }

  onClear() {
    this.selectedNm = []
    this.loadData({...this.dateParams, nmId: this.selectedNm})
  }

  loadArticles(): void {
    this.mpSurfApi
      .load({}, 'products/important')
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: IArticlesPurchase[]) => {
          this.articles = response.map((a) => {
            return {
              name: `${a.nmid} - ${a.SKU}`,
              code: a.nmid,
            };
          });
          this._cdr.markForCheck()
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }

  loadData(params: object): void {
    forkJoin([
      this.mpSurfApi.loadByPost(params, 'adverts/adv-stat'),
      this.mpSurfApi.loadByPost(params, 'adverts/cards')
    ])
    .pipe(untilDestroyed(this), catchError(() => of([{data: []},[]])))
    .subscribe(
      ([rows, cards]: [{data:IAdvStatRow[]},ICardWidget[]]) => {
        this.rowData = rows.data
        this.cards = cards
        this._cdr.markForCheck()
      })
  }

  onDragStopped(event: DragStoppedEvent) {
    // localStorage.setItem('adv-column-defs', JSON.stringify(event.api.getColumnDefs()))
  }
}
