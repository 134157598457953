import { DecimalPipe } from '@angular/common';
import { ApexOptions } from 'ng-apexcharts';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { ORDERS_TABLE_COLUMN_DEFS } from './myorders.mock';
import { UnitPipe } from '../../../shared/pipes/unit.pipe';
import { IChartPoint, IMyOrdersRowData } from './myorders.interface';

const decimalPipe = new DecimalPipe('ru-RU');
const unitPipe = new UnitPipe();

export function getChartOptions(data: {
  chart: IChartPoint[];
}): Partial<ApexOptions> {
  return {
    series: [
      {
        name: 'Заказы (шт)',
        type: 'column',
        data: data.chart.map(elem => +elem.orders_count || 0),
        color: '#74db90',
      },
      {
        name: 'Заказы (руб)',
        type: 'line',
        data: data.chart.map(elem => +elem.orders_sum || 0),
        color: 'rgba(9, 60, 143, 1)',
      },
      {
        name: 'Отмены (шт)',
        type: 'column',
        data: data.chart.map(elem => +elem.cancel_count || 0),
        color: '#e8b0c6',
      },
      {
        name: 'Отмены (руб)',
        type: 'line',
        data: data.chart.map(elem => +elem.cancel_sum || 0),
        color: 'rgba(186, 20, 20, 1)',
      },
    ],
    chart: {
      height: 480,
      type: 'area',
      stacked: false,
      animations: {
        enabled: true,
      },
    },
    dataLabels: { enabled: false },
    //@ts-ignore
    stroke: { curve: 'smooth', width: [2, 3, 2] },
    legend: { horizontalAlign: 'left', offsetX: 40 },
    xaxis: {
      type: 'category',
      categories: data.chart.map(elem => elem.gr_date),
    },
    yaxis: [
      {
        seriesName: 'Заказы (руб)',
        labels: {
          formatter: (v: number) => decimalPipe.transform(v, '1.0-0'),
        },
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
        },
        title: {
          text: 'Рубли',
          style: {
            color: '#000000',
          },
        },
        min: 0,
      },
      {
        seriesName: 'Отмены (шт)',
        opposite: true,
        labels: {},
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
        },
        title: {
          text: 'Штуки',
          style: {
            color: '#000000',
          },
        },
        min: 0,
      },
      {
        seriesName: 'Заказы (руб)',
        labels: {
          formatter: (v: number) => decimalPipe.transform(v, '1.0-0'),
        },
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
        },
        title: {
          text: 'Рубли',
          style: {
            color: '#000000',
          },
        },
        min: 0,
      },
      {
        seriesName: 'Отмены (шт)',
        opposite: true,
        labels: {},
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
        },
        title: {
          text: 'Штуки',
          style: {
            color: '#000000',
          },
        },
        min: 0,
      },
    ],
  };
}


export function generateColumnDefsByDate(rowData: IMyOrdersRowData | null, showInRubles = false): (ColDef | ColGroupDef)[] {
  if(!rowData) return []
  const dates: (ColDef | ColGroupDef)[] = Object.entries(rowData)
  .filter(([key, value]) => key.match(/\d\d.\d\d/))
  .map(([key, value]) => {
    return  {
      field: key,
      headerName: key,
      width: 120,
      type: 'rightAligned',
      cellStyle: {
        textAlign: 'right',
      },
      cellRenderer: (params) => {
        if(params.data[key]) {
          return showInRubles 
          ? `${unitPipe.transform(decimalPipe.transform(params.data[key].order_sum, '1.0-1'), 'rubles')}`
          : `${unitPipe.transform(params.data[key].order_count, 'item')}`
        }
      },
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        const a = nodeA.data[key]
        const b = nodeB.data[key]
        if(showInRubles) {
          return Number(a.order_sum) - Number(b.order_sum)
        }
        return Number(a.order_count) - Number(b.order_count)
      },
    }
  })

  const totalColumn: ColDef = {
    field: 'total',
    headerName: 'Итого',
    width: 120,
    type: 'rightAligned',
    cellStyle: {
      textAlign: 'right',
      fontWeight: '700'
    },
    sortable: true,
    cellRenderer: (params) => {
      return showInRubles 
      ? `${unitPipe.transform(decimalPipe.transform(getDataForTotal(params.data).sum, '1.0-1'), 'rubles')}`
      : `${unitPipe.transform(getDataForTotal(params.data).count, 'item')}`
    },
    comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
      let a = 0;
      let b = 0;
      if(showInRubles) {
        a = Object.entries(nodeA.data).filter(([key, value]) => key.match(/\d\d.\d\d/))
        .map(([key, value]: [string, {order_sum: string}]) => +value.order_sum).reduce((acc, curr) => acc + curr, 0)
        b = Object.entries(nodeB.data).filter(([key, value]) => key.match(/\d\d.\d\d/))
        .map(([key, value]: [string, {order_sum: string}]) => +value.order_sum).reduce((acc, curr) => acc + curr, 0)
      } else {
        a = Object.entries(nodeA.data).filter(([key, value]) => key.match(/\d\d.\d\d/))
        .map(([key, value]: [string, {order_count: string}]) => +value.order_count).reduce((acc, curr) => acc + curr, 0)
        b = Object.entries(nodeB.data).filter(([key, value]) => key.match(/\d\d.\d\d/))
        .map(([key, value]: [string, {order_count: string}]) => +value.order_count).reduce((acc, curr) => acc + curr, 0)
      } 
      return Number(a) - Number(b)
    },
  }

  return [
    ...ORDERS_TABLE_COLUMN_DEFS.slice(0, 5),
    totalColumn,
    ...dates
  ]
}

export function generateSummary(inputRows: IMyOrdersRowData[] | null, type: string, columnDefs: (ColDef)[] ):Partial<IMyOrdersRowData>[]  {
  if(type === "1") {
    const rows = Array.isArray(inputRows) ? inputRows : []
    return [
      {
        photo: 'Итого',
        nmID: null,
        subject: null,
        barcode: null,
        supplierArticle: null,
        order_date: null,
        cancel_date: null,
        seller_sum: rows.map(row => +row.seller_sum).reduce((prev, curr) => prev + curr, 0),
        mp_sum: rows.map(row => +row.mp_sum).reduce((prev, curr) => prev + curr, 0),
        spp: null,
        forPay: rows.map(row => +row.forPay).reduce((prev, curr) => prev + curr, 0),
        warehouseName: null,
        regionName: null,
        delivery_type: null,
        orderType: null,
        srid: null,
        self_buyouts: null
      },
    ]
  }

  const rows = Array.isArray(inputRows) && !!inputRows.length ? inputRows : []

  const agg = {};

  if(rows?.length) {
    Object.entries(rows[0])
    .filter(([key, value]) => key.match(/\d\d.\d\d/))
    .map(([key, _value]) => {
      agg[key] = rows.map(row => row[key]).reduce((acc, item) => {
        return {
          order_count: Number(acc.order_count) + Number(item.order_count),
          order_sum: Number(acc.order_sum) + Number(item.order_sum),
        }
      }, {order_count: 0, order_sum: 0})
    })
    return [
      {
        photo: 'Итого',
        nmID: null,
        subject: null,
        barcode: null,
        supplierArticle: null,
        total: null,
        ...agg
      },
    ]
  } else {
    columnDefs.map(def => def.field).filter((fieldName) => fieldName.match(/\d\d.\d\d/)).forEach(name => agg[name] = { order_count: 0, order_sum: 0  })
    return [
      {
        photo: 'Итого',
        nmID: null,
        subject: null,
        barcode: null,
        supplierArticle: null,
        total: null,
        ...agg
      },
    ]
  }
}

export function getDataForTotal(row: IMyOrdersRowData): { sum: number, count: number } {
  return Object.entries(row)
    .filter(([key, _value]) => key.match(/\d\d.\d\d/))
    .reduce((acc, [_key, value]) => {
        return {
          count: Number(acc.count) + Number(value.order_count),
          sum: Number(acc.sum) + Number(value.order_sum),
        }
      }, {count: 0, sum: 0})
}