export const LOCAL_STORAGE_KEYS = {
  IS_TEST: 'is_test',
  IS_ADMIN: 'is_admin',
  TOKEN: 'token',
  METADATA_VIDEO_LINKS: 'mpMetaDataVideoLinks',
  META_DATA_LAST_UPDATE_TIME: 'mpMetaDatalastUpdateTime',
  META_DATA_NOTIFICATIONS: 'mpMetaDataNotifications',

  PROFILE_DATA_SUBSCRIBE: 'profileDateSubscribe',
  PROFILE_PHONE_NUMBER: 'profilePhoneNumber',
};
