import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { INotificationResponseData } from './notification-dots.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SharedDataService } from 'app/shared/services/shared-data.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-notification-dots',
  templateUrl: './notification-dots.component.html',
  styleUrls: ['./notification-dots.component.scss'],
})
export class NotificationDotsComponent implements OnInit, OnDestroy {
  notificationMessages: INotificationResponseData[] = [];

  displayNewNotifications = false;

  newNotificationId: number;

  oldNotificationId: number;

  clickedCount = 0;

  protected readonly _sharedDataService = inject(SharedDataService);

  ngOnInit(): void {
    this.setUpNotificationService();
  }

  ngOnDestroy(): void {
    this.notificationMessages = [];
  }

  private setUpNotificationService() {
    this.notificationMessages = [];
    this._sharedDataService
      .getData('notifications')
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response: INotificationResponseData[]) => {
          if (response) {
            this.handleNotificationMessages(response);
          }
        },
      });
  }

  notificationClicked() {
    if (this.clickedCount > 1) {
      this.oldNotificationId = this.newNotificationId;
      localStorage.setItem('notification-id', String(this.newNotificationId));
    } else {
      this.displayNewNotifications = false;
      localStorage.setItem('notification-id', String(this.newNotificationId));
    }
    this.clickedCount++;
  }

  private handleNotificationMessages(
    notification: INotificationResponseData[]
  ): void {
    this.notificationMessages = notification;

    this.oldNotificationId = +localStorage.getItem('notification-id');

    if (this.notificationMessages[this.notificationMessages.length - 1]) {
      this.newNotificationId = this.notificationMessages[
        this.notificationMessages.length - 1
      ].id as number;
      this.displayNewNotifications =
        this.newNotificationId > this.oldNotificationId;
    }
  }
}
