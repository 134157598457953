<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <i class="lnr-warning icon-gradient bg-mean-fruit"></i>
      </div>
      <div>
        ДЕМО РЕЖИМ<!--{{control_id}}-->
        <!--<div class="page-title-subheading" *ngIf="control_id">
          <a [routerLink]="['/my/control/']">к списку поставок</a>
        </div>-->
      </div>

    </div>
    <!--<div class="page-title-actions">
      <button type="button" class="btn-shadow mr-3 btn btn-dark">
        <fa-icon [icon]="faStar"></fa-icon>
      </button>
      <button type="button" class="btn-shadow d-inline-flex align-items-center btn btn-success">
        <fa-icon [icon]="faPlus" class="mr-2"></fa-icon>
        Create New
      </button>
    </div>-->

  </div>
</div>

<div class="main-card mb-3 card">

  <div class="card-header">
    Cводка по продавцам за&nbsp;<div class="btn-actions-pane-left">
    <input (change)="newValue()"
           [(ngModel)]="selected"

           [alwaysShowCalendars]="true"
           [isInvalidDate]="isInvalidDate"
           [linkedCalendars]="true"
           [locale]="{
                        applyLabel: 'применить',
                        format: 'DD.MM.YYYY',
                        'daysOfWeek': ['Вс','Пн','Вт','Ср','Чт','Пт','Сб'],
                        'customRangeLabel': 'Свой диапазон',
                        'monthNames': ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь']}"
           [maxDate]='maxDate'
           [minDate]='minDate'
           [ranges]="ranges"
           [showClearButton]="false"
           [showCustomRangeLabel]="true"
           ngxDaterangepickerMd
           placeholder="Выберите даты"
           type="text"/>

    <!--<div class="loader-wrapper d-flex justify-content-center align-items-center" *ngIf="isLoadingResults">
      <div class="loader">
        <div class="pacman">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>-->
  </div>

  </div>


  <div *ngIf="!loading" style="width: 100%;height: 80vh;">
    <ag-grid-angular
      (gridReady)="onGridReady($event)"
      [cacheBlockSize]="cacheBlockSize"

      [cacheOverflowSize]="cacheOverflowSize"
      [columnDefs]="columnDefsSales"
      [infiniteInitialRowCount]="infiniteInitialRowCount"
      [loadingCellRendererParams]="loadingCellRendererParams"
      [loadingCellRenderer]="loadingCellRenderer"
      [localeText]="localeText"

      [maxBlocksInCache]="maxBlocksInCache"
      [maxConcurrentDatasourceRequests]="maxConcurrentDatasourceRequests"

      [paginationPageSize]="paginationPageSize"
      overlayNoRowsTemplate="Нет данных для отображения"

      [pagination]="true"
      [rowData]="rowData"
      [rowHeight]="rowHeight"
      [rowModelType]="rowModelType"
      [rowSelection]="rowSelection"
      class="ag-theme-alpine"
      style="width: 100%; height: 100%;"
    >
    </ag-grid-angular>
  </div>


  <!--<div class="example-loading-shade"
         *ngIf="isLoadingResults || isRateLimitReached">
      <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
      <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">

      </div>
    </div>-->

  <!--<div class="example-container mat-elevation-z8">


  <div class="table-responsive">
      <table style="width:100%;" mat-table [dataSource]="data" class="example-table" matSort matSortActive="seller" matSortDisableClear matSortDirection="desc">
      <table style="width:100%;" mat-table [dataSource]="data" class="align-middle mb-0 table table-borderless table-striped table-hover" matSort matSortActive="cash" matSortDisableClear matSortDirection="desc">



        <ng-container matColumnDef="brands">
          <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Бренд</th>
          <td style="width:300px;" mat-cell *matCellDef="let row"><a href="/all/brands/{{row.brands}}?d1={{selected.startDate | date: 'yyyy-MM-dd'}}&d2={{selected.endDate | date: 'yyyy-MM-dd'}}">{{row.brands}}</a></td>
        </ng-container>

        <ng-container matColumnDef="logo">
          <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Логотип</th>
          <td style="width:300px;" mat-cell *matCellDef="let row"></td>
        </ng-container>

        <ng-container matColumnDef="items">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Товаров</th>
          <td mat-cell *matCellDef="let row">{{row.items}}</td>
        </ng-container>

        <ng-container matColumnDef="rating">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Рейтинг</th>
          <td mat-cell *matCellDef="let row">{{row.rating}}</td>
        </ng-container>

        <ng-container matColumnDef="average_check">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Средний чек</th>
          <td mat-cell *matCellDef="let row">{{row.average_check| number: '1.2-2'}}</td>
        </ng-container>

        <ng-container matColumnDef="sales">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Продаж</th>
          <td mat-cell *matCellDef="let row">{{row.sales| number: '1.0-0'}}</td>
        </ng-container>

        <ng-container matColumnDef="cash">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Оборот</th>
          <td mat-cell *matCellDef="let row">{{row.cash | number: '1.0-0'}}</td>
        </ng-container>


        <ng-container matColumnDef="created">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            Created
          </th>
          <td mat-cell *matCellDef="let row">{{row.created_at | date}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>


    <mat-paginator [length]="resultsLength" [pageSize]="30"></mat-paginator>
    <mat-paginator [length]="resultsLength" [pageSize]="25" [pageSizeOptions]="[5, 10, 25, 50, 100]"></mat-paginator>
  </div>
  </div>-->
</div>
