<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <section class="page-title-heading__left">
        <div class="page-title-icon">
          <i class="pe-7s-look icon-gradient bg-mean-fruit"></i>
        </div>
        <div
          style="
            margin-right: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 6px;
          ">
          Сверка с личным кабинетом МП
          <i
            class="fa fa-question-circle"
            aria-hidden="true"
            pTooltip="Сверка с личным кабинетом МП"></i>
        </div>
        <app-mp-video-play
          [videoLink]="getVideoLinkByPage('fin/reports/reconciliation')" />
        <app-mp-ngb-range-datepicker
          [initialData]="selectedDate"
          (selectedDateEmitter)="dateChanged($event)" />

      </section>
      <section>
        <p-fileUpload
        #uploadedFiles
        pTooltip="Скачайте Детализацию из Финансовых отчётов и в исходном заархивированном виде (ZIP файл) загрузите в сервис"
        (onError)="onBasicUploadError($event, uploadedFiles)"
        (onUpload)="onBasicUploadAuto($event, uploadedFiles)"
        [auto]="true"
        [maxFileSize]="10000000"
        [style]="{ height: '37px', fontSize: '12px' }"
        accept=".zip"
        chooseLabel="Загрузить отчет из ЛК"
        class="mr-4 inline-block"
        label="Import"
        mode="basic"
        name="file"
        url="{{ URL_NEW }}realization/uploaded?token={{ TOKEN }}&shop_id={{
              SHOP_ID
        }}"></p-fileUpload>
      </section>
    </div>
  </div>
</div>

<div class="d-flex" style="flex-direction: column; gap: 18px">
  <div class="d-flex" style="gap: 18px">
    <app-mp-card-info
      *ngFor="let card of cards; let first = first"
      [card]="card"
      [bgColor]="first ? '#CCE7FC' : '#F8D6CB'">
    </app-mp-card-info>
  </div>

  <ag-grid-angular
    (gridReady)="onGridReady($event)"
    (dragStopped)="onDragStopped($event)"
    [columnDefs]="columnsDef"
    [defaultColDef]="defaultColDef"
    [rowData]="rowData"
    [pagination]="true"
    [paginationPageSize]="30"
    [domLayout]="'autoHeight'"
    [enableCellTextSelection]="true"
    class="ag-theme-alpine"
    style="width: 100%;" />
</div>
<p-toast />

