import { DecimalPipe } from "@angular/common";
import { ApexOptions } from "ng-apexcharts";
const decimalPipe = new DecimalPipe('ru-RU')


export function generateChartOptions(data: any): Partial<ApexOptions>{
    const financeActivityData = data.data.find(elem => elem.data.name === 'Финансовая деятельность');
    const investActivityData = data.data.find(elem => elem.data.name === 'Инвестиционная деятельность');
    const operationActivityData = data.data.find(elem => elem.data.name === 'Операционная деятельность');

    const availableFacilitiesData = data.data.find(elem => elem.data.name === 'Доступные средства на конец периода');
    const turnoverData = data.data.find(elem => elem.data.name === 'Оборот денег за период');

    const labels = data.columns.map((column) => {
        //@ts-ignore
        return column.header
    })
    const fininceIncomeMarkers = Object.entries(financeActivityData.children.find(child => child.data.name === 'Поступления').data).filter(([key, value]) => key !== 'name')
    const investIncomeMarkers = Object.entries(investActivityData.children.find(child => child.data.name === 'Поступления').data).filter(([key, value]) => key !== 'name')
    const operationIncomeMarkers = Object.entries(operationActivityData.children.find(child => child.data.name === 'Поступления').data).filter(([key, value]) => key !== 'name')

    const availableFacilitiesMarkers = Object.entries(availableFacilitiesData.data).filter(([key, value]) => key !== 'name').map(([key, v]) => v ? +v : 0)
    const turnoverMarkers = data.columns.map(column => {
        if(turnoverData.data[column.field]){
            return +turnoverData.data[column.field]
        } else {
            return 0
        }
    })

    const totalIncomeMarkers = fininceIncomeMarkers.map(([key, value], index) => {
        const normalizedValueOne = value ? +value : 0
        const normalizedValueTwo = investIncomeMarkers[index][1] ? + investIncomeMarkers[index][1] : 0
        const normalizedValueThree = operationIncomeMarkers[index][1] ? + operationIncomeMarkers[index][1] : 0
        return normalizedValueOne + normalizedValueTwo + normalizedValueThree
    })

    const fininceOutcomeMarkers = Object.entries(financeActivityData.children.find(child => child.data.name === 'Списания').data).filter(([key, value]) => key !== 'name')
    const investOutcomeMarkers = Object.entries(investActivityData.children.find(child => child.data.name === 'Списания').data).filter(([key, value]) => key !== 'name')
    const operationOutcomeMarkers = Object.entries(operationActivityData.children.find(child => child.data.name === 'Списания').data).filter(([key, value]) => key !== 'name')

    const totalOutcomeMarkers = fininceOutcomeMarkers.map(([key, value], index) => {
        const normalizedValueOne = value ? +value : 0
        const normalizedValueTwo = investOutcomeMarkers[index][1] ? + investOutcomeMarkers[index][1] : 0
        const normalizedValueThree = operationOutcomeMarkers[index][1] ? + operationOutcomeMarkers[index][1] : 0
        return normalizedValueOne + normalizedValueTwo + normalizedValueThree
    })


    return {
        legend: {
            position: 'top'
        },
        series: [
            {
                name: 'Поступления (руб)',
                type: 'column',
                color: '#0a691a',
                data: totalIncomeMarkers as number[]
            },
            {
                name: 'Списания (руб)',
                type: 'column',
                color: '#df2e2e',
                data: totalOutcomeMarkers as number[]
            },
            {
                name: 'Доступные средства на конец периода (руб)',
                type: 'line',
                color: '#4286f4',
                data: availableFacilitiesMarkers as number[]
            },
            {
                name: 'Оборот денег за период (руб)',
                type: 'line',
                color: 'black',
                data: turnoverMarkers as number[]
            },
        ],
        chart: {
            height: 360,
            type: 'area',
            stacked: false,
            animations: {
                enabled: true,
            },
        },
        dataLabels: { enabled: true, formatter: val => decimalPipe.transform(val as number, '1.0-0') },
        xaxis: {
            type: 'category',
            categories: labels,
        },
        yaxis: [
            {
                seriesName: 'Поступления (руб)',
                labels: {
                    formatter: (v: number) => decimalPipe.transform(v, '1.0-2'),
                },
                title: {
                    style: {
                        color: '#000000',
                    },
                },
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#000000'
                },
            },
            {
                seriesName: 'Поступления (руб)',
                show: false,
                labels: {
                    formatter: (v: number) => decimalPipe.transform(v, '1.0-2')
                },
            },
            {
                seriesName: 'Поступления (руб)',
                labels: {
                    formatter: (v: number) => decimalPipe.transform(v, '1.0-2'),
                    style: {
                        colors:"#0a691a"
                    }
                },
                title: {
                    text: 'Рубли',
                    style: {
                        color: '#0a691a',
                    },
                },
            },
            {
                seriesName: 'Поступления (руб)',
                show: false,
                labels: {
                    formatter: (v: number) => decimalPipe.transform(v, '1.0-2')
                },
            },
        ],
        stroke: { curve: 'smooth', width: [2, 2, 3, 3], dashArray: [0, 0, 0, 2] },
    }
}
