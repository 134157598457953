import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICurrentTariff, ITariffItem } from '../../tariff.interface';


@Component({
  selector: 'app-tariff-item',
  templateUrl: './tariff-item.component.html',
  styleUrls: ['./tariff-item.component.scss']
})
export class TariffItemComponent {

  @Input() data: ITariffItem | null = null
  @Input() currentPlan: ICurrentTariff | null = null

  @Output() buttonClicked = new EventEmitter<ITariffItem>()
  @Output() recalculationClicked = new EventEmitter()

  recalculationMode = false;

  onClick(data: ITariffItem): void {
    this.recalculationMode ? this.recalculationClicked.emit(data) : this.buttonClicked.emit(data);
  }

}
