import { DecimalPipe } from "@angular/common";
import { ApexOptions } from "ng-apexcharts";

const decimalPipe = new DecimalPipe('ru-RU')
export interface ICommonRates {
  title: string;
  ratesSum: string;
  ratesAmount: string;
  ratesPercent: string;
  separated: boolean;
}
export interface ISalesDynamicChart {
  id: number;
  name: string;
  data: number[];
  btnStatus: boolean;
}
export interface ISalesDynamicChartSeries {
  name: string;
  data: number[];
}
export type ChartSeries = {
  name: string;
  data: number[];
  color?: string;
};

export interface ISalesBalanceChart {
  series: ChartSeries[];
  xaxis: {
    categories: string[];
  };

}

export const COMMON_RATES: ICommonRates[] = [
  {
    title: 'Заказы',
    ratesSum: '3,1 млн',
    ratesAmount: '2 402',
    ratesPercent: '',
    separated: false
  },
  {
    title: 'Продажи',
    ratesSum: '1,5 млн',
    ratesAmount: '2 280',
    ratesPercent: '',
    separated: false
  },
  {
    title: 'Возвраты',
    ratesSum: '98,2 тыс.',
    ratesAmount: '44',
    ratesPercent: '',
    separated: false
  },
  {
    title: 'Самовыкупы',
    ratesSum: '212,2 тыс.',
    ratesAmount: '123',
    ratesPercent: '',
    separated: false
  },
  {
    title: 'Комиссия',
    ratesSum: '20,2 тыс.',
    ratesAmount: '',
    ratesPercent: '',
    separated: true
  },
  {
    title: 'Логистика',
    ratesSum: '98,2 тыс.',
    ratesAmount: '',
    ratesPercent: '',
    separated: true
  },
  {
    title: 'Себест.',
    ratesSum: '662,2 тыс.',
    ratesAmount: '',
    ratesPercent: '',
    separated: true
  },
  {
    title: 'Реклама',
    ratesSum: '112,2 тыс.',
    ratesAmount: '',
    ratesPercent: '',
    separated: true
  },
  {
    title: 'Ср. цена',
    ratesSum: '231',
    ratesAmount: '',
    ratesPercent: '',
    separated: true
  },
  {
    title: 'Выкуп',
    ratesSum: '',
    ratesAmount: '',
    ratesPercent: '93%',
    separated: true
  },
  {
    title: 'ДДР зак.',
    ratesSum: '',
    ratesAmount: '',
    ratesPercent: '7%',
    separated: true
  },
  {
    title: 'ДДР прод.',
    ratesSum: '',
    ratesAmount: '',
    ratesPercent: '10%',
    separated: true
  },
  {
    title: 'Маржа',
    ratesSum: '',
    ratesAmount: '',
    ratesPercent: '38%',
    separated: true
  },
  {
    title: 'ROI',
    ratesSum: '',
    ratesAmount: '',
    ratesPercent: '104%',
    separated: true
  },
];
export const SALES_BALANCE = [
  {
    title: 'Кол-во',
    amount: '1233'
  },
  {
    title: 'Себест.',
    amount: '1.08 млн'
  },
  {
    title: 'Розн. цена',
    amount: '4.12 млн'
  },
];
export const AVERAGE_ORDERS_DAY_ = [
  {
    title: 'Сумма',
    amount: '123 222'
  },
  {
    title: 'Кол-во',
    amount: '123'
  },
];
export const SALES_BALANCE_CHART: Partial<ApexOptions> = {
  series: [{
    name: 'Структура остатка',
    data: [2333, 222, 145, 2111, 222, 60, 111, 887],
    color: "#BCE9DE",
  }],
  xaxis: {
    categories: ['Коледино', 'Электросталь', 'МЛП-Подольск', 'Санкт-Петербург', 'Краснодар', 'Екатеринбург', 'Новосибирск', 'Харабаровск', 'СЦ Белая ДО']
  }
};
export const STRUCTURE_SALES_CHART: Partial<ApexOptions> = {
  series: [{
    name: 'Структура остатка',
    data: [2333, 222, 999, 2111, 888, 555, 444, 887, 234],
  }],
  xaxis: {
    categories: ['Центральный', 'Приволжский', 'Сибирский', 'Санкт-Петербург', 'Южный', 'Дальневосточный', 'Новосибирск', 'Крымский', 'СЦ Белая ДО'],
    labels: {
      formatter: (v: string | number) => decimalPipe.transform(v, '1.0-0'),
    },
    axisBorder: {
      color: 'black'
    }
  },
  dataLabels: {
    formatter: (v: string | number) => decimalPipe.transform(v, '1.0-0'),
    style: { colors: ['#000000']}
  },
};
export const SALES_DYNAMIC_CHART: ISalesDynamicChart[] = [
  {
    id: 1,
    name: 'Заказы, руб.',
    data: [30, 40, 45, 50, 49, 60, 70, 91, 99, 33, 30, 40, 45, 50, 49, 60, 70, 91, 99, 33, 30, 40, 45, 50, 49, 60, 70, 91, 99, 33],
    btnStatus: true,
  },
  {
    id: 2,
    name: 'Заказы, шт.',
    data: [23, 43, 54, 12, 44, 52, 32, 11, 44, 92, 23, 43, 54, 12, 44, 52, 32, 11, 44, 33, 23, 43, 54, 12, 44, 52, 32, 11, 44, 92],
    btnStatus: false,
  },
  {
    id: 3,
    name: 'Продажи, руб.',
    data: [66, 43, 88, 12, 11, 52, 32, 63, 12, 33, 66, 43, 88, 12, 11, 52, 32, 63, 12, 33, 66, 43, 88, 12, 11, 52, 32, 63, 12, 33],
    btnStatus: false,
  },
  {
    id: 4,
    name: 'Продажи, шт.',
    data: [44, 43, 11, 12, 66, 52, 99, 63, 33, 33, 11, 43, 44, 12, 11, 44, 32, 11, 12, 33, 11, 43, 11, 12, 11, 99, 32, 99, 12, 11],
    btnStatus: true,
  },
  {
    id: 5,
    name: 'Возвраты, руб.',
    data: [23, 43, 54, 12, 44, 52, 32, 11, 44, 92, 23, 43, 54, 12, 44, 11, 32, 11, 44, 33, 23, 77, 54, 12, 44, 52, 66, 11, 44, 92],
    btnStatus: false,
  },
  {
    id: 6,
    name: 'Возвраты, шт.',
    data: [88, 40, 45, 50, 49, 60, 70, 11, 55, 66, 30, 40, 45, 50, 49, 60, 70, 11, 45, 11, 30, 40, 11, 50, 49, 11, 70, 91, 11, 33],
    btnStatus: false,
  },
  {
    id: 7,
    name: 'Самовыкупы, руб.',
    data: [23, 55, 54, 12, 44, 52, 32, 11, 44, 55, 23, 43, 54, 12, 44, 55, 32, 11, 55, 33, 23, 77, 54, 12, 55, 52, 66, 55, 44, 55],
    btnStatus: false,
  },
  {
    id: 8,
    name: 'Самовыкупы, шт.',
    data: [88, 40, 45, 50, 49, 60, 55, 11, 55, 66, 30, 40, 45, 50, 49, 60, 70, 55, 45, 11, 30, 55, 11, 50, 49, 11, 70, 55, 11, 55],
    btnStatus: false,
  },
  {
    id: 9,
    name: '% Выкупа',
    data: [22, 43, 88, 12, 33, 52, 32, 44, 12, 33, 66, 55, 88, 12, 11, 52, 77, 63, 12, 88, 66, 43, 88, 99, 11, 52, 11, 63, 11, 11],
    btnStatus: false,
  },
  {
    id: 10,
    name: 'Ср. чек руб.',
    data: [99, 43, 11, 99, 66, 52, 99, 63, 33, 33, 99, 43, 44, 12, 11, 99, 32, 11, 12, 99, 11, 43, 11, 12, 99, 99, 32, 99, 12, 3],
    btnStatus: false,
  }
];
