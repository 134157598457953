import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { SECOND_RANGE_TYPE } from '../../../common-variables/time-ranges-date-picker';
import * as moment from 'moment';
import { ISelectedNgbDate } from '../../../interfaces/common.interface';

@Component({
  selector: 'app-mp-ngb-range-datepicker',
  templateUrl: './ngb-range-datepicker.component.html',
  styleUrls: ['./ngb-range-datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgbRangeDatepickerComponent {
  @Input()
  initialData: any = { startDate: moment(), endDate: moment() };

  @Input()
  rangeType = SECOND_RANGE_TYPE;

  @Input()
  styleParams = null;

  @Output()
  selectedDateEmitter = new EventEmitter(null);

  readonly MIN_DATE = moment().add(-91, 'days');

  readonly MAX_DATE = moment();

  readonly localParamsRU: any = {
    applyLabel: 'применить',
    direction: 'ltr',
    format: 'DD.MM.YYYY',
    daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    customRangeLabel: 'Свой диапазон',
    firstDay: 1,
    monthNames: [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь',
    ],
  };

  readonly invalidDates: moment.Moment[] = [
    moment().add(2, 'days'),
    moment().add(3, 'days'),
    moment().add(5, 'days'),
  ];

  isInvalidDate = (m: moment.Moment) =>
    this.invalidDates.some(d => d.isSame(m, 'day'));

  dataChanged() {
    this.selectedDateEmitter.emit(this.initialData);
  }
}
