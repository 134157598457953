<div class="app-page-title pnl-consolidation">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="btn-actions-pane-left" style="width: 100%">
        <div class="block-order-ftype">
          <div class="page-title-icon">
            <i class="fa fa-bar-chart icon-gradient bg-mean-fruit"></i>
          </div>
          <div>
            Консолидационный отчет <br />
            о прибылях и убытках
            <i
              class="fa fa-question-circle"
              aria-hidden="true"
              [pTooltip]="
                'Строится на основе реализации. Отчет по реализации доступен до ' +
                lastReportDate
              ">
            </i>
          </div>

          <app-mp-video-play [videoLink]="getVideoLinkByPage('fin/pnl')" />

          <app-mp-ngb-range-datepicker
            [initialData]="selectedDate"
            [rangeType]="ranges"
            (selectedDateEmitter)="dateChanged($event)" />
        </div>
      </div>
      <div class="pnl-consolidation__filters">
        <div class="select-button">
          <p-selectButton
            (onChange)="loadPNLList()"
            [style]="{ fontSize: '14px' }"
            [(ngModel)]="selectedDateType"
            [options]="[
              { name: 'По неделям', value: 2 },
              { name: 'По месяцам', value: 3 },
            ]"
            optionLabel="name"
            optionValue="value"
            styleClass="dark-panel"
            [ngStyle]="{ fontSize: '14px', width: '100%', display: 'block' }">
          </p-selectButton>
        </div>
        <p-checkbox
          *ngIf="!isOzon"
          ngbTooltip='Исключает строки "Реклама по удержаниям" и "Прочие удержания/выплаты"'
          placement="top"
          [(ngModel)]="checked"
          [binary]="true"
          inputId="binary"
          label="Показать рекламу по API"
          (onChange)="onCheckboxChange($event)" />
        <p-multiSelect
          (onChange)="loadPNLList()"
          [options]="suppliersList"
          [(ngModel)]="selectedSuppliers"
          [style]="{
            display: 'flex',
            alignItems: 'center',
            height: '32px',
          }"
          optionLabel="label"
          optionValue="value"
          placeholder="Выберите потавщика" />
      </div>
    </div>
  </div>
</div>
<div
  class="card p-treetable"
  [style.width.px]="pnlData?.columns.length * 150 + 400"
  [style.maxWidth]="'100%'">
  <p-treeTable
    [columns]="pnlData?.columns"
    [frozenColumns]="[{ field: 'name', header: 'Статья' }]"
    [metaKeySelection]="true"
    [scrollable]="true"
    [value]="pnlData?.data"
    frozenWidth="320px"
    selectionMode="single"
    styleClass="p-treetable-sm">
    <ng-template let-columns pTemplate="colgroup">
      <colgroup>
        <col *ngFor="let col of columns" style="width: 150px" />
      </colgroup>
    </ng-template>
    <ng-template let-columns pTemplate="header">
      <tr style="height: 50px">
        <th
          *ngFor="let col of columns"
          style="font-size: 12px; text-align: center">
          {{ col.header }}
        </th>
      </tr>
    </ng-template>
    <ng-template let-columns="columns" let-rowData="rowData" pTemplate="body">
      <tr>
        <td
          *ngFor="let col of columns; let i = index"
          class="columns-wrap rowGray"
          style="text-align: center; font-size: 12px">
          <span
            [class.fw-500]="fw_500_headers.includes(rowData.name)"
            [class.fw-600]="rowData.name === 'Выручка'"
            [class.fw-700]="fw_700_headers.includes(rowData.name)"
            [class.fw-900]="rowData.name === 'Чистая прибыль ИТОГ'"
            [class.darkGreen]="
              rowData.name === 'Чистая прибыль ИТОГ' && rowData[col.field] > 0
            "
            [class.darkRed]="
              rowData.name === 'Чистая прибыль ИТОГ' && rowData[col.field] < 0
            "
            [class.color-grey]="color_grey_headers.includes(rowData.name)">
            {{
              (rowData &&
                (rowData[col.field]?.toString()?.includes('%')
                  ? rowData[col.field]
                  : (rowData[col.field] | currency: '' : ''))) ||
                ''
            }}
          </span>
        </td>
      </tr>
    </ng-template>
    <ng-template let-rowData="rowData" let-rowNode pTemplate="frozenbody">
      <tr [ngClass]="{ height: '50px' }">
        <td class="flex items-center">
          <p-treeTableToggler [rowNode]="rowNode" />
          <span
            [pTooltip]="rowData.name"
            [tooltipDisabled]="false"
            [class.color-grey]="color_grey_headers.includes(rowData.name)"
            style="font-size: 15px; font-weight: 600"
            class="special-headers"
            [ngStyle]="{
              'font-weight': specialHeaders.includes(rowData.name)
                ? '600'
                : '400',
              'font-size': specialHeaders.includes(rowData.name)
                ? '15px'
                : '14px',
            }">
            {{ rowData.name }}
          </span>
        </td>
      </tr>
    </ng-template>
  </p-treeTable>
</div>
<button
  (click)="exportExcel()"
  class="btn btn-success button-export-fin-pnl"
  type="button">
  Скачать в Excel
</button>
<p-toast />
