import { Component } from '@angular/core';
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import { AbstractMenuTabComponent } from 'app/menu-tabs/abstract-menu-tab.component';
import { MessageService, ConfirmationService } from 'primeng/api';
import { STORES_COLUMN_DEFS, STORES_ROW_DATA } from './stores.mock';
import { NumberSortFunc } from 'app/shared/utils/functions/number-sort-func';


@Component({
  selector: 'app-stores',
  templateUrl: './stores.component.html',
  styleUrls: ['./stores.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class StoresComponent extends AbstractMenuTabComponent {

  readonly columnDefs = STORES_COLUMN_DEFS
  readonly defaultDef: ColDef = {
    editable: true,
    headerClass: 'header-centered',
    resizable: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: 'center' },
    comparator: NumberSortFunc
  };
  readonly rowData = STORES_ROW_DATA

  constructor() {
    super()
  }

  onGridReady(params: GridReadyEvent<any>) {
    this.gridApi = params.api;
  }

  onCellClicked(params) {
    // Handle click event for action cells
    if (params.column.colId === "action" && params.event.target.dataset.action) {
      let action = params.event.target.dataset.action;

      if (action === "edit") {
        params.api.startEditingCell({
          rowIndex: params.node.rowIndex,
          // gets the first columnKey
          colKey: params.columnApi.getDisplayedCenterColumns()[0].colId
        });
      }

      if (action === "update") {
        params.api.stopEditing(false);
      }

      if (action === "cancel") {
        params.api.stopEditing(true);
      }
    }
  }

  onRowEditingStarted(params) {
    params.api.refreshCells({
      columns: ["action"],
      rowNodes: [params.node],
      force: true
    });
  }
  onRowEditingStopped(params) {
    params.api.refreshCells({
      columns: ["action"],
      rowNodes: [params.node],
      force: true
    });
  }
}