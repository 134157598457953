import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { HeatMapCharts } from './heat-map.model';
import { DatePickerCommon } from '../../../shared/common-variables/date-picker-common';
import { FIRST_RANGE_TYPE } from '../../../shared/common-variables/time-ranges-date-picker';
import {
  IHeatMapListSkuAndCat,
  IHeatMapOrders,
  IHeatMapSales,
} from './heat-map.interface';
import { ConfirmationService, MessageService } from 'primeng/api';
import { PopUpMessages } from '../../../shared/mocks/pop-up-messages.mock';
import { finalize } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { forkJoin, Observable } from 'rxjs';
import {
  GenerateOrdersAverageCheckByDayOfWeek,
  GenerateOrdersAverageReceiptByHours,
  GenerateOrdersByDayOfWeek,
  GenerateOrdersByDayOfWeekFromTo,
  GenerateOrdersByHoursFromTo,
  GenerateOrdersInRubblesByDay,
  GenerateSalesByDayFromTo,
  GenerateSalesByDayOfWeek,
  GenerateSalesByHoursFromTo,
} from './heat-map.func';
import { AbstractMenuTabComponent } from '../../abstract-menu-tab.component';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-heat-map',
  templateUrl: './heat-map.component.html',
  styleUrls: ['./heat-map.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class HeatMapComponent extends AbstractMenuTabComponent implements OnInit {
  DatePickerCommon: DatePickerCommon;
  ranges: any = FIRST_RANGE_TYPE;

  // DROPDOWN
  selectCategory: IHeatMapListSkuAndCat[] = [];
  selectSku: IHeatMapListSkuAndCat[] = []; // TODO - what is selectSku ?
  selectSubject: any;

  // NG MODEL
  skuValue: IHeatMapListSkuAndCat;
  categoryValue: IHeatMapListSkuAndCat;
  subjectValue: any;
  selectedDate: any = { 
    startDate: moment().subtract(7, 'day'),
    endDate: moment().subtract(1, 'day'), 
  };

  //DATA
  ordersByDayOfTheWeek: Partial<HeatMapCharts> = null;
  ordersByDayOfWeekFromTo: Partial<HeatMapCharts> = null;
  ordersByHoursFromTo: Partial<HeatMapCharts> = null;
  ordersAverageCheckByDayOfWeek: Partial<HeatMapCharts> = null;
  ordersInRubblesByDay: Partial<HeatMapCharts> = null;
  ordersAverageReceiptByHours: Partial<HeatMapCharts> = null;
  salesByDayOfTheWeek: Partial<HeatMapCharts> = null;
  salesByDayFromTo: Partial<HeatMapCharts> = null;
  salesByHoursFromTo: Partial<HeatMapCharts> = null;
  lastReportDate = ''
  text = ''
  subtext = ''

  constructor() {
    super();
    this.DatePickerCommon = new DatePickerCommon();
    if (localStorage.getItem('supplier') === null) {
      this._router.navigate(['/settings/shop/']);
    }
  }

  ngOnInit(): void {
    this.getReportDate().pipe(untilDestroyed(this)).subscribe(data => {
      this.lastReportDate = data.last_data
    })

    this._mpSurfService
      .loadMetaData(this.SHOP_ID, this.TOKEN)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        //@ts-ignore
        const { die_text, die_title } = res.data.tutorialVideoLink.find(item => item.pageTitle === 'dashboard/heatmap') || { die_text: null, die_title: null }
        this.text = die_title
        this.subtext = die_text
      })
  }

  get requestBodyGeneral(): {} {
    return {
      startDate: this.selectedDate.startDate.format('YYYY-MM-DD'),
      endDate: this.selectedDate.endDate.format('YYYY-MM-DD'),
    };
  }

  dateChanged(event: any) {
    this.selectedDate = event;
    this.loadHeatMapData();
  }

  changeFilter() {
    const body = {
      cat: this.categoryValue ?? undefined,
      sub: this.subjectValue ?? undefined,
      sku: this.skuValue ?? undefined,
    };

    this.loadHeatMapData(body);
  }

  private loadHeatMapData(body = null): void {
    this.loadSelectLabels(body);

    this.loadHeatmapOrders(body);
    this.loadHeatmapSales(body);
  }

  private loadHeatmapSales(params = null) {
    this.isLoading = true;
    const body = {
      ...this.requestBodyGeneral,
      params,
    };
    this._mpSurfService
      .loadByOld(body, 'getHeatmapSales', 'data')
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(
        (data: IHeatMapSales) => {
          this.generateSalesByDayOfWeek(data);
          this.generateSalesByDayFromTo(data);
          this.generateSalesByHoursFromTo(data);
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.createFailedSummary,
            PopUpMessages.createFailedMessage
          );
        }
      );
  }

  private loadHeatmapOrders(params = null) {
    this.isLoading = true;
    const body = {
      ...this.requestBodyGeneral,
      params,
    };
    this._mpSurfService
      .loadByOld(body, 'getHeatmapOrders', 'data')
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(
        (data: IHeatMapOrders) => {
          this.generateOrdersByDayOfTheWeek(data);
          this.generateOrdersByDayOfWeekFromTo(data);
          this.generateOrdersByHoursFromTo(data);
          this.generateOrdersAverageCheckByDayOfWeek(data);
          this.generateOrdersInRubblesByDay(data);
          this.generateOrdersAverageReceiptByHours(data);
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.createFailedSummary,
            PopUpMessages.createFailedMessage
          );
        }
      );
  }

  private generateOrdersByDayOfTheWeek(data: IHeatMapOrders) {
    this.ordersByDayOfTheWeek = GenerateOrdersByDayOfWeek(data);
  }

  private generateOrdersByDayOfWeekFromTo(data: IHeatMapOrders) {
    this.ordersByDayOfWeekFromTo = GenerateOrdersByDayOfWeekFromTo(data);
  }

  private generateOrdersByHoursFromTo(data: IHeatMapOrders) {
    this.ordersByHoursFromTo = GenerateOrdersByHoursFromTo(data);
  }

  private generateSalesByDayFromTo(data: IHeatMapSales) {
    this.salesByDayFromTo = GenerateSalesByDayFromTo(data);
  }

  private generateSalesByHoursFromTo(data: IHeatMapSales) {
    this.salesByHoursFromTo = GenerateSalesByHoursFromTo(data);
  }

  private generateSalesByDayOfWeek(data: IHeatMapSales) {
    this.salesByDayOfTheWeek = GenerateSalesByDayOfWeek(data);
  }

  private generateOrdersAverageCheckByDayOfWeek(data: IHeatMapOrders) {
    this.ordersAverageCheckByDayOfWeek =
      GenerateOrdersAverageCheckByDayOfWeek(data);
  }

  private generateOrdersInRubblesByDay(data: IHeatMapOrders) {
    this.ordersInRubblesByDay = GenerateOrdersInRubblesByDay(data);
  }

  private generateOrdersAverageReceiptByHours(data: IHeatMapOrders) {
    this.ordersAverageReceiptByHours =
      GenerateOrdersAverageReceiptByHours(data);
  }

  private loadSelectLabels(params = null): void {
    forkJoin([
      this.labelsRequest('getHeatmapListSubect', params),
      this.labelsRequest('getHeatmapListCat', params),
      this.labelsRequest('getHeatmapListSku', params)
    ])
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(
        ([subjectList, categoryList, skuList]) => {
          this.selectSubject = subjectList;
          this.selectCategory = categoryList;
          //@ts-ignore
          this.selectSku = skuList;
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.createFailedSummary,
            PopUpMessages.createFailedMessage
          );
        }
      );
  }

  private labelsRequest(action: string, body = null): Observable<any> {
    const params = this.requestBodyGeneral as any;
    params.params = body;
    return this._mpSurfService.loadByOld(params, action, 'data');
  }
}
