import { ColDef, ColGroupDef } from 'ag-grid-community';
import { BtnCellRendererComponentBuyout } from './components/btn-cell-renderer.component-buyout';

export const MY_BUYOUTS_COLUMN_DEFS: (ColDef | ColGroupDef)[] = [
  {
    headerName: 'Номер заказа',
    field: 'check_id',
    width: 300,
  },
  {
    headerName: 'Доп. расходы по самовыкупам',
    field: 'cost_buyout',
    width: 300,
  },
  {
    headerName: 'Стоимость подрядчика по выкупам',
    field: 'cost_logistics',
    width: 300,
  },
  {
    headerName: 'Комментарии',
    field: 'comment',
    width: 400,
  },
  {
    headerName: 'Действия',
    width: 280,
    field: '',
    filter: false,
    cellRenderer: BtnCellRendererComponentBuyout,
  },
];
