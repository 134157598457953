<div class="btn-actions-pane-left">
  <input (change)="dataChanged()"
         [(ngModel)]="initialData"
         [alwaysShowCalendars]="true"
         [isInvalidDate]="isInvalidDate"
         [linkedCalendars]="true"
         [locale]="localParamsRU"
         [maxDate]="MAX_DATE"
         [minDate]='MIN_DATE'
         [ranges]="rangeType"
         [showClearButton]="false"
         [showCustomRangeLabel]="true"
         [style]="styleParams"
         ngxDaterangepickerMd
         placeholder="Выберите даты"
         readonly
         type="text"/>
</div>
