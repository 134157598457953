import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import * as moment from 'moment';
import * as FileSaver from 'file-saver';

import { generateColumnDefs } from './abs-analyst.mock';
import { LOCALE_TEXT_FOR_FILTERS } from '../../guides/products/products.mock';
import { ColDef, DragStoppedEvent, GridReadyEvent } from 'ag-grid-community';
import { GridOptions } from 'ag-grid';
import { AbstractMenuTabComponent } from '../../abstract-menu-tab.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ISelectedNgbDate } from '../../../shared/interfaces/common.interface';
import { ICardWidget } from '../main-test/main-test.interface';
import { generateCardData } from './abc-analyst.func';
import { NumberSortFunc } from '../../../shared/utils/functions/number-sort-func';
import { IStatus } from '../../guides/products/products.interface';
import { PopUpMessages } from 'app/shared/mocks/pop-up-messages.mock';
import { DOCUMENT } from '@angular/common';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-abc-analyst',
  templateUrl: './abc-analyst.component.html',
  styleUrls: ['./abc-analyst.component.scss'],
  providers: [MessageService, ConfirmationService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AbcAnalystComponent extends AbstractMenuTabComponent implements OnInit {

  isLoading = false;
  selectedDate: ISelectedNgbDate = {
    startDate: moment().subtract(21, 'day'),
    endDate: moment(),
  };

  selectedAnaliseStatus = 1;
  selectedIndicator: number;
  selectedArticleBarcode: number;
  selectedWbParams: number;
  lastReportDate = ''
  summaryData = []
  text = ''
  subtext = ''
  otherDeductionsData: ICardWidget | null = null
  isOzon = false;
  checked = true;
  wbChecked = false;
  statusesList = [];
  categoriesList = [];
  selectedStatuses = [];
  selectedCategories = [];
  columnDefs = []

  fboFbsChecked = false;

  rowDataGeneral = null;
  cards: ICardWidget[] = [];
  readonly localeTextForFilters = LOCALE_TEXT_FOR_FILTERS;
  gridOptions: GridOptions;
  readonly defaultColDef: ColDef = {
    headerClass: 'header-centered',
    resizable: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: 'center' },
    comparator: NumberSortFunc
  };

  ABC_DATA = null
  tariff = ''

  @ViewChild('agGrid', {read: ElementRef}) agGrid: ElementRef | null = null

  constructor(@Inject(DOCUMENT) private readonly _documentRef: Document) {
    super()
    this.gridOptions = {
      context: { componentParent: this },
      enableCellTextSelection: true,
    } as GridOptions;
  }

  ngOnInit(): void {
    this.columnDefs = generateColumnDefs(this.wbChecked, this.fboFbsChecked)
    this.isOzon = JSON.parse(localStorage.getItem('mpall_shop')).marketPlace === 2;
    this.getReportDate().pipe(untilDestroyed(this)).subscribe(data => {
      this.lastReportDate = data.last_data;
    })
    this.loadStatuses();
    this.loadCategories();
    this._mpSurfService
      .loadMetaData(this.SHOP_ID, this.TOKEN)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        //@ts-ignore
        const { die_text, die_title } = res.data.tutorialVideoLink.find(item => item.pageTitle === 'dashboard/abc') || { die_text: null, die_title: null }
        this.text = die_title
        this.subtext = die_text
      })
  }

  onGridReady(params: GridReadyEvent<any>) {
    this.gridApi = params.api;
  }

  private loadStatuses(): void {
    this._mpSurfService
      .load({}, 'products-status')
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: IStatus[]) => {
          this.statusesList = response;
        },
    );
  }

  private loadCategories(): void {
    this._mpSurfService
      .load({}, 'products/category-pnl-list')
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: string[]) => {
          this.categoriesList = response.map(item => ({name: item}));
        },
    );
  }

  loadAbsAnalyst() {
    const params = {
      beginDate: this.selectedDate.startDate.format('YYYY-MM-DD'),
      endDate: this.selectedDate.endDate.format('YYYY-MM-DD'),
      adv_oper: this.checked ? '1' : '0',
      wb_oper: this.wbChecked ? '1' : '0',
      fbo_fbs: this.fboFbsChecked ? '1' : '0',
      myStatus: Array.isArray(this.selectedStatuses) ?  this.selectedStatuses.map(s => s.name) : [],
      categoryPnl: Array.isArray(this.selectedCategories) ? this.selectedCategories.map(s => s.name) : []
    }
    this._mpSurfService.loadByPost(params, 'abc/list-v2')
      .pipe(untilDestroyed(this))
      .subscribe(data => {
        this.ABC_DATA = data;
        //@ts-ignore
        this.rowDataGeneral = data.data;
        //@ts-ignore
        this.otherDeductionsData = data.otherDeductions
        //@ts-ignore
        this.cards = generateCardData(data.data);
        //@ts-ignore
        this.summaryData = [{...data.total, photo_new: 'Итого'}];
        this._cdr.markForCheck();
      })
  }

  dateChanged($event: any) {
    this.selectedDate = $event;
    this.loadAbsAnalyst()
  }

  onCheckboxChange(event: {checked: boolean}): void {
    this.checked = event.checked
    this.loadAbsAnalyst()
  }

  onClearStatuses(): void {
    this.selectedStatuses = [];
    this.loadAbsAnalyst();
  };

  onClearCategories(): void {
    this.selectedCategories = [];
    this.loadAbsAnalyst();
  }

  selectStatus(): void {
    this.loadAbsAnalyst();
  }

  selectCategory(): void {
    this.loadAbsAnalyst();
  }

  exportExcel() {
    this._mpSurfService
      .loadByPost(this.ABC_DATA, `abc/export`)
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: any) => {
          this.importXlsx(response);
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }

  private importXlsx(excelData: any): void {
    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(excelData);
      const csvOutput: string = xlsx.utils.sheet_to_csv(worksheet);
      FileSaver.saveAs(new Blob([csvOutput]), 'ABC_export_' + new Date().getTime() + '.csv');
    });
  }

  onDragStopped(event: DragStoppedEvent) {
    // localStorage.setItem('abc-column-defs', JSON.stringify(event.api.getColumnDefs()))
  }

  onWbCheckboxChange(event: {checked: boolean}): void {
    this.wbChecked = event.checked;
    this.gridApi.setColumnDefs(generateColumnDefs(this.wbChecked, this.fboFbsChecked))
    this.loadAbsAnalyst();
  }

  onFboFbsCheckboxChange(event: {checked: boolean}): void {
    this.fboFbsChecked = event.checked;
    this.gridApi.setColumnDefs(generateColumnDefs(this.wbChecked, this.fboFbsChecked))
    this.loadAbsAnalyst();
  }
}