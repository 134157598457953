import { DecimalPipe } from "@angular/common";
import { ApexOptions } from "ng-apexcharts";

const decimalPipe = new DecimalPipe('ru-RU')

export function generateChartOptions(data: any): Partial<ApexOptions> {
    const netIncomeData = data.data.find(elem => elem.data.item_id === 55);
    const grossIncomeData = data.data.find(elem => elem.data.item_id === 51);
    const profitabilityData = netIncomeData.children.find(child => child.data.item_id === 1055)

    const labels = data.columns
    .filter((column) => column.header !== 'Итого')
    .map((column) => {
        //@ts-ignore
        return column.header
    })
    const netIncomeDataMarkers = Object.entries(netIncomeData.data)
    .filter(([key, value]) => key !== 'item_id' && key !== 'name' && key !== 'total')
    .map(([key, value]) => {
        return value ? +value : 0
    })
    const grossIncomeDataMarkers = Object.entries(grossIncomeData.data)
    .filter(([key, value]) => key !== 'item_id' && key !== 'name' && key !== 'total')
    .map(([key, value]) => {
        return value ? +value : 0
    })

    const profitabilityDataMarkers = Object.entries(profitabilityData.data)
    .filter(([key, value]) => key !== 'item_id' && key !== 'name' && key !== 'total')
    .map(([key, value]) => {
        return value && typeof value === 'string' ? parseFloat(value.replace(',','.')) : 0
    })

    return {
        legend: {
            position: 'top'
        },
        series: [
            {
                name: 'Чистая прибыль (руб)',
                type: 'column',
                color: '#4286f4',
                data: netIncomeDataMarkers as number[]
            },
            {
                name: 'Валовый доход (руб)',
                type: 'line',
                color: 'gray',
                data: grossIncomeDataMarkers as number[]
            },
            {
                name: 'Рентабельность по чистой прибыли (%)',
                type: 'line',
                color: 'green',
                data: profitabilityDataMarkers as number[]
            },
        ],
        chart: {
            height: 360,
            type: 'area',
            stacked: false,
            animations: {
                enabled: true,
            },
        },
        dataLabels: { 
            enabled: true,
            formatter: (val, opt) => {
                // @ts-ignore
                return decimalPipe.transform(val, '1.0-0')
            },
        },
        xaxis: {
            type: 'category',
            categories: labels,
        },
        yaxis: [
            {
                seriesName: 'Чистая прибыль (руб)',
                labels: {
                    formatter: (v: number) => decimalPipe.transform(v, '1.0-2')
                },
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                },
                title: {
                    text: 'Рубли',
                    style: {
                        color: '#000000',
                    },
                },
            },
            {
                seriesName: 'Чистая прибыль (руб)',
                show: false,
                labels: {
                    formatter: (v: number) => decimalPipe.transform(v, '1.0-2')
                },
            },
            {
                seriesName: 'Рентабельность по чистой прибыли (%)',
                opposite: true,
                labels: {},
                title: {
                    text: 'Проценты',
                    style: {
                        color: '#000000',
                    },
                },
            },
        ],
        stroke: { curve: 'smooth', width: [2, 3, 3],  dashArray: [0, 4, 0]},
    }
}