<div *ngIf="suppliersList.length > 1" class="container">
  <div class="row content">
    <div class="content-item col-sm-8 col-10 m-auto">
      <div class="card-shadow-primary profile-responsive card-border mb-3 card">
        <div class="dropdown-menu-header">
          <div class="dropdown-menu-header-inner bg-dark">
            <div class="menu-header-content btn-pane-right">
              <div>
                <h5 class="menu-header-title">Список подключенных Селлеров</h5>
                <h6 class="menu-header-subtitle">
                  Выберите одного из селлеров, чтобы продолжить работу в сервисе
                </h6>
              </div>
            </div>
          </div>
        </div>
        <ul class="list-group list-group-flush">
          <li class="p-0 list-group-item">
            <div class="grid-menu grid-menu-2col overflow-hidden">
              <div class="g-0 row">
                <div *ngFor="let item of suppliersList"
                     class="col-sm-6">
                  <button (click)="navigateBySelectedSupplier(item)"
                          class="btn-icon-vertical btn-square btn-transition btn btn-outline-link">
                    <!--                                        <i class="lnr-license btn-icon-wrapper btn-icon-lg mb-3"></i>-->
                    <img [src]="item.marketplace === 2 ? 'assets/images/ozon-logo.webp' : 'assets/images/wb-logo.jpg'"
                         style="width: 35px; margin-bottom: 3px; border-radius: 10px;" alt="logo">
                    <p>{{ item?.name }}</p>
                  </button>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
