import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimengDatepickerComponent } from './primeng-datepicker/primeng-datepicker.component';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbRangeDatepickerComponent } from './ngb-range-datepicker/ngb-range-datepicker.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { VideoPlayComponent } from './video-play/video-play.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ToastModule } from 'primeng/toast';
import { PageHeaderNotificationBarComponent } from './page-header-notification-bar/page-header-notification-bar.component';
import { CardInfoComponent } from './card-info/card-info.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { UnitPipeModule } from '../../pipes/unit.pipe.module';

@NgModule({
  declarations: [
    PrimengDatepickerComponent,
    NgbRangeDatepickerComponent,
    VideoPlayComponent,
    PageHeaderNotificationBarComponent,
    CardInfoComponent,
  ],
  imports: [
    CommonModule,
    CalendarModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDaterangepickerMd,
    FontAwesomeModule,
    ToastModule,
    NgbTooltipModule,
    UnitPipeModule
  ],
  exports: [
    PrimengDatepickerComponent,
    NgbRangeDatepickerComponent,
    VideoPlayComponent,
    PageHeaderNotificationBarComponent,
    CardInfoComponent
  ],
})
export class UiComponentsModule {}
