import { ChangeDetectorRef, Component, OnInit, ViewChild, inject } from '@angular/core';
import { ConfirmationService, MessageService, TreeNode } from 'primeng/api';
import * as moment from 'moment';
import * as FileSaver from 'file-saver';
import { FORTH_RANGE_TYPE } from '../../../shared/common-variables/time-ranges-date-picker';
import { HttpErrorResponse } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AbstractMenuTabComponent } from '../../abstract-menu-tab.component';
import { ISelectedNgbDate } from '../../../shared/interfaces/common.interface';
import { TreeTable } from 'primeng/treetable';
import { ApexOptions } from 'ng-apexcharts';
import { generateChartOptions } from '../../../shared/utils/functions/pnl-chart-generator'
import { IArticlesPurchase } from '../../../menu-tabs/guides/finpurchase/finpurchase.interface';
import { PopUpMessages } from '../../../shared/mocks/pop-up-messages.mock';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-finpnl',
  templateUrl: './finpnl.component.html',
  styleUrls: ['./finpnl.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class FinpnlComponent
  extends AbstractMenuTabComponent
  implements OnInit
{
  private readonly cdr = inject(ChangeDetectorRef)
  readonly ranges = FORTH_RANGE_TYPE;
  readonly specialHeaders = [
    'Выручка',
    'Переменные затраты (прямые)',
    'Маржинальная прибыль',
    'Постоянные затраты (прямые)',
    'Валовый доход',
    'Постоянные затраты (косвенные)',
    'Постоянные затраты (косвенные)',
    'Доходы внереализационные',
    'Нераспределенная прибыль',
    'Чистая прибыль ИТОГ',
    'Расходы ниже EBITDA',
    'Прибыль до вычета налогов, процентов и амортизации (EBITDA)',
    'Доходы ниже EBITDA',
    'Операционная прибыль',
    'Расходы внереализационные',
  ];
  
  readonly fw_700_headers = [
    'Маржинальная прибыль',
    'Валовый доход',
    'Операционная прибыль',
    'Прибыль до вычета налогов, процентов и амортизации (EBITDA)',
  ]

  readonly fw_500_headers = [
    'Переменные затраты (прямые)',
    'Постоянные затраты (прямые)',
    'Постоянные затраты (косвенные)',
    'Доходы внереализационные',
    'Нераспределенная прибыль',
    'Расходы ниже EBITDA',
    'Доходы ниже EBITDA',
    'Расходы внереализационные',
  ]

  readonly color_grey_headers = [
    'Продажи в ценах поставщика с самовыкупами',
    'Продажи в ценах WB с самовыкупами',
    'Продажи Ozon с самовыкупами'
  ]

  pnlData: TreeTable & { data: any } = null;
  barcodesList: any[] = [];
  subjectsList: any[] = [];
  chartOptions: Partial<ApexOptions> | null = null

  selectedDate: ISelectedNgbDate = {
    startDate: moment().subtract(21, 'day'),
    endDate: moment(),
  };
  selectedNodes: TreeNode[];
  selectedDateType = 3;
  selectedSubject = [];
  selectedNm = [];
  lastReportDate = '';
  checked = false
  apiRealizationChecked = false
  articles = []
  text = ''
  subtext = ''
  isOzon = false;

  categoriesList = [];
  selectedCategories = [];

  FIN_PNL_DATA = null;
  tariff = ''

  numToMonthsMap: Map<string, string> = new Map()
  .set('01', 'янв.')
  .set('02', 'февр.')
  .set('03', 'мар.')
  .set('04', 'апр.')
  .set('05', 'май')
  .set('06', 'июн.')
  .set('07', 'июл.')
  .set('08', 'авг.')
  .set('09', 'сент.')
  .set('10', 'окт.')
  .set('11', 'нояб.')
  .set('12', 'дек.')

  monthToNumsMap: Map<string, string> = new Map()
  .set('янв.', '01')
  .set('февр.', '02')
  .set('мар.', "03")
  .set('апр.', '04')
  .set('май', '05')
  .set('июн.', '06')
  .set('июл.', '07')
  .set('авг.', '08')
  .set('сент.', '09')
  .set('окт.', '10')
  .set('нояб.', '11')
  .set('дек.', '12')

  rangeStartDateItems = moment([new Date().getUTCFullYear(), new Date().getUTCMonth()]).subtract(1, 'month').startOf('month').format('MM/YYYY').split('/')
  rangeStartDate = `${this.numToMonthsMap.get(this.rangeStartDateItems[0])} ${this.rangeStartDateItems[1]}`

  rangeEndDateItems = moment([new Date().getUTCFullYear(), new Date().getUTCMonth()]).endOf('month').format('MM/YYYY').split('/')
  rangeEndDate = `${this.numToMonthsMap.get(this.rangeEndDateItems[0])} ${this.rangeEndDateItems[1]}`

  selectedFromYear = this.rangeStartDateItems[1]
  selectedFromMonth = this.rangeStartDateItems[0]

  selectedToYear = this.rangeEndDateItems[1]
  selectedToMonth = this.rangeEndDateItems[0]

  showStartPlaceholder = false
  showEndPlaceholder = false

  years = [
    {name: '2022', key: '2022'},
    {name: '2023', key: '2023'},
    {name: '2024', key: '2024'},
  ];
  months = [
    {name: 'Январь', key: '01'}, 
    {name: 'Февраль', key: '02'}, 
    {name: 'Март', key: '03'}, 
    {name: 'Апрель', key: '04'},
    {name: 'Май', key: '05'}, 
    {name: 'Июнь', key: '06'}, 
    {name: 'Июль', key: '07'}, 
    {name: 'Август', key: '08'},
    {name: 'Сентябрь', key: '09'}, 
    {name: 'Октябрь', key: '10'}, 
    {name: 'Ноябрь', key: '11'}, 
    {name: 'Декабрь', key: '12'},
  ];


  form = new FormGroup({
    monthFrom: new FormControl(this.selectedFromMonth),
    yearFrom: new FormControl(this.selectedFromYear),
    monthTo: new FormControl(this.selectedToMonth),
    yearTo: new FormControl(this.selectedToYear),
    dateFrom: new FormControl(this.rangeStartDate),
    dateTo: new FormControl(this.rangeEndDate),
  });

  @ViewChild('myDrop1') myDrop1: NgbDropdown

  constructor() {
    super();
  }

  ngOnInit(): void {
    // this.loadAllParameters();
    this.getReportDate().pipe(untilDestroyed(this)).subscribe(data => {
      this.lastReportDate = data.last_data;
    })
    this.loadArticles()
    this.loadSubjects()
    this.loadCategories()
    this.subscribeOnMonthFromChange();
    this.subscribeOnYearFromChange();
    this.subscribeOnMonthToChange();
    this.subscribeOnYearToChange();
    this._mpSurfService
      .loadMetaData(this.SHOP_ID, this.TOKEN)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        //@ts-ignore
        const { die_text, die_title } = res.data.tutorialVideoLink.find(item => item.pageTitle === 'fin/pnl') || { die_text: null, die_title: null }
        this.text = die_title
        this.subtext = die_text
      })
    this.isOzon = JSON.parse(localStorage.getItem('mpall_shop')).marketPlace === 2;
  }

  subscribeOnMonthFromChange(): void {
    this.form.controls['monthFrom'].valueChanges
    .pipe(untilDestroyed(this))
    .subscribe(month => {
      this.showStartPlaceholder = false;
      this.showEndPlaceholder = false;
      const selectedYear = this.form.controls['yearFrom'].value;
      this.form.controls['dateFrom'].setValue(`${this.numToMonthsMap.get(month)} ${selectedYear}`);
    })
  }

  subscribeOnYearFromChange(): void {
    this.form.controls['yearFrom'].valueChanges
    .pipe(untilDestroyed(this))
    .subscribe(year => {
      this.showStartPlaceholder = false;
      this.showEndPlaceholder = false;
      const selectedMonth = this.numToMonthsMap.get(this.form.controls['monthFrom'].value);
      this.form.controls['dateFrom'].setValue(`${selectedMonth} ${year}`);
    })
  }

  subscribeOnMonthToChange(): void {
    this.form.controls['monthTo'].valueChanges
    .pipe(untilDestroyed(this))
    .subscribe(month => {
      this.showStartPlaceholder = false;
      this.showEndPlaceholder = false;
      const selectedYear = this.form.controls['yearTo'].value;
      this.form.controls['dateTo'].setValue(`${this.numToMonthsMap.get(month)} ${selectedYear}`);
    })
  }

  subscribeOnYearToChange(): void {
    this.form.controls['yearTo'].valueChanges
    .pipe(untilDestroyed(this))
    .subscribe(year => {
      this.showStartPlaceholder = false;
      this.showEndPlaceholder = false;
      const selectedMonth =  this.numToMonthsMap.get(this.form.controls['monthTo'].value);
      this.form.controls['dateTo'].setValue(`${selectedMonth} ${year}`);
    })
  }

  getName(s: string) {
    console.log(s);
  }
  newValue() {
    this.loadPNLList();
  }

  dateChanged($event: any) {
    this.selectedDate = $event;
    this.loadPNLList();
  }

  onClearParameters(parameter: string) {
    switch (parameter) {
      case 'article':
        this.selectedNm = [];
        break;
      case 'subject':
        this.selectedSubject = [];
        break;
      default:
        this.selectedParameters();
    }
    this.selectedParameters();
  }

  selectedParameters() {
    this.loadPNLList();
  }

  changeType() {
    this.loadPNLList()
  }

  exportExcel() {
    this._mpSurfService
      .loadByPost(this.FIN_PNL_DATA, `fin-pnl/export`)
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: any) => {
          this.importXlsx(response);
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }

  private importXlsx(excelData: any): void {
    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(excelData);
      const csvOutput: string = xlsx.utils.sheet_to_csv(worksheet);
      FileSaver.saveAs(new Blob([csvOutput]), 'Fin-pnl_export_' + new Date().getTime() + '.csv');
    });
  }

  private loadPNLList() {
    const dateConfig = this.apiRealizationChecked 
    ? {
      startDate: moment([this.form.controls['yearFrom'].value, +this.form.controls['monthFrom'].value - 1]).startOf('month').format('YYYY-MM-DD'),
      endDate: moment([this.form.controls['yearTo'].value, +this.form.controls['monthTo'].value - 1]).endOf('month').format('YYYY-MM-DD'),
    }
    : {
      startDate: this.selectedDate.startDate.format('YYYY-MM-DD'),
      endDate: this.selectedDate.endDate.format('YYYY-MM-DD'),
    }
    const params = {
      type_group: this.apiRealizationChecked ? 3 : this.selectedDateType,
      adv_oper: this.checked ? '1' : '0',
      realization_report: this.apiRealizationChecked ? '1' : '0',
      categoryPnl: Array.isArray(this.selectedCategories) ? this.selectedCategories.map(s => s.name) : [],
      articles: this.getArticles(this.selectedNm),
      subjects: Array.isArray(this.selectedSubject) ? this.selectedSubject.map(item => item.code) : [],
      ...dateConfig
    };
    this._mpSurfService
      .loadByPost(params, 'fin-pnl/pnl-data')
      .pipe(untilDestroyed(this))
      .subscribe(
        (data: any) => {
          this.FIN_PNL_DATA = data;
          this.pnlData = data;
          this.chartOptions = generateChartOptions(data);
          this.cdr.markForCheck()
        },
        (error: HttpErrorResponse) => {
          alert(error.message);
        }
      );
  }

  private loadAllParameters() {
    forkJoin([
      this.getParametersList('fin-dict/fin-nm'),
      this.getParametersList('fin-dict/fin-category'),
      this.getParametersList('fin-dict/fin-subject'),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([responseBarcode, responseCategory, responseSubject]) => {
        this.barcodesList = responseBarcode.data;
        this.categoriesList = responseCategory.data;
        this.subjectsList = responseSubject.data;
      });
  }

  private getParametersList(URL: string): Observable<any> {
    const params = {
      days: 1,
      startDate: this.selectedDate.startDate.format('YYYY-MM-DD'),
      endDate: this.selectedDate.endDate.format('YYYY-MM-DD'),
      shop_id: this.SHOP_ID,
    };
    return this._mpSurfService.load(params, URL);
  }

  private loadArticles() {
    this._mpSurfService
      .load({}, 'products/important')
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: IArticlesPurchase[]) => {
          this.articles = response.map((a) => {
            return {
              name: `${a.nmid} - ${a.SKU}`,
              code: a.nmid,
            };
          });
          this._cdr.markForCheck()
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }

  private loadSubjects() {
    this._mpSurfService
      .load({}, 'products/subjects')
      .pipe(untilDestroyed(this))
      .subscribe(
        (response) => {
          //@ts-ignore
          this.subjectsList = response.map(item => ({name: item, code: item}));
          this._cdr.markForCheck()
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }

  private loadCategories(): void {
    this._mpSurfService
      .load({}, 'products/category-pnl-list')
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: string[]) => {
          this.categoriesList = response.map(r => ({name: r}));
        },
    );
  }

  private getArticles(selections: { code: string, name: string}[]): string[] | number[] {
    if(this.isOzon) {
      return Array.isArray(selections) ?  selections.map(item => item.name.split(' - ')[1]) : []
    }
    return Array.isArray(selections) ?  selections.map(item => +item.code) : []
  }

  onCheckboxChange(event: {checked: boolean}): void {
    this.checked = event.checked
    this.loadPNLList()
  }

  onApiRealizationCheckboxChange(event: {checked: boolean}): void {
    this.apiRealizationChecked = event.checked
    if(this.apiRealizationChecked){
      this.loadPNLList();
    }
  }

  onClearCategory(): void {
    this.selectedCategories = [];
    this.loadPNLList();
  };

  selectCategory(): void {
    this.loadPNLList();
  }

  onOpenChange(isOpened: boolean, dropRef: NgbDropdown, type: 'start' | 'end'): void {
    if(!isOpened) {
      this.loadPNLList();
    }
    if(type === 'start') {
      if(dropRef.isOpen()){
        this.showStartPlaceholder = true
      } else{
        this.showStartPlaceholder = false
      }
    }
    if(type === 'end') {
      if(dropRef.isOpen()){
        this.showEndPlaceholder = true
      } else{
        this.showEndPlaceholder = false
      }
    }
  }
}