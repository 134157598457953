import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'logoPipe'
})
export class LogoPipe implements PipeTransform {

  transform(value: string): string {
    switch (value) {
      case 'wb':
        return '/assets/images/wb-logo.jpg';
      case 'ozon':
        return 'assets/images/ozon-logo.webp';
      default:
        return 'assets/images/ozon-logo.webp';
    }
  }

}