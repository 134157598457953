import { ChartOptions } from "../myorders/myorders.mock";
import { ThousandSeparator } from "../../../shared/utils/functions/number-space-formatter";

export function GenerateSalesChartData(data: any): Partial<ChartOptions> {
    return {
        series: [
            {name: 'Продажи шт.', type: 'column', data: data.sales_qty},
            {name: 'Возвраты шт.', type: 'column', data: data.sales_return_qty},
            {name: 'Продажи ₽', type: 'line', data: data.sales_money},
        ],
        chart: {
            type: 'area', height: 480, stacked: false, animations: {
                enabled: true,
            },
        },
        dataLabels: {enabled: false},
        //@ts-ignore
        colors: ['#1A74E8', '#B33824', '#B22884'],
        stroke: {curve: 'smooth'},
        legend: {horizontalAlign: 'left', offsetX: 40},
        xaxis: {type: 'category', categories: data.category},
        yaxis: [
            {
                seriesName: 'Продажи шт.',
                floating: false,
                decimalsInFloat: 1,
                forceNiceScale: true,
                tickAmount: 3,
                min: 0,
                labels: {
                    formatter: function (value: number): string {
                        return ThousandSeparator(Math.floor(value));
                    },
                },
            },
            {
                seriesName: 'Продажи шт.',
                show: false
            },
            {
                seriesName: 'Продажи ₽',
                forceNiceScale: true,
                floating: false,
                opposite: true,
                labels: {
                    formatter: function (value: number): string {
                        return ThousandSeparator(Math.floor(value));
                    },
                },
            },
        ],
    };
}

export const YAXIS_RETURNS = [
    {
        seriesName: 'Возвраты шт.',
        floating: false,
        decimalsInFloat: 1,
        forceNiceScale: true,
        tickAmount: 4,
        min: 0,
        labels: {
            formatter: function (value: number): string {
                return ThousandSeparator(Math.floor(value));
            },
        },
    },
]