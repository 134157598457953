<div class="col-sm-6 col-md-8">
  <div class="mb-3 card text-white card-body bg-warning">
    <h5 class="card-title">
      {{headerTitle}}
    </h5>

    <span>
      {{barText}}
    </span>

    <ng-content></ng-content>
  </div>
  <br />
</div>

