import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import { ChartComponent } from 'ng-apexcharts';
import { ChartOptions } from '../myorders/myorders.mock';
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import { ColGroupDef } from '@ag-grid-community/core';
// Todo Api
import { IOlympicData } from './indicators2.data';
import { ApiService } from 'app/shared/services/api.service';

@Component({
  selector: 'app-indicators2',
  templateUrl: './indicators2.component.html',
  styleUrls: ['./indicators2.component.scss'],
})
export class Indicators2Component implements OnInit {
  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;
  heading = 'Gauges';
  icon = 'pe-7s-car icon-gradient bg-warm-flame';

  // Todo API
  // dataFinance = DataFinance;
  // dataChart = DataChart;
  dataFinance;
  dataChart;
  titleDataFinance = ['ВЧЕРА', 'СЕГОДНЯ', 'НЕДЕЛЯ'];

  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  alwaysShowCalendars: boolean;
  ranges: any = {
    Сегодня: [moment(), moment()],
    Вчера: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'За 7 дней': [moment().subtract(6, 'days'), moment()],
    'За 30 дней': [moment().subtract(29, 'days'), moment()],
    'Этот месяц': [moment().startOf('month'), moment().endOf('month')],
    'Прошлая неделя': [
      moment().subtract(1, 'weeks').startOf('isoWeek'),
      moment().subtract(1, 'weeks').endOf('isoWeek'),
    ],
    'Прошлый Месяц': [
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month'),
    ],
  };
  invalidDates: moment.Moment[] = [
    moment().add(2, 'days'),
    moment().add(3, 'days'),
    moment().add(5, 'days'),
  ];
  minDate = moment().add(-91, 'days');
  maxDate = moment();
  selected: any = { startDate: moment().add(-14, 'days'), endDate: moment() };
  date_dis: any = false; // для отключения даты если идет загрузка данных
  control_id: any;
  dataperiod: any = 1;
  page = 1;
  items_count_avg: any;
  items: any;
  items_count: any;
  selectedFBOFBS: any = 1;
  order_money_total: any = 0;
  order_qty_total: any = 0;
  order_cancel_total: any = 0;
  visible_btn: any = 1;
  loading: boolean = true;
  // для фильтра
  nmid: any;
  selectedNm: any;
  percent_cancel: any = 0;
  shop_id: any;
  fbofbs: any = [
    { name: 'Все', value: 1 },
    { name: 'FBO', value: 2 },
    { name: 'FBS', value: 3 },
  ];
  // ---------------------ag-grid-----------------------------
  public columnDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: '',
      children: [{ headerName: '', field: 'category', width: 140 }],
    },
    {
      headerName: 'План',
      children: [
        { headerName: 'из СПП', field: 'from_spp', width: 140 },
        { headerName: 'Ввести вручную', field: 'enter_manually', width: 140 },
        { headerName: 'План итого', field: 'plan_total', width: 140 },
      ],
    },

    {
      headerName: 'Факт',
      children: [
        { headerName: 'руб', field: 'fact_rub', width: 140 },
        { headerName: '%', field: 'fact_percent', width: 140 },
        { headerName: '', field: 'fact_status', width: 140 },
      ],
    },
    {
      headerName: 'Прогноз',
      children: [
        { headerName: 'руб', field: 'forecast_rub', width: 140 },
        { headerName: '%', field: 'forecast_percent', width: 140 },
        { headerName: '', field: 'forecast_status', width: 140 },
      ],
    },
  ];
  public defaultColDef: ColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    editable: true,
  };
  public rowData!: IOlympicData[];

  constructor(
    private http: HttpClient,
    private api: ApiService
  ) {}

  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some(d => d.isSame(m, 'day'));
  };

  onGridReady(params: GridReadyEvent<IOlympicData>) {
    // Todo API
    // this.rowData = rowDataGrid;
  }

  ngOnInit(): void {
    let data_shop = localStorage.getItem('mpall_shop');
    let new_data_shop = JSON.parse(data_shop);
    this.shop_id = new_data_shop.shop;

    this.getAllIndicators2();
  }

  getAllIndicators2() {
    const params = {
      startDate: this.selected.startDate.format('YYYY-MM-DD'),
      endDate: this.selected.endDate.format('YYYY-MM-DD'),
      shop_id: this.shop_id,
      buyout: this.visible_btn,
    };
    this.api
      .userRegister(
        'getDBindicatorDiagrams',
        localStorage.getItem('token'),
        params
      )
      .subscribe((res: any) => {
        this.dataFinance = res.data;
      });

    this.api
      .userRegister('getDBindicatorGrid', localStorage.getItem('token'), params)
      .subscribe((res: any) => {
        this.rowData = res.data;
      });

    this.api
      .userRegister(
        'getDBindicatorChart',
        localStorage.getItem('token'),
        params
      )
      .subscribe((res: any) => {
        this.dataChart = res.data;
      });
  }

  // (cellValueChanged)="onCellValueChanged($event)"
  // (rowSelected)="onRowSelected($event)"
  // (selectionChanged)="onSelectionChanged($event)"

  onCellValueChanged(e) {
    console.log(this.rowData, e);
  }

  onRowSelected(e) {
    console.log(this.rowData, e);
  }

  onSelectionChanged(e) {
    console.log(this.rowData, e);
  }

  getDataAgv(period, shop_id) {
    let body = {
      period: period,
      startDate: this.selected.startDate.format('YYYY-MM-DD'),
      endDate: this.selected.endDate.format('YYYY-MM-DD'),
      shop_id: shop_id,
    };

    this.api
      .userRegister('`getDBindicatorGrid`', localStorage.getItem('token'), body)
      .subscribe();
  }

  onPageChange(pageNum: number) {
    var hidden;
    this.loading = true;
    this.visible_btn == 1 ? (hidden = 0) : (hidden = 1);
    let body = {
      currentPage: pageNum,
      cancel: hidden,
      barcode: this.selectedNm,
      fbofbs: this.selectedFBOFBS,
    };
    this._loaddata(body);
  }

  // получаем списко баркодов и артикулов за выбранный период
  getBarcodeNmid() {
    var b = moment(this.selected.startDate.format('YYYY-MM-DD'));
    var a = moment(this.selected.endDate.format('YYYY-MM-DD'));
    var res = a.diff(b, 'days'); // 1

    let body = {
      days: this.dataperiod,
      startDate: this.selected.startDate.format('YYYY-MM-DD'),
      endDate: this.selected.endDate.format('YYYY-MM-DD'),
      shop_id: this.shop_id,
    };
    return new Promise((resolve, reject) => {
      this.api
        .userRegister('getOrdersNm', localStorage.getItem('token'), body)
        .subscribe(
          async (data: any) => {
            this.nmid = data.data;
            this.items_count = data.count;

            if (res == 0) {
              data.count == '0'
                ? (this.items_count_avg = 0)
                : (this.items_count_avg = Math.round(data.count / 1));
            } else {
              this.items_count_avg = Math.round(data.count / a.diff(b, 'days'));
            }

            resolve(true);
          },
          err => {
            console.log(err);
            //reject(err);
          }
        );
    });
  }

  newValue() {
    //блокируем выбор даты когда идет загрузка
    // this.getBarcodeNmid();
    // this.visible_btn = 1;
    // this.loading = true;
    // this.getOrders();
    this.getAllIndicators2();
  }

  view_only_cancel(hidden: any) {
    this.loading = true;
    hidden == 1 ? (this.visible_btn = 0) : (this.visible_btn = 1);
    let body = {
      cancel: hidden,
      barcode: this.selectedNm,
      fbofbs: this.selectedFBOFBS,
    };
    this.page = 1;
    this._loaddata(body);
  }

  getOrders() {
    var hidden;
    this.loading = true;
    this.visible_btn == 1 ? (hidden = 0) : (hidden = 1);
    let body = {
      cancel: hidden,
      barcode: this.selectedNm,
      fbofbs: this.selectedFBOFBS,
    };
    this._loaddata(body);
  }

  // получить данные по породажам за опередленный период
  getChart(params = null) {
    let body = {
      startDate: this.selected.startDate.format('YYYY-MM-DD'),
      endDate: this.selected.endDate.format('YYYY-MM-DD'),
      shop_id: this.shop_id,
      params,
    };
    return new Promise((resolve, reject) => {
      this.api
        .userRegister('getChartOrders', localStorage.getItem('token'), body)
        .subscribe(
          async (data: any) => {
            this.order_money_total = data.order_money_total;
            this.order_qty_total = data.order_qty_total;
            this.order_cancel_total = data.order_cancel_total;
            if (this.order_qty_total == 0) {
              this.percent_cancel = 0;
            } else {
              this.percent_cancel = Number(
                (this.order_cancel_total * 100) / this.order_qty_total
              ).toFixed(2);
            }
            ////////////////////////////////////////////////////////////////////////
            // подгружаем данные для графика
            this.chartOptions = {
              series: [
                { name: 'Заказы ₽', type: 'line', data: data.order_money },
                { name: 'Заказы шт.', type: 'column', data: data.order_qty },
              ],
              chart: {
                type: 'area',
                height: 250,
                stacked: false,
                animations: { enabled: true },
              },
              dataLabels: { enabled: false },
              legend: { horizontalAlign: 'left', offsetX: 40 },
              xaxis: { type: 'category', categories: data.category },
              yaxis: [
                {
                  seriesName: 'Column A',
                  floating: false,
                  forceNiceScale: true,
                  tickAmount: 6,
                  axisTicks: { show: true },
                  axisBorder: { show: true },
                },
                {
                  floating: false,
                  forceNiceScale: true,
                  tickAmount: 6,
                  show: true,
                },
              ],
            };
            resolve(true);
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  // фильтра для артикула
  selectedNmChange(obj: any) {
    var hidden;
    this.loading = true;
    this.visible_btn == 1 ? (hidden = 0) : (hidden = 1);
    let body = {
      barcode: obj.value,
      cancel: hidden,
      fbofbs: this.selectedFBOFBS,
    };
    this._loaddata(body);
  }

  selectedFBOFBSChange(obj: any) {
    var hidden;
    this.loading = true;
    this.visible_btn == 1 ? (hidden = 0) : (hidden = 1);
    let body = { barcode: this.selectedNm, cancel: hidden, fbofbs: obj.value };
    this._loaddata(body);
  }

  ///   ЗАГРУЗКА ДАННЫХ
  _loaddata(params = null) {
    this.date_dis = true;
    var b = moment(this.selected.startDate.format('YYYY-MM-DD'));
    var a = moment(this.selected.endDate.format('YYYY-MM-DD'));
    var res = a.diff(b, 'days'); // 1

    let body = {
      startDate: this.selected.startDate.format('YYYY-MM-DD'),
      endDate: this.selected.endDate.format('YYYY-MM-DD'),
      shop_id: this.shop_id,
      params,
    };
    return new Promise((resolve, reject) => {
      this.api
        .userRegister('getOrders', localStorage.getItem('token'), body)
        .subscribe(
          async (data: any) => {
            this.items = data.data;
            this.items_count = data.count;
            this.getChart({
              cancel: this.visible_btn,
              barcode: this.selectedNm,
            });
            if (res == 0) {
              data.count == '0'
                ? (this.items_count_avg = 0)
                : (this.items_count_avg = Math.round(data.count / 1));
            } else {
              this.items_count_avg = Math.round(data.count / a.diff(b, 'days'));
            }

            this.loading = false;
            this.date_dis = false;
            resolve(true);
          },
          err => {
            console.log(err);
          }
        );
    });
  }
}
